import React, { forwardRef, ReactNode } from 'react'
import MUITypography, { TypographyProps } from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'

import styles from './styles'

export interface Props
  extends TypographyProps {
  children?: ReactNode
  noWrap?: boolean
  size?: 'xsmall' | 'small' | 'medium' | 'large' | 'xlarge'
  weight?: 'normal' | 'bold'
}

const useStyles = makeStyles(styles, {
  name: 'Typography'
})

export const Typography = forwardRef<HTMLElement, Props>(function Typography(
  {
    children,
    ...rest
  },
  ref
) {
  const classes = useStyles(rest)

  return (
    <MUITypography
      {...rest}
      component='span'
      className={classes.root}
      ref={ref}
    >
      {children}
    </MUITypography>
  )
})

Typography.defaultProps = {
  size: 'medium'
}

export default Typography
