import React from 'react'
import { Grid, Box, Slide } from '@material-ui/core'
import { TransitionProps } from '@material-ui/core/transitions'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'
import MailOutlineIcon from '@material-ui/icons/MailOutline'

import { sendEmail } from '~/components/utils/contacts'
import segment from '~/services/segment'
import Modal from '~/components/core/Modal'
import useCopyToClipboard from '~/hooks/useCopyToClipboard'
import Button from '~/components/core/Button'
import Typography from '~/components/core/Typography'
import * as S from './styles'

export type TrackEvent = {
  trackName: string
  payload: {
    [key: string]: unknown
  }
}

export interface Props {
  onClose: () => void
  beforeClose?: () => void
  isOpen: boolean
  email?: string
  text?: string
  trackEvent?: TrackEvent
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Slide direction='up' ref={ref} {...props} />;
});

const useStyles = makeStyles(S.MySearchesListStyled)

const EmailContactModal: React.FC<Props> = ({ onClose, isOpen, email, text, trackEvent, beforeClose }) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const [copyToClipboard] = useCopyToClipboard()

  const sendTrack = () => {
    if (trackEvent?.trackName) {
      segment.track(trackEvent.trackName, trackEvent?.payload)
    }
  }

  const onSendEmail = () => {
    sendEmail(email || '', text || '')
    sendTrack()
    handleClose()
  }

  const handleOnClickCopy = () => {
    copyToClipboard(email || '')
    sendTrack()
    handleClose()
  }

  const handleClose = () => {
    beforeClose?.()
    onClose()
  }

  return (
    <Modal open={isOpen} TransitionComponent={Transition} onBackdropClick={handleClose}>
      <Modal.Title onClose={handleClose}>
        <Typography size='large'>
          {t('emailContactTitle')}
        </Typography>
      </Modal.Title>
      <Modal.Content>
        <Grid container spacing={2} direction='column' justify='center'>
          <Grid item container justify='center'>
            <Button variant='outlined' startIcon={<MailOutlineIcon color='primary' style={{ fontSize: 28 }} />} onClick={() => onSendEmail()}>
              <Typography size='large'>
                {t('sendEmail')}
              </Typography>
            </Button>
          </Grid>
          <Grid item>
            <Box width='100%' display='flex' justifyContent='center'>
              <Typography>
                {t('or')}
              </Typography>
            </Box>
          </Grid>
          <Grid item>
            <Box width='100%' border='1px solid' borderRadius={6} padding={1} marginTop={1}>
              <Grid container spacing={1} wrap='nowrap'>
                <Grid item>
                  <Button size='small' color='default' variant='outlined' onClick={handleOnClickCopy}>
                    <Typography size='small'>
                      {t('copy')}
                    </Typography>
                  </Button>
                </Grid>
                <Grid item container alignItems='center' className={classes.textUrl}>
                  <Typography>
                    {email}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Modal.Content>
    </Modal>
  )
}

export default EmailContactModal
