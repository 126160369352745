import React from 'react'
import { Grid } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { Link, Redirect, useLocation } from 'react-router-dom'

import { ViewerProvider } from '~/components/core/ViewerProvider/context'
import CustomButton from '~/components/core/CustomButton'
import Typography from '~/components/core/Typography'
import { getCdnEndpoint } from '~/components/utils/contacts'

// eslint-disable-next-line complexity
const MainHall: React.FC = () => {
  const { hash } = useLocation()
  const { assets, searches } = React.useContext(ViewerProvider)
  const { t } = useTranslation()

  if (hash) {
    return <Redirect to={hash.split('#')[1]} />
  }

  if (assets?.length) {
      return <Redirect to={`/seller/asset/${assets[0].id}/leads`} />
  }

  if (searches?.length) {
      return <Redirect to={`/buyer/search/${searches[0].id}`} />
  }

  return (
    <Grid component='div' container justify='center' direction='column' alignItems='center' style={{ padding: '80px 10px' }}>
      <Grid item style={{ padding: '32px 0' }}>
        <Typography size='xlarge' weight='bold' color='textPrimary'>{t('main-startTitle')}</Typography>
      </Grid>
      <Grid item style={{ padding: '32px 0' }}>
        <Grid container wrap='nowrap' justify='center' alignItems='center' spacing={4}>
          <Grid item>
            <Link color='inherit' to='/seller/postAsset' style={{ textDecoration: 'none' }}>
              <CustomButton title={t('postNewAsset')} image={getCdnEndpoint('seller-main-hall.jpg')} />
            </Link>
          </Grid>
          <Grid item>
            <Link color='inherit' to='/buyer/postSearch' style={{ textDecoration: 'none' }}>
              <CustomButton title={t('postNewSearch')} image={getCdnEndpoint('vector-search.jpg')} />
            </Link>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default MainHall
