import React from 'react'
import useFetch from 'use-http'
import FeedbackIcon from '@material-ui/icons/Feedback'
import Grid from '@material-ui/core/Grid'
import { useTranslation } from 'react-i18next'

import Button from '~/components/core/Button'
import Typography from '~/components/core/Typography'

export interface Props {
  onSubmit: () => void
  userId?: string | null
  searchId?: string
  assetId?: string
}

const UserFeedback: React.FC<Props> = ({
  onSubmit,
  userId,
  searchId,
  assetId
}) => {
  const { t } = useTranslation()
  const getUrl = (val: 'yes' | 'no'): string => {
    if (assetId) {
      return `api/feedbacks/websiteResponses/deleteProperty?refId=${assetId}&userResponse=${val}`
    }

    if (searchId) {
      return `api/feedbacks/websiteResponses/deletedAllSearches?refId=${searchId}&userResponse=${val}`
    }

    if (userId) {
      return `api/feedbacks/generalResponses/deletedUser?refId=${userId}&userResponse=${val}&userId=${userId}`
    }

    return ''
  }

  const getText = (): string => {
    if (assetId) {
      return 'sellerRemovedProperty'
    }

    if (searchId) {
      return 'removedAllSearches'
    }

    if (userId) {
      return 'userSelfRemove'
    }

    return ''
  }

  const { get: sendFeedback } = useFetch()
  const handleSubmitVote = async (val: 'yes' | 'no') => {
    await sendFeedback(getUrl(val))

    onSubmit()
  }

  return (
    <Grid
      direction='column'
      alignItems='center'
      spacing={6}
      style={{ marginTop: '40px' }}
      container
    >
      <Grid item>
        <FeedbackIcon htmlColor='#4BB5AF' fontSize='large' />
      </Grid>
      <Grid item>
        <Typography size='xlarge'>
          {t(`vote-${getText()}`)}
        </Typography>
      </Grid>
      <Grid item>
        <Grid
          direction='row'
          alignItems='center'
          container
          spacing={4}
        >
          <Grid item>
            <Button variant='outlined' color='primary' size='medium' onClick={() => handleSubmitVote('yes')}>
              <Typography>
                {t('vote-yes')}
              </Typography>
            </Button>
          </Grid>
          <Grid item>
            <Button variant='outlined' color='primary' size='medium' onClick={() => handleSubmitVote('no')}>
              <Typography>
                {t('vote-no')}
              </Typography>
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default UserFeedback
