import React from 'react'
import CheckIcon from '@material-ui/icons/Check'
import ClearIcon from '@material-ui/icons/Clear'

import AssetDetailRawField from '~/components/modules/AssetDetailRawField'

export interface Props {
  label: string
  value?: boolean
  textVal?: string
  icon?: React.ReactNode
}

const AssetBooleanDetailRawField: React.FC<Props> = ({ label, value, icon, textVal }) => {
  const RelevantIcon = value ? CheckIcon : ClearIcon

  return (
    <AssetDetailRawField label={label} icon={icon} value={(typeof textVal === 'string' && textVal) || <RelevantIcon fontSize='small' />} />
  )
}

export default AssetBooleanDetailRawField
