import React, { SVGProps } from 'react'
const Spam = (props: SVGProps<SVGSVGElement>): JSX.Element => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={126}
    height={113}
    fill='none'
    {...props}
    color={props.color || '#E087B1'}
  >
    <path
      fill='currentcolor'
      d='M125.014 43.672v56.375c0 6.875-5.625 12.5-12.5 12.5h-100c-6.875 0-12.5-5.625-12.5-12.5l.062-75c0-6.875 5.563-12.5 12.438-12.5h63.125c-.375 2-.625 4.125-.625 6.25s.25 4.25.625 6.25H12.514l50 31.25L85.45 41.984c2.938 2.688 6.375 4.75 10.188 6.125L62.514 68.797l-50-31.25v62.5h100V49.422c4.625-.938 8.875-3 12.5-5.75Zm-37.5-24.875c0 10.375 8.375 18.75 18.75 18.75s18.75-8.375 18.75-18.75-8.375-18.75-18.75-18.75-18.75 8.375-18.75 18.75Z'
    />
  </svg>
)

export default Spam
