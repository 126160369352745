import LogRocket from 'logrocket'

export default {
  initialize(user = {}) {
    if (process.env.NODE_ENV !== 'development') {
      LogRocket.init('9seheu/hoome')

      LogRocket.identify(user.uid, {
        name: user.displayName,
        email: user.email
      });
    }
  }
}
