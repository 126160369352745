import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { ClickAwayListener, Grow } from '@material-ui/core'
import Popper from '@material-ui/core/Popper'

import * as S from './styles'

const useStyles = makeStyles(S.AssetCardStyled)

export interface Props {
  initOpen?: boolean
  onClose?: () => void
  open: boolean
  anchorRef?: React.RefObject<HTMLDivElement>
  children: React.ReactNode
}

const MarkerSearchPopper: React.FC<Props> = ({
  onClose = () => {}, anchorRef, children, open
}) => {
  const classes = useStyles()

  const handleClose = (event: React.MouseEvent<EventTarget>) => {
    if (anchorRef?.current && anchorRef?.current?.contains(event.target as HTMLElement)) {
      return
    }

    event.stopPropagation()
    onClose()
  }

  const handleListKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Tab') {
      event.preventDefault()
      onClose()
    }
  }

  return (
    <Popper className={classes.root} open={open} anchorEl={anchorRef?.current} role={undefined} transition disablePortal placement='top'>
      {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{ transformOrigin: placement === 'top' ? 'top top' : 'center bottom' }}
            >
              <ClickAwayListener onClickAway={handleClose}>
                <div onKeyDown={handleListKeyDown}>
                  {children}
                </div>
              </ClickAwayListener>
            </Grow>
      )}
    </Popper>

  )
}

export default MarkerSearchPopper
