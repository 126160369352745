import React from 'react'
import Backdrop from '@material-ui/core/Backdrop'
import CircularProgress from '@material-ui/core/CircularProgress'
import { makeStyles } from '@material-ui/core/styles'

import * as S from './styles'

export interface Props {
  open: boolean
  invisible?: boolean
}

const useStyles = makeStyles(S.StyledLoader)

const PageLoader: React.FC<Props> = ({
  open, invisible
}) => {
  const classes = useStyles()

  return (
    <Backdrop invisible={invisible} className={classes.backdrop} open={open}>
      <CircularProgress color='primary' />
    </Backdrop>
  )
}

export default PageLoader
