import React from 'react'

import { ViewerProvider } from '~/components/core/ViewerProvider/context'
import useSelectedSearch from '~/hooks/useSelectedSearch'

export default function useResetNewSearchLeads(): [(id?: string) => void] {
  const [currentSearch] = useSelectedSearch()
  const { resetSearchNewLeads } = React.useContext(ViewerProvider)

  const resetLeads = (id?: string) => {
    resetSearchNewLeads(id || currentSearch?.id || '')
  }

  return [resetLeads]
}
