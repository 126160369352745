import { createStyles } from '@material-ui/core/styles'

export const SellerTipsStyled = createStyles({
  root: {
    width: '100%',
    margin: 0,
    padding: 0,
    paddingBottom: '2rem',
    position: 'relative'
  },
  content: {
    position: 'relative'
  },
  sticky: {
    padding: 0,
    zIndex: 2,
    width: '100%',
    color: 'inherit'
  }
})
