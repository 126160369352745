import React from 'react'
import { useParams } from 'react-router-dom'
import { Grid } from '@material-ui/core'

import { useBreakpoint } from '~/utils/breakpoints'
import SellerTips from '~/components/modules/SellerTips'
import SellerTipsFab from '~/components/modules/SellerTips/fabButton'

interface Props {
  customPattern?: string
}

const AssetLeadsTips = ({
  customPattern
}: Props): JSX.Element => {
  const { id } = useParams<{ id?: string }>()
  const isCompactLayout = useBreakpoint(['small', 'medium'])
  const tipsPatten = customPattern ?? 'tipsText.seller.leads'

  if (isCompactLayout) {
    return (
      <SellerTipsFab pattern={tipsPatten} />
    )
  }

  return (
    <Grid item container sm={4} style={{ flex: 1, position: 'relative' }}>
      <SellerTips sticky id={id} pattern={tipsPatten} />
    </Grid>
  )
}

export default AssetLeadsTips
