import { createStyles } from '@material-ui/core/styles'
import { LinkProps } from 'react-router-dom'

import palette from '~/ThemeProvider/config/palette'

const getColor = (props: LinkProps) => {
  if (props.color === 'primary') {
    return palette.primary.main
  }

  if (props.color === 'secondary') {
    return palette.secondary.main
  }

  return 'inherit'
}

export const StyledLink = createStyles({
  root: {
    '&:visited': {
      color: palette.common.black
    },
    color: 'inherit',
    textDecorationColor: (props: LinkProps) => getColor(props)
  }
})
