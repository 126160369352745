/* eslint-disable complexity */
// import React from 'react'
import { isUndefined, inRange } from 'lodash'
import { useTranslation } from 'react-i18next'

import { AssetFormValidation, AssetFormData } from '~/types/Asset'

export const FORBIDEN_CHARS = ['$', '&&', '||', '<', '>', '\\script', '\\style', 'onerror']

export default function useAssetFormValidation(isEdit?: boolean): [(data: AssetFormData) => AssetFormValidation] {
  const { t } = useTranslation()

  // eslint-disable-next-line complexity
  // eslint-disable-next-line max-statements
  const validateForm = (data: AssetFormData): AssetFormValidation => {
    let vlidationsError = {}

    if (isUndefined(data.postType)) {
      vlidationsError = {
        ...vlidationsError,
        postType: t('errorMsgRequiredField')
      }
    }

    if (!isEdit && (!data.address || !data.address.name)) {
      vlidationsError = {
        ...vlidationsError,
        address: t('errorMsgRequiredField')
      }
    }

    if (isUndefined(data.contactNumber) || data.contactNumber === '') {
      vlidationsError = {
        ...vlidationsError,
        contactNumber: t('errorMsgRequiredField')
      }
    }

    if (isUndefined(data.price)) {
      vlidationsError = {
        ...vlidationsError,
        price: t('errorMsgRequiredField')
      }
    }

    if (isUndefined(data.squareSize)) {
      vlidationsError = {
        ...vlidationsError,
        squareSize: t('errorMsgRequiredField')
      }
    }

    if (isUndefined(data.rooms)) {
      vlidationsError = {
        ...vlidationsError,
        rooms: t('errorMsgRequiredField')
      }
    }

    if (isUndefined(data.isAgent)) {
      vlidationsError = {
        ...vlidationsError,
        isAgent: t('errorMsgRequiredField')
      }
    }

    if (isUndefined(data.remodel)) {
      vlidationsError = {
        ...vlidationsError,
        remodel: t('errorMsgRequiredField')
      }
    }

    if (isUndefined(data.view)) {
      vlidationsError = {
        ...vlidationsError,
        view: t('errorMsgRequiredField')
      }
    }

    if (isUndefined(data.floor)) {
      vlidationsError = {
        ...vlidationsError,
        floor: t('errorMsgRequiredField')
      }
    }

    if (inRange(data.contactNumber?.length, 1, 10)) {
      vlidationsError = {
        ...vlidationsError,
        contactNumber: t('errorMsg-wrongPhoneNumber')
      }
    }

    if (FORBIDEN_CHARS.filter((char) => data.description.includes(char)).length) {
      vlidationsError = {
        ...vlidationsError,
        description: t('error-ilagalChars')
      }
    }

    return vlidationsError
  }

  return [validateForm]
}
