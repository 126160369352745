import { createStyles, Theme } from '@material-ui/core/styles'

// import palette from '~/ThemeProvider/config/palette'

export const LeadListtyled = (theme: Theme) =>
createStyles({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper
  },
  listTitle: {
    justifyContent: 'center'
  },
  sticky: {
    padding: 0,
    zIndex: 2
  }
})
