import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Grid } from '@material-ui/core'
import ListSubheader from '@material-ui/core/ListSubheader'
import { useTranslation } from 'react-i18next'

import useHeightHelper from '~/hooks/useHeightHelper'
import TipItem from './TipItem'
import TipsTitle from './TipsTitle'
import * as S from './styles'

const INTERVAL_TIPS = 30000

const useStyles = makeStyles(S.SellerTipsStyled)

const getRandomNumber = (transLength = 15): number => {
  return Math.floor(Math.random() * (transLength - 0 + 1)) + 0
}

const getKeysForTips = (numArr: number[], transLength?: number): number[] => {
  const num = getRandomNumber(transLength)

  if (numArr.length === 4) {
    return numArr
  }

  if (!numArr.includes(num)) {
    numArr.push(num)
  }

  return getKeysForTips(numArr, transLength)
}

export interface Props {
  sticky?: boolean
  id?: string
  pattern?: string
  hideTitle?: boolean
}

let intervalRef: (null | NodeJS.Timeout) = null

const SellerTips: React.FC<Props> = ({ sticky, id, pattern, hideTitle }) => {
  const classes = useStyles()
  const [keysText, setKeysText] = useState<number[]>([])
  const heightHelperRef = React.useRef<HTMLDivElement>(null)
  const [sizes] = useHeightHelper(heightHelperRef)
  const { t } = useTranslation()
  const partsObj = pattern ? t(`${pattern}`, { returnObjects: true }) as { [key: string]: string; } : undefined

  const loadTips = () => {
    const keys = getKeysForTips([], partsObj ? Object.keys(partsObj).length - 1 : undefined)

    setKeysText(keys)
    intervalTips()
  }

  const intervalTips = () => {
    if (!intervalRef) {
      intervalRef = setInterval(() => {
        loadTips()
      }, INTERVAL_TIPS)
    }
  }

  const cleanIntervalTips = () => {
    if (intervalRef) {
      clearInterval(intervalRef)
      intervalRef = null
    }
  }

  useEffect(() => {
    cleanIntervalTips()
    loadTips()

    return () => {
      cleanIntervalTips()
    }
  }, [id, pattern])

  if (sticky) {
    return (
      <>
        <div id='heightHelper' style={{ height: '100vh', position: 'absolute' }} ref={heightHelperRef} />
        <ListSubheader className={classes.sticky} style={{ minHeight: sizes?.height || 0, height: 'fit-content' }}>
          <Grid container spacing={4} style={{ padding: '12px', marginTop: '40px', position: 'relative' }}>
            <TipsTitle hide={hideTitle} />
            {
              keysText.map((keyText) => (
                <Grid key={keyText} item container>
                  <TipItem textKey={`${pattern ? `${pattern}.` : 'tips-'}${keyText.toString()}`} />
                </Grid>
              ))
            }
          </Grid>
        </ListSubheader>
      </>
    )
  }

  return (
    <>
    <Grid container spacing={4} style={{ padding: '12px' }}>
      <TipsTitle hide={hideTitle} />
      {
        keysText.map((keyText) => (
          <Grid key={keyText} item container>
            <TipItem textKey={`${pattern ? `${pattern}.` : 'tips-'}${keyText.toString()}`} />
          </Grid>
        ))
      }
    </Grid>
    </>
  )
}

export default SellerTips
