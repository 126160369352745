// import React from 'react'
import { isUndefined, inRange } from 'lodash'
import { useTranslation } from 'react-i18next'

import { SearchFormValidation, SearchFormData, AdvangedSearch } from '~/types/Search'
import { REQUIERED_ADVANCED_FIELDS } from '~/components/modules/PostSearchForm/constants'
import { FORBIDEN_CHARS } from '~/hooks/useAssetFormValidation'

export const getAdvancedFields = (data: SearchFormData): AdvangedSearch => {
  const {
    balcony, elevator,
    floor, furnished,
    garden, hadicap,
    panthouse, parking,
    remodel, safeRoom,
    storage, view
  } = data

  return {
    balcony,
    elevator,
    floor,
    furnished,
    garden,
    hadicap,
    panthouse,
    parking,
    remodel,
    safeRoom,
    storage,
    view
  }
}

export const isNotAdvencedFull = (data: AdvangedSearch): boolean => {
  return REQUIERED_ADVANCED_FIELDS.some((field) => isUndefined((data as any)[field]?.importance))
}

export const getEmptyRequiredAdvancedFields = (data: SearchFormData): string[] => {
  const advancedFields = getAdvancedFields(data)

  return REQUIERED_ADVANCED_FIELDS.filter((field) => isUndefined((advancedFields as any)[field]?.importance))
}

export const validateIsAdvancedFieldsNotFull = (data: SearchFormData): boolean => {
  const advancedFields = getAdvancedFields(data)

  return isNotAdvencedFull(advancedFields)
}

export default function useSearchFormValidation(isAdvanced?: boolean): [(data: SearchFormData) => SearchFormValidation] {
  const { t } = useTranslation()

  // eslint-disable-next-line complexity
  const validateForm = (data: SearchFormData): SearchFormValidation => {
    let vlidationsError = {}

    if (isUndefined(data.searchType)) {
      vlidationsError = {
        ...vlidationsError,
        searchType: t('errorMsgRequiredField')
      }
    }

    if (!data.city || !data.city.name) {
      vlidationsError = {
        ...vlidationsError,
        city: t('errorMsgRequiredField')
      }
    }

    // if (isUndefined(data.communications)) {
    //   vlidationsError = {
    //     ...vlidationsError,
    //     communications: t('errorMsgRequiredField')
    //   }
    // }

    if (isUndefined(data.budget.max)) {
      vlidationsError = {
        ...vlidationsError,
        budget: t('errorMsg-requaireMax')
      }
    }

    if (!data.contactNumber) {
      vlidationsError = {
        ...vlidationsError,
        contactNumber: t('errorMsgRequiredField')
      }
    }

    if (inRange(data.contactNumber?.length, 1, 10)) {
      vlidationsError = {
        ...vlidationsError,
        contactNumber: t('errorMsg-wrongPhoneNumber')
      }
    }

    if (FORBIDEN_CHARS.filter((char) => data.neighborhoods?.some((neighObj) => neighObj?.name?.includes(char))).length) {
      vlidationsError = {
        ...vlidationsError,
        'neighborhoods[0]': t('error-ilagalChars')
      }
    }

    const advancedFields = getAdvancedFields(data)

    if (isAdvanced && isNotAdvencedFull(advancedFields)) {
      const emptyFields = getEmptyRequiredAdvancedFields(data)

      vlidationsError = {
        ...vlidationsError,
        advanced: 'סמנו את רמת החשיבות בשדות שמסומנים בכוכבית',
        ...emptyFields.reduce((acc, field) => {
          return {
            ...acc,
            [field]: 'שדה חובה'
          }
        }, {})
      }
    }

    return vlidationsError
  }

  return [validateForm]
}
