/* eslint-disable max-statements */
import React, { useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import useFetch, { CachePolicies } from 'use-http'

import { rollbarError } from '~/utils/rollbar'
import EmptySearchResult from '~/components/pages/v3/EmptySearchResult'
import NoDataMessage from '~/components/modules/NoDataMessage'
import AssetsResultsList, { Props as AssetsResultsListProps } from '~/components/modules/AssetsResultsList'
import SearchResultsContanair from '~/components/modules/SearchResultsContanair'
import ScrollToTopButton from '~/components/modules/ScrollToTopButton'
import { MapContext } from '~/components/core/MapProvider/context'
import { ViewerFavoriteContext } from '~/components/core/ViewerFavoriteProvider/context'
import PageLoader from '~/components/core/PageLoader'
import UserFeedbackModal from '~/components/modules/UserFeedback/modal'
import { SearchLead } from '~/types/Search'
import { useModal } from '~/utils/useModal'
import getPaginationOffset from '~/utils/get-pagination-offset'
import { ViewerProvider } from '~/components/core/ViewerProvider/context'
import useAssetDetailsModal from '~/hooks/useAssetDetailsModal'
import usePrevious from '~/hooks/usePrevious'
import useResetNewSearchLeads from '~/hooks/useResetNewSearchLeads'
import { Props as MapMarkersProps } from '~/components/core/MapView/MapMarker'
// import useSpamWarningModal from '~/hooks/useSpamWarningModal'

export type Sorting = {
  type: 'percentage' | 'price'
  ascending: number
}

export const PAGE_SIZE = 20

export const convertToMapMarkers = (data: SearchLead[], searchId?: string, onClickCallBack: (id: string | null) => void = () => {}): MapMarkersProps[] => {
  return data.map((item) => ({
    lng: item.position.coordinates[0],
    lat: item.position.coordinates[1],
    leadObj: { ...item, searchId },
    onLeadCardClick: (obj: any) => {
      if (obj?.leadId) {
        onClickCallBack(obj?.leadId)
      }
    }
  }))
}

// eslint-disable-next-line complexity
const SearchResults: React.FC = () => {
  const { id, page } = useParams<{ id?: string, page?: string }>()
  const pageInt = (isNaN(Number(page))) ? 0 : Number(page)
  const pageOffset = getPaginationOffset(pageInt, PAGE_SIZE)
  const { showModal, hideModal, isOpen } = useModal()
  const [sortBy, setSortBy] = useState<Sorting>({
      type: 'percentage',
      ascending: 0
    })
  // const { setMap = () => {}, setMapMarkers } = React.useContext(MapContext)
  const { setMapMarkers } = React.useContext(MapContext)
  const { loading, error, data, get: refechLeads } = useFetch<{leads: SearchLead[], totalCount: number}>(`/api/v3/searcher/search/${id}/leads?sortBy=${sortBy.type}&ascending=${sortBy.ascending}&from=${pageOffset}&to=${pageOffset + PAGE_SIZE}`,
  {
    cachePolicy: CachePolicies.NO_CACHE,
    onNewData: (_currTodos, newData) => {
      setMapMarkers(convertToMapMarkers(newData.leads, id, setSelectedLeadId))

      return newData
    }
  },
  [id, sortBy.type, sortBy.ascending, page])
  const { delete: deleteSearch, loading: deleteSearchLoading } = useFetch(`/api/searches/${id}`)
  const { post: deleteLeadPost, loading: deleteLeadLoading } = useFetch('api/searches/removeLead')
  const history = useHistory()
  const [assetDetailsModal,, setSelectedLeadId] = useAssetDetailsModal()
  const { refetchSearches, searches } = React.useContext(ViewerProvider)
  const { removeFavoriteState } = React.useContext(ViewerFavoriteContext)
  const prevId = usePrevious(id)
  const [resetLeads] = useResetNewSearchLeads()

  // useSpamWarningModal(!(!data && loading))

  const handleOnSortChange = (type: 'percentage' | 'price') => {
    setSortBy({
      type,
      ascending: (type === sortBy.type) ? Math.abs(sortBy.ascending - 1) : 0
    })
  }

  const handleDeleteSearch = async () => {
    await deleteSearch()
    setMapMarkers([])
    await refetchSearches()

    if (searches?.length === 1) {
      return showModal()
    }

    return history.push('/buyer')
  }

  const handleOnCloseModal = async () => {
    hideModal()

    history.push('/buyer')
  }

  React.useEffect(() => {
    if (prevId && prevId !== id) {
      resetLeads(prevId)
    }
  }, [id])

  const handleDeleteLead = async (leadId?: string) => {
    await deleteLeadPost({ leadId })

    removeFavoriteState(leadId || '')
    refechLeads()
  }

  if (error) {
    rollbarError({
      error
    })

    return (
      <NoDataMessage type='search' />
    )
  }

  if ((!data && loading)) {
    return <PageLoader open invisible />
  }

  if (!data) {
    return null
  }
  const { leads, totalCount } = data

  const items = leads.reduce((res, item) => {
    if (item.isNew) {
      res.new.push({ item })

      return res
    }

    res.common.push({ item })

    return res
  }, {
    new: [] as AssetsResultsListProps['items'],
    common: [] as AssetsResultsListProps['items']
  })

  if (!leads.length) {
    return <EmptySearchResult onDeleteSearch={handleDeleteSearch} />
  }

  return (
    <>
    <SearchResultsContanair onDelete={handleDeleteSearch} onSortChange={handleOnSortChange} sorting={sortBy}>
      <AssetsResultsList totalCount={totalCount} items={[...items.new, ...items.common]} disabled loading={loading} onRemoveLead={handleDeleteLead} onItemClick={setSelectedLeadId} />
    </SearchResultsContanair>
    <ScrollToTopButton />
    <PageLoader open={deleteSearchLoading || deleteLeadLoading} />
    <UserFeedbackModal
      isOpen={isOpen}
      searchId={id}
      onClose={handleOnCloseModal}
      onSubmit={handleOnCloseModal}
    />
    {assetDetailsModal}
    </>
  )
}

export default SearchResults
