import { useState, useEffect } from 'react'

import toBase64 from '~/components/utils/toBase64'

export default function useFilesToImagesString(files: (string | File)[] | FileList): [string[], boolean] {
  const [values, setValues] = useState<string[]>([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const filesArrToBase64 = async () => {
      const arr = []

      for (let index = 0; index < files.length; index++) {
        const element = files[index]

        if (typeof element === 'string') {
          arr.push(element)
        } else {
          const base64 = await toBase64(element)

          arr.push(base64)
        }
      }

      return arr
    }

    setLoading(true)
    filesArrToBase64().then((data) => {
      setLoading(false)
      setValues(data)

      return data
    }).catch((err) => {
      setLoading(false)
      setValues([])

      return err
    })
  }, [files])

  return [values, loading]
}
