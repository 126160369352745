import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import Grid from '@material-ui/core/Grid'
import CardActionArea from '@material-ui/core/CardActionArea'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import Skeleton from '@material-ui/lab/Skeleton'

import * as S from './styles'

const useStyles = makeStyles(S.AssetCardStyled)

const AssetCardLoader: React.FC = () => {
  const classes = useStyles()

  return (
    <Card className={classes.root}>
      <CardActionArea>
        <div style={{ position: 'relative' }} className={classes.media}>
          <Skeleton variant='rect' width='100%' height='100%' />
        </div>
        <CardContent>
          <Grid container alignItems='stretch' justify='space-between'>
            <Grid item>
              <Skeleton animation='wave' width={100} height={20} />
            </Grid>
            <Grid item>
              <Skeleton animation='wave' width={100} height={20} />
            </Grid>
            <Grid item>
              <Skeleton animation='wave' width={100} height={20} />
            </Grid>
          </Grid>
          <Grid container alignItems='center' spacing={1}>
            <Skeleton animation='wave' width={100} height={30} />
          </Grid>
        </CardContent>
      </CardActionArea>
      <CardActions>
        <Skeleton variant='rect' width={56} height={18} />
        <Skeleton variant='rect' width={56} height={18} />
        <Skeleton variant='rect' width={56} height={18} />
      </CardActions>
    </Card>
  )
}

export default AssetCardLoader
