import React, { useCallback } from 'react'

import { useModal } from '~/utils/useModal'
import SearchDealReadinessModal from '../SearchDealReadinessModal'

export const useSearchDealReadinessModal = (onClose: () => void): [JSX.Element, { showModal: () => void }] => {
  const { showModal, hideModal, isOpen } = useModal()

  const handleClose = useCallback(() => {
    hideModal()
    setTimeout(() => {
      onClose()
    }, 10)
  }, [onClose, hideModal])

  const ModalComponent = (
    <SearchDealReadinessModal
      onClose={handleClose}
      isOpen={isOpen}
    />
  )

  return [ModalComponent, { showModal }]
}
