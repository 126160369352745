import React from 'react'
import { Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Skeleton from '@material-ui/lab/Skeleton'

import Modal from '~/components/core/Modal'
import * as S from '../styles'

const useStyles = makeStyles(S.AssetCardStyled)

const LoadersArr: number[] = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]

const SearchDetailsLoader: React.FC = () => {
  const classes = useStyles()

  return (
    <Modal.Content dividers className={classes.root}>
      <Grid container direction='column' spacing={1}>
        <Grid item className={classes.gridItemInfo}>
          <Skeleton animation='wave' width={140} height={43} />
        </Grid>
        <Grid item className={classes.gridItemInfo}>
          <Grid container alignItems='flex-start' spacing={3}>
            <Grid item className={classes.itemWrp}>
              <Skeleton animation='wave' width={60} height={48} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item className={classes.gridItemInfo}>
          <Grid container alignItems='flex-start' spacing={3}>
            <Grid item>
              <Skeleton animation='wave' width={100} height={43} />
            </Grid>
            <Grid item>
              <Skeleton animation='wave' width={100} height={43} />
            </Grid>
            <Grid item style={{ marginRight: 'auto' }}>
              <Skeleton animation='wave' width={100} height={43} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item className={classes.gridItemInfo}>
          <Grid container direction='row' spacing={3} wrap='nowrap'>
            <Grid item container lg={9} alignItems='stretch'>
              <Grid container direction='column' alignItems='stretch' spacing={1} className={classes.advangedDetails}>
                {
                  LoadersArr.map((key: number) => {
                    return (
                      <Grid item key={key} container>
                        <Skeleton animation='wave' width={160} height={27} />
                      </Grid>
                    )
                  })
                }
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Modal.Content>
  )
}

export default SearchDetailsLoader
