import React from 'react'
import { Grid } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import WbIncandescentIcon from '@material-ui/icons/WbIncandescent'

import Typography from '~/components/core/Typography'

export interface Props {
  hide?: boolean
}

const TipsTitle: React.FC<Props> = ({ hide }) => {
  const { t } = useTranslation()

  if (hide) return null

  return (
    <Grid item container>
      <Grid container spacing={1} wrap='nowrap' style={{ padding: '12px' }} justify='center' alignItems='center'>
        <Grid item container style={{ width: 'auto' }}><WbIncandescentIcon color='secondary' style={{ fontSize: '2.5rem' }} /></Grid>
        <Grid item container style={{ width: 'auto' }}><Typography size='xlarge'>{t('hoomeTips')}</Typography></Grid>
      </Grid>
    </Grid>
  )
}

export default TipsTitle
