import React from 'react'
import {
  Route,
  Switch
} from 'react-router-dom'

import ApiProvider from '~/components/core/ApiProvider'
import AuthProvider from '~/components/core/AuthProvider'
import ViewerProvider from '~/components/core/ViewerProvider'
import ChooseNotificationMethod from '~/components/modules/ChooseNotificationMethod'
import Page from '~/components/core/CompletePage'
import SellerRouter from './seller'
import BuyerRouter from './buyer'
import Plans from '~/components/pages/Plans'
import LeadRedirect from '~/components/pages/LeadRedirect'
import Settings from '~/components/pages/Settings'
import ContactUs from '~/components/pages/ContactUs'
import AboutUs from '~/components/pages/AboutUs'
import Policy from '~/components/pages/Policy'
import MainHall from '~/components/pages/MainHall'
import ViewerFavoriteProvider from '~/components/core/ViewerFavoriteProvider'

const HoomeRouter: React.FC = () => {
  return (
    <AuthProvider>
      <ApiProvider baseUrl={process.env.HOST_URL}>
        <ViewerProvider>
          <ViewerFavoriteProvider>
            <Page>
              <Switch>
                <Route exact path='/'>
                  <MainHall />
                </Route>

                <Route path='/settings'>
                  <Settings />
                </Route>
                <Route path='/contact-us'>
                  <ContactUs />
                </Route>
                <Route path='/about'>
                  <AboutUs />
                </Route>
                <Route path='/policy'>
                  <Policy />
                </Route>
                <Route path='/seller'>
                  <SellerRouter />
                </Route>
                <Route path='/buyer'>
                  <BuyerRouter />
                </Route>
                <Route path='/plans/asset/:assetId/:planType'>
                  <Plans />
                </Route>
                <Route path='/match/:leadId'>
                  <LeadRedirect />
                </Route>
              </Switch>
              <ChooseNotificationMethod />
            </Page>
          </ViewerFavoriteProvider>
        </ViewerProvider>
      </ApiProvider>
    </AuthProvider>
  )
}

export default HoomeRouter
