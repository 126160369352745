import React from 'react'
import { Link as BaseLink, LinkProps } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'

import * as S from './styles'

const useStyles = makeStyles(S.StyledLink)

const Link: React.FC<LinkProps> = ({
  children,
  ...props
}) => {
  const classes = useStyles(props)

  return (
    <BaseLink
      {...props}
      className={classes.root}
    >
      {children}
    </BaseLink>
  )
}

export default Link
