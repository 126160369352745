import React from 'react'
import { Grid } from '@material-ui/core'

import Table from '~/components/core/Table'
import PlanSellerHeaderCell from '../../PlanSellerHeaderCell'
import PlanSellerBodyFeature from '../../PlanSellerBodyFeature'
import PlanCellCheck from '../../PlanCellCheck'
import PlanSellerBodyTextCell from '../../PlanSellerBodyTextCell'
import { PlanColor, PlansSellerProps } from '../../'
import { PaymentType } from '~/types/Payment'

const CellItem = ({
  color,
  title,
  subTitle,
  textCell,
  subTextCell
}: {
  color: PlanColor
  title: string
  subTitle: string
  textCell?: string
  subTextCell?: string
}): JSX.Element => {
  return (
    <Grid container justify='space-between'>
      <Grid item>
        <PlanSellerBodyFeature
          title={title}
          subTitle={subTitle}
        />
      </Grid>
      <Grid item style={{ display: 'inline-flex', width: '90px' }} justify='center' alignItems='center'>
        {
          textCell
          ? (
            <PlanSellerBodyTextCell text={textCell} color={color} subText={subTextCell} />
          )
          : (
            <PlanCellCheck color={color} />
          )
        }
      </Grid>
    </Grid>
  )
}

interface Props extends Pick<PlansSellerProps, 'onSubmit'> {
  color: PlanColor
  headerTitle: string
  headerSubtitle: string
  headerTag?: React.ReactNode
  textCell?: string
  planType: PaymentType
  subTextCell?: string
}

const PlanItem = ({
  color,
  headerTitle,
  headerSubtitle,
  headerTag,
  textCell,
  onSubmit,
  planType,
  subTextCell
}: Props): JSX.Element => {
  return (
    <Table variant='bordered'>
      <Table.Head>
        <Table.Row>
          <Table.Cell>
            <PlanSellerHeaderCell
              title={headerTitle}
              subTitle={headerSubtitle}
              onSubmit={onSubmit}
              value={planType}
              color={color}
              tag={headerTag}
            />
          </Table.Cell>
        </Table.Row>
      </Table.Head>
      <Table.Body>
        <Table.Row bordered>
          <Table.Cell>
            <CellItem
              color={color}
              title='שליחת המודעה למחפשים המתאימים'
              subTitle='אתם מחליטים מי מהמחפשים יראה את הדירה שלכם'
              textCell={textCell}
              subTextCell={subTextCell}
            />
          </Table.Cell>
        </Table.Row>
        <Table.Row bordered>
          <Table.Cell>
            <CellItem
              color={color}
              title='בדיקת ביקוש בזמן אמת'
              subTitle='מידע לגבי כמה מחפשים מתאימים יש במחיר המבוקש'
            />
          </Table.Cell>
        </Table.Row>
        <Table.Row bordered>
          <Table.Cell>
            <CellItem
              color={color}
              title='צפייה בפרטי החיפוש והתקציב של המחפשים המתאימים'
              subTitle='לפי פרטי החיפוש והתקציב תוכלו להחליט למי לשלוח את המודעה'
            />
          </Table.Cell>
        </Table.Row>
        <Table.Row bordered>
          <Table.Cell>
            <CellItem
              color={color}
              title='התראות על מחפשים חדשים'
              subTitle='נשלח לכם התראה שנמצא מחפשים חדשים מתאימים'
            />
          </Table.Cell>
        </Table.Row>
      </Table.Body>
    </Table>
  )
}

export default PlanItem
