import React from 'react'
import DateFnsUtils from '@date-io/date-fns'
import heLocale from 'date-fns/locale/he'
import 'date-fns'
import { MuiPickersUtilsProvider, DatePicker as DatePickerMUI } from '@material-ui/pickers'

export interface Props {
  value?: Date
  label?: string,
  disablePast?: boolean
  onChange?: (date: any) => void
  name?: string
  error?: boolean
}

const DatePicker: React.FC<Props> = ({
  value, label, disablePast, onChange = () => {}, name, error
}) => {
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={heLocale}>
      <DatePickerMUI
        cancelLabel='ביטול'
        margin='normal'
        okLabel=''
        id='date-picker-dialog'
        label={label}
        format='dd/MM/yyyy'
        disablePast={disablePast}
        autoOk
        value={value}
        name={name}
        error={error}
        onChange={onChange}
        helperText={null}
      />
    </MuiPickersUtilsProvider>
  )
}

export default DatePicker
