import React, { useEffect, useState } from 'react'
import MailOutlineIcon from '@material-ui/icons/MailOutline'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import { Box } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import queryString from 'query-string'

import PageLoader from '~/components/core/PageLoader'
import { firebaseApp } from '~/components/core/AuthProvider'
import ResultsMessage from '~/components/core/ResultsMessage'
import Typography from '~/components/core/Typography'
import Button from '~/components/core/Button'
import Page from '~/components/core/CompletePage'
import ForgetPasswordForm from '~/components/modules/ForgetPasswordForm'

const VerifyEmail: React.FC = () => {
  const { t } = useTranslation()
  const [resetPasswordSuccess, setResetPasswordSuccess] = useState(false)
  const [displayPageMessage, setDisplayPageMessage] = useState(false)
  const [displayResetPassword, setDisplayResetPassword] = useState(false)
  const { oobCode, mode } = queryString.parse(location.search)

  useEffect(() => {
    if (!oobCode) {
      window.location.replace('/')
    } else if (mode === 'resetPassword') {
      firebaseApp.auth().verifyPasswordResetCode(oobCode as string).then(() => {
        return setDisplayResetPassword(true)
      }).catch(() => {
        return setDisplayPageMessage(true)
      })
    } else {
      firebaseApp.auth().applyActionCode(oobCode as string).then(() => {
        return window.location.replace('/')
      }).catch(() => {
        // Code is invalid or expired. Ask the user to verify their email address again.
        return setDisplayPageMessage(true)
      })
    }
  }, [])

  const handleSubmitResetPassword = (val: string) => {
    firebaseApp.auth().confirmPasswordReset(oobCode as string, val).then(() => {
      return setResetPasswordSuccess(true)
    }).catch(() => {
      return setDisplayPageMessage(true)
    })
  }

  if (resetPasswordSuccess) {
    return (
      <Page>
        <Box>
          <ResultsMessage
            icon={<CheckCircleOutlineIcon color='secondary' fontSize='large' style={{ height: 150, width: 'auto', marginTop: 40 }} />}
            title={t('resetPasswordSuccess')}
            actionButton={(
              <Button variant='text' color='primary' onClick={() => window.location.replace('/')}>
                <Typography size='large' color='textPrimary' noWrap>{t('labelBack')}</Typography>
              </Button>
            )}
          />
        </Box>
      </Page>
    );
  }

  if (displayResetPassword) {
    return (
      <Page>
        <Box display='flex' alignItems='center' justifyContent='center' width='100%' flexDirection='column'>
          <Box marginY={10}>
            <Typography size='large' color='textPrimary' noWrap>{t('resetPassword')}</Typography>
          </Box>
          <ForgetPasswordForm onSubmit={handleSubmitResetPassword} />
        </Box>
      </Page>
    );
  }

  if (displayPageMessage) {
    return (
      <Page>
        <Box>
          <ResultsMessage
            icon={<MailOutlineIcon color='secondary' fontSize='large' style={{ height: 150, width: 'auto', marginTop: 40 }} />}
            title={t('verifyExpire')}
            actionButton={(
              <Button variant='text' color='primary' onClick={() => window.location.replace('/')}>
                <Typography size='large' color='textPrimary' noWrap>{t('labelBack')}</Typography>
              </Button>
            )}
          />
        </Box>
      </Page>
    );
  }

  return <Page><PageLoader open invisible /></Page>
};

export default VerifyEmail
