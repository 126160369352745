import React, { useState, useEffect } from 'react'
import firebase from 'firebase/app'

import segment from '~/services/segment'
import fbService from '~/services/facebookPixel'
import PageLoader from '~/components/core/PageLoader'
import { renderUnverifiedUser } from '~/components/modules/UnverifiedUser/helper'
import useUITracker from '~/hooks/useUITracker'
import { getConfigObj, shouldVerifyEmail } from './helpers'
import { AuthProvider } from './context'

import 'firebase/auth'

const LOGIN_URL = `${window.location.origin}/login`

const firebaseConfig = getConfigObj()

interface Props {
  baseUrl?: string
  children: React.ReactElement
}

export const firebaseApp = firebase.initializeApp(firebaseConfig)

const AuthProviderWrapper: React.FC<Props> = ({ children }) => {
  const [setUserTracker] = useUITracker()
  const [currentUser, setCurrentUser] = useState<firebase.User | undefined>(undefined)

  const signOut = async () => {
    await firebaseApp.auth().signOut()
    window.location.replace(`/login`)
  }

  const updateUserProfile = async (userObj: {
    displayName?: string | null | undefined;
    photoURL?: string | null | undefined;
  }) => {
    if (!currentUser) {
      return
    }

    await currentUser.updateProfile(userObj)
    await currentUser.reload()

    setCurrentUser(currentUser)
  }

  const updateUserPhone = async (phoneCredential: any) => {
    await currentUser?.updatePhoneNumber(phoneCredential)
    await currentUser?.reload()

    setCurrentUser(currentUser)
  }

  useEffect(() => {
    const unregisterAuthObserver = firebaseApp.auth().onIdTokenChanged(async (user) => {
      if (!user) {
        window.location.replace(`${LOGIN_URL}?redirectUrl=${encodeURIComponent(window.location.href)}`)
      } else {
        segment.initialize({ userId: user.uid, userEmail: user.email || '' });
        fbService.initialize({ userId: user.uid, userEmail: user.displayName || '' });
        setUserTracker({
          uid: user.uid,
          displayName: user.displayName,
          email: user.email
        })
        if (shouldVerifyEmail(user)) {
          segment.track('Email Not Verified')
          user.sendEmailVerification().then(() => {
            return renderUnverifiedUser(signOut)
          }).catch((err) => {
            renderUnverifiedUser(signOut)
            // eslint-disable-next-line no-console
            console.error(err)
          })
        } else {
          // force refresh user token to have updated token with email valid as true
          await user.getIdToken(true)

          setCurrentUser(user)
        }
      }
    })

    return unregisterAuthObserver
  }, [])

  if (!currentUser) {
    return <PageLoader open invisible />
  }

  return (
    <AuthProvider.Provider
      value={{
        currentUser,
        signOut,
        updateUserProfile,
        updateUserPhone
      }}
    >
      {children}
    </AuthProvider.Provider>
  )
}

export default AuthProviderWrapper
