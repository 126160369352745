import React from 'react'
import { Grid } from '@material-ui/core'

import { useBreakpoint } from '~/utils/breakpoints'
import Typography from '~/components/core/Typography'

const PlanSellerBodyFeature = ({
  title,
  subTitle
}: {
  title: string
  subTitle: string
}): JSX.Element => {
  const isCompactLayout = useBreakpoint(['small', 'medium'])

  return (
    <Grid
      direction='column'
      alignItems='stretch'
      justify='center'
      spacing={1}
      container
      style={isCompactLayout ? { width: '210px' } : {}}
    >
      <Grid item>
        <Typography
          style={{
            fontSize: isCompactLayout ? '12px' : '18px',
            color: '#000'
          }}
          weight='bold'
        >
        {title}
        </Typography>
      </Grid>
      <Grid item>
        <Typography
          style={{
            fontSize: isCompactLayout ? '11px' : '13px',
            color: '#000',
            fontWeight: 300
          }}
        >
      {subTitle}
        </Typography>
      </Grid>
    </Grid>
  )
}

export default PlanSellerBodyFeature
