import { toCamel } from 'convert-keys'
import React from 'react'
import { IncomingOptions, Provider } from 'use-http'

import { AuthProvider } from '~/components/core/AuthProvider/context'

interface ApiProviderProps {
  baseUrl?: string
  children: React.ReactElement
  skipAuth?: boolean
}

const ApiProvider: React.FC<ApiProviderProps> = ({ children, baseUrl, skipAuth }) => {
  const { currentUser } = React.useContext(AuthProvider)

  const options: IncomingOptions = {
    interceptors: {
      // TODO: Implement autorization here
      // request: async ({ options, url, path, route }) => {
      request: async ({ options }) => {
        if (skipAuth || !currentUser) {
          return options
        }

        const token = await currentUser.getIdToken()

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (options?.headers) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          options.headers.Authorization = `Bearer ${token}`
        }

        return options
      },

      response: async ({ response }) => {
        const res = response

        if (res.data) res.data = toCamel(res.data)

        return res
      }
    }
  }

  return (
    <Provider url={baseUrl || process.env.HOST_URL} options={options}>
      {children}
    </Provider>
  )
}

export default ApiProvider
