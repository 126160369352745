import React, { ReactNode } from 'react'
import { create } from 'jss'
import rtl from 'jss-rtl'
import { StylesProvider, jssPreset } from '@material-ui/core/styles'

// Configure JSS
const jss = create({ plugins: [...jssPreset().plugins, rtl()] })

interface RTLProviderProps {
  children: ReactNode
  isRTL?: boolean
}

const RTLProvider: React.FC<RTLProviderProps> = ({ children, isRTL }: RTLProviderProps) => {
  if (!isRTL) {
    return <>{children}</>
  }

  return (
    <StylesProvider jss={jss}>
      {children}
    </StylesProvider>
  )
}

export default RTLProvider
