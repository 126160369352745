import React, { SVGProps } from 'react'
const Campaign = (props: SVGProps<SVGSVGElement>): JSX.Element => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={70}
    height={70}
    fill='none'
    {...props}
    >
    <g fill='#E087B1' clipPath='url(#a)'>
      <path d='M52.5 32.086v5.833h11.667v-5.833H52.5ZM46.666 51.36c2.8 2.07 6.446 4.812 9.334 6.97 1.166-1.545 2.333-3.12 3.5-4.666-2.888-2.158-6.533-4.9-9.334-7-1.166 1.575-2.333 3.15-3.5 4.696ZM59.5 16.33c-1.167-1.545-2.334-3.12-3.5-4.666-2.888 2.158-6.533 4.9-9.334 7 1.167 1.546 2.334 3.12 3.5 4.667 2.8-2.1 6.446-4.813 9.334-7ZM11.667 26.25c-3.208 0-5.833 2.625-5.833 5.833v5.834c0 3.208 2.625 5.833 5.833 5.833h2.917v11.667h5.833V43.75h2.916l14.584 8.75v-35l-14.584 8.75H11.668Zm14.67 4.988 5.747-3.442v14.408l-5.746-3.441-1.4-.846H11.667v-5.834h13.271l1.4-.845ZM45.209 34.997c0-3.879-1.692-7.379-4.376-9.77v19.512a12.957 12.957 0 0 0 4.376-9.742Z' />
    </g>
    <defs>
      <clipPath id='a'>
        <path fill='#fff' d='M0 0h70v70H0z' />
      </clipPath>
    </defs>
  </svg>
)

export default Campaign
