import { createStyles } from '@material-ui/core/styles'

import palette from '~/ThemeProvider/config/palette'

export default () =>
  createStyles({
    root: {
      borderTop: `1px solid ${palette.grey.lighter}`
    }
  })
