import { Props as SelecProps } from '~/components/core/Inputs/Select'

export const VIEW_OPTIONS_ARR: SelecProps['options'] = [
  { value: 0, text: 'selectView-none' },
  { value: 1, text: 'selectView-sea' },
  { value: 2, text: 'selectView-green' },
  { value: 3, text: 'selectView-orban' }
]

export const REMODEL_OPTIONS_ARR: SelecProps['options'] = [
  { value: 0, text: 'selectRemodel-new' },
  { value: 1, text: 'selectRemodel-likeNew' },
  { value: 2, text: 'selectRemodel-reserved' },
  { value: 3, text: 'selectRemodel-not' }
]

export const FLOOR_OPTIONS_ARR: SelecProps['options'] = [
  { value: 0, text: 'selectFloor-ground' },
  { value: 1, text: 'selectFloor-low' },
  { value: 2, text: 'selectFloor-medium' },
  { value: 3, text: 'selectFloor-high' }
]

export const FLOOR_RANGES = [
  { value: 0, range: { min: 0, max: 0 } },
  { value: 1, range: { min: 1, max: 3 } },
  { value: 2, range: { min: 4, max: 7 } },
  { value: 3, range: { min: 8, max: 1000 } }
]

export const REQUIERED_ADVANCED_FIELDS = [
  'balcony', 'elevator', 'floor',
  'remodel', 'safeRoom', 'storage'
]
