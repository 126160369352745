import React from 'react'
import { useParams } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import ListSubheader from '@material-ui/core/ListSubheader'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'

import { ViewerProvider } from '~/components/core/ViewerProvider/context'
import Link from '~/components/core/Link'
import { MinimalAsset } from '~/types/Asset'
import { addressWithoutCountry } from '~/components/utils/formating'
import Typography from '~/components/core/Typography'
import Badge from '~/components/core/Badge'
import * as S from './styles'

const useStyles = makeStyles(S.MySearchesListStyled)

export interface Props {
  assets?: MinimalAsset[]
  onClick?: (id: string) => void
}

const UserAssetsList: React.FC<Props> = ({ assets, onClick = () => {} }) => {
  const classes = useStyles()
  const { newAssetLeads } = React.useContext(ViewerProvider)
  const { id } = useParams<{ id?: string }>()

  return (
    <List
      component='nav'
      aria-labelledby='ist-subheader'
      subheader={
        <ListSubheader component='div' id='ist-subheader'>
          <Typography>
            נכסים שלי
          </Typography>
        </ListSubheader>
      }
      className={classes.root}
    >
      {
        (assets || []).map((asset) => {
          const assetNewLeads = newAssetLeads?.find(({ id }) => id === asset.id)

          return (
            <Link color='inherit' key={asset.id} to={`/seller/asset/${asset.id}/leads`} onClick={() => onClick(asset.id)}>
              <ListItem button key={asset.id} selected={id === asset.id}>
                <ListItemText primary={<Typography color='textPrimary'>{addressWithoutCountry(asset.address)}</Typography>} />
                {!!assetNewLeads && <Badge badgeContent={assetNewLeads.numberOfNewLeads} color='secondary' />}
              </ListItem>
            </Link>
          )
        })
      }
    </List>
  )
}

export default UserAssetsList
