import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import TextField, { TextFieldProps } from '@material-ui/core/TextField'

import LabelWrapper, { Props as LabelProps } from '../../LabelWrapper'
import * as S from './styles'

const useStyles = makeStyles(S.StyledInput)

export type Props = TextFieldProps & {
  labelProps?: LabelProps
}

const TextInput: React.FC<Props> = ({ label, labelProps = {}, ...rest }) => {
  const classes = useStyles()

  return (
    <LabelWrapper label={label} direction='column' {...labelProps}>
      <TextField
        {...rest}
        FormHelperTextProps={{
          ...(rest.FormHelperTextProps || {}),
          className: classes.helper
        }}
        InputProps={{
          ...(rest.InputProps || {}),
          className: classes.input
        }}
        inputProps={{
          ...(rest.inputProps || {})
        }}
        className={classes.root}
      />
    </LabelWrapper>
  )
}

export default TextInput
