import { useState, useEffect } from 'react'

interface ElementSizesProps {
  height: number | null | undefined, width: number | null | undefined
}

export type SizesProps = ElementSizesProps | null | undefined

export default function useHeightHelper(ref: React.RefObject<HTMLDivElement>, map?: boolean): [SizesProps] {
  const [height, setHeight] = useState<SizesProps>(null)
  let listerAdded = false

  useEffect(() => {
    const lister: any = () => {
      setHeight({ height: ref.current?.getClientRects()[0].height, width: ref.current?.getClientRects()[0].width })
    }

    if (ref.current) {
      if (!listerAdded) {
        listerAdded = true

        window.addEventListener('resize', lister)
      }

      setHeight({ height: ref.current?.getClientRects()[0].height, width: ref.current?.getClientRects()[0].width })
    }

    return listerAdded ? () => window.removeEventListener('resize', lister) : () => {}
  }, [ref, map])

  return [height]
}
