import React, { useCallback } from 'react'
import { isEmpty } from 'lodash'
import { useFormState } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import { Grid } from '@material-ui/core'

import { useModal } from '~/utils/useModal'
import HelpBox from '~/components/core/HelpBox'
import SearchTypeReminderModal from '~/components/modules/PostSearchMain/SearchTypeReminderModal'
import SellerTypeReminderModal from '~/components/modules/PostSearchMain/SellerTypeReminderModal'
import Typography from '~/components/core/Typography'

const shouldShowModal = (formState: any, isSeller?: boolean) => {
  const errorsKeys = Object.keys(formState.errors)
  const typeFeild = isSeller ? 'postType' : 'searchType'

  return (errorsKeys.length === 1 && formState.errors[typeFeild] && !formState.values[typeFeild])
}

// eslint-disable-next-line complexity
export default function useFormNotification(onSubmit: () => void, isSeller?: boolean, customErrorMessage?: string): [(JSX.Element | null), (JSX.Element | null), () => void] {
  const { t } = useTranslation()
  const formState = useFormState()
  const { showModal, hideModal, isOpen } = useModal()

  const notifications = (formState.submitFailed && (!isEmpty(formState.errors) || !isEmpty(formState.submitErrors)))
    ? (
        <HelpBox>
          <Grid container direction='column'>
            <Grid item>
              <Typography>
                {customErrorMessage ?? t('undoneFormProperty')}
              </Typography>
            </Grid>
            <Grid item>
              <Grid container spacing={1}>
                {
                  Object.keys(formState.errors).map((key) => (
                    <Grid item key={key}>
                      <Typography>
                        {t(key)}
                      </Typography>
                    </Grid>
                  ))
                }
              </Grid>
            </Grid>
          </Grid>
        </HelpBox>
      )
    : null

  const handleSubmit = useCallback(() => {
    if (shouldShowModal(formState, isSeller) && !isOpen) {
      return showModal()
    }

    onSubmit()
  }, [onSubmit, showModal, formState, isOpen, isSeller])

  const handleClose = useCallback(() => {
    hideModal()
    setTimeout(() => {
      onSubmit()
    }, 100)
  }, [hideModal, onSubmit])

  const SearchTypeModal = isSeller
? (
    <SellerTypeReminderModal
      isOpen={isOpen}
      onSubmit={handleClose}
    />
  )
: (
    <SearchTypeReminderModal
      isOpen={isOpen}
      onSubmit={handleClose}
    />
  )

  return [notifications, SearchTypeModal, handleSubmit]
}
