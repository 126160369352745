import { Theme } from '@material-ui/core/styles'

export class HoomeUIProvider {
  theme: Theme

  constructor(theme: Theme) {
    this.theme = theme
  }
}

export default HoomeUIProvider
