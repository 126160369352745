import { AssetFormData, SellerAsset } from '~/types/Asset'

// eslint-disable-next-line complexity
export const getInitFormObj = (asset?: SellerAsset): AssetFormData => {
  return {
    postType: asset ? ((asset.forSale && 'sell') || 'rent') : undefined,
    address: undefined,
    price: asset?.price || undefined,
    squareSize: asset?.squareSize || undefined,
    isAgent: asset ? Number(asset.isAgent) as AssetFormData['isAgent'] : undefined,
    rooms: asset?.rooms || undefined,
    contactNumber: asset?.contactNumber || '',
    floor: asset?.floor ?? undefined,
    balcony: asset?.balcony || 0,
    elevator: asset?.elevator || 0,
    furnished: asset?.furnished || 0,
    garden: asset?.garden || 0,
    hadicap: asset?.hadicap || 0,
    panthouse: asset?.panthouse || 0,
    parking: asset?.parking || 0,
    remodel: asset?.remodel || undefined,
    safeRoom: asset?.safeRoom || 0,
    storage: asset?.storage || 0,
    view: asset?.view || undefined,
    images: asset?.images || [],
    entranceDate: asset?.entranceDate ? new Date(asset.entranceDate) : new Date(),
    description: asset?.description || ''
  }
}
