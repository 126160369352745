import React from 'react'
import { isUndefined } from 'lodash'
import { Grid } from '@material-ui/core'
import StarHalfIcon from '@material-ui/icons/StarHalf'

import Typography from '~/components/core/Typography'
import { DealReadiness } from '~/types/Search'
import { DEAL_READINESS_COPY } from '~/components/modules/PostSearchMain/SearchDealReadinessModal'

export interface Props {
  dealReadiness?: DealReadiness
}

const getDealReadinessLabel = (dealReadiness: DealReadiness) => {
  switch (dealReadiness) {
    case DealReadiness.JustLooking:
      return DEAL_READINESS_COPY.JustLooking
    case DealReadiness.NotSure:
      return DEAL_READINESS_COPY.NotSure
    case DealReadiness.Ready:
      return DEAL_READINESS_COPY.Ready
  }
}

const SearchDetailsDealReadiness = ({ dealReadiness }: Props): JSX.Element => {
  if (isUndefined(dealReadiness)) {
    return <></>
  }

  return (
    <Grid container alignItems='flex-start' spacing={0} direction='column'>
      <Grid item>
        <Grid container alignItems='center' spacing={1}>
          <Grid item>
            <StarHalfIcon />
          </Grid>
          <Grid item>
            <Typography>
            רמת מוכנות לעסקה
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Typography>
          {getDealReadinessLabel(dealReadiness)}
        </Typography>
      </Grid>
    </Grid>
  )
}

export default SearchDetailsDealReadiness
