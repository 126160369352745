import React from 'react'
import { Grid, CardActions, Box } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

import Modal from '~/components/core/Modal'
import Form from '~/components/core/Form'
// import PageLoader from '~/components/core/PageLoader'
import Button from '~/components/core/Button'
import Typography from '~/components/core/Typography'

export type EditableFormValue = {
  displayName: string
}

export interface Props {
  onSubmit: (values: EditableFormValue) => void
  onClose: () => void
  name?: string
  isLoading?: boolean
  isOpen: boolean
}

const EditDisplayNameModal: React.FC<Props> = ({ onClose, isOpen, onSubmit, name, isLoading }) => {
  const { t } = useTranslation()

  return (
    <>
    <Modal open={isOpen} fullWidth>
      <Form onSubmit={onSubmit} initialValues={{ displayName: name || '' }}>
        <Modal.Title onClose={onClose}>
          עריכת שם תצוגה
        </Modal.Title>
        <Modal.Content>
          <Box>
            <Grid container justify='center' spacing={1} alignItems='center'>
              <Grid item container>
                <Form.Input disabled={isLoading} name='displayName' inputProps={{ style: { color: '#00000' } }} />
              </Grid>
            </Grid>
          </Box>
        </Modal.Content>
        <Modal.Actions>
          <CardActions style={{ width: '100%' }}>
            <Button size='small' color='primary' variant='text' onClick={onClose}>
              <Typography size='small'>
                {t('close')}
              </Typography>
            </Button>
            <Form.SubmitButton variant='text'>
              <Typography weight='bold'>{t('save')}</Typography>
            </Form.SubmitButton>
          </CardActions>
        </Modal.Actions>
      </Form>
    </Modal>
    </>
  )
}

export default EditDisplayNameModal
