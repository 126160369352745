import React, { useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useFormState, useForm } from 'react-final-form'
import { useHistory } from 'react-router-dom'
import { Grid } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import SubjectIcon from '@material-ui/icons/Subject'

import LabelWrapper from '~/components/core/LabelWrapper'
import { scrollToTop } from '~/utils/positions'
// import { useModal } from '~/utils/useModal'
import useFormNotification from '~/hooks/useFormNotification'
import Form from '~/components/core/Form'
import Button from '~/components/core/Button'
import Typography from '~/components/core/Typography'
import { ASSET_PROPS_ICONS } from '~/components/modules/AssetDetails/constants'
import NeighborhoodsGroup from '~/components/modules/NeighborhoodsGroupForm'
import DirtyFormModal from '~/components/modules/DirtyFormModal'
import { MapContext } from '~/components/core/MapProvider/context'
import SearcherContactNumberTooltip from '~/components/pages/v3/SearcherContactNumberTooltip'
import SearcherDescriptionTooltip from '~/components/pages/v3/SearcherDescriptionTooltip'
import { Styled } from './styles'

export interface Props {
  onSubmit?: () => void
}

const useStyles = makeStyles(Styled)

const PostSearchMain: React.FC<Props> = () => {
  const classes = useStyles()

  const history = useHistory()
  const formState = useFormState()
  const formObj = useForm()
  const handleOnSubmit = () => {
    scrollToTop()
    formObj.submit()
  }
  const [validationsNotification, SearchTypeModal, preSubmit] = useFormNotification(handleOnSubmit)
  const { setMapMarkers } = React.useContext(MapContext)

  const { t } = useTranslation()
  const getRelevantIcon = (fieldName: string): React.ReactNode => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const RelevantIcon = ASSET_PROPS_ICONS[fieldName]

    return RelevantIcon ? <RelevantIcon /> : null
  }

  // eslint-disable-next-line complexity
  useEffect(() => {
    const { city, neighborhoods } = formState.values
    const mapMarker = (city?.latLng && city?.latLng.lat && city?.latLng.lng) ? [city.latLng as {lat: number, lng: number}] : []
    const mapMarkerNeighborhoods = neighborhoods.filter((place: any) => (place?.latLng && place?.latLng.lat && place?.latLng.lng)).map((place: any) => place.latLng as {lat: number, lng: number})

    setMapMarkers(mapMarkerNeighborhoods.length ? mapMarkerNeighborhoods : mapMarker)
  }, [formState.values.neighborhoods, formState.values.city, setMapMarkers])

  return (
    <>
    {validationsNotification}
    {SearchTypeModal}
    <Grid container alignItems='stretch' spacing={4} style={{ maxWidth: '500px', width: '100%', margin: 0 }}>
      <Grid item container wrap='nowrap'>
        <Form.ToggleButtonsGroup
          style={{ width: '100%', justifyContent: 'space-evenly' }}
          labelProps={{
            style: { padding: '0 16px' },
            icon: getRelevantIcon('searchType'),
            label: t('searchType')
          }}
          name='searchType'
        >
          {
            [{ text: t('labelSale'), value: 'sell' }, { text: t('labelRent'), value: 'rent' }].map(({ text, value }: { text: string, value: string }, index: number) => (
              <Form.ToggleButton key={index} value={value} label={text} />
            ))
          }
        </Form.ToggleButtonsGroup>
      </Grid>
      <Grid item container>
        <Form.Location
          name='city'
          citiesOnly
          label={t('city')}
          textInputProps={{
            labelProps: {
              style: {
                padding: '0 16px'
              },
              icon: getRelevantIcon('city')
            }
            // style: {
            //   display: 'flex',
            //   flexWrap: 'wrap'
            // }
          }}
        />
      </Grid>
      <Grid item container>
        <NeighborhoodsGroup location={formState.values.city} />
      </Grid>
      <Grid item container>
        <Form.RangeNumbers
          minLabel={t('rangeFrom')}
          maxLabel={t('rangeTo')}
          inputProps={{ prefix: '₪', thousandSeparator: true, decimalScale: 0 }}
          name='budget'
          label={t('budget')}
          labelProps={{
            style: { padding: '0 16px' },
            icon: getRelevantIcon('budget')
          }}
        />
      </Grid>
      <Grid item container>
        <Form.RangeNumbers
          minLabel={t('rangeFrom')}
          maxLabel={t('rangeTo')}
          inputProps={{ thousandSeparator: true }}
          name='squareSize'
          label={t('squareSize')}
          labelProps={{
            style: { padding: '0 16px' },
            icon: getRelevantIcon('squareSize')
          }}
        />
      </Grid>
      <Grid item container>
        <Form.RangeNumbers
          minLabel={t('rangeFrom')}
          maxLabel={t('rangeTo')}
          inputProps={{ thousandSeparator: true, halfAllow: true, decimalScale: 1 }}
          name='rooms'
          label={t('rooms')}
          labelProps={{
            style: { padding: '0 16px' },
            icon: getRelevantIcon('rooms')
          }}
        />
      </Grid>
      {/* <Grid item container wrap='nowrap'>
        <Form.Checkbox name='allowAgent' label={t('acceptAgentCalls')} />
      </Grid> */}
      <Grid item container wrap='nowrap'>
        <Form.NumberInput
          allowLeadingZeros
          width='100%'
          name='contactNumber'
          type='tel'
          label={(
            <SearcherContactNumberTooltip>
              {t('contactNumber')}
            </SearcherContactNumberTooltip>
            )}
          labelProps={{
            style: {
              padding: '0 16px'
            },
            icon: getRelevantIcon('contactNumber')
          }}
        />
      </Grid>
      <Grid item container wrap='nowrap'>
        <LabelWrapper
          label={(
            <SearcherDescriptionTooltip>
              תיאור קצר עבור מי הדירה
            </SearcherDescriptionTooltip>
          )}
          icon={<SubjectIcon fontSize='small' />}
          direction='column'
        >
          <Form.Input
            style={{ border: '1px solid #DFDFDF' }}
            classes={{ root: classes.customTextAreaField }}
            rows={4}
            rowsMax={10}
            multiline
            name='tenants'
            placeholder='לדוגמא: מחפשים דירה למשפחה, זוג פלוס 2 ילדים וכלב. מחפשים דירה לטווח ארוך'
          />
        </LabelWrapper>
      </Grid>
      <Grid item container wrap='nowrap' direction='column' spacing={1}>
        <Grid item wrap='nowrap'>
          <Form.DatePicker name='entranceDate' label={t('entranceDate')} />
        </Grid>
        <Grid item>
          <Form.Checkbox name='entranceDateFlexible' label='גמיש' />
        </Grid>
      </Grid>
      <Grid item container>
        <Grid container spacing={2} wrap='nowrap'>
          <Grid item>
            <Button onClick={() => history.goBack()} color='primary' variant='outlined'>
              <Typography color='inherit'>
                {t('back')}
              </Typography>
            </Button>
          </Grid>
          <Grid item>
            <Button onClick={preSubmit} color='primary'>
              <Typography color='inherit'>
                {t('next')}
              </Typography>
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
    <DirtyFormModal
      when={formState.dirty && !formState.submitting}
      shouldBlockNavigation={() => formState.dirty && !formState.submitting}
      mainText={t('search-unsaved')}
    />
    </>
  )
}

export default PostSearchMain
