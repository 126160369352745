import React from 'react'
import ReportIcon from '@material-ui/icons/Report'
import AddIcon from '@material-ui/icons/Add'
import { useTranslation } from 'react-i18next'

import ResultsMessage from '~/components/core/ResultsMessage'
import Button from '~/components/core/Button'
import Link from '~/components/core/Link'
import Typography from '~/components/core/Typography'

export interface Props {
  type?: 'asset' | 'search'
  isPublic?: boolean
}

// eslint-disable-next-line complexity
const NoDataMessage: React.FC<Props> = ({ type, isPublic }) => {
  const { t } = useTranslation()
  const isAsset = type === 'asset'

  return (
    <ResultsMessage
      title={isAsset ? t('assetNotFound') : t('searchNotFound')}
      icon={<ReportIcon color='secondary' fontSize='large' style={{ height: 150, width: 'auto', marginTop: 40 }} />}
      actionButton={(type && !isPublic)
      ? (
        <Link color='inherit' to={isAsset ? '/seller/postAsset' : '/buyer/postSearch'} style={{ textDecoration: 'none' }}>
          <Button endIcon={<AddIcon />} variant='text' color='primary'>
            <Typography color='textPrimary' noWrap>{isAsset ? t('addPropery') : t('newSearch')}</Typography>
          </Button>
        </Link>
      )
      : null}
    />
  )
}

export default NoDataMessage
