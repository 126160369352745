import React, { memo } from 'react'
import CardActions from '@material-ui/core/CardActions'

import SendMessage from '~/components/pages/AssetLeads/components/SendMessage'
import ViewSearchDetails from '~/components/pages/AssetLeads/components/ViewSearchDetails'
import SendMessageTooltip from '~/components/pages/v3/SendMessageTooltip'

export interface Props {
  onSearchDetails: () => void
  leadId: string
  leadName?: string
  sendCount?: number
  onSendMessage?: () => void
  showMessageTooltip?: boolean
}

export const LeadsItemActionsButtons: React.FC<Props> = ({
  onSearchDetails,
  leadId,
  leadName,
  sendCount,
  onSendMessage,
  showMessageTooltip
}) => {
  return (
    <CardActions style={{ paddingLeft: 0 }}>
      {
        showMessageTooltip
? (
          <SendMessageTooltip>
            <SendMessage leadId={leadId} leadName={leadName} sendCount={sendCount} onSendMessage={onSendMessage} />
          </SendMessageTooltip>
        )
: (
          <SendMessage leadId={leadId} leadName={leadName} sendCount={sendCount} onSendMessage={onSendMessage} />
        )
      }
      <ViewSearchDetails onClick={onSearchDetails} />
    </CardActions>
  )
}

export default memo(LeadsItemActionsButtons)
