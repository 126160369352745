import React from 'react'
import { useHistory } from 'react-router-dom'
import useFetch, { CachePolicies } from 'use-http'
import { useTranslation } from 'react-i18next'
import BlockIcon from '@material-ui/icons/Block'
import { Container, Grid } from '@material-ui/core'

import segment from '~/services/segment'
import PostSearchForm from '~/components/modules/PostSearchForm'
import GuideSearcherModal from '~/components/modules/GuideSearcherModal'
import PageLoader from '~/components/core/PageLoader'
import { SearchFormData } from '~/types/Search'
import { ViewerProvider } from '~/components/core/ViewerProvider/context'
import ResultsMessage from '~/components/core/ResultsMessage'
import Button from '~/components/core/Button'
import Typography from '~/components/core/Typography'
import { preparePayload } from './helpers'

const MAX_SEARCHES = 5

const PostSearch: React.FC = () => {
  const history = useHistory()
  const { t } = useTranslation()
  const { refetchSearches, refetchLoading, searches } = React.useContext(ViewerProvider)
  const { post, loading } = useFetch(`/api/v3/searcher`, { cachePolicy: CachePolicies.NO_CACHE })

  const handleOnSubmit = async (data?: SearchFormData) => {
    if (!data) {
      return {}
    }
    const payload = preparePayload(data)
    const res = await post(payload)

    if (res.id) {
      segment.track('New Search', {
        search: res
      })
      await refetchSearches()

      history.push(`/buyer/search/${res.id}?showSpamModal=true`)
    }

    return {}
  }

  if (!searches) {
    return null
  }

  if (searches.length >= MAX_SEARCHES) {
    return (
      <ResultsMessage
        title={t('searchLimitAlert')}
        icon={<BlockIcon color='secondary' fontSize='large' style={{ height: 150, width: 'auto', marginTop: 40 }} />}
        actionButton={(
          <Button variant='text' color='primary' onClick={() => history.goBack()}>
            <Typography size='large' color='textPrimary' noWrap>{t('labelBack')}</Typography>
          </Button>
        )}
      />
    )
  }

  return (
    <>
    <Container style={{ padding: '20px 0' }}>
      <Grid container>
        <Grid item container>
          <PostSearchForm onSubmit={handleOnSubmit} />
        </Grid>
      </Grid>
    </Container>
    <PageLoader open={loading || refetchLoading} />
    <GuideSearcherModal />
    </>
  )
}

export default PostSearch
