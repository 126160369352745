import React from 'react'
import { Grid } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import PermIdentityIcon from '@material-ui/icons/PermIdentity'

import SettingsEditableUserName, { EditableFormValue } from '~/components/modules/SettingsEditableUserName'
import AssetDetailRawField from '~/components/modules/AssetDetailRawField'
import { AuthProvider } from '~/components/core/AuthProvider/context'

interface Props {
  onNameChange: (name: string) => void
}

const SettingsUserDetails: React.FC<Props> = ({ onNameChange }) => {
  const { t } = useTranslation()
  const { currentUser, updateUserProfile } = React.useContext(AuthProvider)
  const handleOnClick = async (values: EditableFormValue) => {
    await updateUserProfile(values)
    onNameChange(values.displayName)

    return true
  }

  if (!currentUser) {
    return null
  }

  return (
    <Grid container direction='column' justify='center' spacing={2} alignItems='center'>
      <Grid item container>
        <AssetDetailRawField icon={<PermIdentityIcon />} value={<SettingsEditableUserName onSave={handleOnClick} />} label={t('displayName')} />
      </Grid>
    </Grid>
  )
}

export default SettingsUserDetails
