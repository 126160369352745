import React from 'react'
import { Grid } from '@material-ui/core'

import { formatPrice } from '~/components/utils/formating'
import AssetValueInfo from '~/components/modules/AssetValueInfo'
import { MinimalAsset } from '~/types/Asset'

export interface Props {
  asset: MinimalAsset
}

const AssetMainDetailsHeader: React.FC<Props> = ({ asset }) => {
  return (
    <Grid container direction='row' spacing={4}>
      <Grid item>
        <AssetValueInfo value={asset.rooms} label='חדרים' />
      </Grid>
      <Grid item>
        <AssetValueInfo value={asset.squareSize} label='מ״ר' />
      </Grid>
      <Grid item>
        <AssetValueInfo value={asset.floor} label='קומה' />
      </Grid>
      <Grid item>
        <AssetValueInfo value={formatPrice(asset.price)} label='מחיר' />
      </Grid>
    </Grid>
  )
}

export default AssetMainDetailsHeader
