import React, { SVGProps } from 'react'
const ClacAssetLarge = (props: SVGProps<SVGSVGElement>): JSX.Element => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={60}
    height={60}
    fill='none'
    {...props}
  >
    <path
      fill='#E087B1'
      d='M53.333 0H6.667C3 0 0 3 0 6.667v46.666C0 57 3 60 6.667 60h46.666C57 60 60 57 60 53.333V6.667C60 3 57 0 53.333 0Zm0 53.333H6.667V6.667h46.666v46.666Z'
    />
    <path
      fill='#E087B1'
      d='M27.5 15.734H10.833v5H27.5v-5ZM50 42.5H33.333v5H50v-5ZM50 34.164H33.333v5H50v-5ZM16.667 49.997h5v-6.666h6.666v-5h-6.666v-6.667h-5v6.667H10v5h6.667v6.666ZM36.967 26.5l4.7-4.7 4.7 4.7 3.533-3.533-4.7-4.734 4.7-4.7L46.367 10l-4.7 4.7-4.7-4.7-3.533 3.533 4.7 4.7-4.7 4.734 3.533 3.533Z'
    />
  </svg>
)

export default ClacAssetLarge
