import { isEqual, isUndefined } from 'lodash'

import { SearchPayload, SearchFormData, RangeType, CommunicationReqPayload, AdvangedSearchItem } from '~/types/Search'
import { LocationData } from '~/components/core/Inputs/LocationInput'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const getCommunicationValue = (allowAgent?: boolean):CommunicationReqPayload => {
  // if (allowAgent) {
  //   return ['private', 'agent']
  // }

  // return ['private']
  return ['private', 'agent']
}

export const getCommunicationSelectValue = (communicationVal?: CommunicationReqPayload): boolean => {
  return isEqual(['private', 'agent'], communicationVal)
}

const checkMaxRange = (rangeObj: RangeType, max: number) => {
    return {
      min: rangeObj.min || 0,
      max: rangeObj.max || max
    }
}

const normalizeAdvancedValue = (advancedValue: AdvangedSearchItem): AdvangedSearchItem => {
  return {
    ...advancedValue,
    importance: advancedValue.importance || 0
  }
}

// eslint-disable-next-line complexity
export const preparePayload = (data: SearchFormData): {formValues: SearchPayload} => {
  const neighborhoods = data.neighborhoods?.filter((neighbor) => (neighbor && neighbor.name))

  return ({
    formValues: {
      forSale: data.searchType === 'sell',
      city: data.city?.name || '',
      cityMetaData: data.city as LocationData,
      neighborhood: neighborhoods?.map(({ name }) => name || '') || [],
      neighborhoodsMetaData: neighborhoods || [],
      contactNumber: data.contactNumber,
      communication: getCommunicationValue(data.allowAgent),
      budget: checkMaxRange(data.budget, data.budget.max || 20000000000),
      rooms: checkMaxRange(data.rooms, 20),
      squareSize: checkMaxRange(data.squareSize, 1000000),
      balcony: normalizeAdvancedValue(data.balcony),
      elevator: normalizeAdvancedValue(data.elevator),
      floor: normalizeAdvancedValue(data.floor),
      furnished: normalizeAdvancedValue(data.furnished),
      garden: normalizeAdvancedValue(data.garden),
      hadicap: normalizeAdvancedValue(data.hadicap),
      panthouse: normalizeAdvancedValue(data.panthouse),
      parking: normalizeAdvancedValue(data.parking),
      remodel: normalizeAdvancedValue(data.remodel),
      safeRoom: normalizeAdvancedValue(data.safeRoom),
      storage: normalizeAdvancedValue(data.storage),
      view: normalizeAdvancedValue(data.view),
      tenants: data.tenants,
      entranceDate: data.entranceDate,
      entranceDateFlexible: Boolean(data.entranceDateFlexible),
      ...(!isUndefined(data.dealReadiness) ? { dealReadiness: Number(data.dealReadiness) } : {})
    }
  })
}
