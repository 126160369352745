export const scrollToTop = (height = 0): void => {
  document.getElementsByTagName('HTML')[0].scrollTop = height
}

export const getCurrentTop = (): number => (
  Math.abs(document.documentElement.getBoundingClientRect().top)
)

export const scrollDownSmooth = (): void => {
  const top = getCurrentTop()

  window.scrollTo({ top: top + document.documentElement.clientHeight, behavior: 'smooth' })
}
