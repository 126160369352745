import React, { SVGProps } from 'react'
const Nis = (props: SVGProps<SVGSVGElement>): JSX.Element => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={19}
    height={15}
    fill='none'
    {...props}
    color={props.color || 'inherit'}
  >
    <path
      fill='currentcolor'
      d='M5.698 15c-.191 0-.338-.052-.442-.156a.656.656 0 0 1-.156-.442V5.12c0-.173.052-.312.156-.416.104-.104.25-.156.442-.156h1.17c.173 0 .312.052.416.156a.52.52 0 0 1 .182.416v7.8h3.874c1.542 0 2.66-.381 3.354-1.144.693-.78 1.04-1.863 1.04-3.25V.752c0-.173.052-.312.156-.416a.602.602 0 0 1 .442-.182h1.196a.52.52 0 0 1 .416.182.52.52 0 0 1 .182.416v7.852c0 1.404-.243 2.583-.728 3.536-.486.953-1.222 1.673-2.21 2.158-.971.468-2.21.702-3.718.702H5.698Zm-5.07 0a.562.562 0 0 1-.416-.156.656.656 0 0 1-.156-.442V.752C.056.579.108.44.212.336A.52.52 0 0 1 .628.154h5.798c1.508 0 2.747.243 3.718.728.988.468 1.716 1.179 2.184 2.132.485.936.728 2.115.728 3.536v3.458c0 .173-.052.32-.156.442a.562.562 0 0 1-.416.156h-1.196a.656.656 0 0 1-.442-.156.656.656 0 0 1-.156-.442v-3.38c0-1.387-.347-2.461-1.04-3.224-.694-.78-1.812-1.17-3.354-1.17H2.422v12.168c0 .173-.061.32-.182.442a.562.562 0 0 1-.416.156H.628Z'
    />
  </svg>
)

export default Nis
