import React from 'react'
import { Grid } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

import Modal from '~/components/core/Modal'
import Button from '~/components/core/Button'
import Typography from '~/components/core/Typography'
import { Coordinates } from '~/types/Asset'
import MapView from '~/components/core/MapView'

export interface Props {
  position?: Coordinates
  onClose: () => void
  isOpen: boolean
}

const MapModal: React.FC<Props> = ({ position, onClose, isOpen }) => {
  const { t } = useTranslation()

  if (!position) {
    return null
  }

  return (
    <Modal open={isOpen}>
      <Modal.Title onClose={onClose}>
        מיקום הנכס
      </Modal.Title>
      <Modal.Content dividers>
        <Grid container direction='column' spacing={1}>
          <Grid item container>
            <div style={{ height: 400, width: 600 }}>
              <MapView show position={position} />
            </div>
          </Grid>
        </Grid>
      </Modal.Content>
        <Modal.Actions>
          <Button size='small' color='primary' variant='text' onClick={onClose}>
            <Typography size='small'>
              {t('close')}
            </Typography>
          </Button>
        </Modal.Actions>
    </Modal>
  )
}

export default MapModal
