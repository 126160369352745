import useFetch, { CachePolicies } from 'use-http'

interface UseSendLeadMessage {
  assetId: string
}

export const useSendAllLeadsMessages = ({
  assetId
}: UseSendLeadMessage): {
  execute: () => Promise<any>
  loading: boolean
  error: any
  response: any
} => {
  const { get, response, loading, error } = useFetch(
    `api/v3/assets/asset/${assetId}/contact_all_searchers`,
    {
      cachePolicy: CachePolicies.NO_CACHE
    }
  )

  const execute = async () => {
    return get()
  }

  return {
    execute,
    loading,
    error,
    response
  }
}
