import React, { useEffect } from 'react'
import { Grid } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import HomeWorkIcon from '@material-ui/icons/HomeWork'
import { useForm } from 'react-final-form'

import { LocationData } from '~/components/core/Inputs/LocationInput'
import { addressWithoutCountry } from '~/components/utils/formating'
import LabelWrapper from '~/components/core/LabelWrapper'
import Form from '~/components/core/Form'

export interface Props {
  location?: LocationData
}

export const NeighborhoodsGroup: React.FC<Props> = ({ location }) => {
  const formObj = useForm()
  const { t } = useTranslation()

  useEffect(() => {
    if (!location?.placeId) {
      formObj.change('neighborhoods', [])
    }
  }, [location?.placeId])

  return (
    <LabelWrapper label={t('neighborhoods')} icon={<HomeWorkIcon fontSize='small' />} direction='column'>
      <Grid container direction='column' spacing={2}>
        <Grid item>
          <Form.MultiLocation
            allowFreeText
            searchTypes={['geocode']}
            noOptionsText={t('noOptionsNeighborhood')}
            isDisabled={!location?.placeId}
            filterType='neighborhood'
            includesIn={location?.name ? addressWithoutCountry(location?.name) : ''}
            location={location?.bounds}
            name='neighborhoods'
          />
        </Grid>
      </Grid>
    </LabelWrapper>
  )
}

export default NeighborhoodsGroup
