import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import AddIcon from '@material-ui/icons/Add'
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder'
import ListSubheader from '@material-ui/core/ListSubheader'
import { Grid } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import Button from '~/components/core/Button'
import Typography from '~/components/core/Typography'
import SearchResultsHeaderDetails from '~/components/modules/SearchResultsHeaderDetails'
import { MinimalSearch } from '~/types/Search'
// import { useBreakpoint } from '~/utils/breakpoints'
import * as S from './styles'

const useStyles = makeStyles(S.SearchResultsHeaderStyled)

export interface Props {
  name: string
  searches: MinimalSearch[]
  openMenu: boolean
  onMenuToggle: () => void
}

const SearchResultsHeader: React.FC<Props> = ({
  name, searches, onMenuToggle, openMenu
}) => {
  const wrapper = React.useRef<HTMLDivElement>(null)
  const { t } = useTranslation()

  const classes = useStyles()

  return (
    <ListSubheader className={classes.sticky}>
      <Grid ref={wrapper} component='div' container wrap='nowrap' justify='center' alignItems='center' className={classes.root}>
        <Grid item>
          <Button size='medium' iconButton={<ExpandMoreIcon />} onClick={onMenuToggle} />
        </Grid>
        <Grid item container>
          <Typography color='textPrimary'>{name}</Typography>
        </Grid>
        <Grid item style={{ marginRight: 'auto' }}>
          <Grid container spacing={1} wrap='nowrap'>
            <Grid item>
              <Link color='inherit' to='/buyer/favorites' style={{ textDecoration: 'none' }}>
                <Button endIcon={<FavoriteBorderIcon color='primary' />} variant='text' color='primary'>
                  <Typography color='textPrimary' noWrap>{t('myFavoritesAssets')}</Typography>
                </Button>
              </Link>
            </Grid>
            <Grid item>
              <Link color='inherit' to='/buyer/postSearch' style={{ textDecoration: 'none' }}>
                <Button endIcon={<AddIcon />} variant='text' color='primary'>
                  <Typography color='textPrimary' noWrap>{t('newSearchAdded')}</Typography>
                </Button>
              </Link>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <SearchResultsHeaderDetails anchorRef={wrapper} searches={searches} open={openMenu} onClose={onMenuToggle} />
    </ListSubheader>
  )
}

export default SearchResultsHeader
