import React, { ReactNode } from 'react'
import { withStyles } from '@material-ui/core/styles'
import DialogTitle, { DialogTitleProps } from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'

import Typography from '~/components/core/Typography'
import * as S from './styles'

const StyledModalTitle = withStyles(S.ModalTitle)(DialogTitle)

export const StyledIconButton = withStyles(S.CloseButton)(IconButton)

export interface Props extends DialogTitleProps {
  children?: ReactNode
  onClose?: () => void
}

const ModalTitle: React.FC<Props> = ({ children, onClose, ...other }) => {
  return (
    <StyledModalTitle id='modal-title' disableTypography {...other}>
      <Typography variant='h6'>{children}</Typography>
      {onClose
        ? (
        <StyledIconButton aria-label='close' onClick={onClose}>
          <CloseIcon />
        </StyledIconButton>
      )
      : null}
    </StyledModalTitle>
  )
}

export default ModalTitle
