import React from 'react'
import { useParams } from 'react-router-dom'

import UserFeedback from '../'

const FeedbackPage: React.FC = () => {
  const { userId } = useParams<{ userId?: string }>()

  const handleOnSubmit = () => {
    window.location.replace(window.location.origin)
  }

  if (!userId) {
    console.log('errorerror')

    return <>ERROR</>
  }

  return (
    <UserFeedback
      userId={userId}
      onSubmit={handleOnSubmit}
    />
  )
}

export default FeedbackPage
