import React from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'
import CardActions from '@material-ui/core/CardActions'
import MailOutlineIcon from '@material-ui/icons/MailOutline'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'
import WhatsAppIcon from '@material-ui/icons/WhatsApp'

import { SearchLead } from '~/types/Search'
import useContactMessage from '~/hooks/useContactMessage'
import Button from '~/components/core/Button'
import Typography from '~/components/core/Typography'
import BuyerFavButton from '~/components/modules/BuyerFavButton'
import * as S from '../styles'

const useStyles = makeStyles(S.AssetCardStyled)

export interface Props {
  item: SearchLead
  onRemove?: (id: string) => void
}

const AssetCardActions: React.FC<Props> = ({ item, onRemove }) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const { buyerToSellerEmail, buyerToSellerWA } = useContactMessage()

  const handleOnContactPhoneClick = (e: React.MouseEvent) => {
    e.stopPropagation()

    buyerToSellerWA({
      leadId: item.leadId,
      address: item.address,
      city: item.city,
      phoneNumber: item.ownerDetails.phoneNumber || ''
    })
  }

  const handleOnContactEmailClick = (e: React.MouseEvent) => {
    e.stopPropagation()

    buyerToSellerEmail({
      leadId: item.leadId,
      address: item.address,
      city: item.city,
      email: item.ownerDetails.email
    })
  }

  const handleOnRemove = (e: React.MouseEvent) => {
    if (!onRemove) {
      return
    }

    e.stopPropagation()

    onRemove(item.leadId)
  }

  return (
    <CardActions>
      {
        !!item.ownerDetails.email && (
          <Button size='small' color='primary' endIcon={<MailOutlineIcon />} variant='text' onClick={handleOnContactEmailClick}>
            <Typography size='small'>
              {t('emailLabel')}
            </Typography>
          </Button>
        )
      }
      {
        !!item.ownerDetails.phoneNumber && (
          <div>
            <Button onClick={handleOnContactPhoneClick} size='small' color='primary' endIcon={<WhatsAppIcon />} variant='text'>
              <Typography size='small'>
                {t('label-WhatsApp')}
              </Typography>
            </Button>
          </div>
        )
      }
      <BuyerFavButton item={item} />
      {
        onRemove && (
          <div className={classes.removeBtn}>
            <Button iconButton={<DeleteOutlineIcon className={classes.removeBtnIcon} />} title='text' onClick={handleOnRemove} />
          </div>
        )
      }
    </CardActions>
  )
}

export default AssetCardActions
