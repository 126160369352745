import React from 'react'
import {
  useField,
  FieldProps as FinalFieldProps,
  FieldRenderProps
} from 'react-final-form'

import BaseDatePicker, { Props as DatePickerProps } from '~/components/core/Inputs/DatePicker'
import FormField from '../FormField'
import LabelWrapper from '../../LabelWrapper'

export type FieldProps<TInputValue> = FinalFieldProps<
  TInputValue,
  FieldRenderProps<TInputValue, HTMLInputElement>,
  HTMLInputElement
>

export type Props = DatePickerProps & FieldProps<DatePickerProps['value']>

export const DatePicker: React.FC<Props> = (props) => {
  const { meta, input } = useField(props.name)
  const error = meta.touched && (meta.error || meta.submitError)

  return (
    <FormField error={error}>
      <LabelWrapper label={props.label} direction='column'>
        <BaseDatePicker {...props} {...input} value={input.value} label='' name={input.name} error={!!error} />
      </LabelWrapper>
    </FormField>
  )
}

export default DatePicker
