import React from 'react'
import { Grid, CardActions } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'

import Modal from '~/components/core/Modal'
import Button from '~/components/core/Button'
import Typography from '~/components/core/Typography'

export interface Props {
  onClose: () => void
  isOpen: boolean
}

const ReachLimitModal: React.FC<Props> = ({ onClose, isOpen }) => {
  const { t } = useTranslation()

  return (
    <Modal open={isOpen}>
      <Modal.Title onClose={onClose}>
      הגבלת שליחה
      </Modal.Title>
      <Modal.Content dividers>
        <Grid container direction='column' spacing={1}>
          <Grid item container justify='center'>
            <ErrorOutlineIcon color='secondary' fontSize='large' style={{ height: 150, width: 'auto', marginBottom: 20 }} />
          </Grid>
          <Grid item>
            <Typography size='large'>
            אנחנו מאפשרים לשלוח כל מודעה עד 3 פעמים לכל מחפש מתאים ועברת  מגבלה זו.
            </Typography>
          </Grid>
        </Grid>
      </Modal.Content>
        <Modal.Actions>
          <CardActions style={{ width: '100%' }}>
            <Button size='small' color='primary' variant='text' onClick={onClose}>
              <Typography size='medium' weight='bold'>
                {t('undestood')}
              </Typography>
            </Button>
          </CardActions>
        </Modal.Actions>
    </Modal>
  )
}

export default ReachLimitModal
