import { createStyles } from '@material-ui/core/styles'

import palette from '~/ThemeProvider/config/palette'

export const RadioButton = createStyles({
  root: {
    fontSize: '20px !important'
  },
  label: {
    color: palette.primary.dark
  }
})

export const RadioButtonRoot = createStyles({
  root: {
    color: palette.primary.main
  }
})
