import React from 'react'
import { useParams } from 'react-router-dom'

import { ViewerProvider } from '~/components/core/ViewerProvider/context'
import { MinimalSearch, NewLeads } from '~/types/Search'

export default function useSelectedSearch(): [MinimalSearch | undefined, NewLeads | undefined] {
  const { id } = useParams<{ id?: string }>()
  const { searches, newSearchLeads } = React.useContext(ViewerProvider)

  const selectedSearch = searches?.find((search) => search.id === id)
  const selectedSearchNewLeads = newSearchLeads?.find((search) => search.id === id)

  return [selectedSearch, selectedSearchNewLeads]
}
