import { createStyles } from '@material-ui/core/styles'

export const StyledLoader = createStyles({
  animation: {
    animation: '$spin 4s infinite linear'
  },
  '@keyframes spin': {
    '0%': {
      transform: 'rotate(0deg)'
    },
    '100%': {
      transform: 'rotate(360deg)'
    }
  }
})
