import React from 'react'
import { Grid, CardActions } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

import { Spam } from '~/components/core/Icons'
import Modal from '~/components/core/Modal'
import Button from '~/components/core/Button'
import Typography from '~/components/core/Typography'

export interface Props {
  onClose: () => void
  isOpen: boolean
}

const SpamEmailsWarningModal: React.FC<Props> = ({ onClose, isOpen }) => {
  const { t } = useTranslation()

  return (
    <Modal open={isOpen}>
      <Modal.Title onClose={onClose}>
      הודעה חשובה
      </Modal.Title>
      <Modal.Content dividers>
        <Grid container direction='column' spacing={1}>
          <Grid item container justify='center' style={{ marginBottom: 20 }}>
            <Spam />
          </Grid>
          <Grid item>
            <Typography size='large'>
              {t('checkSpanWarning')}
            </Typography>
          </Grid>
        </Grid>
      </Modal.Content>
        <Modal.Actions>
          <CardActions style={{ width: '100%' }}>
            <Button size='small' color='primary' variant='text' onClick={onClose}>
              <Typography size='medium' weight='bold'>
                {t('undestood')}
              </Typography>
            </Button>
          </CardActions>
        </Modal.Actions>
    </Modal>
  )
}

export default SpamEmailsWarningModal
