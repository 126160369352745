import { createStyles } from '@material-ui/core'

import { Props } from './'

const SIZES: any = {
  xsmall: '10px',
  small: '12px',
  medium: '14px',
  large: '16px',
  xlarge: '18px'
}

export default () =>
  createStyles({
    root: {
      fontSize: (props: Props) => SIZES[props.size || 'medium'],
      fontWeight: (props: Props) => props.weight ?? 'normal'
    }
  })
