import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useParams, useHistory } from 'react-router-dom'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import { useTranslation } from 'react-i18next'
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone'

import Pagination from '~/components/core/Pagination'
import ScrollToTopButton from '~/components/modules/ScrollToTopButton'
import { PAGE_SIZE } from '~/components/pages/AssetLeads'
import DeleteWarningModal from '~/components/modules/DeleteWarningModal'
import LeadsItem, { Props as LeadsItemProps } from '~/components/modules/LeadsItem'
import { PaymentsProvider } from '~/components/modules/PaymentsProvider/context'
import { useModal } from '~/utils/useModal'
import Typography from '~/components/core/Typography'
import ResultsMessage from '~/components/core/ResultsMessage'
import AssetLeadsTooltip from '~/components/pages/v3/AssetLeadsTooltip'
import Loader from './Loader'
import * as S from './styles'

const useStyles = makeStyles(S.LeadListtyled)

export interface Props {
  items: LeadsItemProps[]
  disabled?: boolean
  loading?: boolean
  onRemoveLead: (leadId: string) => void
  refetchLeads: (route?: string | undefined) => Promise<any>
  showMessageTooltip?: boolean
  totalCount: number
}

let leadIdToRemove: string | undefined | null = null

const LeadsList: React.FC<Props> = ({
  items,
  loading,
  onRemoveLead,
  refetchLeads,
  showMessageTooltip,
  totalCount
}) => {
  const { id: assetId, page } = useParams<{ id?: string, page?: string }>()
  const { currentPlan } = React.useContext(PaymentsProvider)
  const classes = useStyles()
  const history = useHistory()
  const { showModal, hideModal, isOpen } = useModal()
  const { t } = useTranslation()

  const handleOnRemoveLead = (id?: string | undefined) => {
    leadIdToRemove = id
    showModal()
  }

  const handleOnSubmitRemoveLead = () => {
    hideModal()
    onRemoveLead(leadIdToRemove as string)
    leadIdToRemove = null
  }

  if (loading && !items) {
    return (
      <Loader />
    )
  }

  if (!items.length) {
    return (
      <ResultsMessage
        title={t('sellerNoLeads')}
        icon={<NotificationsNoneIcon color='secondary' fontSize='large' style={{ height: 150, width: 'auto', marginTop: 40 }} />}
      />
    )
  }

  return (
    <>
    <List className={classes.root}>
      <ListItem className={classes.listTitle}>
        <Grid container spacing={2} justify='center'>
          <Grid item>
            <AssetLeadsTooltip>
              <Typography style={{ marginRight: 2 }} size='xlarge'>{`${t('leadsMatchBuyers')} ${totalCount ? `(${totalCount})` : ''}`}</Typography>
            </AssetLeadsTooltip>
          </Grid>
        </Grid>
      </ListItem>
      {[...items].map((item, index) => (

        <LeadsItem
          onRemove={handleOnRemoveLead}
          showMessageTooltip={(showMessageTooltip && index === 0)}
          onSendMessage={refetchLeads}
          disabled={['free', 'undefined'].includes(String(currentPlan))}
          key={`${item.leadId}-${index}`}
          {...item}
        />
      ))}
    </List>
    <Box marginTop={1}>
      <Pagination
        activePage={page}
        itemCount={totalCount}
        limit={PAGE_SIZE}
        onPageChange={(to) => history.push(`/seller/asset/${assetId}/leads/${to}`)} />
    </Box>
    <DeleteWarningModal
      isOpen={isOpen}
      onClose={hideModal}
      mainText={t('removeLeadSeller')}
      onSubmit={handleOnSubmitRemoveLead}
    />
    <ScrollToTopButton />
    </>
  )
}

export default LeadsList
