import React from 'react'
import { withStyles, makeStyles } from '@material-ui/core/styles'
import Radio, { RadioProps } from '@material-ui/core/Radio'
import FormControlLabel from '@material-ui/core/FormControlLabel'

import * as S from './styles'

const StyledRadio = withStyles(S.RadioButton)(FormControlLabel)

const useStyles = makeStyles(S.RadioButtonRoot)

export interface Props extends RadioProps {
  label: React.ReactNode
  value: string
}

const RadioButton: React.FC<Props> = ({ label, value }) => {
  const classes = useStyles()

  return (
    <StyledRadio
      value={value}
      control={<Radio color='primary' className={classes.root} />}
      label={label}
      labelPlacement='end'
    />
  )
}

export default RadioButton
