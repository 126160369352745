import React, { useState } from 'react'
import Divider from '@material-ui/core/Divider'
import { makeStyles } from '@material-ui/core/styles'
import { Grid, Box } from '@material-ui/core'
import Avatar from '@material-ui/core/Avatar'
import { useTranslation } from 'react-i18next'

import { AuthProvider } from '~/components/core/AuthProvider/context'
import getInitials from '~/components/utils/getInitials'
import { useModal } from '~/utils/useModal'
import DeleteUserModal from '~/components/modules/DeleteUserModal'
import Button from '~/components/core/Button'
import Typography from '~/components/core/Typography'
import SettingsUserDetails from '~/components/modules/SettingsUserDetails'
import { useBreakpoint } from '~/utils/breakpoints'
import UserNotificationSwitch from '~/components/modules/UserNotificationSwitch'
import * as S from './styles'

interface Props {
  modal?: boolean
}

const useStyles = makeStyles(S.Styled)

const Settings: React.FC<Props> = () => {
  const { t } = useTranslation()
  const { currentUser } = React.useContext(AuthProvider)
  const [displayName, setDisplayName] = useState(currentUser?.displayName)
  const isCompactLayout = useBreakpoint(['small', 'medium'])
  const classes = useStyles()
  const { showModal, hideModal, isOpen } = useModal()

  return (
    <>
    <Box className={classes.topBar} />
    <Grid container direction='column' alignItems={isCompactLayout ? 'center' : 'flex-start'} spacing={2} className={classes.gridWrp}>
      <Grid item>
        <Avatar className={classes.avatar} variant='circular'>{getInitials(displayName || '')}</Avatar>
      </Grid>
      <Grid item>
        <Typography color='textSecondary' size='large'>{currentUser?.email}</Typography>
      </Grid>
      <Grid item container alignItems='center' justify='center' style={{ marginTop: 20 }} className={classes.userDetailsWrp}>
        <SettingsUserDetails onNameChange={setDisplayName} />
      </Grid>
      <Divider />
      <Grid item container alignItems='center' justify='center' className={classes.userDetailsWrp}>
        <UserNotificationSwitch />
      </Grid>
      <Grid item container alignItems='center' justify='center' className={classes.removeButton}>
        <Button color='inherit' variant='outlined' onClick={showModal}>
          {t('removeUser')}
        </Button>
      </Grid>
    </Grid>
    <DeleteUserModal
      isOpen={isOpen}
      onClose={hideModal}
      onSubmit={() => {
        hideModal()
      }}
    />
    </>
  )
}

export default Settings
