import React, { SVGProps } from 'react'
const Published = (props: SVGProps<SVGSVGElement>): JSX.Element => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={16}
    height={16}
    fill='none'
    {...props}
    color={props.color || 'inherit'}
  >
    <path
      fill='currentcolor'
      d='M8 0C3.584 0 0 3.584 0 8s3.584 8 8 8 8-3.584 8-8-3.584-8-8-8Zm0 14.4A6.408 6.408 0 0 1 1.6 8c0-3.528 2.872-6.4 6.4-6.4 3.528 0 6.4 2.872 6.4 6.4 0 3.528-2.872 6.4-6.4 6.4Zm-4.4-2 6.008-2.792L12.4 3.6 6.392 6.392 3.6 12.4ZM8 7.12c.488 0 .88.392.88.88s-.392.88-.88.88A.877.877 0 0 1 7.12 8c0-.488.392-.88.88-.88Z'
    />
  </svg>
)

export default Published
