import { BudgetRange } from '~/types/Asset'

export const getLowestMaxValueFromList = (bugdetList: BudgetRange[]): number => {
  const min = bugdetList.reduce((prev, current) => {
    if (Number.isNaN(current?.max)) {
      return prev
    }

    return prev < current.max ? prev : current.max
  }, Infinity)

  return min === Infinity ? 0 : min
}
