import React, { useEffect, useState } from 'react'
import { Grid, Container } from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress'

import { AssetV3, BudgetRange } from '~/types/Asset'
import LabelWrapper from '~/components/core/LabelWrapper'
import { Match, ClacAssetLarge } from '~/components/core/Icons'
import Button from '~/components/core/Button'
import Typography from '~/components/core/Typography'
import { useBreakpoint } from '~/utils/breakpoints'
import { MapContext } from '~/components/core/MapProvider/context'
import ResultsMessage from '~/components/core/ResultsMessage'
import PriceSlider from '~/components/modules/PriceSlider'
import { getMaxValue, getMinValue, getCurrentLeadsCount } from './services'
import { CurrentLeadsCount } from './services/get-current-leads-count'
import AssetPriceCalculatorInput from '../AssetPriceCalculatorInput'
import DeviationTooltip from '../DeviationTooltip'

interface Props {
  budgetsList: BudgetRange[]
  recommendedPrice: AssetV3['recommendedPrice']
  onBack: () => void
  onNext: (price: number) => void
  loading?: boolean
}

const AssetPriceCalculator = ({
  budgetsList,
  recommendedPrice,
  onBack,
  onNext,
  loading
}: Props): JSX.Element => {
  const [currentPrice, setCurrentPrice] = useState(recommendedPrice)
  const [leadsCountObj, setLeadsCount] = useState<CurrentLeadsCount>()
  const { setMap = () => {} } = React.useContext(MapContext)
  const isCompactLayout = useBreakpoint(['small', 'medium'])

  useEffect(() => {
    setMap(false)
  }, [])

  useEffect(() => {
    const { leadsCount, deviasionCount } = getCurrentLeadsCount(currentPrice, budgetsList)

    setLeadsCount({ leadsCount, deviasionCount })
  }, [currentPrice, setLeadsCount, budgetsList])

  const handleNext = () => {
    onNext(currentPrice)
  }

  const handleChange: any = (event: any, newValue: number | number[]) => {
    const val = Array.isArray(newValue) ? newValue[0] : newValue

    setCurrentPrice(val)
  }

  const handleInputChange: any = (newValue: number) => {
    setCurrentPrice(newValue)
  }

  return (
    <Container style={{ padding: '20px 10px', width: isCompactLayout ? '100%' : '500px' }}>
      <Grid container spacing={4} direction='column' alignItems='center' style={{ width: '100%', margin: 0 }}>
        <Grid item container style={{ width: '100%' }}>
          <ResultsMessage
            title='קביעת מחיר לדירה שלכם'
            subTitle='המחשבון שלנו יציג כמה מחפשים מתאימים יש בהתאם למחיר שתקבעו לאחר מכן תוכלו לבחור למי מהמחפשים לשלוח את המודעה שלכם'
            subTitleStyle={{ fontSize: '16px' }}
            icon={<ClacAssetLarge />}
          />
        </Grid>
        <Grid item style={{ width: '100%' }}>
          <Grid container spacing={4} direction='column' style={{ width: '100%', margin: 0 }}>
            <Grid item>
              <PriceSlider
                value={Math.floor(currentPrice)}
                defaultValue={Math.floor(recommendedPrice)}
                max={getMaxValue(recommendedPrice)}
                min={getMinValue(recommendedPrice)}
                onChange={handleChange}
              />
            </Grid>
            <Grid item>
              <AssetPriceCalculatorInput
                max={getMaxValue(recommendedPrice)}
                min={getMinValue(recommendedPrice)}
                value={Math.floor(currentPrice)}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item>
            <LabelWrapper icon={<Match />} label='מחפשים מתאימים' direction='column'>
              <Grid container spacing={2} direction='column' alignItems='center'>
                <Grid item>
                  <Typography style={{ fontSize: '28px' }} weight='bold'>
                  {leadsCountObj?.leadsCount}
                  </Typography>
                </Grid>
              </Grid>
            </LabelWrapper>
            </Grid>
            <Grid item>
            <LabelWrapper
              icon={<Match />}
              label={(
                <DeviationTooltip>
                  מחפשים מתאימים עם סטיית מחיר
                </DeviationTooltip>
                )}
              direction='column'
            >
              <Grid container spacing={2} direction='column' alignItems='center'>
                <Grid item>
                  <Typography style={{ fontSize: '28px' }} weight='bold'>
                  {leadsCountObj?.deviasionCount}
                  </Typography>
                </Grid>
              </Grid>
            </LabelWrapper>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container spacing={4}>
            <Grid item>
              <Button variant='outlined' color='primary' onClick={onBack} disabled={loading}>
              חזרה
              </Button>
            </Grid>
            <Grid item>
              {
                loading
                  ? <CircularProgress color='primary' />
                  : (
                    <Button variant='contained' color='primary' onClick={handleNext}>
                    המשך
                    </Button>
                  )
              }
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  )
}

export default AssetPriceCalculator
