import React from 'react'

import { useModal } from '~/utils/useModal'
import LeadSearchDetails from '~/components/pages/LeadSearchDetails'

export default function useSearchDetailsModal(leadId: string, onSendMessage?: () => void): [(JSX.Element | null), () => void] {
  const { showModal, hideModal, isOpen } = useModal()

  const searchDetailsModal = (
    isOpen
    ? (
      <LeadSearchDetails
        leadId={leadId}
        onClose={hideModal}
        onSendMessage={onSendMessage}
      />
    )
    : null
  )

  return [searchDetailsModal, showModal]
}
