import React, { SVGProps } from 'react'

const PlanCheck = (props: SVGProps<SVGSVGElement>): JSX.Element => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={20}
    height={20}
    fill='none'
    {...props}
    color={props.color || 'inherit'}
  >
    <path
      fill='currentcolor'
      fillRule='evenodd'
      d='M11.613 1.363a2.5 2.5 0 0 0-3.226 0l-.848.716a2.5 2.5 0 0 1-1.401.58l-1.106.094a2.5 2.5 0 0 0-2.28 2.28L2.657 6.14a2.5 2.5 0 0 1-.581 1.402l-.716.848a2.5 2.5 0 0 0 0 3.226l.716.848a2.5 2.5 0 0 1 .58 1.402l.094 1.105a2.5 2.5 0 0 0 2.28 2.28l1.107.094a2.501 2.501 0 0 1 1.401.581l.848.716a2.5 2.5 0 0 0 3.226 0l.848-.716a2.501 2.501 0 0 1 1.402-.58l1.106-.094a2.5 2.5 0 0 0 2.28-2.28l.093-1.107a2.5 2.5 0 0 1 .581-1.401l.716-.848a2.5 2.5 0 0 0 0-3.226l-.716-.848a2.5 2.5 0 0 1-.58-1.402l-.094-1.106a2.5 2.5 0 0 0-2.28-2.28l-1.106-.093a2.5 2.5 0 0 1-1.402-.581l-.848-.716Zm2.933 6.934a1.125 1.125 0 1 0-1.591-1.59L8.751 10.91 7.046 9.207a1.125 1.125 0 1 0-1.591 1.59l2.5 2.5c.44.44 1.152.44 1.591 0l5-5Z'
      clipRule='evenodd'
    />
  </svg>
)

export default PlanCheck
