import { createStyles } from '@material-ui/core/styles'

import { screens } from '~/utils/breakpoints'

export const Styled = createStyles({
  root: {
    width: '950px',
    [screens('small', 'medium')]: {
      width: 'auto'
    }
  },
  modalWrp: {
    display: 'block'
  }
})
