import React from 'react'

import { Props as MapMarkersProps } from '~/components/core/MapView/MapMarker'

export interface MapContextProps {
  map?: boolean
  setMap?: (bool: boolean) => void
  isMapShow?: boolean
  mapMarkers: MapMarkersProps[],
  setMapMarkers: (markers: MapMarkersProps[]) => void
  setMapApiLoaded: (bool: boolean) => void
  mapApiLoaded: boolean
  selectedMarkerLeadId?: string | null | undefined
  setSelectedMarkerLeadId?: (id?: string) => void
  setLeadIdHover: (id?: string) => void
  leadIdHover: string | null | undefined
}

export const MapContext = React.createContext<MapContextProps>({} as MapContextProps)
