import { createStyles } from '@material-ui/core/styles'

import { screens } from '~/utils/breakpoints'
import palette from '~/ThemeProvider/config/palette'

export const Styled = createStyles({
  topBar: {
    width: '100vw',
    height: 200,
    position: 'absolute',
    backgroundColor: palette.primary.light,
    left: 0,
    [screens('small', 'medium')]: {
      width: '100vw',
      height: 140
    }
  },
  gridWrp: {
    marginTop: 140,
    [screens('small', 'medium')]: {
      marginTop: 94
    }
  },
  avatar: {
    backgroundColor: palette.secondary.dark,
    width: 100,
    height: 100,
    fontSize: '3rem',
    marginLeft: 20,
    [screens('small', 'medium')]: {
      width: 70,
      height: 70,
      fontSize: '2rem',
      marginLeft: 0
    }
  },
  userDetailsWrp: {
    width: '60%',
    [screens('small', 'medium')]: {
      width: '100%'
    },
    alignSelf: 'center'
  },
  removeButton: {
    marginTop: 60,
    color: palette.error.main
  }
})
