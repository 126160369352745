import React from 'react'
import { Grid, CardActions } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

import Modal from '~/components/core/Modal'
import Button from '~/components/core/Button'
import Typography from '~/components/core/Typography'

export interface Props {
  onSubmit: (phone?: number | string) => void
  mainText?: string
  onClose: () => void
  isOpen: boolean
}

const DeleteWarningModal: React.FC<Props> = ({ onClose, isOpen, mainText, onSubmit }) => {
  const { t } = useTranslation()

  return (
    <Modal open={isOpen}>
      <Modal.Title onClose={onClose}>
        לפני מחיקה
      </Modal.Title>
      <Modal.Content dividers>
        <Grid container direction='column' spacing={1}>
          <Grid item>
            <Typography size='large'>
              {mainText}
            </Typography>
          </Grid>
        </Grid>
      </Modal.Content>
        <Modal.Actions>
          <CardActions style={{ width: '100%' }}>
            <Button size='small' color='primary' variant='text' onClick={onClose}>
              <Typography size='small'>
                {t('close')}
              </Typography>
            </Button>
            <Button size='small' color='primary' variant='text' onClick={() => onSubmit()}>
              <Typography size='small'>
              {t('remove')}
              </Typography>
            </Button>
          </CardActions>
        </Modal.Actions>
    </Modal>
  )
}

export default DeleteWarningModal
