import { createStyles } from '@material-ui/core'

export default () =>
  createStyles({
    root: {
      display: 'block'
    },
    circular: {
      borderRadius: '50%'
    }
  })
