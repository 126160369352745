import AccessibleIcon from '@material-ui/icons/Accessible'
import EmojiTransportationIcon from '@material-ui/icons/EmojiTransportation'
import ApartmentIcon from '@material-ui/icons/Apartment'
import FilterFramesIcon from '@material-ui/icons/FilterFrames'
import HttpsIcon from '@material-ui/icons/Https'
import LocalFloristIcon from '@material-ui/icons/LocalFlorist'
import LooksIcon from '@material-ui/icons/Looks'
import StorefrontIcon from '@material-ui/icons/Storefront'
import BuildIcon from '@material-ui/icons/Build'
import WeekendIcon from '@material-ui/icons/Weekend'
import StorageIcon from '@material-ui/icons/Storage'
import AccountBalanceIcon from '@material-ui/icons/AccountBalance'

export const ASSET_PROPS_ICONS = {
  balcony: StorefrontIcon,
  elevator: FilterFramesIcon,
  floor: ApartmentIcon,
  garden: LocalFloristIcon,
  hadicap: AccessibleIcon,
  panthouse: AccountBalanceIcon,
  parking: EmojiTransportationIcon,
  safeRoom: HttpsIcon,
  storage: StorageIcon,
  view: LooksIcon,
  furnished: WeekendIcon,
  remodel: BuildIcon
}

export const BOOLEAN_FIELD = ['balcony', 'elevator', 'floor', 'garden', 'hadicap', 'panthouse', 'parking', 'safeRoom', 'storage', 'view', 'furnished', 'remodel']
