import React, { useState, useEffect } from 'react'

import LabelWrapper from '~/components/core/LabelWrapper'
import { Nis } from '~/components/core/Icons'
import NumberInput from '~/components/core/Inputs/NumberInput'
import FormField from '~/components/core/Form/FormField'

interface Props {
  min: number
  max: number
  value: number
  onChange: (value: number) => void
}

const isInRange = (value: number, min: number, max: number) => {
  return value >= min && value <= max
}

const AssetPriceCalculatorInput = ({
  value,
  max,
  min,
  onChange
}: Props): JSX.Element => {
  const [error, setError] = useState<undefined | string>()
  const [val, setVal] = useState<number>(value)

  const handleInputChange: any = (event: any) => {
    const valueEvent = Number(event.target.value)

    if (!Number.isNaN(valueEvent)) {
      if (!isInRange(valueEvent, min, max)) {
        setError('מספר לא תקין, המספר חייב להיות בטווח בין המינימום והמקסימום של הסליידר')
        setVal(valueEvent)

        return
      }

      setError(undefined)
      onChange(valueEvent)
    }
  }

  useEffect(() => {
    setVal(value)
  }, [value])

  return (
    <LabelWrapper icon={<Nis />} label='מחיר הדירה' direction='column'>
      <FormField error={error}>
        <NumberInput onChange={handleInputChange} value={val} />
      </FormField>
    </LabelWrapper>
  )
}

export default AssetPriceCalculatorInput
