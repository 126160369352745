import { createStyles } from '@material-ui/core/styles'

export const Select = createStyles({
  root: {
    fontSize: '0.75rem',
    minHeight: 'unset',
    whiteSpace: 'normal',
    textAlign: 'center'
  }
})
