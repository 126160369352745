/* eslint-disable complexity */
import React from 'react'
import { useHistory, useParams } from 'react-router-dom'
import useFetch, { CachePolicies } from 'use-http'
import { Grid, Container } from '@material-ui/core'

import PostAssetForm from '~/components/modules/PostAssetForm'
import { rollbarError } from '~/utils/rollbar'
import SellerTips from '~/components/modules/SellerTips'
import SellerTipsFab from '~/components/modules/SellerTips/fabButton'
import { useBreakpoint } from '~/utils/breakpoints'
import PageLoader from '~/components/core/PageLoader'
import { AssetFormData, SellerAsset } from '~/types/Asset'
import { ViewerProvider } from '~/components/core/ViewerProvider/context'
import NoDataMessage from '~/components/modules/NoDataMessage'
import { preparePayload, createFormData } from './helpers'

const EditAsset: React.FC = () => {
  const { id } = useParams<{ id?: string }>()
  const history = useHistory()
  const { refetchAssets, refetchLoading } = React.useContext(ViewerProvider)
  const isCompactLayout = useBreakpoint(['small', 'medium'])
  const { loading, error, data } = useFetch<SellerAsset>(`/api/v3/assets/asset/${id}`, { data: null, cachePolicy: CachePolicies.NO_CACHE }, [id])
  const { put, loading: postLoading } = useFetch(`/api/v3/assets/asset/${id}`, { cachePolicy: CachePolicies.NO_CACHE }, [id]);

  const handleOnSubmit = async (formData?: AssetFormData) => {
    if (!formData) {
      return {}
    }

    const payload = preparePayload(formData, data)
    const fd = createFormData(payload)
    const res = await put(fd)

    if (res.id) {
      await refetchAssets()

      history.push(`/seller/asset/${res.id}/leads`)
    }

    return {}
  }

  if (error) {
    rollbarError({
      error
    })

    return (
      <NoDataMessage type='asset' />
    )
  }

  if (loading || postLoading || refetchLoading) {
    return <PageLoader open invisible />
  }

  return (
    <>
    <Container style={{ padding: '20px 10px' }}>
      <Grid container spacing={4}>
        <Grid item container lg={8}>
          <PostAssetForm asset={data} onSubmit={handleOnSubmit} />
        </Grid>
        {
          !isCompactLayout
          ? (
            <Grid item container sm={4} style={{ flex: 1, position: 'relative' }}>
              <SellerTips sticky id={id} pattern='tipsText.seller.edit' />
            </Grid>
          )
          : (
            <SellerTipsFab pattern='tipsText.seller.edit' />
          )
        }
      </Grid>
    </Container>
    </>
  )
}

export default EditAsset
