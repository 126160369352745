import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import NavigationIcon from '@material-ui/icons/Navigation'
import Fab from '@material-ui/core/Fab'

import { scrollToTop } from '~/utils/positions'
import useScrollToTop from '~/hooks/useScrollToTop'
import { Styled } from './styles'

const useStyles = makeStyles(Styled)

const ScrollToTopButton: React.FC = () => {
  const [isTop] = useScrollToTop()
  const classes = useStyles()

  if (isTop) return null

  return (
    <Fab
      aria-label='add'
      size='small'
      onClick={() => scrollToTop()}
      className={classes.root}
    >
      <NavigationIcon style={{ color: '#fff' }} />
    </Fab>
  )
}

export default ScrollToTopButton
