import React from 'react'
import { Grid, Box } from '@material-ui/core'

import Typography from '~/components/core/Typography'
import { NonPublished, Published } from '~/components/core/Icons'

export interface Props {
  sentCount?: number
}

const PublishedAssetMessageBase = ({
  bgColor,
  text,
  icon
}: {
  bgColor: string,
  text: string,
  icon: React.ReactNode,
}): JSX.Element => {
  return (
    <Box boxShadow='0px 2px 2px 0px rgba(0, 0, 0, 0.25)' bgcolor={bgColor} borderRadius='5px' alignItems='center' padding='4px'>
      <Grid container spacing={1} alignItems='center'>
        <Grid item container style={{ width: 'auto', color: '#fff' }}>
          {icon}
        </Grid>
        <Grid item>
          <Typography style={{ fontSize: '12px', color: '#fff' }}>
            {text}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  )
}

const NonPublishedAssetMessage = (): JSX.Element => {
  return (
    <PublishedAssetMessageBase
      bgColor='#FB928B'
      text='המודעה לא פורסמה למחפשים המתאימים'
      icon={<NonPublished />}
    />
  )
}

const PublishedAssetMessage = ({
  sentCount
}: Props): JSX.Element => {
  // eslint-disable-next-line no-constant-condition
  if (true) {
    return <></>
  }

  if (sentCount) {
    return (
      <PublishedAssetMessageBase
        bgColor='#6FDCA8'
        text={`המודעה פורסמה ל ${sentCount} מחפשים המתאימים`}
        icon={<Published />}
    />
    )
  }

  return (
    <NonPublishedAssetMessage />
  )
}

export default PublishedAssetMessage
