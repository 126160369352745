import React from 'react'

import { ViewerProvider } from '~/components/core/ViewerProvider/context'
import useSelectedAsset from '~/hooks/useSelectedAsset'

export default function useResetNewAssetLeads(): [(id?: string) => void] {
  const [currentAsset] = useSelectedAsset()
  const { resetAssetNewLeads } = React.useContext(ViewerProvider)

  const resetLeads = (id?: string) => {
    resetAssetNewLeads(id || currentAsset?.id || '')
  }

  return [resetLeads]
}
