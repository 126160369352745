import { useTranslation } from 'react-i18next'

import { InitProps } from '~/components/modules/ContactUsForm'

type AssetFormValidation = {
  email?: string
  fullName?: string
  textComment?: string
}

export default function useContactUsFormValidation(): [(data: InitProps) => AssetFormValidation] {
  const { t } = useTranslation()
  const emailRegexp = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/i

  const validateForm = (data: InitProps): AssetFormValidation => {
    let vlidationsError = {}

    if (!emailRegexp.test(data.email)) {
      vlidationsError = {
        ...vlidationsError,
        email: t('errorMsgRequiredField')
      }
    }

    if (!data.fullName) {
      vlidationsError = {
        ...vlidationsError,
        fullName: t('errorMsgRequiredField')
      }
    }

    return vlidationsError
  }

  return [validateForm]
}
