/* eslint-disable max-statements */
import React from 'react'
import { useParams, Redirect, Link } from 'react-router-dom'
import queryString from 'query-string'
import useFetch, { CachePolicies } from 'use-http'
import LinkOffIcon from '@material-ui/icons/LinkOff'
import { useTranslation } from 'react-i18next'

import { rollbarError } from '~/utils/rollbar'
import segment from '~/services/segment'
import PageLoader from '~/components/core/PageLoader'
import Typography from '~/components/core/Typography'
import Button from '~/components/core/Button'
import ResultsMessage from '~/components/core/ResultsMessage'

interface Props {
  modal?: boolean
}

interface DataRes {
  target: 'buyer' | 'seller' | null
  exist: boolean
  assetId?: string
}

// eslint-disable-next-line complexity
const LeadRedirect: React.FC<Props> = () => {
  const { redirectedFrom } = queryString.parse(location.search)
  const { leadId } = useParams<{ leadId?: string }>()
  const { loading, error, data } = useFetch<DataRes | null>(`/api/v1/leads/match/${leadId}`, { cachePolicy: CachePolicies.NO_CACHE }, [leadId])
  const { t } = useTranslation()

  if (loading) {
    return <PageLoader open invisible />
  }

  if (error) {
    rollbarError({
      error
    })

    return (
      <ResultsMessage
        title={t('matchNoLeadFound')}
        icon={<LinkOffIcon color='secondary' fontSize='large' style={{ height: 150, width: 'auto', marginTop: 40 }} />}
        actionButton={(
          <Link color='inherit' to='/' style={{ textDecoration: 'none' }}>
            <Button variant='text' color='primary'>
              <Typography size='large' color='textPrimary' noWrap>{t('labelBack')}</Typography>
            </Button>
          </Link>
        )}
      />
    )
  }

  if (!data) {
    return null
  }

  if (!data.exist) {
    return (
      <ResultsMessage
        title={t('matchNoLeadFound')}
        icon={<LinkOffIcon color='secondary' fontSize='large' style={{ height: 150, width: 'auto', marginTop: 40 }} />}
        actionButton={(
          <Link color='inherit' to='/' style={{ textDecoration: 'none' }}>
            <Button variant='text' color='primary'>
              <Typography size='large' color='textPrimary' noWrap>{t('labelBack')}</Typography>
            </Button>
          </Link>
        )}
      />
    )
  }

  if (redirectedFrom) {
    segment.track(`From ${redirectedFrom} Match - ${data.target}`);
  }

  if (data.target === 'buyer') {
    return (
      <Redirect to={`/buyer/asset/${leadId}`} />
    )
  }

  if (data.target === 'seller') {
    return (
      <Redirect to={`/seller/asset/${data.assetId}/leads`} />
    )
  }

  if (data.assetId) {
    return (
      <Redirect to={`/public/asset/${data.assetId}`} />
    )
  }

  return (
    <Redirect to='/' />
  )
}

export default LeadRedirect
