/* eslint-disable complexity */
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import useFetch, { CachePolicies } from 'use-http'
import { Grid, Container } from '@material-ui/core'

import { useBreakpoint } from '~/utils/breakpoints'
import SellerTips from '~/components/modules/SellerTips'
import SellerTipsFab from '~/components/modules/SellerTips/fabButton'
import PostAssetForm from '~/components/modules/PostAssetForm'
import PageLoader from '~/components/core/PageLoader'
import AssetPriceContainer from '~/components/pages/v3/AssetPriceContainer'
import { AssetFormData, AssetV3 } from '~/types/Asset'
import { ViewerProvider } from '~/components/core/ViewerProvider/context'
import { preparePayload, createFormData } from '~/components/pages/EditAsset/helpers'

const PostAsset: React.FC = () => {
  const history = useHistory()
  const isCompactLayout = useBreakpoint(['small', 'medium'])
  const [showCalculator, setShowCalculator] = useState(false)
  const { refetchAssets, refetchLoading } = React.useContext(ViewerProvider)
  // const { post } = useFetch(`/api/properties/newProperty`, { cachePolicy: CachePolicies.NO_CACHE })
  const { post, loading, data } = useFetch<AssetV3>(`/api/v3/assets`, { cachePolicy: CachePolicies.NO_CACHE })

  const redirectToLeadsPage = (dataRes: AssetV3) => {
    if (!dataRes) {
      return
    }
    refetchAssets()

    history.push(`/seller/asset/${dataRes.id}/leads?showSpamModal=true`)
  }

  const handleOnSubmit = async (formData?: AssetFormData) => {
    if (!formData) {
      return {}
    }

    const payload = preparePayload(formData)
    const fd = createFormData(payload)
    const res: AssetV3 = await post(fd)

    if (res.id) {
      // await refetchAssets()

      if (res.budgetsList?.length) {
        setShowCalculator(true)
      } else {
        redirectToLeadsPage(res)
      }
    }

    return {}
  }

  return (
    <>
    <Container style={{ padding: '20px 10px' }}>
      <Grid container spacing={4}>
        <Grid item container sm={8}>
          {
            showCalculator && data
            ? (
              <AssetPriceContainer
                budgetsList={data.budgetsList ?? []}
                recommendedPrice={data.price}
                onBack={() => redirectToLeadsPage(data)}
                onSetPriceSuccess={() => redirectToLeadsPage(data)}
                assetId={data.id}
              />
            )
            : (
              <PostAssetForm
                onSubmit={handleOnSubmit}
              />
            )
          }
        </Grid>
        {
          !isCompactLayout
          ? (
            <Grid item container sm={4} style={{ flex: 1, position: 'relative' }}>
              <SellerTips sticky pattern={(showCalculator && data) ? 'tipsText.seller.price' : 'tipsText.seller.post'} />
            </Grid>
          )
          : (
            <SellerTipsFab pattern={(showCalculator && data) ? 'tipsText.seller.price' : 'tipsText.seller.post'} />
          )
        }
      </Grid>
      <PageLoader open={loading || refetchLoading} />
    </Container>
    </>
  )
}

export default PostAsset
