import React from 'react'
import numeral from 'numeral'
import { Grid } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

import { useBreakpoint } from '~/utils/breakpoints'
import AssetValueInfo from '~/components/modules/AssetValueInfo'
import { RangeType } from '~/types/Search'

export interface Props {
  price: RangeType
  rooms: RangeType
  squareSize: RangeType
}

const getRangeLabel = ({ min = 0, max = 100000, isPrice = false }: RangeType & {isPrice?: boolean}) => (
  isPrice ? `${`${numeral(min).format('0,0')} ₪`} - ${`${numeral(max).format('0,0')} ₪`}` : `${min} - ${max}`
)

const SearchDetailsMandatoryInfo: React.FC<Props> = ({ rooms, squareSize, price }) => {
  const { t } = useTranslation()
  const isCompactLayout = useBreakpoint(['small', 'medium'])

  return (
    <Grid container alignItems='flex-start' spacing={3}>
      <Grid item>
        <AssetValueInfo label={t('rooms')} value={getRangeLabel(rooms)} />
      </Grid>
      <Grid item>
        <AssetValueInfo label={t('squareSize')} value={getRangeLabel(squareSize)} />
      </Grid>
      <Grid item style={isCompactLayout ? {} : { marginRight: 'auto' }}>
        <AssetValueInfo label={t('budget')} value={getRangeLabel({ ...price, isPrice: true })} />
      </Grid>
    </Grid>
  )
}

export default SearchDetailsMandatoryInfo
