import { createStyles } from '@material-ui/core/styles'

import palette from '~/ThemeProvider/config/palette'

export const AssetCardStyled = createStyles({
  root: {
    height: 250,
    width: '100%',
    display: 'flex',
    alignItems: 'center'
  },
  media: {
    height: 200,
    minWidth: 300,
    maxWidth: 300,
    position: 'relative'
  },
  content: {
    height: 220
  },
  titleBar: {
    background:
      'linear-gradient(to top, rgba(0,0,0,0.9) 0%, rgba(0,0,0,0.5) 70%, rgba(0,0,0,0.1) 100%)'
  },
  avatar: {
    backgroundColor: palette.secondary.dark,
    fontSize: 16
  },
  waLogo: {
    width: '1em',
    height: '1em',
    fontSize: '18px'
  },
  removeBtn: {
    marginLeft: 'auto!important'
  },
  removeBtnIcon: {
    color: palette.error.light
  },
  infoItems: {
    flexGrow: 1
  }
})
