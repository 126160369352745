import React, { useState } from 'react'
import { useModal } from 'react-modal-hook'

import EmailContactModal, { TrackEvent } from '~/components/modules/EmailContactModal'

interface States {
  email?: string
  text?: string
  trackEvent?: TrackEvent
  onBeforeClose?: () => void
}

export default function useContactByEmailModal(): [(email: string, text?: string, trackEvent?: TrackEvent, onBeforeClose?: () => void) => void] {
  const [emailAddress, setEmailAddress] = useState<States>({})
  const [showModal, hideModal] = useModal(() => (
    <EmailContactModal
      onClose={hideModal}
      isOpen
      email={emailAddress.email}
      beforeClose={emailAddress.onBeforeClose}
      text={emailAddress.text}
      trackEvent={emailAddress.trackEvent}
    />
  ), [emailAddress.email])

  const renderEmailModal = (email: string, text?: string, trackEvent?: TrackEvent, onBeforeClose?: () => void) => {
    setEmailAddress({ email, text, trackEvent, onBeforeClose })

    showModal()
  }

  return [renderEmailModal]
}
