import React, { useState } from 'react'

import AssetDetailsModal from '~/components/modules/AssetDetails/Modal'

export default function useAssetDetailsModal(): [(JSX.Element | null), (string | null), (id: string | null) => void] {
  const [selectedLeadId, setSelectedLeadId] = useState<string | null>(null)

  const assetDetailsModal = selectedLeadId
    ? (
      <AssetDetailsModal
        leadId={selectedLeadId}
        onClose={() => setSelectedLeadId(null)}
      />
      )
    : null

  return [assetDetailsModal, selectedLeadId, setSelectedLeadId]
}
