import { createStyles } from '@material-ui/core/styles'

import { palette } from '~/ThemeProvider/config/palette'

export const StyledButton = createStyles({
  containedPrimary: {
    color: `${palette.common.white}`,
    '&:hover': {
      backgroundColor: palette.blue.dark,
      color: palette.common.white
    }
  },
  outlinedPrimary: {
    '&:hover': {
      backgroundColor: 'rgba(116, 198, 190, 0.23)'
    }
  },
  containedSecondary: {
    color: `${palette.common.white} !important`,
    '&:hover': {
      backgroundColor: palette.secondary.dark
    }
  },
  outlinedSecondary: {
    '&:hover': {
      backgroundColor: 'rgba(224, 135, 177, 0.23)'
    }
  }
})
