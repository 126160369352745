import React, { useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import CalendarTodayIcon from '@material-ui/icons/CalendarToday'
import ImportExportIcon from '@material-ui/icons/ImportExport'
import { Grid } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

import { useBreakpoint } from '~/utils/breakpoints'
import { useModal } from '~/utils/useModal'
import DeleteWarningModal from '~/components/modules/DeleteWarningModal'
import AssetMainDetailsHeader from '~/components/modules/AssetMainDetailsHeader'
import { PercentageIcon } from '~/components/modules/SearchResultsContanair'
import SortingButton from '~/components/core/SortingButton'
import Typography from '~/components/core/Typography'
import AssetActionsMenu from '~/components/modules/AssetActionsMenu'
import SellerLeadsHeader from '~/components/modules/SellerLeadsHeader'
// import PaymentsIndicator from '~/components/modules/PaymentsIndicator'
import DisableContainerMask from '~/components/core/DisableContainerMask'
import { ViewerProvider } from '~/components/core/ViewerProvider/context'
import { addressWithoutCountry } from '~/components/utils/formating'
import SellerLeadsFilters, { Props as SellerLeadsFiltersProps } from '~/components/modules/SellerLeadsFilters'
import { LeadsSorting } from '~/types/Leads'
import * as S from './styles'
import PublishedAssetMessage from '~/components/pages/AssetLeads/components/PublishedAssetMessage'

const useStyles = makeStyles(S.SearchResultsContanairStyled)

export interface Props {
  children: React.ReactNode
  name?: string
  onDelete: () => void
  hasLeads?: boolean
  leadsCount?: number
  shownToLeadsCount?: number
  onSortChange?: (type: 'date' | 'percentage' | 'price') => void
  sorting: LeadsSorting
  isLoadingLeads?: boolean
  onFilterChange?: SellerLeadsFiltersProps['onFilterChange']
  filtersState: SellerLeadsFiltersProps['filtersState']
  refetchLeads: (route?: string | undefined) => Promise<any>
}

// eslint-disable-next-line complexity
const SellerLeadsContanair: React.FC<Props> = ({
  children,
  onDelete,
  hasLeads,
  onSortChange,
  sorting,
  isLoadingLeads,
  onFilterChange,
  filtersState,
  leadsCount,
  refetchLeads,
  shownToLeadsCount
}) => {
  const { t } = useTranslation()
  const isCompactLayout = useBreakpoint(['small', 'medium'])
  const history = useHistory()
  const { id } = useParams<{ id?: string }>()
  const { assets } = React.useContext(ViewerProvider)
  const [openMenu, setOpenMenu] = useState(false)
  const classes = useStyles()
  const { showModal, hideModal, isOpen } = useModal()
  const selectedAsset = assets?.find((asset) => asset.id === id)

  if (!selectedAsset) {
    return null
  }

  return (
    <>
    <div className={classes.root}>
      <div className={classes.content}>
        <Grid container spacing={2} style={{ padding: '12px', position: 'relative' }} direction='column'>
          <Grid
            item
            container
            style={isCompactLayout
              ? {
              position: 'absolute',
              left: '0',
              width: 'fit-content'
            }
              : {
              width: 'auto'
            }}
          >
            <AssetActionsMenu
              onEdit={() => history.push(`/seller/editAsset/${id}`)}
              onRemove={showModal}
              onDetails={() => history.push(`/seller/asset/${id}`)}
              onEditPrice={() => history.push(`/seller/asset/${id}/price`)}
            />
          </Grid>
          <Grid item container>
            <Grid container spacing={2} justify='space-between'>
              <Grid item container style={{ flex: 1 }}>
                <Grid container spacing={2}>
                  <Grid item container alignItems='center' style={{ width: 'auto' }}>
                    <Typography weight='bold' size='xlarge'>
                      {addressWithoutCountry(selectedAsset.address)}
                    </Typography>
                  </Grid>
                  <Grid item container alignItems='center' style={{ width: 'auto' }}>
                    <Typography weight='bold' size='xlarge'>
                      {selectedAsset.forSale ? t('labelForSale') : t('labelForRent')}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              {/* <Grid item container style={{ width: 'auto' }}>
                <AssetActionsMenu
                  onEdit={() => history.push(`/seller/editAsset/${id}`)}
                  onRemove={showModal}
                  onDetails={() => history.push(`/seller/asset/${id}`)}
                  onEditPrice={() => history.push(`/seller/asset/${id}/price`)}
                />
              </Grid> */}
            </Grid>
          </Grid>
          <Grid item container style={{ width: 'auto' }}>
            <AssetMainDetailsHeader asset={selectedAsset} />
          </Grid>
          <Grid item container style={{ width: 'auto' }}>
            <PublishedAssetMessage sentCount={shownToLeadsCount} />
          </Grid>
          <Grid item container>
            <Grid container spacing={2} alignItems='flex-start' direction='column'>
              {/* <Grid item>
                <PaymentsIndicator id={id} hasLeads={hasLeads} />
              </Grid> */}
              {
                (hasLeads && !isLoadingLeads) && (
                  <>
                  <Grid item>
                    <Grid
                      container
                      spacing={2}
                      alignItems={isCompactLayout ? 'flex-start' : 'center'}
                      direction='row'
                      wrap='nowrap'
                    >
                      <Grid item>
                        <Grid container spacing={0} alignItems='center' wrap='nowrap'>
                          <Grid item>
                            <ImportExportIcon />
                          </Grid>
                          <Grid item style={{ minWidth: '60px' }}>
                            <Typography size='large' style={{ whiteSpace: 'nowrap' }}>
                              {t('sortBy')}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item>
                        <Grid container spacing={2}>
                          <Grid item>
                            <SortingButton size='small' icon={<CalendarTodayIcon fontSize='small' />} ascending={Boolean(sorting.ascending)} label='תאריך' active={sorting.type === 'date'} onClick={() => onSortChange?.('date')} />
                          </Grid>
                          <Grid item>
                            <SortingButton size='small' icon={<PercentageIcon active={sorting.type === 'percentage'} />} ascending={Boolean(sorting.ascending)} label='אחוז התאמה' active={sorting.type === 'percentage'} onClick={() => onSortChange?.('percentage')} />
                          </Grid>
                          <Grid item>
                            <SortingButton size='small' icon={<PercentageIcon symbol='₪' active={sorting.type === 'price'} />} ascending={Boolean(sorting.ascending)} label='תקציב' active={sorting.type === 'price'} onClick={() => onSortChange?.('price')} />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  {
                    !!onFilterChange && (
                      <Grid item>
                        <SellerLeadsFilters
                          filtersState={filtersState}
                          onFilterChange={onFilterChange}
                        />
                      </Grid>
                    )
                  }
                  </>
                )
              }
            </Grid>
          </Grid>
        </Grid>
        <SellerLeadsHeader
          leadsCount={leadsCount}
          name={addressWithoutCountry(selectedAsset.address)}
          assets={assets}
          openMenu={openMenu}
          onMenuToggle={() => setOpenMenu(!openMenu)}
          onSendToAllMessage={refetchLeads}
        />
        <DisableContainerMask active={openMenu} invisible>
          {children}
        </DisableContainerMask>
      </div>
    </div>
    <DeleteWarningModal
      isOpen={isOpen}
      onClose={hideModal}
      mainText={t('modalRemoveProperty')}
      onSubmit={() => {
        onDelete()
        hideModal()
      }}
    />
    </>
  )
}

export default SellerLeadsContanair
