import AccessibleIcon from '@material-ui/icons/Accessible'
import EmojiTransportationIcon from '@material-ui/icons/EmojiTransportation'
import ApartmentIcon from '@material-ui/icons/Apartment'
import FilterFramesIcon from '@material-ui/icons/FilterFrames'
import HttpsIcon from '@material-ui/icons/Https'
import LocalFloristIcon from '@material-ui/icons/LocalFlorist'
import LooksIcon from '@material-ui/icons/Looks'
import StorefrontIcon from '@material-ui/icons/Storefront'
import BuildIcon from '@material-ui/icons/Build'
import WeekendIcon from '@material-ui/icons/Weekend'
import StorageIcon from '@material-ui/icons/Storage'
import LocationCityIcon from '@material-ui/icons/LocationCity'
import AccountBalanceIcon from '@material-ui/icons/AccountBalance'
import LocalAtmIcon from '@material-ui/icons/LocalAtm'
import DashboardIcon from '@material-ui/icons/Dashboard'
import MeetingRoomIcon from '@material-ui/icons/MeetingRoom'
import ForumIcon from '@material-ui/icons/Forum'
import ContactPhoneIcon from '@material-ui/icons/ContactPhone'
import PageviewIcon from '@material-ui/icons/Pageview'
import WorkOutlineIcon from '@material-ui/icons/WorkOutline'
import VpnKeyIcon from '@material-ui/icons/VpnKey'
import CalendarTodayIcon from '@material-ui/icons/CalendarToday'
import SubjectIcon from '@material-ui/icons/Subject'

export const ASSET_PROPS_ICONS = {
  balcony: StorefrontIcon,
  elevator: FilterFramesIcon,
  floor: ApartmentIcon,
  garden: LocalFloristIcon,
  hadicap: AccessibleIcon,
  panthouse: AccountBalanceIcon,
  parking: EmojiTransportationIcon,
  safeRoom: HttpsIcon,
  storage: StorageIcon,
  view: LooksIcon,
  furnished: WeekendIcon,
  remodel: BuildIcon,
  city: LocationCityIcon,
  budget: LocalAtmIcon,
  squareSize: DashboardIcon,
  rooms: MeetingRoomIcon,
  communications: ForumIcon,
  contactNumber: ContactPhoneIcon,
  searchType: PageviewIcon,
  isAgent: WorkOutlineIcon,
  postType: VpnKeyIcon,
  entranceDate: CalendarTodayIcon,
  description: SubjectIcon
}

export const BOOLEAN_FIELD = ['balcony', 'elevator', 'floor', 'garden', 'hadicap', 'panthouse', 'parking', 'safeRoom', 'storage', 'view', 'furnished', 'remodel']
