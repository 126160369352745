import React, { ReactElement } from 'react'
import { isNull } from 'lodash'
import {
  useField,
  FieldProps as FinalFieldProps,
  FieldRenderProps
} from 'react-final-form'

import BaseToggleButtonGroup, { Props as BaseToggleButtonGroupProps } from '~/components/core/Inputs/ToggleButton/Group'
import { Props as ToggleButtonProps } from '~/components/core/Inputs/ToggleButton/Item'
import FormField from '../FormField'

export type FieldProps<TInputValue> = FinalFieldProps<
  TInputValue,
  FieldRenderProps<TInputValue, HTMLInputElement>,
  HTMLInputElement
>

export type Props = BaseToggleButtonGroupProps & FieldProps<ToggleButtonProps['value']>

export const ToggleButtonGroup: React.FC<Props> = (props) => {
  const { children, ...rest } = props
  const { meta, input } = useField(rest.name)

  const handleOnChange = (_e: any, val: any) => {
    if (isNull(val)) {
      return
    }

    input.onChange(val)
  }

  return (
    <FormField error={meta.touched && (meta.error || meta.submitError)}>
      <BaseToggleButtonGroup {...props} {...input} onChange={handleOnChange}>
        {React.Children.toArray(children)
          .filter(React.isValidElement)
          .map(child =>
            React.cloneElement(child as ReactElement, {
              name: props.name
            })
          )}
      </BaseToggleButtonGroup>
    </FormField>
  )
}

export default ToggleButtonGroup
