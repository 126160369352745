import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import Grid from '@material-ui/core/Grid'
import CardActionArea from '@material-ui/core/CardActionArea'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import Skeleton from '@material-ui/lab/Skeleton'

import * as S from './styles'

const useStyles = makeStyles(S.AssetCardStyled)

const AssetCardWideLoader: React.FC = () => {
  const classes = useStyles()

  return (
    <Card className={classes.root}>
      <div className={classes.media}>
          <Skeleton variant='rect' width='100%' height='100%' />
      </div>
      <CardActionArea>
      <Grid className={classes.content} container alignItems='stretch' justify='space-between' direction='column'>
        <Grid item>
          <CardContent>
            <Grid spacing={1} container alignItems='stretch' justify='space-between' direction='column'>
              <Grid item>
                <Grid container alignItems='stretch' justify='space-between'>
                  <Grid item>
                    <Skeleton animation='wave' width={200} height={40} />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Grid container alignItems='stretch' spacing={8}>
                  <Grid item>
                    <Skeleton animation='wave' width={100} height={20} />
                  </Grid>
                  <Grid item>
                    <Skeleton animation='wave' width={100} height={20} />
                  </Grid>
                  <Grid item>
                  <Skeleton animation='wave' width={100} height={20} />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Grid container alignItems='center' spacing={1}>
                  <Skeleton animation='wave' width='100%' height={30} />
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Grid>
        <Grid item>
          <CardActions>
            <Skeleton variant='rect' width={56} height={18} />
            <Skeleton variant='rect' width={56} height={18} />
            <Skeleton variant='rect' width={56} height={18} />
          </CardActions>
        </Grid>
      </Grid>
      </CardActionArea>
    </Card>
  )
}

export default AssetCardWideLoader
