export interface Layout {
  contentWidthWide: string
  contentWidth: string
  contentMinWidth?: string
  contentPaddingHorizontal: string
}

const layout: Layout = {
  contentWidthWide: '90rem',
  contentWidth: '68rem',
  contentPaddingHorizontal: '1rem'
}

export default layout
