import React from 'react'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import Skeleton from '@material-ui/lab/Skeleton'

const LeadsItemLoader: React.FC = () => {
  return (
    <ListItem>
      <ListItemAvatar>
        <Skeleton variant='rect' width={40} height={40} />
      </ListItemAvatar>
      <ListItemText primary={<Skeleton animation='wave' width={100} height={24} />} secondary={<Skeleton animation='wave' width={140} height={22} />} color='secondary' />
      <Skeleton variant='rect' animation='wave' width={120} height={44} />
    </ListItem>
  )
}

export default LeadsItemLoader
