import React from 'react'
import { Grid } from '@material-ui/core'
// import { useTranslation } from 'react-i18next'

import Table from '~/components/core/Table'
import { MostPopularTag } from '~/components/core/Icons'
import { PaymentType } from '~/types/Payment'
import { useBreakpoint } from '~/utils/breakpoints'
import PlanSellerHeaderCell from './PlanSellerHeaderCell'
import PlanSellerBodyFeature from './PlanSellerBodyFeature'
import PlanSellerHeaderFeatures from './PlanSellerHeaderFeatures'
import PlanCellCheck from './PlanCellCheck'
import PlanSellerBodyTextCell from './PlanSellerBodyTextCell'
import MobilePlanItem from './mobile/PlanItem'
import ScrollToBottomButton from '~/components/modules/ScrollToBottomButton'

export enum PlanColor {
  grey = 'grey',
  green = 'green',
  darkGreen = 'dark-green',
}

export const COLOR_MAP: Record<PlanColor, string> = {
  [PlanColor.grey]: '#949090',
  [PlanColor.green]: '#4BB5AF',
  [PlanColor.darkGreen]: '#006064'
}

export interface PlansSellerProps {
  value?: PaymentType
  onSubmit: (value: PaymentType | undefined) => void
}

const PlansSeller: React.FC<PlansSellerProps> = ({
  onSubmit
}) => {
  // const { t } = useTranslation()
  const isCompactLayout = useBreakpoint(['small', 'medium'])

  if (!isCompactLayout) {
    return (
      <Table variant='bordered'>
        <Table.Head>
          <Table.Row>
            <Table.Cell>
              <PlanSellerHeaderFeatures />
            </Table.Cell>
            <Table.Cell>
              <PlanSellerHeaderCell
                title='חינם'
                value='free'
                onSubmit={onSubmit}
                subTitle='לתמיד'
                color={PlanColor.grey}
              />
            </Table.Cell>
            <Table.Cell>
            <PlanSellerHeaderCell
              title='₪139'
              value='standard'
              onSubmit={onSubmit}
              subTitle='ללא הגבלת זמן'
              color={PlanColor.green}
            />
            </Table.Cell>
            <Table.Cell>
            <PlanSellerHeaderCell
              title='₪179'
              subTitle='28 ימים'
              value='premium'
              onSubmit={onSubmit}
              color={PlanColor.darkGreen}
              tag={<MostPopularTag />}
            />
            </Table.Cell>
          </Table.Row>
        </Table.Head>
        <Table.Body>
        <Table.Row bordered>
            <Table.Cell>
              <PlanSellerBodyFeature
                title='פרסום המודעה'
                subTitle='אתם מחליטים מי מהמחפשים יראה את הדירה שלכם'
              />
            </Table.Cell>
            <Table.Cell>
              <PlanSellerBodyTextCell text='15 מחפשים מתאימים' subText='בסה”כ לכל הדירות שלך' color={PlanColor.grey} />
            </Table.Cell>
            <Table.Cell>
            <PlanSellerBodyTextCell text='10 מחפשים מתאימים' subText='לדירה המשודרגת' color={PlanColor.green} />
            </Table.Cell>
            <Table.Cell>
            <PlanSellerBodyTextCell text='ללא הגבלה' subText='לדירה המשודרגת' color={PlanColor.darkGreen} />
            </Table.Cell>
          </Table.Row>
          <Table.Row bordered>
            <Table.Cell>
              <PlanSellerBodyFeature
                title='בדיקת ביקוש בזמן אמת'
                subTitle='מידע לגבי כמה מחפשים מתאימים יש במחיר המבוקש'
              />
            </Table.Cell>
            <Table.Cell>
              <PlanCellCheck color={PlanColor.grey} />
            </Table.Cell>
            <Table.Cell>
            <PlanCellCheck color={PlanColor.green} />
            </Table.Cell>
            <Table.Cell>
            <PlanCellCheck color={PlanColor.darkGreen} />
            </Table.Cell>
          </Table.Row>
          <Table.Row bordered>
            <Table.Cell>
              <PlanSellerBodyFeature
                title='צפייה בפרטי החיפוש והתקציב של המחפשים המתאימים'
                subTitle='לפי פרטי החיפוש והתקציב תוכלו להחליט למי לשלוח את המודעה'
              />
            </Table.Cell>
            <Table.Cell>
              <PlanCellCheck color={PlanColor.grey} />
            </Table.Cell>
            <Table.Cell>
            <PlanCellCheck color={PlanColor.green} />
            </Table.Cell>
            <Table.Cell>
            <PlanCellCheck color={PlanColor.darkGreen} />
            </Table.Cell>
          </Table.Row>
          <Table.Row bordered>
            <Table.Cell>
              <PlanSellerBodyFeature
                title='התראות על מחפשים חדשים'
                subTitle='נשלח לכם התראה שנמצא מחפשים חדשים מתאימים'
              />
            </Table.Cell>
            <Table.Cell>
              <PlanCellCheck color={PlanColor.grey} />
            </Table.Cell>
            <Table.Cell>
            <PlanCellCheck color={PlanColor.green} />
            </Table.Cell>
            <Table.Cell>
            <PlanCellCheck color={PlanColor.darkGreen} />
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    )
  }

  return (
    <>
    <Grid
      direction='column'
      alignItems='stretch'
      justify='center'
      spacing={2}
      container
      style={{ marginBottom: 30, width: '100%' }}
    >
      <Grid item>
        <MobilePlanItem
          color={PlanColor.grey}
          planType='free'
          onSubmit={onSubmit}
          headerTitle='חינם'
          headerSubtitle='לתמיד'
          textCell='15'
          subTextCell='לסך כל הדירות'
        />
      </Grid>
      <Grid item>
        <MobilePlanItem
          color={PlanColor.green}
          planType='standard'
          onSubmit={onSubmit}
          headerTitle='₪139'
          headerSubtitle='ללא הגבלת זמן'
          textCell='10'
          subTextCell='לדירה המשודרגת'
        />
      </Grid>
      <Grid item>
        <MobilePlanItem
          color={PlanColor.darkGreen}
          planType='premium'
          onSubmit={onSubmit}
          headerTitle='₪179'
          headerSubtitle='28 ימים'
          textCell='ללא הגבלה'
          subTextCell='לדירה המשודרגת'
          headerTag={<MostPopularTag />}
        />
      </Grid>
    </Grid>
    <ScrollToBottomButton />
    </>
  )
}

export default PlansSeller
