import React, { SVGProps } from 'react'
const Match = (props: SVGProps<SVGSVGElement>): JSX.Element => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={28}
    height={28}
    fill='none'
    {...props}
    color={props.color || 'inherit'}
  >
    <g clipPath='url(#a)'>
      <path fill='#fff' d='M0 0h28v28H0z' />
      <path
        fill='currentcolor'
        d='M12.833 13.997A4.665 4.665 0 0 0 17.5 9.331a4.665 4.665 0 0 0-4.667-4.667 4.665 4.665 0 0 0-4.666 4.667 4.665 4.665 0 0 0 4.666 4.666Zm0-7a2.34 2.34 0 0 1 2.334 2.334 2.34 2.34 0 0 1-2.334 2.333A2.34 2.34 0 0 1 10.5 9.331a2.34 2.34 0 0 1 2.333-2.334Zm-7 14c.234-.735 2.999-1.96 5.787-2.263L14 16.401c-.455-.047-.793-.07-1.167-.07-3.115 0-9.333 1.563-9.333 4.666v2.334H14l-2.333-2.334H5.833Zm18.2-6.416-5.985 6.031-2.415-2.426L14 19.83l4.048 4.083 7.619-7.688-1.634-1.645Z'
      />
    </g>
    <path stroke='#000' strokeWidth={2} d='M1 1h26v26H1z' />
    <defs>
      <clipPath id='a'>
        <path fill='#fff' d='M0 0h28v28H0z' />
      </clipPath>
    </defs>
  </svg>
)

export default Match
