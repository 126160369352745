/* eslint-disable complexity */
import React, { useState, useCallback } from 'react'
import { Grid, CardActions } from '@material-ui/core'
import useFetch, { CachePolicies } from 'use-http'
import { makeStyles } from '@material-ui/core/styles'
import Avatar from '@material-ui/core/Avatar'
import CircularProgress from '@material-ui/core/CircularProgress'

import { AuthProvider } from '~/components/core/AuthProvider/context'
import Button from '~/components/core/Button'
import getInitials from '~/components/utils/getInitials'
import Typography from '~/components/core/Typography'
import SettingsUserDetails from '~/components/modules/SettingsUserDetails'
import UserNotificationSwitch, { getPayloadValues } from '~/components/modules/UserNotificationSwitch'
import Modal from '~/components/core/Modal'
import { ViewerProvider } from '~/components/core/ViewerProvider/context'
import * as S from '~/components/pages/Settings/styles'

const useStyles = makeStyles(S.Styled)

const ChooseNotificationMethod = (): JSX.Element => {
  const { currentUser } = React.useContext(AuthProvider)
  const { refetchUserDetails, userDetails, refetchLoading } = React.useContext(ViewerProvider)
  const classes = useStyles()
  const [selectValue, setSelectValue] = useState<string[]>(['whatsapp', 'email'])
  const { post, loading: postLoading } = useFetch(`/users/setUserNotifications`, { cachePolicy: CachePolicies.NO_CACHE })
  const [displayName, setDisplayName] = useState(currentUser?.displayName)
  const [hideModal, setHideModal] = useState(false)

  const handleSave = useCallback(() => {
    const payload = getPayloadValues({
      userId: currentUser?.uid,
      values: selectValue
    })

    post(payload).then(() => {
      setHideModal(true)

      return refetchUserDetails()
    }).catch((error) => {
      setHideModal(true)
      console.error('error', error)
    })
  }, [post, selectValue, currentUser, refetchUserDetails])

  if (!userDetails?.showNotificationOnLoad || hideModal) {
    return <></>
  }

  return (
    <Modal open fullWidth>
      <Modal.Title>
      הגדרות פרופיל
      </Modal.Title>
      <Modal.Content dividers>
        <Grid container direction='column' spacing={1}>
          <Grid item alignItems='center' justify='center' container>
            <Avatar className={classes.avatar} variant='circular'>{getInitials(displayName || '')}</Avatar>
          </Grid>
          <Grid item container alignItems='center' justify='center' style={{ marginTop: 20 }} className={classes.userDetailsWrp}>
            <SettingsUserDetails onNameChange={setDisplayName} />
          </Grid>
          <Grid item container alignItems='center' justify='center' className={classes.userDetailsWrp}>
            <UserNotificationSwitch onChange={setSelectValue} initValues={selectValue} avoidSubmitOnChange />
          </Grid>
        </Grid>
      </Modal.Content>
        <Modal.Actions>
          <CardActions style={{ width: '100%', justifyContent: 'flex-end' }}>
            <Button size='small' color='primary' variant='text' onClick={handleSave} disabled={postLoading || refetchLoading}>
              {(postLoading || refetchLoading)
? <CircularProgress color='primary' size='23px' />
                : (<Typography size='medium' weight='bold'>
                שמור
              </Typography>)}
            </Button>
          </CardActions>
        </Modal.Actions>
    </Modal>
  )
}

export default ChooseNotificationMethod
