import React from 'react'
import { Grid, Box, Slide } from '@material-ui/core'
import { TransitionProps } from '@material-ui/core/transitions'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'
import {
  EmailShareButton,
  EmailIcon,
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  WhatsappShareButton,
  WhatsappIcon
} from 'react-share'

import Modal from '~/components/core/Modal'
import useCopyToClipboard from '~/hooks/useCopyToClipboard'
import Button from '~/components/core/Button'
import Typography from '~/components/core/Typography'
import segment from '~/services/segment'
import * as S from './styles'

export interface Props {
  onClose: () => void
  isOpen: boolean
  assetId?: string
  isSeller?: boolean
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Slide direction='up' ref={ref} {...props} />;
});

interface InnerButtonProps {
  label: string
  icon: React.ReactNode
}

const InnerButton: React.FC<InnerButtonProps> = ({ label, icon }) => (
  <Box width={120}>
    <Grid container spacing={1} alignItems='center'>
      <Grid item>
        {icon}
      </Grid>
      <Grid item>
        <Typography size='large'>
          {label}
        </Typography>
      </Grid>
    </Grid>
  </Box>
)

const useStyles = makeStyles(S.MySearchesListStyled)

const ShareAssetModal: React.FC<Props> = ({ onClose, isOpen, assetId, isSeller }) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const [copyToClipboard] = useCopyToClipboard(t('assetShareUrlCopied'))
  const shareUrl = `${window.location.origin}/public/asset/${assetId}`

  const handleOnClick = (source: string) => {
    segment.track('User Shared Asset', {
      source,
      isSellerShared: !!isSeller
    })
  }

  const handleOnClickCopy = () => {
    copyToClipboard(shareUrl)
    handleOnClick('copied')
  }

  return (
    <Modal open={isOpen} TransitionComponent={Transition} onBackdropClick={onClose}>
      <Modal.Title onClose={onClose}>
        <Typography size='large'>
          {t('shareTitle')}
        </Typography>
      </Modal.Title>
      <Modal.Content dividers>
        <Grid container spacing={2} direction='column' justify='space-between'>
          <Grid item>
            <Grid container spacing={1} justify='space-evenly'>
              <Grid item onClick={() => handleOnClick('email')}>
                <EmailShareButton body={t('shareMsgText')} subject={t('shareMsgText')} url={shareUrl}>
                  <InnerButton label={t('email')} icon={<EmailIcon round size={40} />} />
                </EmailShareButton>
              </Grid>
              <Grid item>
                <FacebookShareButton onClick={() => handleOnClick('facebook')} title={t('shareMsgText')} url={shareUrl}>
                  <InnerButton label={t('facebook')} icon={<FacebookIcon round size={40} />} />
                </FacebookShareButton>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container spacing={1} justify='space-evenly'>
              <Grid item>
                <WhatsappShareButton onClick={() => handleOnClick('WhatsApp')} title={t('shareMsgText')} url={shareUrl}>
                  <InnerButton label={t('label-WhatsApp')} icon={<WhatsappIcon round size={40} />} />
                </WhatsappShareButton>
              </Grid>
              <Grid item>
                <TwitterShareButton onClick={() => handleOnClick('twitter')} title={t('shareMsgText')} url={shareUrl}>
                  <InnerButton label={t('twitter')} icon={<TwitterIcon round size={40} />} />
                </TwitterShareButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Modal.Content>
        <Modal.Actions className={classes.actions}>
          <Box width='100%' border='1px solid' borderRadius={6} padding={1}>
            <Grid container spacing={1} wrap='nowrap'>
              <Grid item>
                <Button size='small' color='default' variant='outlined' onClick={handleOnClickCopy}>
                  <Typography size='small'>
                    {t('copy')}
                  </Typography>
                </Button>
              </Grid>
              <Grid item container alignItems='center' className={classes.textUrl}>
                <Typography>
                  {shareUrl}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Modal.Actions>
    </Modal>
  )
}

export default ShareAssetModal
