import React, { useCallback } from 'react'
import useFetch, { CachePolicies } from 'use-http'
import { Container } from '@material-ui/core'

import Link from '~/components/core/Link'
import { rollbarError } from '~/utils/rollbar'
import Button from '~/components/core/Button'
import Typography from '~/components/core/Typography'
import { ClacAssetLarge } from '~/components/core/Icons'
import ResultsMessage from '~/components/core/ResultsMessage'
import { BudgetRange } from '~/types/Asset'
import { useBreakpoint } from '~/utils/breakpoints'
import AssetPriceCalculator from '~/components/pages/v3/AssetPriceCalculator'

interface Props {
  assetId: string
  onSetPriceSuccess: () => void
  budgetsList: BudgetRange[]
  recommendedPrice: number
  onBack: () => void
}

const AssetPriceContainer = ({
  assetId,
  budgetsList,
  recommendedPrice,
  onSetPriceSuccess,
  onBack
}: Props): JSX.Element => {
  const isCompactLayout = useBreakpoint(['small', 'medium'])
  const { put, loading: postLoading, error } = useFetch(`/api/v3/assets/asset/${assetId}/price`, {
    cachePolicy: CachePolicies.NO_CACHE
  })

  const handleNext = useCallback(async (price: number) => {
    await put({ price })

    onSetPriceSuccess()
  }, [onSetPriceSuccess, put])

  if (error) {
    rollbarError({
      error
    })

    return <></>
  }

  if (!budgetsList.length) {
    return (
      <Container style={{ padding: '20px 10px', width: isCompactLayout ? '100%' : '500px' }}>
        <ResultsMessage
          title='מחשבון ביקוש'
          subTitle='לא מצאנו מחפשים מתאימים במחיר שהגדרת כדי שנוכל לבדוק טווח מחירים אחר הגדירו מחיר נמוך יותר לדירה'
          icon={<ClacAssetLarge style={{ marginTop: 40 }} />}
          actionButton={(
            <Link color='inherit' to={`/seller/editAsset/${assetId}`} style={{ textDecoration: 'none' }}>
              <Button variant='outlined' color='primary' style={{ marginTop: '20px' }}>
                <Typography size='large' noWrap>עריכת מחיר</Typography>
              </Button>
            </Link>
          )}
        />
      </Container>
    )
  }

  return (
    <AssetPriceCalculator
      budgetsList={budgetsList}
      recommendedPrice={recommendedPrice}
      loading={postLoading}
      onBack={onBack}
      onNext={handleNext}
    />
  )
}

export default AssetPriceContainer
