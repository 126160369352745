export default {
  initialize({
    userId, userEmail
  }: { userId: string, userEmail: string }) {
    if (window.fbq) {
      window.fbq('init', '647024295717262', {
        em: userEmail,
        external_id: userId
      });
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this._defaultPayload = {
      em: userEmail,
      external_id: userId
    };
  },
  track(eventName: string) {
    const payload = {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      ...(this._defaultPayload || {})
    }

    window.fbq('track', eventName, payload);

    if (process.env.NODE_ENV === 'development') {
      // eslint-disable-line no-console
      console.log(`Sent event to Facebook Pixel: "${eventName}"`);
    }
  }
};
