import React, { SVGProps } from 'react'

const Send = (props: SVGProps<SVGSVGElement>): JSX.Element => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={19}
    height={16}
    fill='none'
    {...props}
    color={props.color || 'inherit'}
>
    <path
      fill='currentcolor'
      d='M2.342 3.025 8.6 5.708l-6.266-.833.008-1.85Zm6.25 7.267-6.258 2.683v-1.85l6.258-.833ZM.675.5.667 6.333 13.167 8 .667 9.667.675 15.5 18.167 8 .675.5Z'
    />
  </svg>
)

export default Send
