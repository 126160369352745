import { createStyles, Theme } from '@material-ui/core/styles'

import { screens } from '~/utils/breakpoints'
import palette from '~/ThemeProvider/config/palette'

export const Button = (theme: Theme): Record<never, string> => createStyles({
  root: {
    color: palette.primary.dark,
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [screens('small', 'medium')]: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1)
    },
    border: `1px solid ${palette.primary.dark} !important`,
    borderRadius: '20px !important',
    fontSize: '0.75rem',
    minWidth: '72px',
    height: '30px'
  },
  selected: {
    color: `${palette.common.white} !important`,
    backgroundColor: `${palette.primary.dark} !important`
  }
})
