import React, { useState } from 'react'
import useFetch, { CachePolicies } from 'use-http'
import { useHistory } from 'react-router-dom'

import { ViewerFavoriteContext } from './context'
import PageLoader from '~/components/core/PageLoader'
import { SearchLead } from '~/types/Search'

interface Props {
  children: React.ReactElement | React.ReactNode
}

const ViewerFavoriteProvider: React.FC<Props> = ({ children }) => {
  const history = useHistory()
  const [favorites, setFavorites] = useState<SearchLead[]>()
  const { loading: favoritesLeadsLoading, error: favoriteError, get: refechFavoriteLeads } = useFetch<SearchLead[]>(`/api/v2/searcher/favorites`,
    {
      cachePolicy: CachePolicies.NO_CACHE,
      onNewData: (_currTodos, newData) => {
        setFavorites(newData)

        return newData
      }
    },
  [])
  const isBuyerRoutes = history.location.pathname.includes('/buyer')
  const removeFavoriteState = (leadId: string) => {
    const filteredFav = favorites?.filter((asset) => asset.leadId !== leadId)

    setFavorites(filteredFav)
  }

  const addFavoriteState = (lead: SearchLead) => {
    const newFavorites = [...(favorites || []), { ...lead }]

    setFavorites(newFavorites)
  }

  if (isBuyerRoutes && (!favorites && favoritesLeadsLoading)) {
    return <PageLoader invisible open />
  }

  if (favoriteError) {
    console.error(favoriteError)
  }

  return (
    <ViewerFavoriteContext.Provider
      value={{
        searchFavorites: favorites,
        searchFavoritesLoading: favoritesLeadsLoading,
        refetchSearchFavorites: refechFavoriteLeads,
        removeFavoriteState,
        addFavoriteState
      }}
    >
      {children}
    </ViewerFavoriteContext.Provider>
  )
}

export default ViewerFavoriteProvider
