import { createStyles, Theme } from '@material-ui/core/styles'
import { deepPurple, brown } from '@material-ui/core/colors'

import palette from '~/ThemeProvider/config/palette'

export const LeadItemStyled = (theme: Theme) =>
createStyles({
  pink: {
    fontSize: '1.3rem',
    color: theme.palette.getContrastText(palette.secondary.dark),
    backgroundColor: palette.secondary.dark
  },
  purple: {
    fontSize: '1.3rem',
    color: theme.palette.getContrastText(deepPurple[500]),
    backgroundColor: deepPurple[500]
  },
  green: {
    fontSize: '1.3rem',
    color: '#fff',
    backgroundColor: palette.primary.main,
    cursor: 'pointer'
  },
  brown: {
    fontSize: '1.3rem',
    color: theme.palette.getContrastText(brown[500]),
    backgroundColor: brown[500]
  }
})
