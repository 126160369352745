import { createStyles } from '@material-ui/core/styles'

export const Styled = createStyles({
  root: {
    color: '#fff'
  },
  colorSecondary: {
    backgroundColor: '#792853',
    color: '#fff'
  }
})
