import React, { useState } from 'react'
import { createStyles, makeStyles } from '@material-ui/core/styles'

import Image from '~/components/core/Image'
import { getIconUri } from '~/utils/img-access'
import { SearchLead } from '~/types/Search'
import MarkerSearchPopper from '~/components/modules/MarkerSearchPopper'
import AssetCard from '~/components/modules/AssetCard'

const mapMarkerPink = getIconUri('mapMarkerSecondary.svg');
const mapMarkerGreen = getIconUri('mapMarkerPrimary.svg');

export interface Props {
  lat: number
  lng: number
  leadObj?: SearchLead
  onLeadCardClick?: (leadObj?: SearchLead) => void
  isHovered?: boolean
}

export const Styled = createStyles({
  wrapper: {
    height: 40,
    width: 'auto'
  },
  marker: {
    height: 40,
    width: 'auto'
  }
})

const useStyles = makeStyles(Styled)

const MapMarker: React.FC<Props> = ({ lat, lng, leadObj, isHovered, onLeadCardClick = () => {} }) => {
  const classes = useStyles()
  const markerRef = React.useRef<HTMLDivElement>(null)
  const [openPopper, setOpenPopper] = useState<boolean>(false)
  const isFocused = (openPopper || isHovered)
  const markerSrc = isFocused ? mapMarkerGreen : mapMarkerPink

  const handleOnMarkerClick = (e: any) => {
    e?.stopPropagation()

    if (!leadObj) {
      return
    }

    return setOpenPopper(!openPopper)
  }

  const marker = (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <div ref={markerRef} className={classes.wrapper} style={{ zIndex: isFocused ? 999 : 998 }} lat={lat} lng={lng}>
      <Image alt='logo' src={markerSrc} className={classes.marker} />
    </div>
  )

  if (leadObj) {
    return (
      <>
      <MarkerSearchPopper anchorRef={markerRef} open={openPopper} onClose={() => setOpenPopper(false)}>
        <div style={{ height: 300, width: 270, zIndex: 99999 }}>
          <AssetCard item={leadObj} allowFullScreen={false} onClick={() => onLeadCardClick(leadObj)} />
        </div>
      </MarkerSearchPopper>
      <div onClick={handleOnMarkerClick}>
        {marker}
      </div>
      </>
    )
  }

  return marker
}

export default MapMarker
