import React from 'react'
import { Grid, Box } from '@material-ui/core'

import ViewerNavbarSearches from '~/components/modules/ViewerNavbarSearches'
import ViewerNavbarAssets from '~/components/modules/ViewerNavbarAssets'

const ViewerNavbar: React.FC = () => {
  return (
    <Box marginX={4}>
      <Grid container spacing={2}>
        <Grid item>
          <ViewerNavbarSearches />
        </Grid>
        <Grid item>
          <ViewerNavbarAssets />
        </Grid>
      </Grid>
    </Box>
  )
}

export default ViewerNavbar
