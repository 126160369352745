import React from 'react'
import {
  useField,
  FieldProps as FinalFieldProps,
  FieldRenderProps
} from 'react-final-form'
import { useTranslation } from 'react-i18next'

import BaseInput from '~/components/core/Inputs/TextInput'
import FormField from '../FormField'

type ValueType =
  | string
  | string[]
  | number
  | boolean
  | null
  | undefined
  | File

export type FieldProps<TInputValue> = FinalFieldProps<
  TInputValue,
  FieldRenderProps<TInputValue, HTMLInputElement>,
  HTMLInputElement
>

export type Props<
  TInputValue
> = FieldProps<TInputValue> & {
    name: string
    type?: string
    hideFieldLabel?: boolean
    fieldType?: string
  }

const FORBIDEN_CHARS = ['$', '&&', '||', '<', '>', '\\script', '\\style', 'onerror']

export const Input: React.FC<Props<ValueType>> = (props) => {
  const { t } = useTranslation()
  const { meta, input } = useField(props.name)
  const errChars = FORBIDEN_CHARS.filter((char) => input.value.includes(char)).length ? t('error-ilagalChars') : undefined
  const error = errChars || (meta.touched && (meta.error || meta.submitError))

  return (
    <FormField error={error}>
      <BaseInput {...props} {...input} error={!!error} />
    </FormField>
  )
}

export default Input
