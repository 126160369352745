import { createStyles } from '@material-ui/core/styles'

export default () =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'center',
      height: '20px',
      alignItems: 'center'
    },
    body: {
      fontSize: '14px',
      display: 'flex',
      alignSelf: 'center'
    }
  })
