import React, { ReactNode } from 'react'
import { withStyles } from '@material-ui/core/styles'
import DialogContent, { DialogContentProps } from '@material-ui/core/DialogContent'

import * as S from './styles'

const StyledModalContent = withStyles(S.ModalContent)(DialogContent)

export interface Props extends DialogContentProps {
  children?: ReactNode
}

const ModalContent: React.FC<Props> = ({ children, ...other }) => {
  return (
    <StyledModalContent {...other}>
      {children}
    </StyledModalContent>
  )
}

export default ModalContent
