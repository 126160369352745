import { createStyles } from '@material-ui/core/styles'

export const MySearchesListStyled = () =>
createStyles({
  actions: {
    justifyContent: 'center'
  },
  textUrl: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    maxWidth: '300px',
    width: 'auto',
    direction: 'rtl'
  }
})
