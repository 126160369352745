import { createStyles } from '@material-ui/core/styles'

export const AssetCardStyled = createStyles({
  root: {
    width: '100%',
    zIndex: 10
  },
  list: {
    zIndex: 10
  }
})
