import React, { FC, HTMLAttributes, ReactChild } from 'react'

import HoomeTheme from './ThemeProvider'
import Hoome from '~/components/modules/Hoome'
import { initialize } from '~/i18n'

initialize()

export interface Props extends HTMLAttributes<HTMLDivElement> {
  children?: ReactChild;
}

const App: FC<Props> = () => {
  return (
    <HoomeTheme>
      <Hoome />
    </HoomeTheme>
  )
}

export default App
