import { createStyles } from '@material-ui/core/styles'

export const StyledChip = createStyles({
  root: {
    backgroundColor: '#000',
    color: '#fff',
    '&:focus': {
      backgroundColor: '#000'
    }
  },
  deleteIcon: {
    color: '#fff',
    '&:hover': {
      color: '#fff'
    }
  },
  icon: {
    color: '#fff'
  },
  outlined: {
    backgroundColor: '#fff',
    color: '#000',
    '& .MuiChip-icon': {
      color: '#000'
    }
  }
})
