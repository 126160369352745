import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

import Modal from '~/components/core/Modal'
import TipsTitle from '../TipsTitle'
import SellerTips, { Props as SellerTipsProps } from '../'
import * as S from './styles'

const useStyles = makeStyles(S.Styled)

export interface Props {
  leadId?: string
  onClose: () => void
  open: boolean
  pattern?: SellerTipsProps['pattern']
}

const SellerTipsModal: React.FC<Props> = ({ onClose, open, pattern }) => {
  const classes = useStyles()

  return (
    <Modal open={open} maxWidth='lg'>
      <Modal.Title onClose={onClose}>
        <TipsTitle />
      </Modal.Title>
      <Modal.Content dividers className={classes.root}>
        <SellerTips pattern={pattern} hideTitle />
      </Modal.Content>
    </Modal>
  )
}

export default SellerTipsModal
