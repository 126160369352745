import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward'
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward'
import Chip, { ChipProps } from '@material-ui/core/Chip'

import { StyledChip } from './styles'

export interface Props
  extends ChipProps {
  onClick?: () => void
  ascending?: boolean
  active?: boolean
  noIcon?: boolean
}

const useStyles = makeStyles(StyledChip)

// eslint-disable-next-line complexity
const SortingButton: React.FC<Props> = ({
  active,
  ascending,
  noIcon,
  ...rest
}) => {
  const classes = useStyles()
  const ascendingIcon = Number(ascending) === 0 ? <ArrowDownwardIcon /> : <ArrowUpwardIcon />

  return (
    <Chip
      classes={classes}
      deleteIcon={(active && !noIcon) ? ascendingIcon : undefined}
      variant={active ? 'default' : 'outlined'}
      onDelete={(active && !noIcon) ? rest?.onClick : undefined}
      clickable
      {...rest}
    />
  )
}

export default SortingButton
