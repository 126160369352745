import React, { SVGProps } from 'react'
const CellCheck = (props: SVGProps<SVGSVGElement>): JSX.Element => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={120}
    height={120}
    fill='none'
    {...props}
  >
    <circle cx={60} cy={60} r={60} fill='url(#a)' />
    <path
      fill='#fff'
      d='M80.69 22.285H47c-3.706 0-6.738 3.032-6.738 6.738V39.13h6.738v-6.738H80.69v53.902H47v-6.738h-6.738v10.107c0 3.706 3.032 6.738 6.738 6.738H80.69c3.706 0 6.738-3.032 6.738-6.738v-60.64c0-3.706-3.032-6.738-6.738-6.738Zm-40.393 42.01-8.59-8.59-4.28 4.278L40.264 72.82l24.223-24.223-4.279-4.278-19.91 19.977Z'
    />
    <defs>
      <linearGradient
        id='a'
        x1={60}
        x2={60}
        y1={0}
        y2={120}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#006064' />
        <stop offset={1} stopColor='#006064' stopOpacity={0.89} />
      </linearGradient>
    </defs>
  </svg>
)

export default CellCheck
