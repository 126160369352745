import React, { useEffect } from 'react'

import { useBreakpoint } from '~/utils/breakpoints'
import { MapContext } from '~/components/core/MapProvider/context'
import PageLoader from '~/components/core/PageLoader'
import { SellerAsset } from '~/types/Asset'

export interface Props {
  children: React.ReactElement | React.ReactNode
  position?: SellerAsset['propertyPos']
}

const FormWithMap: React.FC<Props> = ({ children, position }) => {
  const { setMapMarkers, setMap = () => {}, mapApiLoaded } = React.useContext(MapContext)
  const isCompactLayout = useBreakpoint(['small', 'medium'])

  useEffect(() => {
    setMap(true)
    setMapMarkers(position ? [position] : [])

    return () => {
      setMapMarkers([])

      return setMap(false)
    }
  }, [])

  if (!isCompactLayout && !mapApiLoaded) {
    return <PageLoader open />
  }

  return (
    <>
      {children}
    </>
  )
}

export default FormWithMap
