import React from 'react'
import { Grid } from '@material-ui/core'

import Typography from '~/components/core/Typography'
import { PlanColor, COLOR_MAP } from '../'

const PlanSellerHeaderPrice = ({
  title, subTitle, color
}: {
  title: string
  subTitle: string
  color: PlanColor
}): JSX.Element => {
  return (
    <Grid
      alignItems='baseline'
      justify='center'
      spacing={1}
      container
    >
      <Grid item>
        <Typography style={{ fontSize: '40px', color: COLOR_MAP[color] }}>
        {title}
        </Typography>
      </Grid>
      <Grid item>
      <Typography style={{ fontSize: '14px', height: 'fit-content' }}>
        /{subTitle}
      </Typography>
      </Grid>
    </Grid>
  )
}

export default PlanSellerHeaderPrice
