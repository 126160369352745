import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty'

import ResultsMessage from '~/components/core/ResultsMessage'
import { Lead } from '~/types/Leads'
import * as S from './styles'

const useStyles = makeStyles(S.StyledLoader)

export interface Props {
  retryCall: () => Promise<void>
  data: Lead[] | 'loadingLeads'[] | undefined
  children: React.ReactNode
}

const SellerLoadingLeads: React.FC<Props> = ({
  retryCall, data, children
}) => {
  const classes = useStyles()
  const [startTimeout, setStartTimeout] = useState<boolean>(false)
  const { t } = useTranslation()
  const loadingLeads = ((data?.length === 1) && data[0] === 'loadingLeads')

  useEffect(() => {
    if (loadingLeads && !startTimeout) {
      setStartTimeout(true)

      setTimeout(async () => {
        await retryCall()

        setStartTimeout(false)
      }, 2000)
    }
  }, [startTimeout, loadingLeads])

  if (loadingLeads) {
    return (
      <ResultsMessage
        title={t('sellerLoadingLeads')}
        icon={<HourglassEmptyIcon className={classes.animation} color='secondary' fontSize='large' style={{ height: 150, width: 'auto', marginTop: 40 }} />}
      />
    )
  }

  return <>{children}</>
}

export default SellerLoadingLeads
