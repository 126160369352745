import React from 'react'
import useFetch, { CachePolicies } from 'use-http'
import moment from 'moment'

import { PaymentStatus, PaymentType } from '~/types/Payment'
import { PaymentsProvider } from './context'

const PRIMIUM_DAYS = 28

const getPrimiumTime = (date: string) => {
  const currentUtc = moment.utc(new Date()).valueOf()

  return currentUtc - new Date(date || '').getTime()
}

const getPlanType = ({ primiumDays, status }: { primiumDays?: number, status?: PaymentStatus }): PaymentType => {
  if (primiumDays && primiumDays > 0) return 'premium'

  if (status?.remainingNumberOfClicks && !status.isFree) return 'standard'

  return 'free'
}

interface Props {
  id: string
  children: React.ReactNode
}

const PaymentsProviderWrapper: React.FC<Props> = ({ id, children }) => {
  const { loading, error, data: status, get: refetch } = useFetch<PaymentStatus>(`api/payments/status/${id}`,
  {
    cachePolicy: CachePolicies.NO_CACHE
  },
  [id])

  const primiumDays = (status?.premiumStartDate) ? moment.duration(getPrimiumTime(status?.premiumStartDate as string), 'milliseconds').asDays() : null
  const primiumDaysLeft = primiumDays ? Math.floor(PRIMIUM_DAYS - primiumDays) + 1 : 0

  const currentPlan = getPlanType({ primiumDays: primiumDaysLeft, status })

  if (error) {
    console.log(error)

    return <>ERROR</>
  }

  return (
    <PaymentsProvider.Provider
      value={{
        status,
        currentAssetId: id,
        primiumDaysLeft,
        currentPlan,
        loading,
        refetch,
        remainingNumberOfClicks: status?.remainingNumberOfClicks || 0
      }}
    >
      {children}
    </PaymentsProvider.Provider>
  )
}

export default PaymentsProviderWrapper
