import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import SelectMUI, { SelectProps } from '@material-ui/core/Select'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'

import LabelWrapper, { Props as LabelProps } from '../../LabelWrapper'
import Checkbox from '../Checkbox'
import MenuItem from './MenuItem'
import * as S from './styles'

type Option<T extends string | number = string | number> = {
  key?: number
  text: string
  value: T
}

export interface Props extends SelectProps {
  options: Option[]
  placeholder?: string
  errMsg?: string
  labelProps?: LabelProps
  label?: React.ReactNode
  withCheckbox?: boolean
}

const StyledSelect = withStyles(S.Select)(SelectMUI)

const Select: React.FC<Props> = ({ options, placeholder, errMsg, labelProps = {}, withCheckbox, ...rest }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget)
  }

  return (
    <LabelWrapper direction='column' {...labelProps}>
      <FormControl style={{ minWidth: '100%' }} component='fieldset' error={!!errMsg}>
        {placeholder && <InputLabel id='select-label'>{placeholder}</InputLabel>}
        <StyledSelect
          {...rest}
          id='select'
          placeholder='dfdf'
          onClick={handleClick}
          MenuProps={{
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right'
            }
          }}
        >
          {options.map((item: Option, index) => {
            return (
            <MenuItem key={index} value={item.value}>
              {(withCheckbox && rest.multiple) && <Checkbox checked={(rest.value as string[]).includes(item.value as string)} />}
              {item.text}
            </MenuItem>
            )
          })}
        </StyledSelect>
      </FormControl>
    </LabelWrapper>
  )
}

export default Select
