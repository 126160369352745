import React from 'react'
import firebase from 'firebase/app'

interface Props {
    currentUser: firebase.User | undefined,
    signOut: () => Promise<void>,
    updateUserProfile: (userObj: {
        displayName?: string | null | undefined;
        photoURL?: string | null | undefined;
    }) => Promise<void>,
    updateUserPhone: (phoneCredential: any) => Promise<void>
  }

export const AuthProvider = React.createContext<Props>({} as Props)
