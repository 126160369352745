import { createStyles } from '@material-ui/core/styles'

import { Props } from './'

export default () =>
  createStyles({
    root: {
      position: 'relative'
    },
    actions: {
      display: 'flex',
      flexDirection: 'column',
      opacity: (props: Props) => props.invisible ? 1 : '28%',
      pointerEvents: 'none',
      position: 'relative'
    },
    layout: {
      position: 'absolute',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%'
    },
    clickable: {
      cursor: 'pointer',
      '-webkit-tap-highlight-color': 'transparent'
    }
  })
