import { createStyles } from '@material-ui/core/styles'

import palette from '~/ThemeProvider/config/palette'

export const StyledInput = createStyles({
  root: {
    width: '100%'
  },
  label: {
    color: palette.common.black,
    fontSize: '14px',
    '-webkit-font-smoothing': 'antialiased',
    textTransform: 'capitalize',
    '&.Mui-focused': {
      color: palette.common.black
    },
    '&.Mui-error': {
      color: palette.common.black
    }
  },
  input: {
    '& input': {
      textAlign: 'center !important',
      direction: 'rtl !important',
      color: palette.primary.dark,
      lineHeight: '25px',
      padding: '2px 2px',
      fontSize: '19px',
      '&::placeholder': {
        color: 'rgb(59, 59, 59) !important',
        fontSize: '15px',
        textAlign: 'center'
      }
    },
    '&.MuiInput-underline:before': {
      borderBottom: '1px solid -internal-light-dark(rgb(118, 118, 118), rgb(133, 133, 133)) !important'
    },
    '&.MuiInput-underline:after': {
      borderBottom: '2px solid #118188 !important'
    },
    '&.Mui-error': {
      '&.MuiInput-underline:after': {
        borderBottom: `2px solid ${palette.red.main} !important`
      }
    }
  },
  helper: {
    textAlign: 'right',
    fontFamily: "'Roboto', sans-serif",
    color: `${palette.red.main} !important`,
    fontSize: '13px',
    letterSpacing: '0',
    '-webkit-font-smoothing': 'antialiased',
    marginTop: '0'
  }
})
