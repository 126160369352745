import React from 'react'
import { Grid, Container } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import MailOutlineIcon from '@material-ui/icons/MailOutline'

import ResultsMessage from '~/components/core/ResultsMessage'
import Button from '~/components/core/Button'
import Typography from '~/components/core/Typography'

interface Props {
  signOut: () => void
}

const UnverifiedUser: React.FC<Props> = ({ signOut }) => {
  const { t } = useTranslation()

  return (
    <Container>
        <Grid container direction='column' alignItems='center' spacing={2} style={{ marginTop: 80 }}>
          <Grid item>
            <ResultsMessage
              title={[t('hi'), ` ${t('user.unverified.1')}`, <div key='bold' style={{ fontWeight: 'bold' }}>{t('user.unverified.2')}</div>]}
              icon={<MailOutlineIcon color='secondary' fontSize='large' style={{ height: 150, width: 'auto', marginTop: 40 }} />}
              actionButton={(
                <Button variant='text' color='primary' onClick={signOut}>
                  <Typography size='large' color='textPrimary' noWrap>{t('addNewEmail')}</Typography>
                </Button>
              )}
            />
          </Grid>
        </Grid>
      </Container>
    )
};

export default UnverifiedUser
