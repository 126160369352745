import { createStyles } from '@material-ui/core/styles'

export const SearchResultsContanairStyled = createStyles({
  root: {
    width: '100%',
    margin: 0,
    padding: 0,
    paddingBottom: '2rem',
    position: 'relative'
  },
  content: {
    position: 'relative'
  }
})
