import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'
import HighlightOffIcon from '@material-ui/icons/HighlightOff'

import * as S from './styles'

const useStyles = makeStyles(S.StyledUploadButton)

export interface Props {
  onReset: () => void
  image?: string
  onChange: (files: FileList) => void
  size: 'small' | 'medium' | 'large'
}

const UploadButton: React.FC<Props> = (props) => {
  const { onReset, image, onChange, size } = props
  const classes = useStyles(props)

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = e.target

    if (files?.length) {
      if (files?.length > 8) {
        alert('Too many Files')
      } else {
        onChange(files)
      }
    }
  }

  return (
    <div className={classes.root}>
      {image
? (
        <>
          <IconButton aria-label='delete' onClick={onReset} className={classes.closeButton} size={(size === 'large') ? 'medium' : size}>
            <HighlightOffIcon />
          </IconButton>
          <img
            alt='file'
            className={classes.button}
            src={image}
          />
        </>
      )
: (
        <>
          <input
            accept='image/*'
            className={classes.input}
            onChange={handleOnChange}
            id='contained-button-file'
            multiple
            type='file'
          />
          <label htmlFor='contained-button-file'>
            <Button className={classes.button} variant='outlined' component='span'>
              <AddCircleOutlineIcon fontSize='large' />
            </Button>
          </label>
        </>
      )}
    </div>
  )
}

export default UploadButton
