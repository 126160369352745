import React from 'react'

import Modal from '~/components/core/Modal'
// import Button from '~/components/core/Button'
import UserFeedback, { Props as UserFeedbackProps } from '../'
// import Typography from '~/components/core/Typography'

export interface Props extends UserFeedbackProps {
  onClose: () => void
  isOpen: boolean
}

const UserFeedbackModal: React.FC<Props> = ({ onClose, isOpen, ...rest }) => {
  return (
    <Modal open={isOpen}>
      <Modal.Title onClose={onClose}>
        פידבק
      </Modal.Title>
      <Modal.Content dividers>
        <UserFeedback
          {...rest}
        />
      </Modal.Content>
    </Modal>
  )
}

export default UserFeedbackModal
