/* eslint-disable complexity */
import React from 'react'
import { useForm, useFormState } from 'react-final-form'
import { useHistory } from 'react-router-dom'
import { Grid } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import SubjectIcon from '@material-ui/icons/Subject'

import { useModal } from '~/utils/useModal'
import { scrollToTop } from '~/utils/positions'
import useSelectOptions from '~/hooks/useSelectOptions'
import useFormNotification from '~/hooks/useFormNotification'
import Form from '~/components/core/Form'
import Button from '~/components/core/Button'
import Typography from '~/components/core/Typography'
import { ASSET_PROPS_ICONS } from '~/components/modules/AssetDetails/constants'
import { FLOOR_INPUT, BOOLEAN_INPUT_YES_NO, VIEW_OPTIONS_ARR, REMODEL_OPTIONS_ARR } from '~/components/modules/PostAssetForm/constants'
import LabelWrapper from '~/components/core/LabelWrapper'
import DirtyFormModal from '~/components/modules/DirtyFormModal'
import MapModal from '~/components/modules/MapModal'
import { SellerAsset } from '~/types/Asset'

export interface Props {
  onSubmit?: () => void
  asset?: SellerAsset
}

const TOGGLE_BUTTONS_OPTIONS: { text: string, value: number }[] = [{ text: 'לא', value: 0 }, { text: 'כן', value: 1 }]

export interface YesNoInputProps {
  name: string
  label: string
}

const YesNoInput: React.FC<YesNoInputProps> = ({ name, label }) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const RelevantIcon = ASSET_PROPS_ICONS[name]

  return (
    <LabelWrapper label={label} icon={(RelevantIcon) && <RelevantIcon fontSize='small' />} direction='column'>
      {/* {selectOpts && <Form.Select options={optsWithT(selectOpts)} name={`${name}.value`} />} */}
      <Form.ToggleButtonsGroup
        style={{ width: '100%', justifyContent: 'space-evenly' }}
        name={name}
      >
        {
          TOGGLE_BUTTONS_OPTIONS.map(({ text, value }: { text: string, value: number }, index: number) => (
            <Form.ToggleButton key={index} value={value} label={text} />
          ))
        }
      </Form.ToggleButtonsGroup>
    </LabelWrapper>
  )
}

const PostAssetMain: React.FC<Props> = ({
  asset
}) => {
  const history = useHistory()
  const formState = useFormState()
  const { showModal, hideModal, isOpen } = useModal()
  const formObj = useForm()
  const { getOptions } = useSelectOptions()

  const handleOnSubmit = () => {
    scrollToTop()
    formObj.submit()
  }

  const [validationsNotification, validationTypeModal, preSubmitValidation] = useFormNotification(handleOnSubmit, true)
  const { t } = useTranslation()

  const getRelevantIcon = (fieldName: string): React.ReactNode => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const RelevantIcon = ASSET_PROPS_ICONS[fieldName]

    return RelevantIcon ? <RelevantIcon /> : null
  }

  return (
    <>
    {validationsNotification}
    {validationTypeModal}
    <br />
    <Grid container alignItems='stretch' spacing={4} style={{ maxWidth: '500px' }}>
      <Grid item container wrap='nowrap'>
        <Form.ToggleButtonsGroup
          style={{ width: '100%', justifyContent: 'space-evenly' }}
          labelProps={{
            style: { padding: '0 16px' },
            icon: getRelevantIcon('postType'),
            label: 'דירה ל'
          }}
          name='postType'
        >
          {
            [{ text: t('labelSale'), value: 'sell' }, { text: t('labelRent'), value: 'rent' }].map(({ text, value }: { text: string, value: string }, index: number) => (
              <Form.ToggleButton key={index} value={value} label={text} />
            ))
          }
        </Form.ToggleButtonsGroup>
      </Grid>
      <Grid item container>
        <Grid container spacing={2} wrap='nowrap'>
          <Grid item container>
            {asset
              ? (
              <LabelWrapper label={t('address')} icon={getRelevantIcon('city')} direction='column'>
                <Typography>{asset.address}</Typography>
              </LabelWrapper>
            )
            : (
              <Form.Location searchTypes={['address']} name='address' label={t('address')} textInputProps={{ placeholder: t('noOptionsAsset'), labelProps: { style: { padding: '0 16px' }, icon: getRelevantIcon('city') } }} />
            )}
          </Grid>
          {
            !!(formState.values.address?.latLng?.lat || asset?.propertyPos) && (
              <Grid item alignItems='flex-end' container style={{ width: 'auto' }}>
                <Button variant='text' onClick={showModal}>
                  <Typography color='secondary'>{t('watchOnMap')}</Typography>
                </Button>
              </Grid>
            )
          }
        </Grid>
      </Grid>
      <Grid item container wrap='nowrap'>
        <Form.NumberInput allowLeadingZeros width='100%' decimalScale={0} name='price' prefix='₪' thousandSeparator label='מה המחיר שחשבתם עליו?' labelProps={{ style: { padding: '0 16px' }, icon: getRelevantIcon('budget') }} />
      </Grid>
      <Grid item container wrap='nowrap'>
        <Form.NumberInput allowLeadingZeros width='100%' thousandSeparator name='squareSize' label='שטח (מ”ר)' labelProps={{ style: { padding: '0 16px' }, icon: getRelevantIcon('squareSize') }} />
      </Grid>
      <Grid item container wrap='nowrap'>
        <Form.NumberInput decimalScale={1} halfAllow allowLeadingZeros width='100%' thousandSeparator name='rooms' label={t('rooms')} labelProps={{ style: { padding: '0 16px' }, icon: getRelevantIcon('rooms') }} />
      </Grid>
      <Grid item container wrap='nowrap'>
        <Form.NumberInput allowLeadingZeros width='100%' thousandSeparator name='contactNumber' type='tel' label={t('contactNumber')} labelProps={{ style: { padding: '0 16px' }, icon: getRelevantIcon('contactNumber') }} />
      </Grid>
      <Grid item container wrap='nowrap'>
        <Form.Select fullWidth options={getOptions(BOOLEAN_INPUT_YES_NO)} name='isAgent' labelProps={{ label: t('isAgent'), style: { padding: '0 16px' }, icon: getRelevantIcon('isAgent') }} />
      </Grid>
      <Grid item container wrap='nowrap'>
        <Form.Select fullWidth options={getOptions(FLOOR_INPUT)} name='floor' labelProps={{ label: t('floor'), style: { padding: '0 16px' }, icon: getRelevantIcon('floor') }} />
      </Grid>
      <Grid item container wrap='nowrap'>
        <Form.Select fullWidth options={getOptions(REMODEL_OPTIONS_ARR)} name='remodel' labelProps={{ label: 'מצב הדירה', style: { padding: '0 16px' }, icon: getRelevantIcon('remodel') }} />
      </Grid>
      <Grid item container wrap='nowrap'>
        <Form.Select fullWidth options={getOptions(VIEW_OPTIONS_ARR)} name='view' labelProps={{ label: t('view'), style: { padding: '0 16px' }, icon: getRelevantIcon('view') }} />
      </Grid>
      <Grid item container wrap='nowrap'>
        <YesNoInput name='garden' label={t('garden')} />
      </Grid>
      <Grid item container wrap='nowrap'>
        <YesNoInput name='balcony' label={t('balcony')} />
      </Grid>
      <Grid item container wrap='nowrap'>
        <YesNoInput name='elevator' label={t('elevator')} />
      </Grid>
      <Grid item container wrap='nowrap'>
        <YesNoInput name='furnished' label={t('furnished')} />
      </Grid>
      <Grid item container wrap='nowrap'>
        <YesNoInput name='hadicap' label={t('hadicap')} />
      </Grid>
      <Grid item container wrap='nowrap'>
        <YesNoInput name='panthouse' label={t('panthouse')} />
      </Grid>
      <Grid item container wrap='nowrap'>
        <YesNoInput name='parking' label={t('parking')} />
      </Grid>
      <Grid item container wrap='nowrap'>
        <YesNoInput name='safeRoom' label={t('safeRoom')} />
      </Grid>
      <Grid item container wrap='nowrap'>
        <YesNoInput name='storage' label={t('storage')} />
      </Grid>
      <Grid item container wrap='nowrap'>
        <LabelWrapper label={t('description')} icon={<SubjectIcon fontSize='small' />} direction='column'>
          <Form.Input style={{ border: '1px solid #DFDFDF' }} rows={4} rowsMax={10} multiline name='description' />
        </LabelWrapper>
      </Grid>
      <Grid item container wrap='nowrap'>
        <Form.UploadFilesGroup name='images' label={t('images')} />
      </Grid>
      <Grid item container wrap='nowrap'>
        <Form.DatePicker disablePast={!asset} name='entranceDate' label={t('entranceDate')} />
      </Grid>
      <Grid item container>
        <Grid container spacing={2} wrap='nowrap'>
          <Grid item>
            <Button onClick={() => history.goBack()} color='primary' variant='outlined'>
              <Typography color='inherit'>
                {t('back')}
              </Typography>
            </Button>
          </Grid>
          <Grid item>
            <Button color='primary' onClick={preSubmitValidation}>
              <Typography color='inherit'>
                {t(asset ? 'edit' : 'המשך למחשבון ביקוש')}
              </Typography>
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
    <DirtyFormModal
      when={formState.dirty && !formState.submitting}
      shouldBlockNavigation={() => formState.dirty && !formState.submitting}
      mainText={t('asset-unsaved')}
    />
    <MapModal
      position={asset?.propertyPos || formState.values.address?.latLng}
      onClose={hideModal}
      isOpen={isOpen}
    />
    </>
  )
}

export default PostAssetMain
