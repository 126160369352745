import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import { useTranslation } from 'react-i18next'
import { MenuList, List, ListItem, ListItemText, ListItemIcon, ClickAwayListener, Grow, Grid } from '@material-ui/core'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined'
import EditIcon from '@material-ui/icons/Edit'
import Popper from '@material-ui/core/Popper'

import { useBreakpoint } from '~/utils/breakpoints'
import Typography from '~/components/core/Typography'
import Button from '~/components/core/Button'
import { ClacAsset } from '~/components/core/Icons'
import * as S from './styles'

const useStyles = makeStyles(S.Styled)

export interface Props {
  onRemove: () => void
  onEdit: () => void
  onDetails: () => void
  onEditPrice: () => void
}

const AssetActionsMenu: React.FC<Props> = ({ onRemove, onEdit, onDetails, onEditPrice }) => {
  const { t } = useTranslation()
  const isCompactLayout = useBreakpoint(['small', 'medium'])
  const wrapper = React.useRef<HTMLDivElement>(null)
  const [open, setOpen] = useState(false)
  const classes = useStyles()

  const handleClose = (event: React.MouseEvent<EventTarget>) => {
    if (wrapper?.current && wrapper?.current?.contains(event.target as HTMLElement)) {
      return
    }

    event.stopPropagation()
    setOpen(false)
  }

  const handleListKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Tab') {
      event.preventDefault()
      setOpen(false)
    }
  }

  const handleOnRemove = () => {
    onRemove()
    setOpen(false)
  }

  const handleOnEdit = () => {
    onEdit()
    setOpen(false)
  }

  const handleOnDetails = () => {
    onDetails()
    setOpen(false)
  }

  const handleOnEditPrice = () => {
    onEditPrice()
    setOpen(false)
  }

  if (!isCompactLayout) {
    return (
      <Grid container spacing={1}>
        <Grid item container alignItems='center' style={{ width: 'auto' }}>
          <Button variant='text' size='small' onClick={handleOnEditPrice} startIcon={<ClacAsset color='#4BB5AF' fontSize='small' />}>
            <Typography size='small' color='textPrimary'>מחשבון ביקוש</Typography>
          </Button>
        </Grid>
        <Grid item container alignItems='center' style={{ width: 'auto' }}>
          <Button variant='text' size='small' onClick={handleOnDetails} startIcon={<VisibilityOutlinedIcon color='primary' fontSize='small' />}>
            <Typography size='small' color='textPrimary'>{t('watchAssetDetails')}</Typography>
          </Button>
        </Grid>
        <Grid item container alignItems='center' style={{ width: 'auto' }}>
          <Button variant='text' size='small' onClick={handleOnEdit} startIcon={<EditIcon color='primary' fontSize='small' />}>
            <Typography size='small' color='textPrimary'>{t('editPropery')}</Typography>
          </Button>
        </Grid>
        <Grid item container alignItems='center' style={{ width: 'auto' }}>
          <Button variant='text' size='small' onClick={handleOnRemove} startIcon={<DeleteOutlineIcon color='error' fontSize='small' />}>
            <Typography size='small' color='textPrimary'>{t('removeProperty')}</Typography>
          </Button>
        </Grid>
      </Grid>
    )
  }

  return (
    <>
    <div ref={wrapper}>
      <Button iconButton={<MoreVertIcon />} size='small' onClick={() => setOpen(!open)} />
    </div>
    <Popper className={classes.popper} open={open} anchorEl={wrapper?.current} role={undefined} transition disablePortal placement='bottom-end'>
      {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
            >
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  id='menu-list-grow' onKeyDown={handleListKeyDown}
                >
                  <List
                    component='nav'
                    aria-labelledby='ist-subheader'
                    className={classes.root}
                  >
                    <ListItem button onClick={handleOnEditPrice}>
                      <ListItemIcon>
                        <ClacAsset fontSize='small' />
                      </ListItemIcon>
                      <ListItemText primary={<Typography color='textPrimary'>מחשבון ביקוש</Typography>} />
                    </ListItem>
                    <ListItem button onClick={handleOnDetails}>
                      <ListItemIcon>
                        <VisibilityOutlinedIcon fontSize='small' />
                      </ListItemIcon>
                      <ListItemText primary={<Typography color='textPrimary'>{t('watchAssetDetails')}</Typography>} />
                    </ListItem>
                    <ListItem button onClick={handleOnEdit}>
                      <ListItemIcon>
                        <EditIcon fontSize='small' />
                      </ListItemIcon>
                      <ListItemText primary={<Typography color='textPrimary'>{t('editPropery')}</Typography>} />
                    </ListItem>
                    <ListItem button onClick={handleOnRemove}>
                      <ListItemIcon>
                        <DeleteOutlineIcon fontSize='small' />
                      </ListItemIcon>
                      <ListItemText primary={<Typography color='textPrimary'>{t('removeProperty')}</Typography>} />
                    </ListItem>
                  </List>
                </MenuList>
              </ClickAwayListener>
            </Grow>
      )}
    </Popper>
    </>
  )
}

export default AssetActionsMenu
