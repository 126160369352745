import { createStyles, Theme } from '@material-ui/core/styles'

import { screens } from '~/utils/breakpoints'

export const LeadListtyled = (theme: Theme) =>
createStyles({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper
  },
  listItemWrp: {
    [screens('small', 'medium')]: {
      margin: 10
    }
  }
})
