import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Container, Grid } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import CalendarTodayIcon from '@material-ui/icons/CalendarToday'

import ImagesGallery from '~/components/modules/ImageGallery'
import PercentageBox from '~/components/modules/PercentageBox'
import AssetValueInfo from '~/components/modules/AssetValueInfo'
import AssetDetailsHeader from '~/components/modules/AssetDetailsHeader'
import AssetBooleanDetailRawField from '~/components/modules/AssetBooleanDetailRawField'
import AssetDetailRawField from '~/components/modules/AssetDetailRawField'
import AssetDetailDescriptionField from '~/components/modules/AssetDetailDescriptionField'
import AssetDetailsMandatoryInfo from '~/components/modules/AssetDetailsMandatoryInfo'
import AssetsDetailsContactsActions from '~/components/modules/AssetsDetailsContactsActions'
import { useBreakpoint } from '~/utils/breakpoints'
import * as S from './styles'
import { ASSET_PROPS_ICONS, BOOLEAN_FIELD } from './constants'
import { VIEW_OPTIONS_ARR, REMODEL_OPTIONS_ARR } from '~/components/modules/PostAssetForm/constants'
import { SellerAsset, BuyerAsset } from '~/types/Asset'
import MapView from '~/components/core/MapView'
import Typography from '~/components/core/Typography'
import { addressWithoutCountry } from '~/components/utils/formating'
import Loader from './Loader'

const useStyles = makeStyles(S.AssetCardStyled)

const getTextValue = (val: any, type: 'remodel' | 'view') => {
  if (type === 'remodel') {
    return REMODEL_OPTIONS_ARR.find((item) => item.value === val)?.text
  }

  return VIEW_OPTIONS_ARR.find((item) => item.value === val)?.text
}

export interface Props {
  item?: SellerAsset | BuyerAsset
  seller?: boolean
  isModal?: boolean
  loading?: boolean
  isPublic?: boolean
  onToggleGalleryFullScreen?: (isFullScreen: boolean) => void
}

// eslint-disable-next-line complexity
const AssetDetails: React.FC<Props> = ({ item, seller, isModal, loading, isPublic, onToggleGalleryFullScreen }) => {
  const { t } = useTranslation()
  const isCompactLayout = useBreakpoint(['small', 'medium'])
  const classes = useStyles()

  if (loading) {
    return (
      <Container className={classes.root}>
        <Loader />
      </Container>
    )
  }

  if (!item) {
    return null
  }

  const postType = item?.forSale ? 'sell' : 'rent'

  return (
    <Container className={classes.root}>
      <Grid container direction='column' spacing={1}>
        <Grid item className={classes.gridItemInfo}>
          <AssetDetailsHeader isPublic={isPublic} seller={seller} id={item.id} leadId={item.leadId} date={new Date(item.dateCreated)} postType={postType} item={item} />
        </Grid>
        {
          !!(item.images.length) && (
            <Grid item>
              <div style={{ position: 'relative' }}>
              <ImagesGallery
                showThumbnails={!isCompactLayout}
                onToggleFullScreen={onToggleGalleryFullScreen}
                customClass={!isCompactLayout ? 'big-size' : undefined}
                images={item.images}
                allowFullScreen
              />
              </div>
            </Grid>
          )
        }
        <Grid item className={classes.gridItemInfo}>
          <Grid container alignItems='flex-start' spacing={3}>
            <Grid item className={classes.itemWrp}>
              <AssetValueInfo size='large' label={t('address')} value={addressWithoutCountry(item.address)} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item className={classes.gridItemInfo}>
          <AssetDetailsMandatoryInfo
            price={item.price}
            squareSize={item.squareSize}
            rooms={item.rooms}
          />
        </Grid>
        {
          !seller && !isPublic && (item as BuyerAsset).percentage && (
            <Grid item className={classes.gridItemInfo} style={{ marginTop: 10 }}>
              <PercentageBox percentage={(item as BuyerAsset).percentage} />
            </Grid>
          )
        }
        <Grid item className={classes.gridItemInfo}>
          <Grid container direction='row' spacing={3} wrap='nowrap'>
            <Grid item container lg={9} alignItems='stretch'>
              <Grid container direction='column' alignItems='stretch' spacing={1} className={classes.advangedDetails}>
                {
                  // eslint-disable-next-line complexity
                  BOOLEAN_FIELD.map((key: string) => {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    const RelevantIcon = ASSET_PROPS_ICONS[key]

                    if (key === 'floor') {
                      return (
                        <Grid item key={key} container>
                          {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                          {/* @ts-ignore */}
                          <AssetDetailRawField label={t(key)} value={<Typography>{item[key]}</Typography>} icon={RelevantIcon && <RelevantIcon fontSize='small' />} />
                        </Grid>
                      )
                    }

                    if (['remodel', 'view'].includes(key)) {
                      /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
                      /* @ts-ignore */
                      const valText = getTextValue(item[key], key)

                      return (
                        <Grid item key={key} container>
                          {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                          {/* @ts-ignore */}
                          <AssetDetailRawField label={t(key)} value={<Typography>{t(valText)}</Typography>} icon={RelevantIcon && <RelevantIcon fontSize='small' />} />
                        </Grid>
                      )
                    }

                    return (
                      <Grid item key={key} container>
                        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                        {/* @ts-ignore */}
                        <AssetBooleanDetailRawField label={t(key)} value={Boolean(item[key])} icon={RelevantIcon && <RelevantIcon fontSize='small' />} />
                      </Grid>
                    )
                  })
                }
                <Grid item container>
                  <AssetDetailRawField label={t('entranceDate')} value={<Typography>{moment(new Date(item.entranceDate)).format('DD/MM/YY')}</Typography>} icon={<CalendarTodayIcon fontSize='small' />} />
                </Grid>
                <AssetDetailDescriptionField
                  value={item.description}
                />
              </Grid>
            </Grid>
            {
              (!isModal && !seller && !isCompactLayout) && (
                <Grid item container sm={3}>
                  <AssetsDetailsContactsActions isPublic={isPublic} item={item as BuyerAsset} />
                </Grid>
              )
            }
          </Grid>
        </Grid>
        <Grid item className={classes.gridItemInfo}>
          <div className={classes.mapWrapper}>
            <MapView show position={item.propertyPos} />
          </div>
        </Grid>
      </Grid>
      {(!isModal && !seller && isCompactLayout) && <AssetsDetailsContactsActions isPublic={isPublic} item={item as BuyerAsset} isModal={isModal} />}
    </Container>
  )
}

export default AssetDetails
