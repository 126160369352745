import { createStyles, Theme } from '@material-ui/core/styles'

export const StyledBox = (theme: Theme) => createStyles({
  root: {
    width: '90%',
    '& > * + *': {
      marginTop: theme.spacing(2)
    }
  },
  body: {
    marginRight: theme.spacing(2)
  }
})
