import React, { useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { MenuList, ClickAwayListener, Grow } from '@material-ui/core'
import Popper from '@material-ui/core/Popper'
import { useParams } from 'react-router-dom'

import useResetNewSearchLeads from '~/hooks/useResetNewSearchLeads'
import { MinimalSearch } from '~/types/Search'
import UserSearchesList from '~/components/modules/UserSearchesList'
import * as S from './styles'

const useStyles = makeStyles(S.AssetCardStyled)

export interface Props {
  initOpen?: boolean
  onClose?: () => void
  open: boolean
  anchorRef?: React.RefObject<HTMLDivElement>
  searches: MinimalSearch[]
}

const SearchResultsHeaderDetails: React.FC<Props> = ({
  onClose = () => {}, anchorRef, searches, open
}) => {
  const { id } = useParams<{ id?: string }>()
  const classes = useStyles()
  const [resetLeads] = useResetNewSearchLeads()

  const handleClose = (event: React.MouseEvent<EventTarget>) => {
    if (anchorRef?.current && anchorRef?.current?.contains(event.target as HTMLElement)) {
      return
    }

    event.stopPropagation()
    onClose()
  }

  const handleListKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Tab') {
      event.preventDefault()
      onClose()
    }
  }

  useEffect(() => {
    return () => {
      resetLeads(id)
    }
  }, [])

  const handleClickList = () => {
    onClose()
  }

  return (
    <Popper className={classes.root} open={open} anchorEl={anchorRef?.current} role={undefined} transition disablePortal placement='bottom-end'>
      {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
            >
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  id='menu-list-grow' onKeyDown={handleListKeyDown}
                >
                <UserSearchesList searches={searches} onClick={handleClickList} />
                </MenuList>
              </ClickAwayListener>
            </Grow>
      )}
    </Popper>

  )
}

export default SearchResultsHeaderDetails
