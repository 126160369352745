import 'react-app-polyfill/ie11'
import ReactDOM from 'react-dom'
import React from 'react'
import dotenv from 'dotenv'

import rollbar from '~/services/rollbar'
import Hoome from './App'

dotenv.config({ path: `../.env.${process.env.NODE_ENV}`, debug: false })

rollbar.initialize()

const App: React.FC = () => {
    return (
        <Hoome />
    )
}

export default App

ReactDOM.render(<App />, document.getElementById('root'))
