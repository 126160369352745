import React from 'react'
import { useParams } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import ListSubheader from '@material-ui/core/ListSubheader'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'

import Link from '~/components/core/Link'
import Badge from '~/components/core/Badge'
import { ViewerProvider } from '~/components/core/ViewerProvider/context'
import { MinimalSearch } from '~/types/Search'
import { addressWithoutCountry } from '~/components/utils/formating'
import Typography from '~/components/core/Typography'
import * as S from './styles'

const useStyles = makeStyles(S.MySearchesListStyled)

export interface Props {
  searches: MinimalSearch[]
  onClick?: (id: string) => void
}

const UserSearchesList: React.FC<Props> = ({ searches, onClick = () => {} }) => {
  const classes = useStyles()
  const { id } = useParams<{ id?: string }>()
  const { newSearchLeads } = React.useContext(ViewerProvider)

  return (
    <List
      component='nav'
      aria-labelledby='ist-subheader'
      subheader={
        <ListSubheader component='div' id='ist-subheader'>
          <Typography>
            חיפושים שלי
          </Typography>
        </ListSubheader>
      }
      className={classes.root}
    >
      {
        searches.map((search) => {
          const searchNewLeads = newSearchLeads?.find(({ id: searchId }) => searchId === search.id)

          return (
            <Link color='inherit' key={search.id} to={`/buyer/search/${search.id}`} onClick={() => onClick(search.id)}>
              <ListItem button key={search.id} selected={id === search.id}>
                <ListItemText primary={<Typography color='textPrimary'>{addressWithoutCountry(search.city)}</Typography>} />
                {!!searchNewLeads?.numberOfNewLeads && <Badge badgeContent={searchNewLeads.numberOfNewLeads} color='secondary' />}
              </ListItem>
            </Link>
          )
        })
      }
    </List>
  )
}

export default UserSearchesList
