import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import { useTranslation } from 'react-i18next'
import { MenuList, List, ListItem, ListItemText, ListItemIcon, ClickAwayListener, Grow, Grid } from '@material-ui/core'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'
// import ForumIcon from '@material-ui/icons/Forum'
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined'
import Popper from '@material-ui/core/Popper'

import { useBreakpoint } from '~/utils/breakpoints'
// import SearchCommunicationModal from '~/components/modules/SearchCommunicationModal'
// import { useModal } from '~/utils/useModal'
import Typography from '~/components/core/Typography'
import Button from '~/components/core/Button'
import * as S from './styles'

const useStyles = makeStyles(S.Styled)

export interface Props {
  onRemove: () => void
  onSearchDetails: () => void
  searchId?: string
  preventCompactLayout?: boolean
}

const SearchActionsMenu: React.FC<Props> = ({ onRemove, onSearchDetails, preventCompactLayout }) => {
  const { t } = useTranslation()
  const isCompactLayout = useBreakpoint(['small', 'medium'])
  const wrapper = React.useRef<HTMLDivElement>(null)
  const [open, setOpen] = useState(false)
  const classes = useStyles()
  // const { showModal, hideModal, isOpen } = useModal()

  const handleClose = (event: React.MouseEvent<EventTarget>) => {
    if (wrapper?.current && wrapper?.current?.contains(event.target as HTMLElement)) {
      return
    }

    event.stopPropagation()
    setOpen(false)
  }

  const handleListKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Tab') {
      event.preventDefault()
      setOpen(false)
    }
  }

  const handleOnRemove = () => {
    onRemove()
    setOpen(false)
  }

  const handleOnDetails = () => {
    onSearchDetails()
    setOpen(false)
  }

  // const handleOnCommunications = () => {
  //   showModal()
  //   setOpen(false)
  // }

  if (!isCompactLayout || preventCompactLayout) {
    return (
      <>
      <Grid container spacing={1}>
        <Grid item container alignItems='center' style={{ width: 'auto' }}>
          <Button variant='text' size='small' onClick={handleOnDetails} startIcon={<VisibilityOutlinedIcon color='primary' fontSize='small' />}>
            <Typography size='small' color='textPrimary'>פרטי החיפוש</Typography>
          </Button>
        </Grid>
        {/* <Grid item container alignItems='center' style={{ width: 'auto' }}>
          <Button variant='text' size='small' onClick={handleOnCommunications} startIcon={<ForumIcon color='primary' fontSize='small' />}>
            <Typography size='small' color='textPrimary'>{t('communications')}</Typography>
          </Button>
        </Grid> */}
        <Grid item container alignItems='center' style={{ width: 'auto' }}>
          <Button variant='text' size='small' onClick={handleOnRemove} startIcon={<DeleteOutlineIcon color='error' fontSize='small' />}>
            <Typography size='small' color='textPrimary'>מחיקה</Typography>
          </Button>
        </Grid>
      </Grid>
      {/* <SearchCommunicationModal
        searchId={searchId}
        isOpen={isOpen}
        onClose={hideModal}
    /> */}
      </>
    )
  }

  return (
    <>
    <div ref={wrapper}>
      <Button size='medium' iconButton={<MoreVertIcon />} onClick={() => setOpen(!open)} />
    </div>
    <Popper className={classes.popper} open={open} anchorEl={wrapper?.current} role={undefined} transition disablePortal placement='bottom-end'>
      {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
            >
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  id='menu-list-grow' onKeyDown={handleListKeyDown}
                >
                  <List
                    component='nav'
                    aria-labelledby='ist-subheader'
                    className={classes.root}
                  >
                    <ListItem button onClick={handleOnDetails}>
                      <ListItemIcon>
                        <VisibilityOutlinedIcon fontSize='small' />
                      </ListItemIcon>
                      <ListItemText primary={<Typography color='textPrimary'>{t('watchSearchDetails')}</Typography>} />
                    </ListItem>
                    {/* <ListItem button onClick={handleOnCommunications}>
                      <ListItemIcon>
                        <ForumIcon fontSize='small' />
                      </ListItemIcon>
                      <ListItemText primary={<Typography color='textPrimary'>{t('communications')}</Typography>} />
                    </ListItem> */}
                    <ListItem button onClick={handleOnRemove}>
                      <ListItemIcon>
                        <DeleteOutlineIcon fontSize='small' />
                      </ListItemIcon>
                      <ListItemText primary={<Typography color='textPrimary'>{t('removeSearch')}</Typography>} />
                    </ListItem>
                  </List>
                </MenuList>
              </ClickAwayListener>
            </Grow>
      )}
    </Popper>
    {/* <SearchCommunicationModal
      searchId={searchId}
      isOpen={isOpen}
      onClose={hideModal}
    /> */}
    </>
  )
}

export default SearchActionsMenu
