import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { Grid, Container, Box } from '@material-ui/core'
import ThumbUpIcon from '@material-ui/icons/ThumbUpOutlined'

import { useModal } from '~/utils/useModal'
import SearchActionsMenu from '~/components/modules/SearchActionsMenu'
import SearchDetails from '~/components/pages/SearchDetails'
import DeleteWarningModal from '~/components/modules/DeleteWarningModal'
import SellerTips from '~/components/modules/SellerTips'
import SellerTipsFab from '~/components/modules/SellerTips/fabButton'
import { useBreakpoint } from '~/utils/breakpoints'
import { MapContext } from '~/components/core/MapProvider/context'
import ResultsMessage from '~/components/core/ResultsMessage'
import Button from '~/components/core/Button'
import Link from '~/components/core/Link'
import Typography from '~/components/core/Typography'

interface Props {
  onDeleteSearch: () => void
}

const EmptySearchResult = ({
  onDeleteSearch
}: Props): JSX.Element => {
  const { t } = useTranslation()
  const { id } = useParams<{ id?: string }>()
  const { setMap = () => {} } = React.useContext(MapContext)
  const isCompactLayout = useBreakpoint(['small', 'medium'])
  const { showModal, hideModal, isOpen } = useModal()
  const { showModal: showSearchDetails, hideModal: hideSearchDetails, isOpen: isOpenSearchDetails } = useModal()

  useEffect(() => {
    setMap(false)
  }, [])

  return (
    <>
    <Container style={{ padding: '20px 10px' }}>
      <SearchActionsMenu preventCompactLayout searchId={id} onRemove={showModal} onSearchDetails={showSearchDetails} />
      <Grid container spacing={4}>
        <Grid item container sm={8}>
        <ResultsMessage
          title='פרופיל החיפוש שלכם נוצר בהצלחה!'
          subTitle='נשלח לכם התראה כל פעם שבעל דירה ישלח לכם מודעה מתאימה וכל המודעות שתקבלו יופיעו כאן בדשבורד האישי שלכם.'
          icon={<ThumbUpIcon color='secondary' fontSize='large' style={{ height: 150, width: 'auto', marginTop: 40 }} />}
          actionButton={(
            <Box marginTop='40px'>
              <Link color='inherit' to='/buyer/postSearch' style={{ textDecoration: 'none' }}>
                <Button variant='outlined' color='primary'>
                  <Typography noWrap>{t('newSearchAdded')}</Typography>
                </Button>
              </Link>
            </Box>
          )}
        />
        </Grid>
        {
          !isCompactLayout
          ? (
            <Grid item container sm={4} style={{ flex: 1, position: 'relative' }}>
              <SellerTips sticky pattern='pandingSearch' />
            </Grid>
          )
          : (
            <SellerTipsFab pattern='pandingSearch' />
          )
        }
      </Grid>
    </Container>
    <DeleteWarningModal
      isOpen={isOpen}
      onClose={hideModal}
      mainText={t('modalDetedeSearchMessage')}
      onSubmit={() => {
        onDeleteSearch()
        hideModal()
      }}
    />
    {
      isOpenSearchDetails && (
        <SearchDetails onClose={hideSearchDetails} />
      )
    }
    </>
  )
}

export default EmptySearchResult
