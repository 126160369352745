import React from 'react'
import { Redirect } from 'react-router-dom'

import { ViewerProvider } from '~/components/core/ViewerProvider/context'

const SearchesHall: React.FC = () => {
  const { searches } = React.useContext(ViewerProvider)

  if (!searches) {
    return <>NO SEARCHES DATA</>
  }

  if (searches.length) {
    return <Redirect to={`/buyer/search/${searches[0].id}`} />
  }

  return <Redirect to='/buyer/postSearch' />
}

export default SearchesHall
