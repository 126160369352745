import fullstory from '~/services/fullstory'
import logrocket from '~/services/logrocket'

export default {
  initialize(user = {}, source = 'fullstory') {
  // Fullstory Snippet
    if (source === 'logrocket') {
      logrocket.initialize(user)
    } else {
      fullstory.initialize()
    }
  }
}
