import React, { ImgHTMLAttributes } from 'react'
import cx from 'classnames'
import { makeStyles } from '@material-ui/core/styles'

import styles from './styles'

type VariantType = 'rectangle' | 'circular'

export interface Props
  extends ImgHTMLAttributes<HTMLImageElement> {
  /** Image alt text */
  alt: string
  /** Image url */
  src: string
  /** A set of image sources */
  srcSet?: string
  /** Image shape type */
  variant?: VariantType
}

const useStyles = makeStyles(styles, {
  name: 'Image'
})

export const Image: React.FC<Props> = (props) => {
  const classes = useStyles(props)
  const {
    src,
    srcSet,
    alt,
    className,
    variant = 'rectangle',
    style,
    ...rest
  } = props

  return (
    <img
      {...rest}
      src={src}
      srcSet={srcSet}
      alt={alt}
      className={cx(
        {
          [classes.circular]: variant === 'circular'
        },
        classes.root,
        className
      )}
      style={style}
    />
  )
}

export default Image
