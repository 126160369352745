import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import { useTranslation } from 'react-i18next'
import { Grid } from '@material-ui/core'
import Avatar from '@material-ui/core/Avatar'
import SettingsIcon from '@material-ui/icons/Settings'

import Button from '~/components/core/Button'
import Link from '~/components/core/Link'
import Typography from '~/components/core/Typography'
import getInitials from '~/components/utils/getInitials'
import { AuthProvider } from '~/components/core/AuthProvider/context'
import * as S from './styles'

const useStyles = makeStyles(S.Styled)

const ViewerSidebarUser: React.FC = () => {
  const { currentUser } = React.useContext(AuthProvider)
  const { t } = useTranslation()
  const classes = useStyles()

  if (!currentUser) {
    return null
  }

  return (
    <ListItem divider className={classes.root}>
      <Grid container direction='column' spacing={2}>
        <Grid item>
          <Grid container justify='space-between'>
            <Grid item>
              <ListItemAvatar>
                <Avatar className={classes.avatar} variant='circular'>{getInitials(currentUser.displayName || '')}</Avatar>
              </ListItemAvatar>
            </Grid>
            <Grid item>
              <Link color='inherit' to='/settings'>
                <Button startIcon={<SettingsIcon />} variant='text' color='primary'>
                  <Typography color='textPrimary' noWrap>{t('menu-settings')}</Typography>
                </Button>
              </Link>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container justify='space-between'>
            <Grid item>
              <ListItemText primary={<Typography size='xlarge'>{currentUser.displayName}</Typography>} secondary={<Typography size='large'>{currentUser.email}</Typography>} color='secondary' />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </ListItem>
  )
}

export default ViewerSidebarUser
