import copy from 'copy-to-clipboard'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'

export default function useCopyToClipboard(copiedMsg?: string): [(text: string) => void] {
  const { enqueueSnackbar } = useSnackbar()
  const { t } = useTranslation()

  const copyToClipboard = (text: string) => {
    copy(text, {
      message: 'העתק אימייל'
    })
    enqueueSnackbar(copiedMsg || t('emailCopied'))
  }

  return [copyToClipboard]
}
