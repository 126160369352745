import React from 'react'
import { Box } from '@material-ui/core'

import { useBreakpoint } from '~/utils/breakpoints'
import Button from '~/components/core/Button'
import Typography from '~/components/core/Typography'
import { PlanColor, COLOR_MAP } from '../'

const PlanSellerHeaderPriceButton = ({
  color,
  onClick
}: {
  color: PlanColor
  onClick: () => void
}): JSX.Element => {
  const isCompactLayout = useBreakpoint(['small', 'medium'])

  return (
    <Button
      onClick={onClick}
      style={{
        backgroundColor: COLOR_MAP[color],
        width: isCompactLayout ? '100%' : '224px',
        padding: 0,
        height: '52px'
      }}>
      <Box paddingX='24px' paddingY='16px'>
        <Typography style={{ color: '#E6E9F5', fontSize: '14px' }} weight='bold'>
        בחירת תוכנית
        </Typography>
      </Box>
    </Button>
  )
}

export default PlanSellerHeaderPriceButton
