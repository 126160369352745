import { createStyles } from '@material-ui/core/styles'

import { screens } from '~/utils/breakpoints'
import palette from '~/ThemeProvider/config/palette'

export const AssetCardStyled = createStyles({
  root: {
    width: '100%',
    margin: 0,
    padding: 0,
    paddingBottom: '2rem',
    paddingTop: '2rem',
    position: 'relative'
  },
  actionsBar: {
    paddingRight: 0,
    paddingLeft: 0,
    padding: 10
  },
  gridItemInfo: {
    paddingLeft: '2rem!important',
    paddingRight: '2rem!important',
    [screens('small', 'medium')]: {
      paddingLeft: '0!important',
    paddingRight: '0!important'
    }
  },
  advangedDetails: {
    marginTop: 10
  },
  itemWrp: {
    marginBottom: 6,
    marginTop: 6
  },
  titleBar: {
    background:
      'linear-gradient(to top, rgba(0,0,0,0.9) 0%, rgba(0,0,0,0.5) 70%, rgba(0,0,0,0.1) 100%)'
  },
  avatar: {
    backgroundColor: palette.secondary.dark,
    fontSize: 16
  },
  waLogo: {
    width: '1em',
    height: '1em',
    fontSize: '18px'
  },
  mapWrapper: {
    height: 300
  }
})
