import React from 'react'
import {
  Route,
  Switch,
  Redirect
} from 'react-router-dom'

import AssetBuyer from '~/components/pages/AssetBuyer'
import SearchResults from '~/components/pages/SearchResults'
import SearchDetails from '~/components/pages/SearchDetails'
import PostSearch from '~/components/pages/PostSearch'
import SearchesHall from '~/components/pages/SearchesHall'
import BuyerFavoritesLeads from '~/components/pages/BuyerFavoritesLeads'

const BuyerRouter: React.FC = () => {
  return (
    <Switch>
      <Route exact path='/'>
        <Redirect to='/buyer' />
      </Route>

      <Route exact path='/buyer'>
        <SearchesHall />
      </Route>
      <Route exact path='/buyer/asset/:leadId'>
        <AssetBuyer />
      </Route>
      <Route exact path={['/buyer/search/:id', '/buyer/search/:id/:page']}>
        <SearchResults />
      </Route>
      <Route exact path='/buyer/postSearch'>
        <PostSearch />
      </Route>
      <Route exact path={['/buyer/search/:id/details', '/buyer/search/:id/details/:page']}>
        <SearchResults />
        <SearchDetails />
      </Route>
      <Route exact path='/buyer/favorites'>
        <BuyerFavoritesLeads />
      </Route>
      <Route exact path={['/buyer/searchs/:id']}>
        <SearchResults />
        <SearchDetails />
      </Route>
    </Switch>
  )
}

export default BuyerRouter
