import React, { ReactNode } from 'react'
import ToggleButtonGroup, { ToggleButtonGroupProps } from '@material-ui/lab/ToggleButtonGroup'
import { makeStyles } from '@material-ui/core/styles'

import LabelWrapper, { Props as LabelProps } from '../../../LabelWrapper'
import * as S from './styles'

export interface Props extends ToggleButtonGroupProps {
  children: ReactNode
  value?: string | number | undefined
  labelProps?: LabelProps
}

const useStyles = makeStyles(S.StyledGrpop)

const ToggleGroup: React.FC<Props> = ({
  children, value, labelProps = {}, ...rest
}) => {
  const classes = useStyles()
  const childrenArr = React.Children.toArray(children)

  return (
    <LabelWrapper direction='column' {...labelProps}>
      <ToggleButtonGroup
        {...rest}
        className={classes.root}
        value={value}
        exclusive
      >
        {childrenArr}
      </ToggleButtonGroup>
    </LabelWrapper>

  )
}

export default ToggleGroup
