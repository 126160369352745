import React, { useEffect } from 'react'
import useFetch from 'use-http'
import { useTranslation } from 'react-i18next'
import { Grid } from '@material-ui/core'
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder'

import AssetsResultsList from '~/components/modules/AssetsResultsList'
import ScrollToTopButton from '~/components/modules/ScrollToTopButton'
import { MapContext } from '~/components/core/MapProvider/context'
import ResultsMessage from '~/components/core/ResultsMessage'
import PageLoader from '~/components/core/PageLoader'
import Typography from '~/components/core/Typography'
import { ViewerFavoriteContext } from '~/components/core/ViewerFavoriteProvider/context'
import useAssetDetailsModal from '~/hooks/useAssetDetailsModal'
import { convertToMapMarkers } from '~/components/pages/SearchResults'

const BuyerFavoritesLeads: React.FC = () => {
  const { setMap = () => {}, setMapMarkers, map } = React.useContext(MapContext)
  const { t } = useTranslation()
  const { post: deleteLeadPost, loading: deleteLeadLoading } = useFetch('api/searches/removeLead')
  const { searchFavorites, removeFavoriteState } = React.useContext(ViewerFavoriteContext)
  const [assetDetailsModal,, setSelectedLeadId] = useAssetDetailsModal()

  useEffect(() => {
    setMap(true)

    return () => setMap(false)
  }, [])

  useEffect(() => {
    if (map) {
      setMapMarkers(convertToMapMarkers(searchFavorites || [], undefined, setSelectedLeadId))
    }
  }, [map, searchFavorites?.length])

  const handleDeleteLead = async (leadId?: string) => {
    if (!leadId) {
      return
    }

    await deleteLeadPost({ leadId })
    removeFavoriteState(leadId)
  }

  if (!searchFavorites) {
    return null
  }

  if (!searchFavorites.length) {
    return (
      <ResultsMessage
        title={t('noFavorites')}
        icon={<FavoriteBorderIcon color='secondary' fontSize='large' style={{ height: 150, width: 'auto', marginTop: 40 }} />}
      />
    )
  }

  const items = searchFavorites.map((item) => ({
    item
  }))

  return (
    <>
    <Grid container spacing={3} direction='column'>
      <Grid item style={{ marginTop: '12px' }}>
        <Grid container alignItems='center'>
          <Grid item>
            <FavoriteBorderIcon color='secondary' style={{ fontSize: '1.8rem' }} />
          </Grid>
          <Grid item>
            <Typography weight='bold' style={{ fontSize: '1.8rem', padding: '12px' }}>
              {t('myFavoritesAssets')}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <AssetsResultsList items={items} disabled onRemoveLead={handleDeleteLead} onItemClick={setSelectedLeadId} />
      </Grid>
    </Grid>
    <ScrollToTopButton />
    <PageLoader open={deleteLeadLoading} />
    {assetDetailsModal}
    </>
  )
}

export default BuyerFavoritesLeads
