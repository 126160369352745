import React, { useEffect, useRef } from 'react'
import { Grid, CardActions } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import useFetch from 'use-http'

import Modal from '~/components/core/Modal'
import PageLoader from '~/components/core/PageLoader'
import Button from '~/components/core/Button'
import Typography from '~/components/core/Typography'
import { AuthProvider } from '~/components/core/AuthProvider/context'

export interface Props {
  onSubmit: () => void
  onClose: () => void
  isOpen: boolean
}

const DeleteUserModal: React.FC<Props> = ({ onClose, isOpen }) => {
  const { t } = useTranslation()
  const history = useHistory()
  const uid = useRef<string | null | undefined>(null)
  const { currentUser } = React.useContext(AuthProvider)
  const { delete: deleteUser, loading } = useFetch(`/users/deleteUser`)

  useEffect(() => {
    uid.current = currentUser?.uid
  }, [])

  const handleDelete = async () => {
    await deleteUser()
    history.replace(`/public/feedback/${uid.current}`)
  }

  return (
    <>
    <Modal open={isOpen}>
      <Modal.Title onClose={onClose}>
        לפני מחיקה
      </Modal.Title>
      <Modal.Content dividers>
        <Grid container direction='column' spacing={1}>
          <Grid item>
            <Typography size='large'>
              {t('permanently-remove')}
            </Typography>
          </Grid>
        </Grid>
      </Modal.Content>
      <Modal.Actions>
        <CardActions style={{ width: '100%' }}>
          <Button size='small' color='primary' variant='text' onClick={onClose}>
            <Typography size='small'>
              {t('close')}
            </Typography>
          </Button>
          <Button size='small' color='primary' variant='text' onClick={handleDelete}>
            <Typography size='small'>
            {t('remove')}
            </Typography>
          </Button>
        </CardActions>
      </Modal.Actions>
    </Modal>
    <PageLoader open={loading} />
    </>
  )
}

export default DeleteUserModal
