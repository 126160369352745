import { useState } from 'react'
import axios from 'axios'

export type LatLngItem = {
  lat: number | null
  lng: number | null
}

export type Bounds = {
  northeast: LatLngItem | null
  southwest: LatLngItem | null
} | undefined | null

export type GeoPosition = {
  latLng: LatLngItem
  bounds?: Bounds | null | undefined
  formattedAddress?: string
}

const useGeoPosition = (): [({ placeId, address }: {placeId?: string | undefined, address?: string | undefined }) => Promise<GeoPosition>, boolean, boolean] => {
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(false)

  // eslint-disable-next-line complexity
  const perform = async ({ placeId, address }: {placeId?: string | undefined, address?: string | undefined }): Promise<GeoPosition> => {
    if (!placeId && !address) {
      return ({
        latLng: { lat: null, lng: null }
      })
    }

    try {
          setLoading(true)
          const res = await axios.get(`https://maps.googleapis.com/maps/api/geocode/json?place_id=${placeId}&key=AIzaSyBseMsD-w3DQJF0VuPBHG7UqXKbTMPVIYI`)
          const { location, bounds, viewport }: { location: any, bounds: any, viewport: any } = res.data.results[0].geometry

          setLoading(false)

          if (!location) {
            return ({
              latLng: { lat: null, lng: null }
            })
          }

          return ({
            latLng: { lat: location.lat, lng: location.lng },
            bounds: bounds || viewport,
            formattedAddress: res.data.results[0].formatted_address
          })
      } catch (err) {
        setLoading(false)
        setError(true)

        return ({
          latLng: { lat: null, lng: null }
        })
    }
  }

  return [perform, loading, error]
}

export default useGeoPosition
