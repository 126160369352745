import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import CheckboxMUI, { CheckboxProps } from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import CheckBoxIcon from '@material-ui/icons/CheckBox'

import LabelWrapper, { Props as LabelProps } from '../../LabelWrapper'
import Typography from '~/components/core/Typography'
import styles from './styles'

const useStyles = makeStyles(styles, {
  name: 'Checkbox'
})

export interface Props extends CheckboxProps {
  labelNode?: React.ReactNode
  native?: boolean
  label?: string
  labelProps?: LabelProps
}

const Checkbox: React.FC<Props> = ({ label, labelNode, labelProps, ...rest }) => {
  const classes = useStyles(rest)

  return (
    <LabelWrapper direction='column' {...labelProps}>
      <FormControlLabel
        control={
          <CheckboxMUI
            {...rest}
            classes={{
              root: classes.root
            }}
            color='primary'
            icon={<CheckBoxOutlineBlankIcon className={classes.icon} />}
            checkedIcon={<CheckBoxIcon className={classes.icon} />}
          />
        }
        className={classes.labelWrp}
        style={rest.disabled ? { cursor: 'not-allowed' } : {}}
        label={labelNode || <Typography className={classes.label}>{label}</Typography>}
      />
    </LabelWrapper>
  )
}

export default Checkbox
