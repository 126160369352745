import React from 'react'

import { MapContext } from './context'
import { Props as MapMarkersProps } from '~/components/core/MapView/MapMarker'

interface Props {
  children: React.ReactElement
  onMapChange?: (isShow: boolean) => void
}

const MapProviderWrapper: React.FC<Props> = ({ children, onMapChange = () => {} }) => {
  const [map, setMap] = React.useState(false)
  const [mapApiLoaded, setMapApiLoaded] = React.useState(false)
  const [leadIdHover, setLeadIdHover] = React.useState<string | null | undefined>(null)
  const [mapMarkers, setMapMarkers] = React.useState<MapMarkersProps[]>([])
  const [selectedMarkerLeadId, setSelectedMarkerLeadId] = React.useState<string | null | undefined>(null)

  const handleSetMap = (show: boolean) => {
    onMapChange(show)
    setMap(show)
  }

  return (
    <MapContext.Provider value={{
      map,
      setMap: handleSetMap,
      mapMarkers,
      setMapMarkers,
      setMapApiLoaded,
      mapApiLoaded,
      selectedMarkerLeadId,
      setSelectedMarkerLeadId,
      setLeadIdHover,
      leadIdHover
      }}
    >
      {children}
    </MapContext.Provider>
  )
}

export default MapProviderWrapper
