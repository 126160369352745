import { createStyles, Theme } from '@material-ui/core/styles'

import palette from '~/ThemeProvider/config/palette'
import { Props } from './'

const SIZES_HEIGHT = {
  small: '90px',
  medium: '150px',
  large: '200px'
}

const SIZES_WIDTH = {
  small: '90px',
  medium: '150px',
  large: '100%'
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const StyledUploadButton = (theme: Theme) => createStyles({
  root: {
    '& > *': {
      margin: theme.spacing(1)
    },
    height: (props: Props) => SIZES_HEIGHT[props.size],
    width: (props: Props) => SIZES_WIDTH[props.size]
  },
  input: {
    display: 'none'
  },
  button: {
    height: '100% !important',
    width: '100% !important',
    color: palette.primary.dark
  },
  closeButton: {
    position: 'absolute',
    zIndex: 6
  }
})
