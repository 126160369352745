import React, { useEffect } from 'react'
import queryString from 'query-string'
import { useModal } from 'react-modal-hook'

import SpamEmailsWarningModal from '~/components/modules/SpamEmailsWarningModal'

export default function useSpamWarningModal(visible?: boolean, onModalClose?: () => void): void {
  const { showSpamModal } = queryString.parse(location.search)
  const [, hideModal] = useModal(() => (
    <SpamEmailsWarningModal
      onClose={() => {
        hideModal()
        onModalClose?.()
      }}
      isOpen
    />
  ), [onModalClose])

  useEffect(() => {
    if (showSpamModal && visible) {
      // showModal()
      onModalClose?.()
    }
  }, [visible])
}
