import React, { useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { Grid } from '@material-ui/core'

import segment from '~/services/segment'
import ResultsMessage from '~/components/core/ResultsMessage'
import { Campaign } from '~/components/core/Icons'
import PlansSeller from '~/components/modules/PlansSeller/v3'
import { PaymentType } from '~/types/Payment'
import { AuthProvider } from '~/components/core/AuthProvider/context'

const HOST_URL = process.env.HOST_URL;

const Plans: React.FC = () => {
  const history = useHistory()
  const { currentUser } = React.useContext(AuthProvider)
  const { assetId, planType } = useParams<{ assetId: string, planType: PaymentType }>()

  useEffect(() => {
    segment.track('Redirect to Plans selection page', {
      propertyId: assetId
    })
  }, [])

  const handleOnFreeClick = () => {
    return history.push(`/seller/asset/${assetId}/leads`)
  }

  const handleOnStandardClick = () => {
    return window.location.replace(`${HOST_URL}api/payments/startTransaction/standard/${assetId}?userId=${currentUser?.uid}`)
  }

  const handleOnPremiumClick = () => {
    return window.location.replace(`${HOST_URL}api/payments/startTransaction/premium/${assetId}?userId=${currentUser?.uid}`)
  }

  const handleOnSubmit = (val: PaymentType | undefined) => {
    if (val === 'free') {
      return handleOnFreeClick()
    }

    if (val === 'standard') {
      return handleOnStandardClick()
    }

    if (val === 'premium') {
      return handleOnPremiumClick()
    }
  }

  return (
    <Grid container direction='column' alignItems='center' spacing={4} style={{ marginTop: 20, marginBottom: 40 }}>
      <Grid item>
        <ResultsMessage
          title='חבילות פרסום'
          subTitle='שדרגו את חבילת הפרסום ופרסמו את המודעה למחפשים שהדירה שלכם מתאימה להם. המחפשים המתאימים יראו את המודעה רק אם תפרסמו להם אותה'
          icon={<Campaign />}
        />
      </Grid>
      <Grid item style={{ width: '100%', display: 'flex' }} justify='center'>
        <PlansSeller value={planType} onSubmit={handleOnSubmit} />
      </Grid>
    </Grid>
  )
}

export default Plans
