import React from 'react'
import { Grid } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import SubjectIcon from '@material-ui/icons/Subject'

import { useBreakpoint } from '~/utils/breakpoints'
import Typography from '~/components/core/Typography'

export interface Props {
  value: string | React.ReactNode
  label?: string
}

const AssetDetailDescriptionField = ({ value, label }: Props): JSX.Element => {
  const { t } = useTranslation()
  const isCompactLayout = useBreakpoint(['small', 'medium'])

  if (!value) {
    return <></>
  }

  return (
    <Grid item key='description-field' container>
      <Grid container alignItems='center' wrap='nowrap' direction={isCompactLayout ? 'column' : 'column'}>
        <Grid item container wrap='nowrap' alignItems='flex-start' style={{ alignSelf: 'flex-start' }}>
          <Grid container wrap='nowrap' spacing={1}>
              <Grid item>
                <SubjectIcon fontSize='small' />
              </Grid>
            <Grid item container alignItems='center'>
              <Typography size='medium' weight='normal'>
                {label || t('description')}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item alignItems='stretch' container style={{ maxWidth: '80vw' }}>
          <Typography style={{ width: '100%', wordWrap: 'break-word' }}>
            {value}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default AssetDetailDescriptionField
