import { Lead } from '~/types/Leads'
import { isLoadingLeads } from './'

export const getSentMessagesCount = (leads: 'loadingLeads'[] | Lead[] | undefined): number => {
    if (isLoadingLeads(leads)) {
        return 0
    }

    return (leads as Lead[])?.reduce<number>((acc, lead) => {
      if (lead.timesOwnerContactedSearcher > 0) {
        // eslint-disable-next-line no-param-reassign
        acc++
      }

      return acc
    }, 0)
}
