import React from 'react'
import { Grid, Box } from '@material-ui/core'
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined'

import Typography from '~/components/core/Typography'

export interface Props {
  onClick: () => void
}

const ViewSearchDetails = ({
  onClick
}: Props): JSX.Element => {
  return (
    <Box width='max-content' style={{ cursor: 'pointer' }} onClick={onClick}>
      <Grid container spacing={0} direction='column' alignItems='center'>
        <Grid item style={{ display: 'inline-flex' }}>
          <VisibilityOutlinedIcon color='primary' />
        </Grid>
        <Grid item alignItems='center' style={{ display: 'inline-flex' }}>
          <Typography color='textPrimary' style={{ fontSize: '12px' }}>
        פרטי החיפוש
          </Typography>
        </Grid>
      </Grid>
    </Box>
  )
}

export default ViewSearchDetails
