import React from 'react'
import { useParams } from 'react-router-dom'
import useFetch from 'use-http'

import { rollbarError } from '~/utils/rollbar'
import NoDataMessage from '~/components/modules/NoDataMessage'
import { BuyerAsset } from '~/types/Asset'
import AssetDetails from '~/components/modules/AssetDetails'

const Asset: React.FC = () => {
  const { id } = useParams<{ id?: string }>()
  const { loading, error, data } = useFetch<BuyerAsset>(`/api/v2/properties/get-public-asset-details/${id}`, [id])

  if (error) {
    rollbarError({
      error
    })

    return (
      <NoDataMessage type='asset' isPublic />
    )
  }

  return (
    <AssetDetails item={{ ...data, id: id || '' } as BuyerAsset} loading={loading} isPublic />
  )
}

export default Asset
