import { createStyles } from '@material-ui/core/styles'

import { screens } from '~/utils/breakpoints'

export const StyledGrpop = () => createStyles({
  root: {
    [screens('small', 'medium')]: {
      width: '100%',
      justifyContent: 'space-around'
    }
  }
})
