import React from 'react'
import {
  useField,
  FieldProps as FinalFieldProps,
  FieldRenderProps
} from 'react-final-form'
import { useTranslation } from 'react-i18next'

import BaseRangeNumbers, { Props as BaseRangeNumbersProps, RangeValue } from '~/components/core/Inputs/RangeNumbers'
import FormField from '../FormField'

export type FieldProps<TInputValue> = FinalFieldProps<
  TInputValue,
  FieldRenderProps<TInputValue, HTMLInputElement>,
  HTMLInputElement
>

export type Props = BaseRangeNumbersProps & FieldProps<BaseRangeNumbersProps['value']>

export const RangeNumbers: React.FC<Props> = (props) => {
  const { t } = useTranslation()
  const { meta, input } = useField(props.name, {
    validate: ({ min, max }) => {
      return (Number(min) > Number(max)) ? t('errorMsg-minBigerMax') : undefined
    }
  })
  const error = meta.touched && (meta.error || meta.submitError)

  const handleOnChange = (data: RangeValue) => {
    input.onChange(data)
  }

  return (
    <FormField error={meta.touched && (meta.error || meta.submitError)} required={props.required}>
      <BaseRangeNumbers {...props} {...input} name={input.name} onChange={handleOnChange} inputProps={{ ...props.inputProps, error: !!error }} />
    </FormField>
  )
}

export default RangeNumbers
