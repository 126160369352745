import { Theme, createStyles } from '@material-ui/core/styles'

import palette from '~/ThemeProvider/config/palette'

export default ({ layout }: Theme) =>
  createStyles({
    root: {
      flex: 1,
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '60px',
      backgroundColor: palette.primary.dark
    },
    content: {
      height: '100%',
      flexGrow: 1,
      justifyContent: 'center',
      alignItems: 'center',
      maxWidth: layout.contentWidth,
      padding: '0 1rem',
      color: palette.common.white
    },
    wide: {
      maxWidth: layout.contentWidthWide
    },
    fullWidth: {
      maxWidth: '100%'
    },
    flex: {
      display: 'flex'
    }
  })
