import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import ToggleButton, { ToggleButtonProps } from '@material-ui/lab/ToggleButton'

import * as S from './styles'

const StyledToggleButton = withStyles(S.Button)(ToggleButton)
// const useStyles = makeStyles(S.StyledRadio)

export interface Props extends ToggleButtonProps {
  label?: string
  value: string | number
}

const RadioButton: React.FC<Props> = ({ label, value, ...rest }) => {
  return (
    <StyledToggleButton {...rest} value={value}>
      {label}
    </StyledToggleButton>
  )
}

export default RadioButton
