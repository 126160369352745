import React, { ReactElement } from 'react'
import {
  useField,
  FieldProps as FinalFieldProps,
  FieldRenderProps
} from 'react-final-form'

import BaseUploadFilesGroup, { Props as BaseUploadFilesGroupProps, DataProps } from '~/components/core/Inputs/UploadButtons/group'
import { Props as ToggleButtonProps } from '~/components/core/Inputs/ToggleButton/Item'
import FormField from '../FormField'
import LabelWrapper from '../../LabelWrapper'

export type FieldProps<TInputValue> = FinalFieldProps<
  TInputValue,
  FieldRenderProps<TInputValue, HTMLInputElement>,
  HTMLInputElement
>

export type Props = BaseUploadFilesGroupProps & FieldProps<ToggleButtonProps['value']> & {
  name: string
  label?: string
}

export const UploadFilesGroup: React.FC<Props> = (props) => {
  const { children, ...rest } = props
  const { meta, input } = useField(rest.name)

  const handleOnChange = (files: DataProps) => {
    input.onChange(files)
  }

  return (
    <FormField error={meta.touched && (meta.error || meta.submitError)}>
      <LabelWrapper label={props.label} direction='column'>
        <BaseUploadFilesGroup {...props} {...input} onChange={handleOnChange} filesArr={input.value}>
          {React.Children.toArray(children)
            .filter(React.isValidElement)
            .map(child =>
              React.cloneElement(child as ReactElement, {
                name: props.name
              })
            )}
        </BaseUploadFilesGroup>
      </LabelWrapper>
    </FormField>
  )
}

export default UploadFilesGroup
