import { useTranslation } from 'react-i18next'

import { InitProps } from '~/components/modules/ForgetPasswordForm'

type AssetFormValidation = {
  password?: string
}

export default function useResetPasswordFormValidation(): [(data: InitProps) => AssetFormValidation] {
  const { t } = useTranslation()
  const noSpaceRegexp = /^\S*$/

  const validateForm = (data: InitProps): AssetFormValidation => {
    let vlidationsError = {}

    if (!data.password) {
      vlidationsError = {
        ...vlidationsError,
        password: t('errorMsgRequiredField')
      }
    }

    if (data.password?.length && data.password.length < 6) {
      vlidationsError = {
        ...vlidationsError,
        password: t('minimumLetterSix')
      }
    }

    if (!noSpaceRegexp.test(data.password)) {
      vlidationsError = {
        ...vlidationsError,
        password: t('noSpaceAllow')
      }
    }

    return vlidationsError
  }

  return [validateForm]
}
