import { createStyles } from '@material-ui/core/styles'

import { screens } from '~/utils/breakpoints'
import palette from '~/ThemeProvider/config/palette'

export const AssetCardStyled = createStyles({
  wrapper: {
    maxWidth: 'auto'
  },
  root: {
    width: '650px',
    [screens('small', 'medium')]: {
      width: 'auto'
    }
  },
  green: {
    fontSize: '1.3rem',
    color: '#fff',
    backgroundColor: palette.primary.main
  },
  actionsBar: {
    paddingRight: 0,
    paddingLeft: 0,
    padding: 10
  },
  gridItemInfo: {
    paddingLeft: '8px!important',
    paddingRight: '8px!important'
  },
  advangedDetails: {
    marginTop: 10
  },
  itemWrp: {
    marginBottom: 6,
    marginTop: 6
  },
  titleBar: {
    background:
      'linear-gradient(to top, rgba(0,0,0,0.9) 0%, rgba(0,0,0,0.5) 70%, rgba(0,0,0,0.1) 100%)'
  },
  avatar: {
    backgroundColor: palette.secondary.dark,
    fontSize: 16
  },
  waLogo: {
    width: '1em',
    height: '1em',
    fontSize: '18px'
  }
})
