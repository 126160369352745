import React from 'react'
import { Box } from '@material-ui/core'

import { PlanCheck } from '~/components/core/Icons'
import { PlanColor, COLOR_MAP } from '../'

const PlanCellCheck = ({
  color
}: {
  color: PlanColor
}): JSX.Element => {
  return (
    <Box display='flex' justifyContent='center'>
      <PlanCheck color={COLOR_MAP[color]} />
    </Box>
  )
}

export default PlanCellCheck
