import { createStyles } from '@material-ui/core/styles'

export const ContactActionsStyled = createStyles({
  actionsBar: {
    paddingRight: 0,
    paddingLeft: 0,
    padding: 10
  },
  waLogo: {
    width: '1em',
    height: '1em',
    fontSize: '18px'
  },
  paperRoot: {
    marginTop: '20px',
    borderRadius: '15px',
    width: '200px',
    height: '200px',
    padding: '40px'
  }
})
