import React from 'react'
import { Grid } from '@material-ui/core'

import Sidebar from '~/components/core/Sidebar'
import ViewerSidebarAssetsItem from '~/components/modules/ViewerSidebarAssetsItem'
import ViewerSidebarSearchesItem from '~/components/modules/ViewerSidebarSearchesItem'
import ViewerSidebarUser from '~/components/modules/ViewerSidebarUser'
import ViewerSidebarCommon from '~/components/modules/ViewerSidebarCommon'

export interface Props {
  onClose: () => void
  open: boolean
}

const ViewerSidebar: React.FC<Props> = ({ onClose, open }) => {
  return (
    <Sidebar onClose={onClose} open={open}>
      <Grid container style={{ minHeight: '100%' }} justify='space-between' direction='column'>
        <Grid item container>
          <ViewerSidebarUser />
          <ViewerSidebarAssetsItem />
          <ViewerSidebarSearchesItem />
        </Grid>
        <Grid item container style={{ paddingBottom: 10 }}>
          <ViewerSidebarCommon />
        </Grid>
      </Grid>
    </Sidebar>
  )
}

export default ViewerSidebar
