import React from 'react'
import moment from 'moment'
import { Grid } from '@material-ui/core'
import PeopleOutlineIcon from '@material-ui/icons/PeopleOutline'
import EditIcon from '@material-ui/icons/Edit'
import { useTranslation } from 'react-i18next'

import Button from '~/components/core/Button'
import Link from '~/components/core/Link'
import ShareAsset from '~/components/modules/ShareAsset'
import BuyerFavButton from '~/components/modules/BuyerFavButton'
import Typography from '~/components/core/Typography'
import { useBreakpoint } from '~/utils/breakpoints'
import AssetValueInfo from '~/components/modules/AssetValueInfo'
import { SellerAsset, BuyerAsset } from '~/types/Asset'
import { SearchLead } from '~/types/Search'

export interface Props {
  postType: 'sell' | 'rent'
  date?: Date
  id?: string
  seller?: boolean
  leadId?: string
  item?: SellerAsset | BuyerAsset
  isPublic?: boolean
}

const POST_TYPE: { [key in Props['postType']]: string } = {
  rent: 'להשכרה',
  sell: 'למכירה'
}

const getShortenItemObj = (item: BuyerAsset): SearchLead => {
  return {
    address: item.address,
    city: item.city,
    deviation: 0,
    isNew: false,
    images: item.images,
    leadId: item.leadId as string,
    percentage: item.percentage,
    position: item.position,
    price: item.price,
    rooms: item.rooms,
    squareSize: item.squareSize,
    searchId: item.searchIdConnect,
    ownerDetails: {
      email: item.contactEmail,
      name: item.contactName,
      phoneNumber: item.contactNumber
    }
  }
}

const WITHOUT_REALATOR = 'ללא עמלת תיווך'
const WITH_REALATOR = 'יש עמלת תיווך'

// eslint-disable-next-line complexity
const AssetDetailsHeader: React.FC<Props> = ({ postType, date, id, seller, leadId, item, isPublic }) => {
  const isCompactLayout = useBreakpoint(['small', 'medium'])
  const { t } = useTranslation()

  return (
    <Grid container alignItems='flex-start' justify='space-between' direction={(seller && isCompactLayout) ? 'column-reverse' : 'row'} spacing={(seller && isCompactLayout) ? 2 : 0}>
      <Grid item>
        <AssetValueInfo value={`${POST_TYPE[postType]} - ${item?.isAgent ? WITH_REALATOR : WITHOUT_REALATOR}`} label={`ת.פרסום המודעה ${moment(date).format('DD/MM/YY')}`} />
      </Grid>
      <Grid item>
        <Grid container spacing={2} alignItems='center' wrap='nowrap'>
          {
            seller && (
              <>
              <Grid item>
                <Link color='inherit' to={`/seller/asset/${id}/leads`}>
                  <Button startIcon={<PeopleOutlineIcon />} variant='text' color='primary'>
                    <Typography color='textPrimary' noWrap>{t('leadsMatchBuyers')}</Typography>
                  </Button>
                </Link>
              </Grid>
              <Grid item>
                <Link color='inherit' to={`/seller/editAsset/${id}`}>
                  <Button startIcon={<EditIcon />} variant='text' color='primary'>
                    <Typography color='textPrimary' noWrap>{t('editPropery')}</Typography>
                  </Button>
                </Link>
              </Grid>
              </>
            )
          }
          {
            !!id && (
              <Grid item>
                <ShareAsset assetId={id} isSeller={seller} />
              </Grid>
            )
          }
          {
            (!seller && !isPublic) && (
              <Grid item>
                <BuyerFavButton item={getShortenItemObj(item as BuyerAsset)} leadId={leadId} textPrimary />
              </Grid>
            )
          }
        </Grid>
      </Grid>
    </Grid>
  )
}

export default AssetDetailsHeader
