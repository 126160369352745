import React from 'react'
import useFetch, { CachePolicies } from 'use-http'
import { useSnackbar } from 'notistack'

import SearchDetails from '~/components/modules/SearchDetails'
import { getFirstNameAndInitials } from '~/components/utils/getInitials'
import { LeadSearchDetails as LeadSearchDetailsTypes } from '~/types/Leads'

interface Props {
  onClose: () => void
  onContactSearcher?: () => void
  leadId: string
  onSendMessage?: () => void
}

const LeadSearchDetails: React.FC<Props> = ({
  onClose, onContactSearcher, leadId, onSendMessage
}) => {
  const { enqueueSnackbar } = useSnackbar()
  const {
    loading,
    error,
    data
  } = useFetch<LeadSearchDetailsTypes>(`/api/v3/assets/get-search-details/${leadId}`, {
    data: null,
    cachePolicy: CachePolicies.NO_CACHE,
    onError: () => {
      enqueueSnackbar('שגיאה, אנא נסו שוב מאוחר יותר', {
        variant: 'error'
      })
    }
  }, [leadId])

  if (error) {
    return null
  }

  return (
    <SearchDetails
      onClose={onClose}
      searcherName={data?.searcherDisplayName}
      leadDetails={{
        leadId,
        leadName: getFirstNameAndInitials(data?.searcherDisplayName ?? ''),
        sendCount: data?.timesOwnerContactedSearcher,
        onSendMessage
      }}
      onContactSearcher={onContactSearcher}
      search={data?.search as LeadSearchDetailsTypes['search']}
      availableContactMethods={data?.availableContactMethods}
      loading={loading}
    />
  )
}

export default LeadSearchDetails
