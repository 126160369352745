import { useState, useEffect } from 'react'
import useFetch, { CachePolicies } from 'use-http'

import frontendAnalytic from '~/services/frontendAnalytic'

interface TrackerUser {
  uid: string
  displayName?: string | null
  email?: string | null
}

interface TrackerData {
  ACTIVE_UI_TRACKER: number
  UI_TRACKERS: {
    fullstory: 1
    logrocket: 2
  }
}

let initilized = false

export default function useUITracker(): [(user: TrackerUser) => void] {
  const { data } = useFetch<TrackerData>(`${window.location.origin}/tracker.json`, { cachePolicy: CachePolicies.NO_CACHE }, [])
  const [userTracker, setUserTracker] = useState<TrackerUser>()

  useEffect(() => {
    if (userTracker && data && !initilized) {
      initilized = true
      const tracker = data?.ACTIVE_UI_TRACKER === 2 ? 'logrocket' : 'fullstory'

      frontendAnalytic.initialize(userTracker, tracker)
    }
  }, [userTracker, data])

  return [setUserTracker]
}
