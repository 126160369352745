import React from 'react'
import { Grid, Box } from '@material-ui/core'

import { useBreakpoint } from '~/utils/breakpoints'
import PlanSellerHeaderPrice from '../PlanSellerHeaderPrice'
import PlanSellerHeaderPriceButton from '../PlanSellerHeaderPriceButton'
import { PlansSellerProps } from '../../index'
import { PlanColor } from '../'

interface Props extends PlansSellerProps {
  color: PlanColor
  title: string
  subTitle: string
  tag?: React.ReactNode
}

const PlanSellerHeaderCell = ({
  color,
  title,
  subTitle,
  tag,
  onSubmit,
  value
}: Props): JSX.Element => {
  const isCompactLayout = useBreakpoint(['small', 'medium'])

  const handleClick = () => {
    onSubmit(value)
  }

  return (
    <Grid
      direction='column'
      alignItems='stretch'
      justify='center'
      spacing={2}
      container
      style={{
        marginBottom: isCompactLayout ? 16 : 30,
        position: 'relative'
      }}
    >
      {tag && (
        <Box position='absolute' top={0} right={-16}>
          {tag}
        </Box>
      )}
      <Grid item>
        <PlanSellerHeaderPrice
          title={title}
          subTitle={subTitle}
          color={color}
        />
      </Grid>
      <Grid item>
        <PlanSellerHeaderPriceButton color={color} onClick={handleClick} />
      </Grid>
    </Grid>
  )
}

export default PlanSellerHeaderCell
