/* eslint-disable complexity */
import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import moment from 'moment'
import { Grid, CardActions } from '@material-ui/core'
import Avatar from '@material-ui/core/Avatar'
// import MailOutlineIcon from '@material-ui/icons/MailOutline'
// import WhatsAppIcon from '@material-ui/icons/WhatsApp'
import { useTranslation } from 'react-i18next'
import CalendarTodayIcon from '@material-ui/icons/CalendarToday'

import getInitials from '~/components/utils/getInitials'
import SendMessage from '~/components/pages/AssetLeads/components/SendMessage'
import { addressWithoutCountry, onlyLocationName } from '~/components/utils/formating'
import Modal from '~/components/core/Modal'
import AssetValueInfo from '~/components/modules/AssetValueInfo'
import AssetDetailRawField from '~/components/modules/AssetDetailRawField'
import AssetBooleanDetailRawField from '~/components/modules/AssetBooleanDetailRawField'
import SearchDetailsMandatoryInfo from '~/components/modules/SearchDetailsMandatoryInfo'
import SearchDetailsDealReadiness from '~/components/modules/SearchDetailsDealReadiness'
import AssetDetailDescriptionField from '~/components/modules/AssetDetailDescriptionField'
// import Button from '~/components/core/Button'
import Typography from '~/components/core/Typography'
import * as S from './styles'
import { ASSET_PROPS_ICONS, BOOLEAN_FIELD } from './constants'
import { Search } from '~/types/Search'
import { ContactMethods } from '~/types/Leads'
import Loader from './Loader'

const FLOOR_LABEL = [
  'selectFloor-ground',
  'selectFloor-low',
  'selectFloor-medium',
  'selectFloor-high'
]

const REMODEL_LABEL = [
  'selectRemodel-new',
  'selectRemodel-likeNew',
  'selectRemodel-reserved',
  'selectRemodel-not'
]

const VIEW_LABEL = [
  'selectView-none',
  'selectView-sea',
  'selectView-green',
  'selectView-orban'
]

const useStyles = makeStyles(S.AssetCardStyled)

interface BuyerContactsProps {
  onContactEmail?: () => void
  contactName?: string
  onContactNumber?: () => void
}

interface LeadDetails {
  leadId: string
  sendCount?: number
  leadName?: string
  onSendMessage?: () => void
}

export interface Props {
  search: Search
  buyerContacts?: BuyerContactsProps
  availableContactMethods?: ContactMethods[]
  searcherName?: string
  onClose: () => void
  loading?: boolean
  onContactSearcher?: () => void
  leadDetails?: LeadDetails
}

const IMPORTANCE_TEXT = ['לא חשוב', 'נחמד שיהיה', 'חשוב מאוד', 'חובה']

const SearchDetails: React.FC<Props> = ({ search, onClose, loading, leadDetails, searcherName }) => {
  const { t } = useTranslation()
  const classes = useStyles()

  return (
    <Modal open maxWidth='md'>
      <Modal.Title onClose={onClose}>
        פרטי החיפוש
      </Modal.Title>
      {
        loading
        ? <Loader />
        : (
          <Modal.Content dividers className={classes.root}>
            <Grid container direction='column' spacing={1}>
              {
                searcherName && (
                  <Grid item className={classes.gridItemInfo}>
                    <Grid container alignItems='center' spacing={1}>
                      <Grid item>
                        <Avatar className={classes.green}>{getInitials(searcherName)}</Avatar>
                      </Grid>
                      <Grid item>
                        <Typography size='large'>{searcherName}</Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                )
              }
              <Grid item className={classes.gridItemInfo}>
                <AssetValueInfo value={search.forSale ? t('labelForSale') : t('labelForRent')} label={`ת.העלאת החיפוש ${moment(new Date(search.dateCreated)).format('DD/MM/YY')}`} />
              </Grid>
              <Grid item className={classes.gridItemInfo}>
                <Grid container alignItems='flex-start' spacing={3}>
                  <Grid item className={classes.itemWrp}>
                    <AssetValueInfo size='large' label='עיר' value={addressWithoutCountry(search.city)} />
                  </Grid>
                </Grid>
              </Grid>
              {
                !!search.neighborhood?.length && (
                  <Grid item className={classes.gridItemInfo}>
                    <Grid container alignItems='flex-start' spacing={3}>
                      <Grid item className={classes.itemWrp}>
                        <AssetValueInfo label='שכונות' value={search.neighborhood.map((loc) => onlyLocationName(loc)).toString().replaceAll(',', ', ')} />
                      </Grid>
                    </Grid>
                  </Grid>
                )
              }
              <Grid item className={classes.gridItemInfo}>
                <SearchDetailsMandatoryInfo
                  price={search.budget}
                  squareSize={search.squareSize}
                  rooms={search.rooms}
                />
              </Grid>
              <Grid item className={classes.gridItemInfo}>
                <AssetDetailDescriptionField
                  value={search.tenants}
                  label='תיאור קצר עבור מי הדירה'
                />
              </Grid>
              <Grid item className={classes.gridItemInfo}>
                <SearchDetailsDealReadiness
                  dealReadiness={search.dealReadiness}
                />
              </Grid>
              <br />
              <Grid item className={classes.gridItemInfo}>
                <Grid container direction='row' wrap='nowrap'>
                  <Grid item container lg={9} alignItems='stretch'>
                    <Grid container direction='column' alignItems='stretch' spacing={1}>
                      {
                        BOOLEAN_FIELD.map((key: string) => {
                          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                          // @ts-ignore
                          const RelevantIcon = ASSET_PROPS_ICONS[key]

                          if (key === 'floor') {
                            return (
                              <Grid item key={key} container>
                                {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                                {/* @ts-ignore */}
                                <AssetDetailRawField
                                  label={t(key)}
                                  value={(
                                    <Grid container direction='column' spacing={1} wrap='nowrap'>
                                      <Grid item>
                                        <Typography size='small'>{t(FLOOR_LABEL[Number(search[key].value)])}</Typography>
                                      </Grid>
                                      <Grid item container>
                                        <Typography size='small'>{IMPORTANCE_TEXT[Number(search[key].importance)]}</Typography>
                                      </Grid>
                                    </Grid>
                                  )}
                                  icon={RelevantIcon && <RelevantIcon fontSize='small' />}
                                />
                              </Grid>
                            )
                          }

                          /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
                          /* @ts-ignore */
                          if (['remodel', 'view'].includes(key) && search[key].importance > 0) {
                            const label = key === 'remodel' ? REMODEL_LABEL : VIEW_LABEL

                            return (
                              <Grid item key={key} container>
                                <AssetDetailRawField
                                  label={t(key)}
                                  value={(
                                    <Grid container direction='row' spacing={1} wrap='nowrap'>
                                      <Grid item>
                                        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                                        {/* @ts-ignore */}
                                        <Typography size='small'>{t(label[search[key].value])}</Typography>
                                      </Grid>
                                      <Grid item container>
                                        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                                        {/* @ts-ignore */}
                                        <Typography size='small'>{IMPORTANCE_TEXT[search[key].importance || 0]}</Typography>
                                      </Grid>
                                    </Grid>
                                  )}
                                  icon={RelevantIcon && <RelevantIcon fontSize='small' />}
                                />
                              </Grid>
                            )
                          }

                          return (
                            <Grid item key={key} container>
                              {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                              {/* @ts-ignore */}
                              <AssetBooleanDetailRawField label={t(key)} textVal={(IMPORTANCE_TEXT[search[key].importance])} icon={RelevantIcon && <RelevantIcon fontSize='small' />} />
                            </Grid>
                          )
                        })
                      }
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item container direction='column' spacing={0} lg={9} alignItems='stretch'>
                <Grid item wrap='nowrap'>
                  <AssetDetailRawField label={t('entranceDate')} value={<Typography>{moment(new Date(search.entranceDate)).format('DD/MM/YY')}</Typography>} icon={<CalendarTodayIcon fontSize='small' />} />
                </Grid>
                <Grid item>
                <AssetBooleanDetailRawField label='גמיש' value={search.entranceDateFlexible} />
                </Grid>
              </Grid>
            </Grid>
          </Modal.Content>
        )
      }
      {
        !!(leadDetails && !loading) && (
          <Modal.Actions>
            <CardActions style={{ width: '100%', justifyContent: 'flex-end' }}>
              <SendMessage
                leadId={leadDetails.leadId}
                leadName={leadDetails.leadName}
                sendCount={leadDetails.sendCount}
                textDirection='row'
                // eslint-disable-next-line react/jsx-handler-names
                onSendMessage={leadDetails.onSendMessage}
              />
              {/* <Box width='max-content' onClick={() => onContactSearcher()} style={{ cursor: 'pointer' }}>
                <Grid container spacing={1}>
                  <Grid item style={{ display: 'inline-flex' }}>
                    <Send color='#4BB5AF' />
                  </Grid>
                  <Grid item alignItems='center' style={{ display: 'inline-flex' }}>
                    <Typography color='textPrimary' style={{ fontSize: '12px' }}>
                    שלח מודעה
                    </Typography>
                  </Grid>
                </Grid>
              </Box> */}
            </CardActions>
          </Modal.Actions>
        )
      }
    </Modal>
  )
}

export default SearchDetails
