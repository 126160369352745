import { createStyles } from '@material-ui/core/styles'

import palette from '~/ThemeProvider/config/palette'

export const MenuItem = createStyles({
  root: {
    boxSizing: 'border-box',
    lineHeight: '1rem',
    padding: 0,
    paddingTop: 0,
    paddingBottom: 0,
    fontSize: '1rem',
    minHeight: 'unset',
    whiteSpace: 'normal'
  },
  gutters: {
    padding: '0.625rem',
    paddingLeft: '0.625rem',
    paddingRight: '0.625rem'
  },
  '&$selected': {
    color: palette.primary.main,
    backgroundColor: palette.primary.light
  }
})
