import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ListSubheader from '@material-ui/core/ListSubheader'
import { Grid } from '@material-ui/core'

import Button from '~/components/core/Button'
import Typography from '~/components/core/Typography'
import SellerLeadsHeaderDetails from '~/components/modules/SellerLeadsHeaderDetails'
import UserAssetsList from '~/components/modules/UserAssetsList'
import SendMessageToAll from '~/components/pages/AssetLeads/components/SendMessageToAll'
import { MinimalAsset } from '~/types/Asset'
// import useResetNewAssetLeads from '~/hooks/useResetNewAssetLeads'
import * as S from './styles'

const useStyles = makeStyles(S.SearchResultsHeaderStyled)

export interface Props {
  name: string
  assets?: MinimalAsset[]
  openMenu: boolean
  onMenuToggle: () => void
  leadsCount?: number
  onSendToAllMessage?: () => void
}

const SellerLeadsHeader: React.FC<Props> = ({
  name, assets, onMenuToggle, openMenu, leadsCount, onSendToAllMessage
}) => {
  // const { id } = useParams<{ id?: string }>()
  const wrapper = React.useRef<HTMLDivElement>(null)
  // const { t } = useTranslation()
  // const [resetLeads] = useResetNewAssetLeads()
  const classes = useStyles()

  const handleClickList = () => {
    onMenuToggle()
  }

  return (
    <ListSubheader className={classes.sticky}>
      <Grid ref={wrapper} component='div' container wrap='nowrap' justify='center' alignItems='center' className={classes.root}>
        <Grid item>
          <Button size='medium' iconButton={<ExpandMoreIcon />} onClick={onMenuToggle} />
        </Grid>
        <Grid item container>
          <Typography color='textPrimary'>{name}</Typography>
        </Grid>
        <Grid item style={{ marginRight: 'auto', paddingLeft: '16px' }}>
          <SendMessageToAll leadsCount={leadsCount} onSendToAllMessage={onSendToAllMessage} />
        </Grid>
      </Grid>
      <SellerLeadsHeaderDetails anchorRef={wrapper} open={openMenu} onClose={onMenuToggle}>
        <UserAssetsList assets={assets} onClick={handleClickList} />
      </SellerLeadsHeaderDetails>
    </ListSubheader>
  )
}

export default SellerLeadsHeader
