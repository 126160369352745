import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import cx from 'classnames'

import styles from './styles'

export interface Props {
  active?: boolean
  children: React.ReactElement | React.ReactNode
  layout?: React.ReactElement | string
  invisible?: boolean
  onClick?: () => void
}

const useStyles = makeStyles(styles)

const DisableContainerMask: React.FC<Props> = (props) => {
  const { active, children, layout, onClick } = props
  const classes = useStyles(props)
  const classNameRootstyles = cx(classes.root, {
    [classes.clickable]: !!onClick
  })

  const classNamestyles = cx({
    [classes.actions]: active
  })

  const LayoutElm = (active && layout) ? <div className={classes.layout}>{layout}</div> : null

  return (
    <div className={classNameRootstyles} onClick={active ? onClick : undefined}>
      {LayoutElm}
      <div className={classNamestyles}>
        {children}
      </div>
    </div>
  )
}

export default DisableContainerMask
