import React, { ReactNode } from 'react'
import ListItem, { ListItemProps } from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'

import Badge from '~/components/core/Badge'

export interface Props extends ListItemProps {
  text: React.ReactNode,
  secondaryText?: React.ReactNode,
  key?: string | number
  icon?: ReactNode
  onClick?: () => void
  badge?: ReactNode
}

const SidebarItem: React.FC<Props> = ({ text, key, icon, onClick = () => {}, selected, secondaryText, badge }) => {
  return (
    <ListItem key={key} onClick={onClick} selected={selected} button>
      {icon && <ListItemIcon>{icon}</ListItemIcon>}
      <ListItemText
        style={{
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          width: 110,
          whiteSpace: 'nowrap'
        }}
        primary={text}
      />
      {secondaryText && <ListItemText primary={secondaryText} />}
      {badge && <Badge badgeContent={badge} color='secondary' />}
    </ListItem>
  )
}

export default SidebarItem
