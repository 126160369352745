import React, { SVGProps } from 'react'
const StepBell = (props: SVGProps<SVGSVGElement>): JSX.Element => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={120}
    height={120}
    fill='none'
    {...props}
  >
    <circle cx={60} cy={60} r={60} fill='url(#a)' />
    <g clipPath='url(#b)'>
      <path
        fill='#fff'
        d='M60.857 100.142c4.322 0 7.858-3.535 7.858-7.857H53c0 4.322 3.536 7.857 7.857 7.857ZM84.43 76.571V56.928c0-12.06-6.404-22.157-17.679-24.829v-2.671a5.885 5.885 0 0 0-5.893-5.893 5.885 5.885 0 0 0-5.892 5.893v2.671c-11.236 2.672-17.68 12.729-17.68 24.829v19.643l-7.856 7.857v3.929h62.857v-3.929l-7.857-7.857Zm-7.857 3.928H45.143V56.928c0-9.743 5.932-17.679 15.714-17.679s15.715 7.936 15.715 17.679v23.571ZM43.493 29.742l-5.618-5.618c-9.428 7.19-15.636 18.268-16.186 30.84h7.858c.589-10.411 5.932-19.525 13.946-25.222Zm48.675 25.222h7.857c-.59-12.572-6.796-23.65-16.186-30.84l-5.578 5.618c7.936 5.697 13.318 14.811 13.907 25.222Z'
      />
    </g>
    <defs>
      <linearGradient
        id='a'
        x1={60}
        x2={60}
        y1={0}
        y2={120}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#006064' />
        <stop offset={1} stopColor='#006064' stopOpacity={0.89} />
      </linearGradient>
      <clipPath id='b'>
        <path fill='#fff' d='M13.715 13.715H108V108H13.715z' />
      </clipPath>
    </defs>
  </svg>
)

export default StepBell
