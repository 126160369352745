import { Theme, createStyles } from '@material-ui/core/styles'

import palette from '~/ThemeProvider/config/palette'
import { screens } from '~/utils/breakpoints'

export const headerHeight = { default: '4.5rem', smallAndMedium: '3rem' }

export default ({ layout }: Theme) =>
  createStyles({
    light: {
      backgroundColor: palette.common.white
    },
    dark: {
      backgroundColor: palette.primary.darker
    },
    content: {
      boxSizing: 'border-box',
      display: 'flex',
      alignItems: 'center',
      margin: '0 auto',
      justifyContent: 'space-between',
      maxWidth: layout.contentWidthWide,
      padding: `0 ${layout.contentPaddingHorizontal}`,
      height: headerHeight.default,
      [screens('small', 'medium')]: {
        height: headerHeight.smallAndMedium
      }
    },
    wrapper: {
      height: headerHeight.default,
      [screens('small', 'medium')]: {
        height: headerHeight.smallAndMedium
      }
    },
    wide: {
      maxWidth: layout.contentWidth
    },
    fullWidth: {
      maxWidth: '100%'
    },
    left: {
      display: 'flex',
      alignItems: 'center'
    },
    center: {
      display: 'flex',
      alignItems: 'center',
      alignSelf: 'center'
    },
    right: {
      display: 'flex',
      alignItems: 'center'
    },
    divider: {
      width: '1px',
      height: '1.75em',
      backgroundColor: palette.common.white,
      opacity: 0.8
    },
    logoContainer: {
      [screens('small', 'medium')]: {
        lineHeight: '1em',
        position: 'absolute',
        left: '50%',
        transform: 'translate(-50%, 0%)'
      }
    },
    logo: {
      [screens('small', 'medium')]: {
        fontSize: '1.5em',
        padding: 0,
        width: 'fit-content'
      },
      cursor: 'pointer',
      width: '180px',
      height: '100%'
    }
  })
