import React from 'react'
import { Grid, Container } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import useFetch, { CachePolicies } from 'use-http'
import ThumbUpIcon from '@material-ui/icons/ThumbUp'

import ResultsMessage from '~/components/core/ResultsMessage'
import Button from '~/components/core/Button'
import Link from '~/components/core/Link'
import ContactUsForm, { InitProps } from '~/components/modules/ContactUsForm'
import Typography from '~/components/core/Typography'

interface Props {
  modal?: boolean
}

const ContactUs: React.FC<Props> = () => {
  const { post, loading, response } = useFetch('/api/feedbacks/contactUs', { cachePolicy: CachePolicies.NO_CACHE })
  const { t } = useTranslation()

  const handleOnSubmit = (values: InitProps | undefined) => {
    return post({
      email: values?.email, name: values?.fullName, userComment: values?.textComment
    })
  }

  if (response.ok && response.status === 200) {
    return (
      <Container>
        <Grid container direction='column' alignItems='center' spacing={2} style={{ marginTop: 80 }}>
          <Grid item>
            <ResultsMessage
              title={t('contactUsDone')}
              icon={<ThumbUpIcon color='secondary' fontSize='large' style={{ height: 150, width: 'auto', marginTop: 40 }} />}
              actionButton={(
                <Link color='inherit' to='' style={{ textDecoration: 'none' }}>
                  <Button variant='text' color='primary'>
                    <Typography size='large' color='textPrimary' noWrap>{t('labelBack')}</Typography>
                  </Button>
                </Link>
              )}
            />
          </Grid>
        </Grid>
      </Container>
    )
  }

  return (
    <Container style={{ paddingBottom: '20px' }}>
    <Grid container direction='column' alignItems='center' spacing={2} style={{ marginTop: 80 }}>
      <Grid item>
        <Typography weight='bold' style={{ fontSize: '1.8rem' }}>
          {t('menu-contactus')}
        </Typography>
      </Grid>
      <Grid item>
        <ContactUsForm onSubmit={handleOnSubmit} loading={loading} />
      </Grid>
    </Grid>
    </Container>
  )
}

export default ContactUs
