import { LocationData } from '~/components/core/Inputs/LocationInput'

export type Importance = 0 | 1 | 2 | 3

export type AdvangedSearchItem = {
  value: number
  importance: Importance | undefined | null
}
export type RangeType = {
  min: number | undefined,
  max: number | undefined
}

export enum DealReadiness {
  JustLooking = 0,
  NotSure = 1,
  Ready = 2,
}

export type CommunicationReqPayload = ('private' | 'agent')[]

export type CommunicationOptItem = {value: number, reqValue: CommunicationReqPayload}

export type Position = {
  type: string
  coordinates: number[]
}

export type AdvangedSearch = {
  balcony: AdvangedSearchItem
  elevator: AdvangedSearchItem
  floor: AdvangedSearchItem
  furnished: AdvangedSearchItem
  garden: AdvangedSearchItem
  hadicap: AdvangedSearchItem
  panthouse: AdvangedSearchItem
  parking: AdvangedSearchItem
  remodel: AdvangedSearchItem
  safeRoom: AdvangedSearchItem
  storage: AdvangedSearchItem
  view: AdvangedSearchItem
}

export type RangeSearch = {
  budget: RangeType
  rooms: RangeType
  squareSize: RangeType
}

export type Search = {
  area: Importance[]
  bounds: any[]
  city: string
  communication: string[]
  contactNumber: string
  'date_created'?: string
  dateCreated: string
  description: string
  entranceDate: string
  entranceDateFlexible: boolean
  forSale: boolean
  leadsArr?: string[]
  locations: string[]
  neighborhood: string[]
  newUpdatesLeads: string[]
  neighborhoodBounds?: any[]
  propertiesArr: []
  shortComment?: string
  totalScore: number
  updatedAt: string
  tenants?: string
  userId: string
  _id?: string
  id: string
  dealReadiness?: DealReadiness
} & AdvangedSearch & RangeSearch

export type MinimalSearch = {
  city: string
  forSale: boolean
  numberOfNewLeads: number
  id: string
  communication: CommunicationReqPayload | undefined,
  contactNumber: string
}

export type OwnerDetails = {
  email: string
  name: string
  phoneNumber?: string
}

export type SearchLead = {
  address: string
  city: string
  deviation: number
  isNew: boolean
  images: string[]
  leadId: string
  percentage: number
  position: Position
  price: number
  rooms: number
  squareSize: number
  searchId?: string
  ownerDetails: OwnerDetails
}

export type SearchFormData = {
  searchType: 'rent' | 'sell' | undefined | number,
  city: LocationData | undefined,
  neighborhoods: LocationData[] | undefined,
  communications: number | undefined,
  tenants?: string
  allowAgent?: boolean
  contactNumber: string
  entranceDate?: Date
  entranceDateFlexible?: boolean
  dealReadiness?: DealReadiness
} & RangeSearch & AdvangedSearch

export type RangeTypeValidation = {
  min?: string,
  max?: string
}

export type SearchFormValidation = {
  searchType?: string | undefined | number,
  city?: string,
  communications?: number | undefined,
  contactNumber?: string
  budget?: string | RangeTypeValidation
  rooms?: string | RangeTypeValidation
  squareSize?: string | RangeTypeValidation
}

export type SearchPayload = {
  forSale: boolean,
  city: string,
  cityMetaData: LocationData
  neighborhood: string[]
  tenants?: string
  neighborhoodsMetaData: LocationData[]
  bounds?: LocationData['bounds'] // TODO: check with Asaf if needed
  contactNumber?: string
  communication?: CommunicationReqPayload,
  entranceDate?: Date
  entranceDateFlexible?: boolean
  dealReadiness?: DealReadiness
} & AdvangedSearch & RangeSearch

export type NewLeads = {
  id: string
  numberOfNewLeads: number
}
