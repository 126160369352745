import { useState, useEffect } from 'react'

import { MinimalSearch, NewLeads } from '~/types/Search'

export default function useNewSearchLeadsCache(searches: MinimalSearch[] | undefined): [(NewLeads[] | null), (searchId: string) => void] {
  const [newLeads, setNewLeads] = useState<NewLeads[] | null>(null)

  useEffect(() => {
    if (searches && !newLeads) {
      const newLeadObj = searches.map(({ id, numberOfNewLeads }) => ({ id, numberOfNewLeads })).filter(({ numberOfNewLeads }) => numberOfNewLeads)

      setNewLeads(newLeadObj)
    }
  }, [searches])

  const resetSearchNewLeads = (searchId: string) => {
    if (!searchId) {
      return
    }

    const newLeadObj = (newLeads || []).filter(({ id }) => id !== searchId)

    setNewLeads(newLeadObj)
  }

  return [newLeads, resetSearchNewLeads]
}
