import React from 'react'
import {
  FieldProps as FinalFieldProps,
  FieldRenderProps
} from 'react-final-form'

import BaseToggleButton, { Props as ToggleButtonProps } from '~/components/core/Inputs/ToggleButton/Item'

export type FieldProps<TInputValue> = FinalFieldProps<
  TInputValue,
  FieldRenderProps<TInputValue, HTMLInputElement>,
  HTMLInputElement
>

export type Props = ToggleButtonProps & {
  name?: string
}

export const ToggleButton: React.FC<Props> = (props) => {
  return (
    <BaseToggleButton {...props} />
  )
}

export default ToggleButton
