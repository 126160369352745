import React from 'react'
import OriginImageGallery from 'react-image-gallery'
import ArrowBackIosRoundedIcon from '@material-ui/icons/ArrowBackIosRounded'
import ArrowForwardIosRoundedIcon from '@material-ui/icons/ArrowForwardIosRounded'
import IconButton from '@material-ui/core/IconButton'
import Box from '@material-ui/core/Box'
// import HighlightOffIcon from '@material-ui/icons/HighlightOff'
import CloseIcon from '@material-ui/icons/Close'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'

import './styles'
import Typography from '~/components/core/Typography'
import { useBreakpoint } from '~/utils/breakpoints'
import { getCdnEndpoint } from '~/components/utils/contacts';

export interface Props {
  images: string[]
  isShowBullets?: boolean
  isShowNav?: boolean
  allowFullScreen?: boolean
  showThumbnails?: boolean
  customClass?: string
  onToggleFullScreen?: (isFullScreen: boolean) => void
}

// eslint-disable-next-line complexity
const ImageGallery: React.FC<Props> = ({
  images, isShowBullets, isShowNav, allowFullScreen, showThumbnails = false, customClass, onToggleFullScreen
}) => {
  const thumbClicked = React.useRef(false)
  const [isFullScreen, setIsFullScreen] = React.useState(false)
  const _imageGallery = React.useRef<OriginImageGallery>(null)
  const isCompactLayout = useBreakpoint(['small', 'medium'])

  // const handleCloseEvent = () => {
  //   setTimeout(() => {
  //       if (isFullScreen) {
  //           if (!thumbClicked.current) {
  //               _imageGallery?.current?.exitFullScreen()
  //               setIsFullScreen(false)
  //           }
  //       }
  //       if (thumbClicked.current) {
  //           thumbClicked.current = false
  //       }
  //   }, 150)
  // }

  React.useEffect(() => {
    onToggleFullScreen?.(isFullScreen)
    if (isFullScreen) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      document.querySelector('body')?.style?.overflowY = 'hidden'
    } else {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      document.querySelector('body')?.style?.overflowY = 'auto'
    }

    return () => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      document.querySelector('body')?.style?.overflowY = 'auto'
    }
  }, [isFullScreen, onToggleFullScreen])

  React.useEffect(() => {
    const handleEsc = (event: KeyboardEvent) => {
      if (event.key === '27') {
        setIsFullScreen(false)
      }
    }

    // const handleFullScreenClick = (e: any) => {
    //   e.stopPropagation()
    //   thumbClicked.current = true
    // }

    if (isFullScreen) {
      window.addEventListener('keydown', handleEsc)
      // document.addEventListener('mousedown', handleCloseEvent)
      // document.addEventListener('touchstart', handleCloseEvent)
      // document.addEventListener('touchmove', handleFullScreenClick)
      // document.getElementsByClassName('image-gallery-slides')[0]?.addEventListener('mousedown', handleFullScreenClick)
    }

    return () => {
      window.removeEventListener('keydown', handleEsc)
      // document.removeEventListener('mousedown', handleCloseEvent)
      // document.removeEventListener('touchstart', handleCloseEvent)
      // document.removeEventListener('touchmove', handleFullScreenClick)
      // document.getElementsByClassName('image-gallery-slides')[0]?.removeEventListener('mousedown', handleFullScreenClick)
    }
  }, [isFullScreen])

  const imgsArrFix = images.map((img) => {
    const fixHttpsStr = (img.includes('https')) ? img : `https${img.split('http')[1]}`

    return ({
      original: fixHttpsStr,
      thumbnail: fixHttpsStr
    })
  })
  const renderLeftNav: React.FC<any> = (onClick) => {
    return (
      <ArrowBackIosRoundedIcon
        fontSize='large'
        htmlColor='white'
        className='image-gallery-custom-left-nav'
        onClick={(e) => {
          e.stopPropagation()
          thumbClicked.current = true

          return onClick(e.stopPropagation())
        }}
      />
    )
  }
  const renderRightNav: React.FC<any> = (onClick) => {
    return (
      <ArrowForwardIosRoundedIcon
        fontSize='large'
        htmlColor='white'
        className='image-gallery-custom-right-nav'
        onClick={(e) => {
          e.stopPropagation()
          thumbClicked.current = true

          return onClick(e.stopPropagation())
        }}
      />
    )
  }

  const hanleCloseGallery = () => {
    _imageGallery?.current?.exitFullScreen()
    setIsFullScreen(false)
  }

  const handleGalleryClick = () => {
    if (!isFullScreen) {
      _imageGallery?.current?.fullScreen()
      setIsFullScreen(true)
    }
  }

  if (!images.length) {
    return (
      <img src={getCdnEndpoint('no_image.png')} alt='no_image' style={{ width: '100%', height: '200px', borderRadius: '12px' }} />
    )
  }

  return (
    <div>
      {isFullScreen
        ? (<Box position='fixed' top={0} right={0} zIndex={8001} width='100%' bgcolor='rgb(0, 0, 0)'>
            <Box display='flex' paddingX={isCompactLayout ? 2 : 5} justifyContent='flex-end' alignItems='center'>
              {
                !isCompactLayout
                ? (
                  <Typography size='large' style={{ color: 'white' }}>סגור</Typography>
                )
                : <Typography style={{ color: 'white' }}>חזור</Typography>
              }
              <IconButton
                style={isCompactLayout ? {} : { fontSize: '3rem' }}
                onClick={hanleCloseGallery}>
                  {
                    isCompactLayout
                    ? (
                      <ArrowBackIosIcon htmlColor='white' />
                    )
                    : (
                      <CloseIcon
                        fontSize='inherit'
                        htmlColor='white'
                        // style={{
                        //   position: '', top: '0', right: '5px', zIndex: 8001
                        // }}
                      />
                    )
                  }
                </IconButton>
            </Box>
          </Box>)
         : null}
      <OriginImageGallery
        // className='image-gallery'
        ref={_imageGallery}
        showThumbnails={showThumbnails}
        additionalClass={customClass}
        thumbnailPosition={showThumbnails ? 'right' : undefined}
        showBullets={isShowBullets}
        onThumbnailClick={() => {
          if (isFullScreen) {
              thumbClicked.current = true
          }
        }}
        showPlayButton={false}
        stopPropagation
        showNav={isShowNav}
        showFullscreenButton={false}
        useBrowserFullscreen={false}
        onClick={allowFullScreen ? handleGalleryClick : undefined}
        renderLeftNav={renderLeftNav}
        renderRightNav={renderRightNav}
        items={imgsArrFix}
      />
    </div>
  )
}

export default ImageGallery
