declare module '@material-ui/core/styles/createPalette' {
  interface SimplePaletteColorOptions {
    lighter?: string
    light?: string
    main: string
    dark?: string
    darker?: string
  }

  interface Palette {
    blue: SimplePaletteColorOptions
    green: SimplePaletteColorOptions
    red: SimplePaletteColorOptions
    pink: SimplePaletteColorOptions
  }
}

declare module '@material-ui/core' {
  interface Color {
    lighter?: string
    light?: string
    main?: string
    main2?: string
    dark?: string
    darker?: string
  }
}

export const colors = {
  grey: {
    lightest: '#f1f1f1',
    lighter: '#d9d9d9',
    light: '#f6f6f6',
    main: '#adadad',
    dark: '#7a7a7a',
    darker: '#525252'
  },
  blue: {
    light: '#74c6be',
    main: '#4BB5AF',
    dark: '#006064'
  },
  pink: {
    light: '#FFABD3',
    main: '#E087B1',
    dark: '#F65C8C'
  },
  red: {
    light: '#ff7070',
    main: '#FF8A80',
    dark: '#E23D2F'
  },
  green: {
    lighter: '#a5c9a1',
    main: '#1fa910'
  },
  common: {
    black: '#000',
    white: '#fff'
  }
}

export const palette = {
  // MUI adds additional colors, like `contrastText` to the
  // palette. So to prevent changing colors object we should
  // deep copy it.
  ...JSON.parse(JSON.stringify(colors)),
  primary: JSON.parse(JSON.stringify(colors.blue)),
  secondary: JSON.parse(JSON.stringify(colors.pink)),
  error: JSON.parse(JSON.stringify(colors.red)),
  grey: {
    100: colors.grey.lighter,
    200: colors.grey.light,
    300: colors.grey.main,
    400: colors.grey.dark,
    500: colors.grey.darker,
    ...colors.grey
  },
  text: {
    primary: colors.common.black,
    secondary: colors.grey.main
  },
  background: {
    default: colors.common.white
  }
}

export default palette
