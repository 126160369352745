import React, { useEffect, useRef } from 'react'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { useTranslation } from 'react-i18next'
import Grid from '@material-ui/core/Grid'
import parse from 'autosuggest-highlight/parse'
import { useDebounce } from 'use-debounce'

import useQueryLocation from '~/hooks/useQueryLocation'
import useGeoPosition, { LatLngItem, Bounds } from '~/hooks/useGeoPosition'
import TextInput, { Props as TextInputProps } from '../TextInput'

export type LocationData = { latLng?: LatLngItem, name: string | null, bounds?: Bounds, formattedAddress?: string, placeId?: string }

export interface Props {
  onSelect?: (data: LocationData) => void
  citiesOnly?: boolean
  location?: Bounds
  label?: React.ReactNode
  value?: string | null
  name?: string | undefined
  textInputProps?: TextInputProps
  filterType?: string
  includesIn?: string
  isDisabled?: boolean
  error?: boolean
  searchTypes?: string[]
  noOptionsText?: string
  allowFreeText?: boolean
}

const LocationInput = React.forwardRef<HTMLDivElement, Props>(function LocationInput({
  onSelect = () => {},
  citiesOnly,
  location,
  label = '',
  value,
  name,
  textInputProps = {},
  filterType,
  includesIn,
  isDisabled,
  error,
  searchTypes = [],
  noOptionsText,
  allowFreeText
}, ref) {
  const [currentValue, setValue] = React.useState<string | null>(value || null)
  const { t } = useTranslation()
  const [inputValue, setInputValue] = React.useState('')
  const [searchInput] = useDebounce(inputValue, 500)
  const requestOptions = {
    types: citiesOnly ? ['(cities)', ...searchTypes] : [...searchTypes],
    ...((location) ? { location } : {})
  }
  const [options, loading] = useQueryLocation(currentValue, searchInput, requestOptions, filterType, includesIn)
  const [performGeo] = useGeoPosition()
  const isFreeText = useRef(false)

  useEffect(() => {
    if (!value) {
      setValue('')
    }
  }, [value])

  const handleSelectedOption = async (selectedOption: any) => {
    const placeId = selectedOption?.place_id
    const { latLng, bounds, formattedAddress } = await performGeo({ placeId })

    onSelect({ latLng, bounds, name: selectedOption?.description || null, formattedAddress, placeId })
    isFreeText.current = false
  }

  // eslint-disable-next-line complexity
  const handleOnBlur = async (e: any) => {
    const value = e.target.value

    if (options.length === 1 && value === options[0].structured_formatting?.main_text) {
      setValue(value?.split(', ישראל')[0])
      handleSelectedOption(options[0])
    } else if (allowFreeText && value && (!currentValue || isFreeText.current)) {
      setValue(value)
      onSelect({ name: value })
      isFreeText.current = true
    }
  }

  return (
    <Autocomplete
      loading={loading}
      forcePopupIcon={false}
      openOnFocus={false}
      ref={ref}
      noOptionsText={noOptionsText || t('noOptions')}
      loadingText={`${t('loading')}...`}
      disabled={isDisabled}
      onBlur={handleOnBlur}
      getOptionLabel={(option: any) => (typeof option === 'string' ? option : option.description)}
      filterOptions={(opt) => opt}
      options={options}
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={currentValue}
      onChange={(_e, newValue: any | null) => {
        setValue(newValue?.description?.split(', ישראל')[0] || null)
        handleSelectedOption(newValue)
      }}
      onInputChange={(_e, newInputValue) => {
        setInputValue(newInputValue)
      }}
      renderInput={(params) => {
          const inputParams = params.inputProps as any

          return (
            <TextInput onMouseDownCapture={inputParams?.value ? undefined : (e) => e.stopPropagation()} name={name} ref={params.InputProps.ref} label={label} type='text' {...textInputProps} {...params} error={!!error} />
          )
      }}
      renderOption={(option: any) => {
        if (typeof option === 'string') {
          return option
        }

        const matches = option.matched_substrings
        const parts = parse(
          option.description.split(', ישראל')[0],
          matches.map((match: any) => [match.offset, match.offset + match.length])
        )

        return (
          <Grid container alignItems='center'>
            <Grid
              item
              xs
              style={{
                borderBottom: '1px solid #DFDFDF', fontSize: '18px'
              }}
            >
              {parts.map((part, index) => (
                <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                  {part.text}
                </span>
              ))}
            </Grid>
          </Grid>
        )
      }}
    />
  )
})

export default LocationInput
