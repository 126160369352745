import React, { useState, useCallback } from 'react'
import { Box, CardActions } from '@material-ui/core'

import ResultsMessage from '~/components/core/ResultsMessage'
import { ViewerProvider } from '~/components/core/ViewerProvider/context'
import Modal from '~/components/core/Modal'
import Button from '~/components/core/Button'
import Typography from '~/components/core/Typography'
import {
  UsersConnect,
  StepBell,
  StepCheck,
  CellCheck
} from '~/components/core/Icons'

export interface Props {
  onSubmit: (phone?: number | string) => void
  mainText?: string
  onClose: () => void
  isOpen: boolean
}

interface StepItem {
  text: string
  icon: React.ReactNode
}

const STEPS: StepItem[] = [{
  text: 'מייצרים פרופיל חיפוש שבנוי מדרישות החיפוש שלכם ועבור מי הדירה והטכנולוגיה שלנו מייצרת התאמות (match) עם בעלי דירות',
  icon: <UsersConnect />
}, {
  text: 'ההתאמות מוצגות קודם לבעלי הדירות המתאימים והם שולחים אליכם את המודעה שלהם ככה אתם מקבלים רק דירות אמיתיות ורלוונטיות',
  icon: <CellCheck />
}, {
  text: 'כשבעל דירה שולח אליכם מודעה תקבלו מאיתנו התראה שנמצאה התאמה חדשה אנחנו לא חושפים את פרטי התקשורת שלכם לבעלי הדירות.',
  icon: <StepBell />
}, {
  text: 'אתם צופים בפרטי הדירה שבעל הדירה שלח אליכם ומחליטים אם ליצור קשר ולהתקדם',
  icon: <StepCheck />
}]

const GuideSearcherModal = (): JSX.Element => {
  const [open, setOpen] = useState(true)
  const [step, setStep] = useState(0)
  const { searches } = React.useContext(ViewerProvider)

  const handleClose = useCallback(() => {
    setOpen(false)
  }, [])

  const handleStep = useCallback(() => {
    if (step === 3) {
      return handleClose()
    }

    setStep(step + 1)
  }, [step, handleClose])

  if (searches?.length) {
    return <></>
  }

  return (
    <Modal open={open}>
      <Modal.Title>
      <Typography size='xlarge'>
      לפני שמתחילים, איך זה עובד?
      </Typography>
      </Modal.Title>
      <Modal.Content dividers>
        <Box>
          <ResultsMessage
            title={(
              <Typography>
                {STEPS[step].text}
              </Typography>
            )}
            icon={STEPS[step].icon}
          />
        </Box>
      </Modal.Content>
        <Modal.Actions>
          <CardActions style={{ width: '100%', justifyContent: 'flex-end', display: 'flex' }}>
            <Button color='primary' variant='text' onClick={handleStep}>
              <Typography weight='bold' size='xlarge'>
                {step === 3 ? 'סגור' : 'הבא'}
              </Typography>
            </Button>
          </CardActions>
        </Modal.Actions>
    </Modal>
  )
}

export default GuideSearcherModal
