import React from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import ContactMailIcon from '@material-ui/icons/ContactMail'
import InfoIcon from '@material-ui/icons/Info'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import PolicyIcon from '@material-ui/icons/Policy'

import { AuthProvider } from '~/components/core/AuthProvider/context'
import Sidebar from '~/components/core/Sidebar'
import Typography from '~/components/core/Typography'

const ViewerSidebarCommon: React.FC = () => {
  const { t } = useTranslation()
  const { signOut } = React.useContext(AuthProvider)

  return (
    <>
      <Link to='/contact-us' style={{ textDecoration: 'none', width: '100%' }}>
        <Sidebar.Item icon={<ContactMailIcon />} text={<Typography size='large' color='textPrimary'>{t('menu-contactus')}</Typography>} />
      </Link>
      <Link to='/policy' style={{ textDecoration: 'none', width: '100%' }}>
        <Sidebar.Item icon={<PolicyIcon />} text={<Typography size='large' color='textPrimary'>{t('link-policy')}</Typography>} />
      </Link>
      <Link to='/about' style={{ textDecoration: 'none', width: '100%' }}>
        <Sidebar.Item icon={<InfoIcon />} text={<Typography size='large' color='textPrimary'>{t('link-about')}</Typography>} />
      </Link>
      {
        !!(signOut) && (
          <Sidebar.Item onClick={signOut} icon={<ExitToAppIcon />} text={<Typography size='large' color='textPrimary'>{t('menu-signout')}</Typography>} />
        )
      }
    </>
  )
}

export default ViewerSidebarCommon
