import useFetch, { CachePolicies } from 'use-http'

interface UseSendLeadMessage {
  leadId: string
  assetId: string
}

export const useSendLeadMessage = ({
  leadId,
  assetId
}: UseSendLeadMessage): {
  execute: () => Promise<any>
  loading: boolean
  error: any
  response: any
} => {
  const { put, response, loading, error } = useFetch(
    `api/v3/assets/asset/${assetId}/contact_searcher/${leadId}`,
    {
      cachePolicy: CachePolicies.NO_CACHE
    }
  )

  const execute = async () => {
    return put()
  }

  return {
    execute,
    loading,
    error,
    response
  }
}
