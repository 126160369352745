import { BudgetRange } from '~/types/Asset'

const MAX_DEVIASION = 0.1
const NO_DEVIASION = 1

const isPriceInRange = (currentPrice: number, bugetItem: BudgetRange, deviasion: number): boolean => {
  return ((bugetItem.max * deviasion) >= currentPrice) && ((bugetItem.min / deviasion) <= currentPrice)
}

const isPriceWithDeviationInRange = (currentPrice: number, bugetItem: BudgetRange, deviasion: number): boolean => {
  const deviasionPrice = currentPrice * deviasion

  return ((bugetItem.max + deviasionPrice) >= currentPrice) && ((bugetItem.min - deviasionPrice) <= currentPrice)
}

export type CurrentLeadsCount = {
  deviasionCount: number
  leadsCount: number
}

export const getCurrentLeadsCount = (currentPrice: number, budgetsList: BudgetRange[]): CurrentLeadsCount => {
  const filteredLeadsWithDeviasion = budgetsList.filter((lead) => {
    if (!lead?.max) {
      return false
    }

    return isPriceWithDeviationInRange(currentPrice, lead, MAX_DEVIASION)
  })

  const filteredLeadsWithoutDeviasion = filteredLeadsWithDeviasion.filter((lead) => {
    if (!lead?.max) {
      return false
    }

    return isPriceInRange(currentPrice, lead, NO_DEVIASION)
  })

  return {
      deviasionCount: filteredLeadsWithDeviasion.length - filteredLeadsWithoutDeviasion.length,
      leadsCount: filteredLeadsWithoutDeviasion.length
    }
}
