import React, { useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import AddIcon from '@material-ui/icons/Add'
import { List, Box } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone'

import Button from '~/components/core/Button'
import Link from '~/components/core/Link'
import Typography from '~/components/core/Typography'
import DeleteWarningModal from '~/components/modules/DeleteWarningModal'
import Pagination from '~/components/core/Pagination'
import { PAGE_SIZE } from '~/components/pages/SearchResults'
import ResultsMessage from '~/components/core/ResultsMessage'
import { MapContext } from '~/components/core/MapProvider/context'
import useSelectedSearch from '~/hooks/useSelectedSearch'
import { useBreakpoint } from '~/utils/breakpoints'
import { useModal } from '~/utils/useModal'
import AssetCard, { Props as AssetCardProps } from '~/components/modules/AssetCard'
import Loader from './Loader'
import * as S from './styles'

const useStyles = makeStyles(S.LeadListtyled)

export interface Props {
  items: AssetCardProps[]
  disabled?: boolean
  loading?: boolean
  totalCount: number
  onRemoveLead: (leadId: string) => void
  onItemClick: (leadId: string) => void
}

let leadIdToRemove: string | undefined | null = null

const AssetsResultsList: React.FC<Props> = ({
  items, loading, onRemoveLead, onItemClick, totalCount
}) => {
  const { id, page } = useParams<{ id?: string, page?: string }>()
  const history = useHistory()
  const classes = useStyles()
  const { t } = useTranslation()
  const { setLeadIdHover = () => {}, setMap = () => {} } = React.useContext(MapContext)
  const isCompactLayout = useBreakpoint(['small', 'medium'])
  const [, selectedSearchNewLeads] = useSelectedSearch()
  const { showModal, hideModal, isOpen } = useModal()

  useEffect(() => {
    setMap(true)

    return () => setMap(false)
  }, [])

  const handleOnMouseEnter = (leadid?: string | undefined) => {
    setLeadIdHover(leadid)
  }

  const handleOnRemoveLead = (leadid?: string | undefined) => {
    leadIdToRemove = leadid
    showModal()
  }

  const handleOnSubmitRemoveLead = () => {
    hideModal()
    onRemoveLead(leadIdToRemove as string)
    leadIdToRemove = null
  }

  const handleOnClick = (leadId: string) => {
    onItemClick(leadId)
  }

  if (loading) {
    return (
      <Loader isCompactLayout={isCompactLayout} />
    )
  }

  if (!items.length) {
    return (
      <ResultsMessage
        title={t('buyer-emptySearchResult')}
        icon={<NotificationsNoneIcon color='secondary' fontSize='large' style={{ height: 150, width: 'auto', marginTop: 40 }} />}
        actionButton={(
          <Link color='inherit' to='/buyer/postSearch' style={{ textDecoration: 'none' }}>
            <Button endIcon={<AddIcon />} variant='outlined' color='primary' style={{ marginTop: '20px' }}>
              <Typography size='large' color='textPrimary' noWrap>{t('newSearch')}</Typography>
            </Button>
          </Link>
        )}
      />
    )
  }

  return (
    <>
    <List className={classes.root}>
      {items.map((item) => {
        return (
          <div key={`${item.item.leadId}`} className={classes.listItemWrp}>
            <AssetCard
              allowFullScreen={false}
              onMouseLeave={() => handleOnMouseEnter()}
              onClick={handleOnClick}
              onMouseEnter={() => handleOnMouseEnter(item.item.leadId)}
              {...item}
              item={{ ...item.item, isNew: !!(selectedSearchNewLeads?.numberOfNewLeads && item.item.isNew) }}
              wide={!isCompactLayout}
              onRemove={handleOnRemoveLead}
            />
          </div>
        )
      })}
    </List>
    <Box marginTop={1}>
      <Pagination
        activePage={page}
        itemCount={totalCount}
        limit={PAGE_SIZE}
        onPageChange={(to) => history.push(`/buyer/search/${id}/${to}`)} />
    </Box>
    <DeleteWarningModal
      isOpen={isOpen}
      onClose={hideModal}
      mainText={t('removeLeadBuyer')}
      onSubmit={handleOnSubmitRemoveLead}
    />
    </>
  )
}

export default AssetsResultsList
