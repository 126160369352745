import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import List from '@material-ui/core/List'

import LeadsItemLoader from '~/components/modules/LeadsItem/Loader'
import * as S from '../styles'

const useStyles = makeStyles(S.LeadListtyled)

export interface Props {
  isCompactLayout?: boolean
}

const AssetsResultsList: React.FC<Props> = () => {
  const classes = useStyles()

  return (
    <List className={classes.root}>
      <LeadsItemLoader />
      <LeadsItemLoader />
      <LeadsItemLoader />
      <LeadsItemLoader />
    </List>
  )
}

export default AssetsResultsList
