import React, { useState } from 'react'
import { isEmpty } from 'lodash'
import { Grid, InputAdornment } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import VisibilityIcon from '@material-ui/icons/Visibility'
import VpnKeyIcon from '@material-ui/icons/VpnKey'

import PageLoader from '~/components/core/PageLoader'
import Form from '~/components/core/Form'
import useResetPasswordFormValidation from '~/hooks/useResetPasswordFormValidation'
import LabelWrapper from '~/components/core/LabelWrapper'
import Typography from '~/components/core/Typography'

export interface InitProps {
  password: string
}

export interface Props {
  onSubmit?: (val: InitProps['password']) => void
  loading?: boolean
}

const INIT_FORM: InitProps = {
  password: ''
}

const ForgetPasswordForm: React.FC<Props> = ({ onSubmit = () => {}, loading }) => {
  const { t } = useTranslation()
  const [validateForm] = useResetPasswordFormValidation()
  const [initData, setInitData] = useState(INIT_FORM)
  const [visiblePassword, setVisiblePassword] = useState<boolean>(false)

  const handleOnSubmit = (data: InitProps) => {
    const vlidation = validateForm(data)

    if (!isEmpty(vlidation)) {
      return vlidation
    }

    setInitData(data)
    onSubmit(data.password)

    return {}
  }

  if (loading) {
    return <PageLoader open invisible />
  }

  return (
    <Form initialValues={initData} onSubmit={handleOnSubmit} validate={validateForm}>
      <Grid container alignItems='stretch' spacing={4} style={{ maxWidth: '500px' }}>
        <Grid item container wrap='nowrap'>
          <LabelWrapper label={t('newPassword')} icon={<VpnKeyIcon fontSize='small' />} direction='column'>
            <Form.Input
              name='password'
              type={visiblePassword ? 'text' : 'password'}
              InputProps={{
              endAdornment: (
                  <InputAdornment style={{ cursor: 'pointer' }} onClick={() => setVisiblePassword(!visiblePassword)} position='start'>
                    <VisibilityIcon />
                  </InputAdornment>
                )
              }}
            />
          </LabelWrapper>
        </Grid>
        <Grid justify='center' item container wrap='nowrap'>
          <Form.SubmitButton color='primary' variant='contained'>
            <Typography color='inherit'>
              {t('changePassword')}
            </Typography>
          </Form.SubmitButton>
        </Grid>
      </Grid>
    </Form>
  )
}

export default ForgetPasswordForm
