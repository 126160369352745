import { Props as SelecProps } from '~/components/core/Inputs/Select'

export const VIEW_OPTIONS_ARR: SelecProps['options'] = [
  { value: 0, text: 'selectView-none' },
  { value: 1, text: 'selectView-sea' },
  { value: 2, text: 'selectView-green' },
  { value: 3, text: 'selectView-orban' }
]

export const REMODEL_OPTIONS_ARR: SelecProps['options'] = [
  { value: 0, text: 'selectRemodel-new' },
  { value: 1, text: 'selectRemodel-likeNew' },
  { value: 2, text: 'selectRemodel-reserved' },
  { value: 3, text: 'selectRemodel-not' }
]

const getFloors = (): SelecProps['options'] => {
  const floorsArr = [{ value: 0, text: '0' }]

  for (let ind = 1; ind < 31; ind++) {
    floorsArr.push({
      value: ind,
      text: ind.toString()
    })
  }

  return floorsArr
}

export const FLOOR_INPUT: SelecProps['options'] = getFloors()

export const BOOLEAN_INPUT_YES_NO: SelecProps['options'] = [
  { value: 0, text: 'selectBase-no' },
  { value: 1, text: 'selectBase-yes' }
]

export const FLOOR_OPTIONS_ARR: SelecProps['options'] = [
  { value: 0, text: 'selectFloor-ground' },
  { value: 1, text: 'selectFloor-low' },
  { value: 2, text: 'selectFloor-medium' },
  { value: 3, text: 'selectFloor-high' }
]

export const REMODEL_INPUT: SelecProps['options'] = [
  { value: 0, text: 'select.remodel.new' },
  { value: 1, text: 'select.remodel.likeNew' },
  { value: 2, text: 'select.remodel.reserved' },
  { value: 3, text: 'select.remodel.not' }
]

export const VIEW_INPUT: SelecProps['options'] = [
  { value: 0, text: 'select.view.none' },
  { value: 1, text: 'select.view.sea' },
  { value: 2, text: 'select.view.green' },
  { value: 3, text: 'select.view.orban' }
]

export const FLOOR_RANGES = [
  { value: 0, range: { min: 0, max: 0 } },
  { value: 1, range: { min: 1, max: 3 } },
  { value: 2, range: { min: 4, max: 7 } },
  { value: 3, range: { min: 8, max: 1000 } }
]
