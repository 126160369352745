import React from 'react'
import { useParams } from 'react-router-dom'

import { ViewerProvider } from '~/components/core/ViewerProvider/context'
import { MinimalAsset } from '~/types/Asset'

export default function useSelectedAsset(): [MinimalAsset | undefined] {
  const { id } = useParams<{ id?: string }>()
  const { assets } = React.useContext(ViewerProvider)

  const selectedAsset = assets?.find((asset) => asset.id === id)

  return [selectedAsset]
}
