import React from 'react'
import { Container } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import { FloatingBottomBarStyled } from './styles'

export interface Props {
  children: React.ReactElement
}

const useStyles = makeStyles(FloatingBottomBarStyled, {
  name: 'FloatingBottomBar'
})

const FloatingBottomBar: React.FC<Props> = ({ children }) => {
  const classes = useStyles()

  return (
    <Container className={classes.root}>
      {children}
    </Container>
  )
}

export default FloatingBottomBar
