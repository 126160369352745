import React from 'react'
import useFetch, { CachePolicies } from 'use-http'

import { ViewerFavoriteContext } from '~/components/core/ViewerFavoriteProvider/context'
import { SearchLead } from '~/types/Search'

export default function useFavoriteLead(lead: SearchLead | null, leadid?: string): [() => void, boolean] {
  const leadId = lead?.leadId || leadid || ''
  const { searchFavorites, removeFavoriteState, addFavoriteState, refetchSearchFavorites, searchFavoritesLoading } = React.useContext(ViewerFavoriteContext)
  const { post: postSetFav } = useFetch('api/searches/setFavoritesSearchProperties', { cachePolicy: CachePolicies.NO_CACHE })
  const { post: postRemoveFav } = useFetch('api/searches/removeFavoritesSearchProperties', { cachePolicy: CachePolicies.NO_CACHE })

  const isFavorite = () => (searchFavorites || []).some((asset) => asset.leadId === leadId)

  // eslint-disable-next-line complexity
  const toggleFavorite = async () => {
    if (searchFavoritesLoading) {
      return
    }

    if (isFavorite()) {
      removeFavoriteState(leadId)
    } else if (lead) {
      addFavoriteState(lead)
    }

    const post = isFavorite() ? postRemoveFav : postSetFav
    const res = await post({ leadId })

    if (res) {
      refetchSearchFavorites()
    }
  }

  return [toggleFavorite, isFavorite()]
}
