import React from 'react'
import Tooltip from '@material-ui/core/Tooltip'
import InfoIcon from '@material-ui/icons/InfoOutlined'
import { makeStyles } from '@material-ui/core/styles'

import Typography from '~/components/core/Typography'
import styles from './styles'

const blockedText = 'מחפשים מתאימים עם סטיית מחיר של עד 20% בין מחיר הדירה לתקציב החיפוש'

const useStyles = makeStyles(styles)

interface Props {
  children: React.ReactNode
}

const DeviationTooltip = ({
  children
}: Props): JSX.Element => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <div className={classes.body}>
        {children}
      </div>
      <Tooltip
        leaveTouchDelay={5000}
        enterTouchDelay={100}
        title={
          <Typography>{blockedText}</Typography>
        }
      >
        <InfoIcon htmlColor='#4BB5AF' fontSize='small' />
      </Tooltip>
    </div>
  )
}

export default DeviationTooltip
