import { createStyles, Theme } from '@material-ui/core/styles'

import { screens } from '~/utils/breakpoints'
import palette from '~/ThemeProvider/config/palette'

export const Styled = (theme: Theme) => createStyles({
  root: {
    direction: 'rtl',
    zIndex: 900,
    backgroundColor: palette.common.white,
    '&:hover': {
      backgroundColor: palette.grey.lighter
    },
    position: 'fixed',
    bottom: theme.spacing(10),
    right: theme.spacing(4),
    [screens('small', 'medium')]: {
      bottom: theme.spacing(4)
      // right: theme.spacing(4)
    }
  },
  icon: {
    transform: 'rotate(180deg)'
  }
})
