import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import BasePagination from '@material-ui/lab/Pagination'

import styles from './styles'

const NUMBER_OF_ITEMS_DISPLAY_LIMIT = 10000

const DEFAULT_PAGE = 1

const parsePage = (page?: string | number) =>
  (page && Number(page)) || DEFAULT_PAGE

const getTotalPages = (limit?: number, itemCount?: number) =>
  limit && itemCount ? Math.ceil(itemCount / limit) : undefined

export interface Props {
  activePage?: string | number
  onPageChange: (page: number) => void
  limit?: number
  itemCount?: number
}

const useStyles = makeStyles(styles)

const Pagination: React.FC<Props> = ({
  activePage = DEFAULT_PAGE,
  itemCount,
  limit,
  onPageChange
}) => {
  const classes = useStyles()
  const totalCount =
    itemCount && Math.min(itemCount, NUMBER_OF_ITEMS_DISPLAY_LIMIT)
  const totalPages = getTotalPages(limit, totalCount)

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    onPageChange(value)
  }

  if (!totalPages || totalPages < 2) {
    return null
  }

  return (
    <BasePagination
      classes={{
        ul: classes.root
      }}
      size='large'
      page={parsePage(activePage)}
      onChange={handleChange}
      count={totalPages}
    />
  )
}

export default Pagination
