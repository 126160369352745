import React, { useCallback } from 'react'
import useFetch, { CachePolicies } from 'use-http'
import { useParams, useHistory } from 'react-router-dom'
import { Grid } from '@material-ui/core'

import PageLoader from '~/components/core/PageLoader'
import { rollbarError } from '~/utils/rollbar'
import AssetLeadsTips from '~/components/modules/AssetLeadsTips'
import { BudgetRange } from '~/types/Asset'
import { ViewerProvider } from '~/components/core/ViewerProvider/context'
import AssetPriceContainer from '~/components/pages/v3/AssetPriceContainer'

interface BudgetListCall {
  assetPrice: number
  budgetList: BudgetRange[]
}

const AssetPricePage = (): JSX.Element => {
  const history = useHistory()
  const { refetchAssets } = React.useContext(ViewerProvider)
  const { id } = useParams<{ id: string }>()
  const { data, loading, error } = useFetch<BudgetListCall>(`/api/v3/assets/asset/${id}/budgetList`, {
    cachePolicy: CachePolicies.NO_CACHE
  }, [id])

  const handleNext = useCallback(async () => {
    refetchAssets()
    history.push(`/seller/asset/${id}/leads`)
  }, [history, id, refetchAssets])

  if (error) {
    rollbarError({
      error
    })

    return <></>
  }

  if (loading) {
    return <PageLoader open invisible />
  }

  if (!data) {
    return <></>
  }

  return (
    <Grid container spacing={3} wrap='nowrap'>
      <Grid item container lg={8}>
        <AssetPriceContainer
          budgetsList={data.budgetList}
          assetId={id}
          recommendedPrice={data.assetPrice}
          onBack={handleNext}
          onSetPriceSuccess={handleNext}
        />
      </Grid>
      <AssetLeadsTips customPattern='tipsText.seller.price' />
    </Grid>
  )
}

export default AssetPricePage
