import React from 'react'
import useScrollTrigger from '@material-ui/core/useScrollTrigger'
import Slide from '@material-ui/core/Slide'

interface Props {
  children: React.ReactElement
  active?: boolean
}

const HideOnScroll: React.FC<Props> = (props) => {
  const { children, active } = props
  const trigger = useScrollTrigger()

  if (!active) return children

  return (
    <Slide appear={false} direction='down' in={!trigger}>
      {children}
    </Slide>
  )
}

export default HideOnScroll
