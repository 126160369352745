import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import List from '@material-ui/core/List'

import AssetCardWideLoader from '~/components/modules/AssetCard/Wide/Loader'
import AssetCardLoader from '~/components/modules/AssetCard/Loader'
import * as S from '../styles'

const useStyles = makeStyles(S.LeadListtyled)

export interface Props {
  isCompactLayout?: boolean
}

const AssetsResultsList: React.FC<Props> = ({
  isCompactLayout
}) => {
  const classes = useStyles()

  if (isCompactLayout) {
    return (
      <List className={classes.root}>
        <AssetCardLoader />
        <AssetCardLoader />
        <AssetCardLoader />
        <AssetCardLoader />
      </List>
    )
  }

  return (
    <List className={classes.root}>
      <AssetCardWideLoader />
      <AssetCardWideLoader />
      <AssetCardWideLoader />
      <AssetCardWideLoader />
    </List>
  )
}

export default AssetsResultsList
