import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import NavigationIcon from '@material-ui/icons/Navigation'
import Fab from '@material-ui/core/Fab'

import { scrollDownSmooth } from '~/utils/positions'
import useScrollToBottom from '~/hooks/useScrollToBottom'
import { Styled } from './styles'

const useStyles = makeStyles(Styled)

const ScrollToBottomButton: React.FC = () => {
  const [isBottom] = useScrollToBottom()
  const classes = useStyles()

  if (isBottom) return null

  return (
    <Fab
      aria-label='add'
      size='small'
      onClick={scrollDownSmooth}
      className={classes.root}
    >
      <NavigationIcon style={{ color: '#fff', transform: 'rotate(180deg)' }} />
    </Fab>
  )
}

export default ScrollToBottomButton
