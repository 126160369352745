import React from 'react'
import { useParams, useHistory } from 'react-router-dom'
import useFetch from 'use-http'

import SearchDetails from '~/components/modules/SearchDetails'
import { Search } from '~/types/Search'

interface Props {
  onClose?: () => void
}

const SearchDetailsPage: React.FC<Props> = ({ onClose }) => {
  const { id } = useParams<{ id?: string }>()
  const history = useHistory()
  const { loading, error, data } = useFetch<Search>(`/api/v3/searches/search/${id}`, { data: null }, [id])

  const handleOnClose = () => {
    if (onClose) {
      return onClose()
    }

    return history.goBack()
  }

  if (error) {
    console.log('errorerror', error)

    return <>ERROR</>
  }

  return (
    <SearchDetails onClose={handleOnClose} search={data as Search} loading={loading} />
  )
}

export default SearchDetailsPage
