import React, { useContext } from 'react'
import { isUndefined } from 'lodash'
import GoogleMapReact, { fitBounds } from 'google-map-react'

import MapMarker, { Props as MarkerProps } from '~/components/core/MapView/MapMarker'
import { Props as MapViewProps } from '~/components/core/MapView'
import { SizesProps } from '~/hooks/useHeightHelper'
import { MapContext } from '~/components/core/MapProvider/context'

const defaultCenter: MapViewProps['position'] = {
  lat: 31.1817727880375,
  lng: 34.969963199999995
}

const INCREASE_DECIMAL = 0.000002

const getBounds = (markers: MarkerProps[]): GoogleMapReact.NESWBounds => {
  if (isUndefined(window.google) || isUndefined(window.google.maps)) {
    return {
      ne: defaultCenter,
      sw: defaultCenter
    }
  }

  const bounds = new google.maps.LatLngBounds()
  const hashedCoord: {[key: string]: boolean} = {}

  markers.forEach((marker) => {
    const coordsStr = `${marker.lat.toString()}${marker.lng.toString()}`

    if (hashedCoord[coordsStr]) {
      bounds.extend({ ...marker, lat: marker.lat + INCREASE_DECIMAL, lng: marker.lng + INCREASE_DECIMAL })
    } else {
      hashedCoord[coordsStr] = true
      bounds.extend(marker)
    }
  })

  return {
    ne: bounds.getNorthEast().toJSON(),
    sw: bounds.getSouthWest().toJSON()
  }
}

export default function useMarkersForMap(sizes: SizesProps, position?: MarkerProps): [JSX.Element[], MapViewProps['position'], number] {
  const { mapMarkers, leadIdHover } = useContext(MapContext)
  const markersArr = position ? [position] : mapMarkers
  // eslint-disable-next-line react/jsx-handler-names
  const markers = markersArr.map((marker, index) => <MapMarker key={`marker-${index}`} lat={marker.lat} lng={marker.lng} onLeadCardClick={marker.onLeadCardClick} leadObj={marker.leadObj} isHovered={marker.leadObj && leadIdHover === marker.leadObj.leadId} />)

  if (!markers.length) {
    return [markers, defaultCenter, 8]
  }

  if (markers.length === 1) {
    return [markers, markersArr[0], 16]
  }

  const { center, zoom } = fitBounds(getBounds(markersArr), sizes as GoogleMapReact.Size || { width: 800, height: 800 })

  return [markers, center, zoom]
}
