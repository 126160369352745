import { useEffect, useState } from 'react'

export default function useScrollToTop(): [boolean] {
  const elm = document.getElementsByTagName('HTML')[0]
  const [isTop, setIsTop] = useState<boolean>(elm.scrollTop === 0)

  const handleOnScroll = () => {
      setIsTop(elm.scrollTop === 0)
  }

  useEffect(() => {
    window.addEventListener('scroll', handleOnScroll)

    return () => window.removeEventListener('scroll', handleOnScroll)
  }, [])

  return [isTop]
}
