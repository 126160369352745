import React, { forwardRef } from 'react'
import { makeStyles, Theme } from '@material-ui/core/styles'
import MUITableCell, { TableCellProps } from '@material-ui/core/TableCell'

import styles from './styles'
import { TableSectionContext, TableSection } from '../Table/TableSectionContext'

type AlignType = 'inherit' | 'left' | 'center' | 'right' | 'justify'

export interface Props
  extends TableCellProps {
  align?: AlignType
  colSpan?: number
  rowSpan?: number
}

const useStyles = makeStyles<Theme>(styles, { name: 'TableCell' })

export const TableCell = forwardRef<HTMLTableCellElement, Props>(
  function TableCell(props, ref) {
    const {
      align = 'inherit',
      style,
      children,
      colSpan,
      rowSpan,
      ...rest
    } = props

    const muiClasses = useStyles()
    const tableSection = React.useContext(TableSectionContext)
    const isHead = tableSection === TableSection.HEAD

    return (
      <MUITableCell
        {...rest}
        ref={ref}
        align={align}
        classes={muiClasses}
        style={{
          ...(style || {}),
          ...(isHead ? { fontWeight: 'bold' } : {})
        }}
        colSpan={colSpan}
        rowSpan={rowSpan}
      >
        {children}
      </MUITableCell>
    )
  }
)

export default TableCell
