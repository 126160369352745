import React from 'react'
import NumberFormat, { NumberFormatProps, NumberFormatValues } from 'react-number-format'

import TextInput from '../TextInput'
import LabelWrapper, { Props as LabelProps } from '../../LabelWrapper'

export interface Props extends NumberFormatProps {
  harizontalLabel?: React.ReactNode
  halfAllow?: boolean
  labelProps?: LabelProps
}

const NumberInput: React.FC<Props> = ({
  onChange, value, error, isNumericString, label, decimalScale, harizontalLabel, halfAllow, labelProps = {}, ...rest
}) => {
  const handleIsHalf = (val: NumberFormatValues) => {
    const { floatValue = 0 } = val

    return (floatValue % 1 === 0) || (floatValue % 1 === 0.5)
  }

  return (
    <>
      <LabelWrapper label={harizontalLabel || label} direction={harizontalLabel ? 'row' : 'column'} {...labelProps}>
        <NumberFormat
          error={!!error}
          isAllowed={halfAllow ? handleIsHalf : rest.isAllowed}
          helperText={error || undefined}
          customInput={TextInput}
          value={value}
          onChange={onChange}
          allowNegative={false}
          decimalScale={decimalScale}
          {...rest}
          isNumericString={isNumericString}
        />
      </LabelWrapper>
    </>
  )
}

export default NumberInput
