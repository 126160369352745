import { isUndefined } from 'lodash'

import { AssetFormData, PortAssetPayload, SellerAsset } from '~/types/Asset'

// eslint-disable-next-line complexity
export const preparePayload = (data: AssetFormData, asset?: SellerAsset): PortAssetPayload => {
  return ({
    forSale: data.postType === 'sell',
    propertyId: asset?.id,
    city: (data.address?.name || asset?.address)?.split(',')[1]?.trim() || '',
    address: data.address?.name || asset?.address || '',
    propertyPos: JSON.stringify(data.address?.latLng || asset?.propertyPos),
    neighborhood: data.neighborhood?.name || '',
    contactNumber: data.contactNumber,
    isAgent: !!data.isAgent,
    price: data.price as number,
    rooms: data.rooms as number,
    squareSize: data.squareSize as number,
    balcony: data.balcony,
    elevator: data.elevator,
    floor: data.floor as number,
    furnished: data.furnished,
    garden: data.garden,
    hadicap: data.hadicap,
    panthouse: data.panthouse,
    parking: data.parking,
    remodel: data.remodel,
    safeRoom: data.safeRoom,
    storage: data.storage,
    view: data.view,
    description: data.description,
    images: data.images,
    entranceDate: data.entranceDate ? new Date(data.entranceDate) : new Date()
  })
}

export const createFormData = (data: PortAssetPayload): FormData => {
  const fd = new FormData()

  Object.keys(data).forEach((key: string) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const elm = data[key]

    if (!isUndefined(elm)) {
      if (key !== 'images') {
        fd.append(key, elm.toString())
      } else {
        const newImgs: string[] = []
        const imagesElm = elm as PortAssetPayload['images']

        Array.from(imagesElm).forEach((img, index) => {
          if (typeof img === 'string') {
            newImgs.push(img)
          } else {
            fd.append(`image${index}`, img)
          }
        })
        fd.append(key, newImgs.toString())
      }
    }
  })

  return fd
}
