/* eslint-disable no-undef */

analytics.load(process.env.SEGMENT_KEY as string);

export default {
  initialize({ userId, userEmail }: { userId: string, userEmail: string }) {
    const searchParam = document.location && new URLSearchParams(document.location.search);
    const isFbReferrer = !!(searchParam && searchParam.get('fbclid')) || (document.referrer && document.referrer.includes('facebook.com'));

    analytics.identify(userId, {
      email: userEmail
    });

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this._defaultPayload = {
      userId,
      user_id: userId,
      user_email: userEmail,
      FacebookReferrer: isFbReferrer
    }
  },
  track(eventName: string, eventData = {}) {
    const payload = {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      ...this._defaultPayload,
      ...eventData
    };

    analytics.track(eventName, payload);

    if (process.env.NODE_ENV === 'development') {
      // eslint-disable-line no-console
      console.log(`Sent event to Segment.com: "${eventName}" with payload: ${JSON.stringify(payload)}`);
    }
  }
};
