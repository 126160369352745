import { createStyles, Theme } from '@material-ui/core/styles'

import { screens } from '~/utils/breakpoints'

const headerHeight = { default: '4.5rem', smallAndMedium: '3rem' }

export default ({ layout }: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      // height: '100%',
      width: '100%',
      ...(layout.contentMinWidth && { minWidth: layout.contentMinWidth }),

      '& > footer, & > header': {
        flex: 0
      },
      '& > header + *': {
        marginTop: headerHeight.default,
        [screens('small', 'medium')]: {
          marginTop: headerHeight.smallAndMedium
        }
      }
    }
  })
