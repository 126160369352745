import React from 'react'
import { Grid, CardActions } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { useFormState } from 'react-final-form'

import Form from '~/components/core/Form'
import Modal from '~/components/core/Modal'
import Button from '~/components/core/Button'
import Typography from '~/components/core/Typography'
import { ASSET_PROPS_ICONS } from '~/components/modules/AssetDetails/constants'

export interface Props {
  onSubmit: (phone?: number | string) => void
  isOpen: boolean
}

const SearchTypeReminderModal: React.FC<Props> = ({ isOpen, onSubmit }) => {
  const { t } = useTranslation()
  const formState = useFormState()

  const FieldIcon = ASSET_PROPS_ICONS.searchType

  return (
    <Modal open={isOpen}>
      <Modal.Title>
        {t('emptyPhoneAlert-title')}
      </Modal.Title>
      <Modal.Content dividers style={{ overflowX: 'hidden' }}>
        <Grid container direction='column' spacing={2}>
          <Grid item>
            <Typography>
              שכחתם למלא את השדה החשוב הזה
            </Typography>
          </Grid>
          <Grid item container wrap='nowrap'>
          <Form.ToggleButtonsGroup
            style={{ width: '400px', justifyContent: 'space-evenly' }}
            labelProps={{
              style: { padding: '0 16px' },
              icon: <FieldIcon />,
              label: t('searchType')
            }}
            name='searchType'
          >
            {
              [{ text: t('labelSale'), value: 'sell' }, { text: t('labelRent'), value: 'rent' }].map(({ text, value }: { text: string, value: string }, index: number) => (
                <Form.ToggleButton key={index} value={value} label={text} />
              ))
            }
        </Form.ToggleButtonsGroup>
          </Grid>
        </Grid>
      </Modal.Content>
        <Modal.Actions>
          <CardActions style={{ width: '100%' }}>
            <Button color='primary' variant='text' disabled={!formState.values.searchType} onClick={() => onSubmit()}>
              <Typography weight='bold'>
              המשך
              </Typography>
            </Button>
          </CardActions>
        </Modal.Actions>
    </Modal>
  )
}

export default SearchTypeReminderModal
