import React from 'react'
import Grid from '@material-ui/core/Grid'

import Typography, { Props as TypographyProps } from '~/components/core/Typography'

export interface Props {
  label: string
  value: string | React.ReactNode,
  direction?: 'column' | 'row',
  size?: 'small' | 'medium' | 'large'
}

const SIZES: { [key in 'small' | 'medium' | 'large']: {label: TypographyProps['size'], value: TypographyProps['size']} } = {
  small: {
    label: 'xsmall',
    value: 'medium'
  },
  medium: {
    label: 'small',
    value: 'large'
  },
  large: {
    label: 'medium',
    value: 'xlarge'
  }
}

const AssetValueInfo: React.FC<Props> = ({ label, value, direction = 'column', size = 'medium' }) => {
  const sizes = SIZES[size]

  return (
    <Grid container alignItems='flex-start' direction={direction} spacing={0}>
      <Grid item>
      <Typography size={sizes.value} weight='bold'>
        {value}
      </Typography>
      </Grid>
      <Grid item>
        <Typography size={sizes.label}>
          {label}
        </Typography>
      </Grid>
    </Grid>
  )
}

export default AssetValueInfo
