import React, {
  useMemo,
  LiHTMLAttributes,
  AnchorHTMLAttributes,
  ButtonHTMLAttributes,
  CSSProperties,
  HTMLAttributes,
  ReactNode
} from 'react'
import { withStyles } from '@material-ui/core/styles'
import MUIMenuItem, { MenuItemProps } from '@material-ui/core/MenuItem'

import * as S from './styles'

export type Classes = Record<string, string>

export interface BaseProps {
  /** Classnames applied to root element */
  className?: string
  /** Style applied to root element */
  style?: CSSProperties
}

export interface JssProps {
  classes: Classes
}

export type StandardProps = BaseProps & JssProps

type ButtonOrAnchorProps = AnchorHTMLAttributes<HTMLAnchorElement> &
  ButtonHTMLAttributes<HTMLButtonElement>

export type MenuItemAttributes = LiHTMLAttributes<HTMLLIElement> &
  HTMLAttributes<HTMLDivElement> &
  ButtonOrAnchorProps

export interface Props
  extends MenuItemProps {
  disabled?: boolean
  children?: ReactNode
  selected?: boolean
  value?: string | string[] | number
  button?: true | undefined;
}

const generateKey = (() => {
  let count = 0

  return () => String(++count)
})()

const StyledMenuItem = withStyles(S.MenuItem)(MUIMenuItem)

export const MenuItem: React.FC<Props> = ({
  children,
  disabled,
  disableGutters,
  selected,
  style,
  value,
  ...rest
}) => {
  const key = useMemo(generateKey, [])

  return (
    <StyledMenuItem
      {...rest}
      key={key}
      disabled={disabled}
      disableGutters={disableGutters}
      style={style}
      value={value}
      selected={selected}
    >
      <span
        style={style}
      >
        {children}
      </span>
    </StyledMenuItem>
  )
}

export default MenuItem
