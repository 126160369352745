import React, { forwardRef } from 'react'
import { makeStyles, createMuiTheme, ThemeProvider } from '@material-ui/core/styles'
import MUISlider, { SliderProps } from '@material-ui/core/Slider'
import { CompoundedComponentWithRef } from '@shared/types'

import { StyledSlider } from './styles'

const theme = createMuiTheme({
  direction: 'ltr'
})

const useStyles = makeStyles(StyledSlider)

export interface Props extends SliderProps {}

const Slider = forwardRef<HTMLElement, Props>(function Slider({
  children, ...props
}, ref) {
  const classes = useStyles()

  return (
    <ThemeProvider theme={theme}>
      <MUISlider {...props} aria-label='core slider' ref={ref} classes={classes}>
        {children}
      </MUISlider>
    </ThemeProvider>
  )
}) as CompoundedComponentWithRef<Props, HTMLElement>

export default Slider
