import React from 'react'
import {
  Route,
  Switch
} from 'react-router-dom'

import ApiProvider from '~/components/core/ApiProvider'
import Asset from '~/components/pages/public/Asset'
import Page from '~/components/core/CompletePage'
import FeedbackPage from '~/components/modules/UserFeedback/page'
import ContactUs from '~/components/pages/ContactUs'
import Policy from '~/components/pages/Policy'
import AboutUs from '~/components/pages/AboutUs'

const PublicHoome: React.FC = () => {
  return (
    <ApiProvider skipAuth baseUrl={process.env.HOST_URL}>
      <Page>
        <Switch>
          <Route exact path='/public/feedback/:userId'>
            <FeedbackPage />
          </Route>
          {/* public asset details */}
          <Route exact path='/public/asset/:id'>
            <Asset />
          </Route>
          <Route exact path='/public/contact-us'>
            <ContactUs />
          </Route>
          <Route exact path='/public/policy'>
            <Policy />
          </Route>
          <Route exact path='/public/about'>
            <AboutUs />
          </Route>
        </Switch>
      </Page>
    </ApiProvider>
  )
}

export default PublicHoome
