import React from 'react'
import Tooltip from '@material-ui/core/Tooltip'
import { isNumber } from 'lodash'

import Slider, { Props as SliderProp } from '~/components/core/Slider'
import Typography from '~/components/core/Typography'

interface Props {
  children: React.ReactElement
  value: number
}

const ValueLabelComponent = (props: Props) => {
  const { children, value } = props;

  return (
    <Tooltip
      open
      enterTouchDelay={0}
      placement='top'
      PopperProps={{
        style: {
          zIndex: 20
        }
      }}
      title={(
        <Typography style={{ fontSize: '12px' }}>
          {value.toLocaleString()}
        </Typography>

)}
      arrow
    >
      {children}
    </Tooltip>
  );
}

const getMarks = (props: SliderProp) => {
  const marks = []

  if (isNumber(props.min)) {
    marks.push({
      value: props.min,
      label: props.min?.toLocaleString()
    })
  }

  if (isNumber(props.max)) {
    marks.push({
      value: props.max,
      label: props.max?.toLocaleString()
    })
  }

  return marks
}

const PriceSlider = (props: SliderProp): JSX.Element => {
  return (
    <Slider
      {...props}
      valueLabelDisplay='on'
      ValueLabelComponent={ValueLabelComponent}
      track={false}
      step={10}
      marks={getMarks(props)}
    />
  )
}

export default PriceSlider
