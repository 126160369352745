const DEFAULT_PAGE_SIZE = 10
const NUMBER_OF_ITEMS_DISPLAY_LIMIT = 10000

const getPaginationOffset = (
  page?: string | number,
  pageSize: number = DEFAULT_PAGE_SIZE,
  maxItems: number = NUMBER_OF_ITEMS_DISPLAY_LIMIT
): number => {
  if (!page) {
    return 0
  }

  const pageNum = Number(page) || 1
  const pageAbs = Math.max(1, pageNum)

  return Math.min((pageAbs - 1) * pageSize, maxItems - pageSize)
}

export default getPaginationOffset
