import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import { Grid } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import AttachMoneyIcon from '@material-ui/icons/AttachMoney'

import { useModal } from '~/utils/useModal'
import SearchDetails from '~/components/pages/SearchDetails'
import DeleteWarningModal from '~/components/modules/DeleteWarningModal'
import { Sorting } from '~/components/pages/SearchResults'
import SortingButton from '~/components/core/SortingButton'
import Typography from '~/components/core/Typography'
import SearchResultsHeader from '~/components/modules/SearchResultsHeader'
import SearchActionsMenu from '~/components/modules/SearchActionsMenu'
import DisableContainerMask from '~/components/core/DisableContainerMask'
import { ViewerProvider } from '~/components/core/ViewerProvider/context'
import useSelectedSearch from '~/hooks/useSelectedSearch'
import { addressWithoutCountry } from '~/components/utils/formating'
import * as S from './styles'

const useStyles = makeStyles(S.SearchResultsContanairStyled)

export const PercentageIcon: React.FC<{ active?: boolean, symbol?: string }> = ({ active, symbol = '%' }) => (
  <div
    style={{
      width: '17px',
      height: '17px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      fontSize: '18px',
      fontWeight: 'bold',
      marginRight: '4px',
      marginLeft: '-6px',
      color: active ? 'inherit' : '#616161'
    }}
  >
    {symbol}
  </div>
)

export interface Props {
  children: React.ReactNode
  name?: string
  onSortChange: (type: 'percentage' | 'price') => void
  sorting: Sorting
  onDelete: () => void
}

const SearchResultsContanair: React.FC<Props> = ({
  children,
  sorting,
  onSortChange,
  onDelete
}) => {
  const { t } = useTranslation()
  const { id } = useParams<{ id?: string }>()
  const { searches } = React.useContext(ViewerProvider)
  const [openMenu, setOpenMenu] = useState(false)
  const classes = useStyles()
  const { showModal, hideModal, isOpen } = useModal()
  const { showModal: showSearchDetails, hideModal: hideSearchDetails, isOpen: isOpenSearchDetails } = useModal()
  const [selectedSearch] = useSelectedSearch()

  if (!selectedSearch) {
    return null
  }

  return (
    <>
    <div className={classes.root}>
      <div className={classes.content}>
        <Grid container spacing={2} style={{ padding: '12px' }}>
          <Grid item container>
            <Grid container spacing={2} justify='space-between'>
              <Grid item container alignItems='center' style={{ width: 'auto' }}>
                <Grid container spacing={2}>
                  <Grid item>
                    <Typography weight='bold' size='xlarge'>
                      {addressWithoutCountry(selectedSearch.city)}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography weight='bold' size='xlarge'>
                      {selectedSearch.forSale ? t('labelForSale') : t('labelForRent')}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item container style={{ width: 'auto' }}>
                <SearchActionsMenu searchId={id} onRemove={showModal} onSearchDetails={showSearchDetails} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item container>
            <Grid container spacing={2} alignItems='center'>
              <Grid item>
                <Typography size='large'>
                  {t('sortBy')}
                </Typography>
              </Grid>
              <Grid item>
                <SortingButton icon={<AttachMoneyIcon />} ascending={Boolean(sorting.ascending)} label='מחיר' active={sorting.type === 'price'} onClick={() => onSortChange('price')} />
              </Grid>
              <Grid item>
                <SortingButton icon={<PercentageIcon active={sorting.type === 'percentage'} />} ascending={Boolean(sorting.ascending)} label='אחוז התאמה' active={sorting.type === 'percentage'} onClick={() => onSortChange('percentage')} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <SearchResultsHeader name={addressWithoutCountry(selectedSearch.city)} searches={searches || []} openMenu={openMenu} onMenuToggle={() => setOpenMenu(!openMenu)} />
        <DisableContainerMask active={openMenu} invisible>
          {children}
        </DisableContainerMask>
      </div>
    </div>
    <DeleteWarningModal
      isOpen={isOpen}
      onClose={hideModal}
      mainText={t('modalDetedeSearchMessage')}
      onSubmit={() => {
        onDelete()
        hideModal()
      }}
    />
    {
      isOpenSearchDetails && (
        <SearchDetails onClose={hideSearchDetails} />
      )
    }
    </>
  )
}

export default SearchResultsContanair
