import React from 'react'
import { Grid, CardActions } from '@material-ui/core'
import { useFormState } from 'react-final-form'

import Form from '~/components/core/Form'
import { useBreakpoint } from '~/utils/breakpoints'
import Modal from '~/components/core/Modal'
import Button from '~/components/core/Button'
import Typography from '~/components/core/Typography'
import { DealReadiness } from '~/types/Search'

export const DEAL_READINESS_COPY = {
  JustLooking: 'רק מסתכלים',
  NotSure: 'מתלבטים איזה דירה לרכוש',
  Ready: 'מוכנים לרכישת דירה'
}

export interface Props {
  onClose: () => void
  isOpen: boolean
}

const Label = ({ text }: { text: string }) => {
  return (
    <Typography size='small' color='textPrimary' weight='bold'>
      {text}
    </Typography>
  )
}

const SellerTypeReminderModal = ({ isOpen, onClose }: Props): JSX.Element => {
  const formState = useFormState()
  const isCompactLayout = useBreakpoint(['small', 'medium'])

  return (
    <Modal open={isOpen}>
      <Modal.Title>
      דבר אחרון לפני שמסיימים
      </Modal.Title>
      <Modal.Content dividers>
        <Grid container direction='column' spacing={2}>
          <Grid item>
            <Typography>
            מה רמת המוכנות שלכם לרכישת דירה?
            </Typography>
          </Grid>
          <Grid item container wrap='nowrap' style={isCompactLayout ? {} : { width: '400px' }}>
          <Form.RadioGroup name='dealReadiness'>
            <Form.RadioButton value={DealReadiness.JustLooking.toString()} label={<Label text={DEAL_READINESS_COPY.JustLooking} />} />
            <Form.RadioButton value={DealReadiness.NotSure.toString()} label={<Label text={DEAL_READINESS_COPY.NotSure} />} />
            <Form.RadioButton value={DealReadiness.Ready.toString()} label={<Label text={DEAL_READINESS_COPY.Ready} />} />
          </Form.RadioGroup>
          </Grid>
        </Grid>
      </Modal.Content>
        <Modal.Actions>
          <CardActions style={{ width: '100%', justifyContent: 'flex-end' }}>
            <Button color='primary' variant='contained' disabled={!formState.values.dealReadiness} onClick={onClose}>
              <Typography weight='bold'>
              המשך
              </Typography>
            </Button>
          </CardActions>
        </Modal.Actions>
    </Modal>
  )
}

export default SellerTypeReminderModal
