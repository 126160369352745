import React from 'react'
import {
  FieldProps as FinalFieldProps,
  FieldRenderProps
} from 'react-final-form'

import BaseUploadFile, { Props as ToggleButtonProps } from '~/components/core/Inputs/UploadButtons/item'

export type FieldProps<TInputValue> = FinalFieldProps<
  TInputValue,
  FieldRenderProps<TInputValue, HTMLInputElement>,
  HTMLInputElement
>

export type Props = ToggleButtonProps & {
  name?: string
}

export const UploadFile: React.FC<Props> = (props) => {
  return (
    <BaseUploadFile {...props} />
  )
}

export default UploadFile
