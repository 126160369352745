import { createStyles, Theme } from '@material-ui/core/styles'

export const MySearchesListStyled = (theme: Theme) =>
createStyles({
  root: {
    width: '99.6%',
    backgroundColor: theme.palette.background.paper,
    boxShadow: `0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)`
  },
  nested: {
    paddingLeft: theme.spacing(4)
  }
})
