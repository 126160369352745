import {
  createMuiTheme,
  MuiThemeProvider,
  ThemeOptions
} from '@material-ui/core/styles'
import React, {
  FunctionComponent,
  ReactNode
} from 'react'
import { ModalProvider } from 'react-modal-hook'
import { Helmet } from 'react-helmet'
import { SnackbarProvider } from 'notistack'

import {
  palette,
  layout,
  typography,
  sizes
} from './config'
import FontsLoader from './FontsLoader'
import Provider from './UIProvider'
import RTLProvider from './RTLProvider'
import CssBaseline from './CssBaseline'

const ThemeUI = {
  palette,
  layout,
  sizes,
  typography,
  props: {
    MuiButtonBase: {
      disableRipple: true
    },
    MuiList: {
      disablePadding: true
    },
    MuiPaper: {
      square: true
    },
    MuiOutlinedInput: {
      notched: false
    }
  }
}

const UIProvider = new Provider(createMuiTheme(ThemeUI))
const RTLtheme = createMuiTheme({
  direction: 'rtl'
})

const Viewport = () => {
  const content = 'width=device-width, user-scalable=no'

  return (
    <Helmet>
      <meta name='viewport' content={content} />
      {/* <body dir={isRTL ? 'rtl' : 'rtl'} /> */}
      {/* <link rel='shortcut icon' href='https://storage.googleapis.com/hoome-imgs/app-assets/images/favicon/favicon-32x32.png' />
      <link rel='icon' href='https://storage.googleapis.com/hoome-imgs/app-assets/images/favicon/favicon.ico' /> */}
    </Helmet>
  )
}

interface HoomeUIProps {
  children?: ReactNode
  theme?: ThemeOptions
  isRTL?: boolean
}

const HoomeThemeProvider: FunctionComponent<HoomeUIProps> = ({
  children
}) => {
  const fullTheme = {
    ...RTLtheme,
    ...UIProvider.theme,
    direction: 'rtl'
  }

  return (
    <MuiThemeProvider theme={fullTheme}>
      <CssBaseline />
      <Viewport />
      <FontsLoader />
      <RTLProvider isRTL>
        <SnackbarProvider maxSnack={3}>
          <ModalProvider>{children}</ModalProvider>
        </SnackbarProvider>
      </RTLProvider>
    </MuiThemeProvider>
  )
}

export { HoomeThemeProvider }
export default HoomeThemeProvider
