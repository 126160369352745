import React from 'react'
import Tooltip from '@material-ui/core/Tooltip'
import Box from '@material-ui/core/Box'
import InfoIcon from '@material-ui/icons/InfoOutlined'
import { makeStyles } from '@material-ui/core/styles'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'

import Typography from '~/components/core/Typography'
import styles from './styles'

const blockedText = 'שלחו את המודעה כדי לחשוף את הדירה שלכם. מחפשים מתאימים שלא תשלחו אליהם את המודעה לא יראו אותה ולא יוכלו ליצור איתכם קשר.'

const useStyles = makeStyles(styles)

interface Props {
  children: React.ReactNode
}

const AssetLeadsTooltip = ({
  children
}: Props): JSX.Element => {
  const [open, setOpen] = React.useState(false);

  const handleTooltipClose = () => {
    setOpen(false)
  }

  const handleTooltipOpen = () => {
    setOpen(true)
  }
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <ClickAwayListener onClickAway={handleTooltipClose}>
        <Tooltip
          PopperProps={{
            disablePortal: true
          }}
          onClose={handleTooltipClose}
          open={open}
          placement='bottom-start'
          disableFocusListener
          disableHoverListener
          disableTouchListener
          title={
            <Typography>{blockedText}</Typography>
          }
        >
          <Box onClick={handleTooltipOpen}>
            <InfoIcon htmlColor='#4BB5AF' fontSize='small' />
          </Box>
        </Tooltip>
      </ClickAwayListener>
      <div className={classes.body}>
        {children}
      </div>
    </div>
  )
}

export default AssetLeadsTooltip
