import React, { memo } from 'react'
import moment from 'moment'
import { makeStyles } from '@material-ui/core/styles'
import ListItem from '@material-ui/core/ListItem'
import Grid from '@material-ui/core/Grid'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import Avatar from '@material-ui/core/Avatar'

import useSearchDetailsModal from '~/hooks/useSearchDetailsModal'
import { useBreakpoint } from '~/utils/breakpoints'
import Typography from '~/components/core/Typography'
import getInitials, { getFirstNameAndInitials } from '~/components/utils/getInitials'
import LeadsItemActionsButtons from '~/components/modules/LeadsItemActionsButtons'
import SearchLeadLabels from '~/components/modules/SearchLeadLabels'
import { Lead } from '~/types/Leads'
import * as S from './styles'

const useStyles = makeStyles(S.LeadItemStyled)

const COLORS: AvatarColors[] = ['pink', 'brown', 'green']

type AvatarColors = 'pink' | 'brown' | 'green'

const HE_LETTER = ['א', 'ב', 'ג', 'ד', 'ה', 'ו', 'ז', 'ח', 'ט', 'י', 'כ', 'ך', 'ל', 'מ', 'ם', 'נ', 'ן', 'ס', 'ע', 'פ', 'ף', 'צ', 'ץ', 'ק', 'ר', 'ש', 'ת']
const LETTERS = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z']
const ALL_LETTERS = [...LETTERS, ...HE_LETTER]

export const getRandomColor = ({ leadColor, initialsName }: { leadColor?: AvatarColors, initialsName: string }): AvatarColors => {
  if (leadColor && COLORS.includes(leadColor)) {
    return leadColor
  }

  const nameStr = ALL_LETTERS.includes(initialsName[0]) ? initialsName[0] : 'A'
  const nameSecondStr = ALL_LETTERS.includes(initialsName[1]) ? initialsName[1] : 'A'
  const colorIndex = (ALL_LETTERS.indexOf(nameStr) + ALL_LETTERS.indexOf(nameSecondStr)) % COLORS.length

  return COLORS[colorIndex]
}

export type ContactMethods = 'email' | 'phone'

export interface Props extends Lead {
  leadColor?: AvatarColors
  disabled?: boolean
  onRemove?: (id: string) => void
  onSendMessage?: () => void
  showMessageTooltip?: boolean
}

export const LeadsItem: React.FC<Props> = ({
  displayName,
  leadId,
  deviation,
  isNew,
  alreadyViewed,
  percentage,
  searchCreationDate,
  searcherBudget,
  timesOwnerContactedSearcher,
  onSendMessage,
  showMessageTooltip
}) => {
  const classes = useStyles()
  const initialsName = getInitials(displayName)
  const isCompactLayout = useBreakpoint(['small', 'medium'])
  const [searchDeatilsModal, openSearchDetailsModal] = useSearchDetailsModal(leadId, onSendMessage)

  const renderNameWithLabel = () => {
    if (isCompactLayout) {
      return (
        <Typography size='large'>{getFirstNameAndInitials(displayName)}</Typography>
      )
    }

    return (
      <Grid container spacing={2}>
        <Grid item>
          <Typography size='large'>{getFirstNameAndInitials(displayName)}</Typography>
        </Grid>
        <Grid item>
          <SearchLeadLabels viewed={alreadyViewed} size='xsmall' deviation={deviation} isNew={isNew} relative />
        </Grid>
      </Grid>
    )
  }

  return (
    <>
    <ListItem disableGutters={isCompactLayout}>
      <ListItemAvatar>
        <Avatar onClick={openSearchDetailsModal} className={classes.green}>{initialsName}</Avatar>
      </ListItemAvatar>
      <Grid container alignItems='center' justify='space-between'>
        <Grid item>
          <Grid container spacing={0} direction='column'>
            <Grid item>
              <Typography size='small' color='textSecondary'>{moment(new Date(searchCreationDate)).format('DD/MM/YY')}</Typography>
            </Grid>
            <Grid item style={{ marginTop: '-6px' }}>
              <ListItemText primary={renderNameWithLabel()} secondary={<Typography color='textPrimary'>{` תקציב עד ₪${Math.round(searcherBudget.max).toLocaleString()}`}</Typography>} color='secondary' />
            </Grid>
            <Grid item>
              <Typography color='textPrimary'>{`${Math.round(percentage * 100)}% התאמה`}</Typography>
            </Grid>
            {
              isCompactLayout && (
                <Grid item>
                  <SearchLeadLabels viewed={alreadyViewed} size='xsmall' deviation={deviation} isNew={isNew} relative />
                </Grid>
              )
            }
          </Grid>
        </Grid>
      </Grid>
      <LeadsItemActionsButtons
        onSearchDetails={openSearchDetailsModal}
        showMessageTooltip={showMessageTooltip}
        leadName={getFirstNameAndInitials(displayName)}
        leadId={leadId}
        sendCount={timesOwnerContactedSearcher}
        onSendMessage={onSendMessage}
      />
    </ListItem>
    {searchDeatilsModal}
    </>
  )
}

export default memo(LeadsItem)
