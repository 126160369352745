import React from 'react'
import { useParams } from 'react-router-dom'
import useFetch, { CachePolicies } from 'use-http'
import { Grid } from '@material-ui/core'

import { rollbarError } from '~/utils/rollbar'
import SellerTips from '~/components/modules/SellerTips'
import SellerTipsFab from '~/components/modules/SellerTips/fabButton'
import { SellerAsset } from '~/types/Asset'
import AssetDetails from '~/components/modules/AssetDetails'
import NoDataMessage from '~/components/modules/NoDataMessage'
import { useBreakpoint } from '~/utils/breakpoints'

const Asset: React.FC = () => {
  const { id } = useParams<{ id?: string }>() // api/properties/singleProperty
  const { loading, error, data } = useFetch<SellerAsset>(`/api/v3/assets/asset/${id}`, { cachePolicy: CachePolicies.NO_CACHE }, [id])
  // const { loading, error, data } = useFetch<SellerAsset>(`/api/properties/singleProperty/${id}`, { data: null, cachePolicy: CachePolicies.NO_CACHE }, [id])
  const isCompactLayout = useBreakpoint(['small', 'medium'])

  if (error) {
    rollbarError({
      error
    })

    return (
      <NoDataMessage type='asset' />
    )
  }

  if (!data) {
    return null
  }

  return (
    <Grid container spacing={4}>
      <Grid item container lg={8}>
        <AssetDetails seller item={data} loading={loading} />
      </Grid>
      {
        !isCompactLayout
        ? (
          <Grid item container lg={4} style={{ flex: 1, position: 'relative' }}>
            <SellerTips sticky id={id} pattern='tipsText.seller.leads' />
          </Grid>
        )
        : (
          <SellerTipsFab pattern='tipsText.seller.leads' />
        )
      }
    </Grid>
  )
}

export default Asset
