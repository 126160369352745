import React, {
  useContext,
  ReactNode
} from 'react'
import { useHistory } from 'react-router-dom'
import AppBar, { AppBarProps } from '@material-ui/core/AppBar'
import cx from 'classnames'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'

import { PageContext, PageContextProps } from '../'
import { getV3IconUri } from '~/utils/img-access'
import HideOnScroll from '~/components/core/HideOnScroll'
import Image from '~/components/core/Image'
import { useBreakpoint } from '~/utils/breakpoints'
import styles from './styles'

const hoomeIcon = getV3IconUri('v3-app-logo-green.png');
const hoomeIconPrimaryColor = getV3IconUri('v3-app-logo-dark-green.png');
const hoomeIconNoText = getV3IconUri('v3-logo-icon-dark-green.svg');

export interface Props extends AppBarProps {
  /** Title which is displayed along the `Logo` */
  title?: string
  /** Content for the left side of the `Header`  */
  leftContent?: ReactNode
  /** Content for the right side of the `Header`  */
  rightContent?: ReactNode
  /** Content for the center of the `Header`  */
  centerContent?: ReactNode
  /** Action items  */
  actionItems?: ReactNode
  /** should hide on scroll */
  hideOnScroll?: boolean
  /** Color variant */
  varient?: 'dark' | 'light'
  /** logo direction */
  logoAlign?: 'start' | 'center' | 'end'
}

const useStyles = makeStyles(styles, {
  name: 'PageHeader'
})

// eslint-disable-next-line complexity
export const PageHeader: React.FC<Props> = (props) => {
  const classes = useStyles(props)
  const {
    rightContent,
    leftContent,
    centerContent,
    actionItems,
    hideOnScroll = false,
    varient = 'dark',
    logoAlign = 'center',
    ...rest
  } = props
  const isCompactLayout = useBreakpoint(['small', 'medium'])
  const history = useHistory()

  const { width } = useContext<PageContextProps>(PageContext)

  const innerClassName = cx(
    {
      [classes.fullWidth]: width === 'full',
      [classes.wide]: width === 'wide'
    },
    classes[varient],
    classes.content
  )
  const wrapperClassnames = cx(
    classes.wrapper,
    classes[varient]
  )

  const iconType = isCompactLayout ? hoomeIconNoText : hoomeIconPrimaryColor
  const iconSrc = varient === 'light' ? iconType : hoomeIcon
  const invisibleEl = (logoAlign === 'center' && leftContent) ? <div style={{ visibility: 'hidden' }}>{leftContent}</div> : null
  const imageElm = <Image onClick={() => history?.push('')} alt='logo' src={iconSrc} className={classes.logo} />

  return (
    <HideOnScroll active={hideOnScroll}>
      <AppBar className={wrapperClassnames} {...rest} position={hideOnScroll ? 'fixed' : rest.position}>
        <Grid container className={innerClassName}>
          <Grid item className={classes.left}>
            {logoAlign === 'start' ? imageElm : (leftContent || invisibleEl)}
          </Grid>
          <Grid item className={classes.center}>
           {logoAlign === 'center' ? imageElm : centerContent}
          </Grid>

          <Grid item className={classes.right}>
            {!isCompactLayout && actionItems}
            {logoAlign === 'end' ? imageElm : (rightContent || invisibleEl)}
          </Grid>
        </Grid>
      </AppBar>
    </HideOnScroll>
  )
}
