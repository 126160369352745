import { useState } from 'react'

interface UseFilterLeads {
  handleOnFilterChange: (type: FilterType) => void,
  filtersState: FilterType[]
}

export type FilterType = 'deviation' | 'unreachable'

export interface FilterState {
  deviation?: boolean | undefined,
  unreachable?: boolean | undefined
}

// eslint-disable-next-line complexity
export default function useFilterLeads(): UseFilterLeads {
  const [filterBy, toggleFilter] = useState<FilterType[]>([])

  const handleOnFilterChange = (filterType: FilterType) => {
    if (filterBy.includes(filterType)) {
      return toggleFilter(filterBy.filter((item) => item !== filterType))
    }

    return toggleFilter([...filterBy, filterType])
  }

  return ({
    handleOnFilterChange,
    filtersState: filterBy
  })
}
