import { Theme, createStyles } from '@material-ui/core/styles'

import palette from '~/ThemeProvider/config/palette'

export default ({ transitions }: Theme) =>
  createStyles({
    root: {
      height: 'auto',
      borderBottom: `0 !important`,
      '&$selected': {
        backgroundColor: `${palette.grey.lightest} !important`,
        '&:hover': {
          backgroundColor: `${palette.grey.lightest} !important`
        }
      },

      '&$hover:hover': {
        backgroundColor: palette.blue.lightest
      }
    },
    bordered: {
      borderBottom: `1px solid ${palette.grey.lightest} !important`,
      backgroundColor: `${palette.common.white} !important !important`
    },
    transparent: {
      borderBottom: `1px solid ${palette.grey.lightest} !important`,
      backgroundColor: `transparent !important`
    },
    stripeEven: {
      background: () => `${palette.grey.lightest} !important`
    },
    hover: {
      transition: transitions.create('background-color', {
        duration: transitions.duration.shortest
      })
    },
    selected: {}
  })
