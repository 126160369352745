import React from 'react'
import { useTranslation } from 'react-i18next'
import ShareIcon from '@material-ui/icons/Share'

import Button from '~/components/core/Button'
import Typography from '~/components/core/Typography'
import { useModal } from '~/utils/useModal'
import ShareAssetModal from './ShareAssetModal'

export interface Props {
  assetId?: string
  secondary?: boolean,
  isSeller?: boolean
}

const ShareAsset: React.FC<Props> = ({
  assetId, secondary, isSeller
}) => {
  const { t } = useTranslation()
  const { showModal, hideModal, isOpen } = useModal()

  return (
    <>
    <Button onClick={showModal} size='small' color={secondary ? 'secondary' : 'primary'} startIcon={<ShareIcon />} variant='text'>
      <Typography color='textPrimary' size='medium'>
        {t('share')}
      </Typography>
    </Button>
    <ShareAssetModal isSeller={isSeller} isOpen={isOpen} onClose={hideModal} assetId={assetId} />
    </>
  )
}

export default ShareAsset
