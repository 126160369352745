import React, { ReactElement } from 'react'
import {
  useField,
  FieldProps as FinalFieldProps,
  FieldRenderProps
} from 'react-final-form'

import BaseRadioGroup, { Props as BaseRadioGroupProps } from '~/components/core/Inputs/RadioInput/RadioGroup'
import { Props as RadioButtonProps } from '~/components/core/Inputs/RadioInput/RadioButton'
import FormField from '../FormField'

export type FieldProps<TInputValue> = FinalFieldProps<
  TInputValue,
  FieldRenderProps<TInputValue, HTMLInputElement>,
  HTMLInputElement
>

export type Props = BaseRadioGroupProps & FieldProps<RadioButtonProps['value']>

export const RadioGroup: React.FC<Props> = (props) => {
  const { children, ...rest } = props
  const { meta, input } = useField(rest.name, { type: 'radio' })

  return (
    <FormField error={meta.touched && (meta.error || meta.submitError)}>
      <BaseRadioGroup {...rest} {...input}>
        {React.Children.toArray(children)
          .filter(React.isValidElement)
          .map(child =>
            React.cloneElement(child as ReactElement, {
              name: props.name
            })
          )}
      </BaseRadioGroup>
    </FormField>
  )
}

export default RadioGroup
