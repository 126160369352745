import React from 'react'
import { Grid } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

import Typography from '~/components/core/Typography'

const AboutUs: React.FC = () => {
  const { t } = useTranslation()
  const partsStrArr = t('aboutus', { returnObjects: true }) as string[]

  return (
    <>
    <Grid container direction='column' alignItems='center' spacing={2} style={{ marginTop: 80, marginBottom: 80 }}>
      <Grid item>
        <Typography weight='bold' style={{ fontSize: '1.8rem' }}>
          {t('link-about')}
        </Typography>
      </Grid>
      <Grid item>
        <Grid container direction='column' spacing={2} style={{ maxWidth: 600 }}>
          {
            partsStrArr.map((partStr, index) => (
              <Grid container item key={`aboutusKey-${index}`}>
                <Typography>{partStr}</Typography>
              </Grid>
            ))
          }
        </Grid>
      </Grid>
    </Grid>
    </>
  )
}

export default AboutUs
