import React, { useState } from 'react'
import { isEmpty } from 'lodash'

import { scrollToTop } from '~/utils/positions'
import Form from '~/components/core/Form'
import PostSearchMain from '~/components/modules/PostSearchMain'
import PostSearchAdvanced from '~/components/modules/PostSearchAdvanced'
import { SearchFormData } from '~/types/Search'
import useSearchFormValidation from '~/hooks/useSearchFormValidation'
import { getInitFormObj } from './helpers'
import FormWithMap from '~/components/core/FormWithMap'

export interface Props {
  onSubmit?: (data?: SearchFormData) => Promise<any>
}

const PostSearchForm: React.FC<Props> = ({ onSubmit = () => {} }) => {
  const [showAdvanced, setShowAdvanced] = useState<boolean>(false)
  const [formStatesStep, setFormStatesStep] = useState<SearchFormData | null>(null)
  const [validateForm] = useSearchFormValidation(showAdvanced)

  const handleOnSubmit = async (data: SearchFormData) => {
    const vlidation = validateForm(data)

    if (!isEmpty(vlidation)) {
      scrollToTop()

      return vlidation
    }

    if (!showAdvanced) {
      setFormStatesStep(data)
      setShowAdvanced(true)
      scrollToTop()

      return vlidation
    }

    const res = await onSubmit(data)

    return res
  }

  return (
    <Form initialValues={formStatesStep || getInitFormObj()} onSubmit={handleOnSubmit} validate={!showAdvanced ? validateForm : undefined} style={{ width: '100%' }}>
      <FormWithMap>
        {showAdvanced ? <PostSearchAdvanced onBack={() => setShowAdvanced(false)} /> : <PostSearchMain onSubmit={() => setShowAdvanced(true)} />}
      </FormWithMap>
    </Form>
  )
}

export default PostSearchForm
