import React from 'react'
import { Grid } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import FormatQuoteIcon from '@material-ui/icons/FormatQuote'

import Typography from '~/components/core/Typography'

export interface Props {
  textKey: string
}

const SellerTips: React.FC<Props> = ({ textKey }) => {
  const { t } = useTranslation()

  return (
    <>
    <Grid container spacing={1} wrap='nowrap' style={{ padding: '12px' }}>
      <Grid item>
        <FormatQuoteIcon color='secondary' />
      </Grid>
      <Grid item container>
        <Typography>
          {t(textKey)}
        </Typography>
      </Grid>
    </Grid>
    </>
  )
}

export default SellerTips
