import React from 'react'
import { Box } from '@material-ui/core'

import Typography from '~/components/core/Typography'
import { PlanColor, COLOR_MAP } from '../'

const PlanSellerBodyTextCell = ({
  text,
  color,
  subText
}: {
  text: string
  subText?: string
  color: PlanColor
}): JSX.Element => {
  return (
    <Box display='flex' justifyContent='center' flexDirection='column' alignItems='center'>
      <Typography style={{ fontSize: '18px', color: COLOR_MAP[color] }} weight='bold'>
        {text}
      </Typography>
      {
        subText && (
          <Typography style={{ fontSize: '12px', color: COLOR_MAP[color] }} weight='bold'>
            {subText}
          </Typography>
        )
      }
    </Box>
  )
}

export default PlanSellerBodyTextCell
