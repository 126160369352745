import React from 'react'
import numeral from 'numeral'
import { makeStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import Grid from '@material-ui/core/Grid'
import CardActionArea from '@material-ui/core/CardActionArea'
import CardContent from '@material-ui/core/CardContent'
import { useTranslation } from 'react-i18next'

import PercentageBox from '~/components/modules/PercentageBox'
import Typography from '~/components/core/Typography'
import SearchLeadLabels from '~/components/modules/SearchLeadLabels'
import ImagesGallery from '~/components/modules/ImageGallery'
import * as S from './styles'
import { Props } from '../'
import AssetCardActions from '../Actions'

const useStyles = makeStyles(S.AssetCardStyled)

const AssetCard: React.FC<Props> = ({ item, onMouseEnter, onMouseLeave, allowFullScreen = true, onClick = () => {}, onRemove }) => {
  const { t } = useTranslation()
  const classes = useStyles()

  return (
    <Card className={classes.root} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} onClick={() => onClick(item.leadId)}>
      <div className={classes.media}>
        <SearchLeadLabels isNew={item.isNew} deviation={item.deviation} />
        <ImagesGallery
          images={item.images}
          customClass='is-wide'
          allowFullScreen={allowFullScreen}
        />
      </div>
      <CardActionArea component='div'>
      <Grid className={classes.content} container alignItems='stretch' wrap='nowrap' justify='space-between' direction='column'>
        <Grid item>
          <CardContent>
            <Grid spacing={2} container alignItems='stretch' justify='space-between' direction='column'>
              <Grid item>
                <Grid container alignItems='stretch' justify='space-between'>
                  <Grid item>
                    <Typography size='xlarge' gutterBottom variant='h2'>
                      {item.address}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item container className={classes.infoItems}>
                <Grid container alignItems='stretch' justify='space-between'>
                  <Grid item>
                    <Typography size='medium' gutterBottom variant='h5'>
                      {item.rooms} {t('rooms')}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography size='medium' gutterBottom variant='h5'>
                      {item.squareSize} {t('squareSize')}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography size='medium' gutterBottom variant='h5'>
                      {numeral(item.price).format('0,0')} ₪
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <PercentageBox percentage={item.percentage} />
              </Grid>
            </Grid>
          </CardContent>
        </Grid>
        <Grid item>
          <AssetCardActions item={item} onRemove={onRemove} />
        </Grid>
      </Grid>
      </CardActionArea>
    </Card>
  )
}

export default AssetCard
