import React, { useEffect, useState } from 'react'
import { Grid, CardActions } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { Prompt, useHistory } from 'react-router-dom'

import { useModal } from '~/utils/useModal'
import Modal from '~/components/core/Modal'
import Button from '~/components/core/Button'
import Typography from '~/components/core/Typography'

export interface Props {
  when: boolean
  mainText?: string
  shouldBlockNavigation: () => boolean
  onLeavingPage?: () => void
}

const DirtyFormModal: React.FC<Props> = ({ when, mainText, shouldBlockNavigation, onLeavingPage }) => {
  const { t } = useTranslation()
  const history = useHistory()
  const [lastLocation, setLastLocation] = useState<string>()
  const [confirmedNavigation, setConfirmedNavigation] = useState(false)
  const { showModal, hideModal, isOpen } = useModal()

  const handleConfirmNavigationClick = () => {
    hideModal()
    setConfirmedNavigation(true)
  }

  const handleBlockedNavigation = (locationObj: any) => {
    if (!confirmedNavigation && shouldBlockNavigation()) {
      showModal()
      setLastLocation(locationObj.pathname)

      return false
    }

    return true
  }

  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      // Navigate to the previous blocked location with your navigate function
      if (onLeavingPage) {
        onLeavingPage()
      } else {
        history.push(lastLocation)
      }
    }
  }, [confirmedNavigation, lastLocation])

  return (
    <>
    <Prompt when={when} message={handleBlockedNavigation} />
    <Modal open={isOpen}>
      <Modal.Title onClose={hideModal}>
        הודעה
      </Modal.Title>
      <Modal.Content dividers>
        <Grid container direction='column' spacing={1}>
          <Grid item>
            <Typography size='large'>
              {mainText}
            </Typography>
          </Grid>
        </Grid>
      </Modal.Content>
        <Modal.Actions>
          <CardActions style={{ width: '100%' }}>
            <Button size='small' color='primary' variant='text' onClick={hideModal}>
              <Typography size='small'>
                {t('cancel-label')}
              </Typography>
            </Button>
            <Button size='small' color='primary' variant='text' onClick={handleConfirmNavigationClick}>
              <Typography size='small'>
              {t('accept-label')}
              </Typography>
            </Button>
          </CardActions>
        </Modal.Actions>
    </Modal>
    </>
  )
}

export default DirtyFormModal
