import React from 'react'
import {
  useField,
  FieldProps as FinalFieldProps,
  FieldRenderProps
} from 'react-final-form'

import BaseSelect, { Props as BaseSelectProps } from '~/components/core/Inputs/Select'
import LabelWrapper from '../../LabelWrapper'
import FormField from '../FormField'

export type FieldProps<TInputValue> = FinalFieldProps<
  TInputValue,
  FieldRenderProps<TInputValue, HTMLInputElement>,
  HTMLInputElement
>

export type Props = BaseSelectProps & FieldProps<BaseSelectProps['value']> & {
  label?: string | undefined
}

export const Select: React.FC<Props> = (props) => {
  const { meta, input } = useField(props.name)
  const error = meta.touched && (meta.error || meta.submitError)

  return (
    <FormField error={error}>
      <LabelWrapper label={props.label} direction='column'>
        <BaseSelect {...props} {...input} name={input.name} error={!!error} />
      </LabelWrapper>
    </FormField>
  )
}

export default Select
