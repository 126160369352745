import React from 'react'
import { Grid } from '@material-ui/core'

import Typography from '~/components/core/Typography'

const PlanSellerHeaderFeatures = (): JSX.Element => {
  return (
    <Grid
      direction='column'
      alignItems='stretch'
      justify='center'
      spacing={2}
      container
    >
      <Grid item>
        <Typography style={{ fontSize: '18px', color: '#252430' }} weight='bold'>
        מוצרים
        </Typography>
      </Grid>
      <Grid item>
      <Typography style={{ fontSize: '14px', color: '#858BA0' }}>
      בחרו את התוכנית שמתאימה לכם ביותר
        </Typography>
      </Grid>
    </Grid>
  )
}

export default PlanSellerHeaderFeatures
