import { createStyles } from '@material-ui/core/styles'

import palette from '~/ThemeProvider/config/palette'

export const Styled = createStyles({
  root: {
    paddingTop: 10,
    paddingBottom: 10
  },
  avatar: {
    backgroundColor: palette.secondary.dark
  }
})
