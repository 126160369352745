import { Theme, createStyles } from '@material-ui/core/styles'

import { screens } from '~/utils/breakpoints'

export default ({ layout }: Theme) =>
  createStyles({
    root: {
      flex: 1,
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    content: {
      height: '100%',
      flexGrow: 1,
      maxWidth: layout.contentWidthWide,
      padding: '0 1rem',
      [screens('small', 'medium')]: {
        padding: '0 0.4rem'
      }
    },
    wide: {
      maxWidth: layout.contentWidth,
      [screens('small', 'medium')]: {
        maxWidth: '100%',
        boxSizing: 'border-box'
      }
    },
    fullWidth: {
      maxWidth: '100%',
      padding: 0,
      [screens('small', 'medium')]: {
        boxSizing: 'border-box'
      }
    },
    flex: {
      display: 'flex'
    }
  })
