import { SearchFormData } from '~/types/Search'

export const getInitFormObj = (): SearchFormData => ({
  searchType: undefined,
  city: undefined,
  neighborhoods: [],
  budget: {
    min: 0,
    max: undefined
  },
  squareSize: {
    min: 0,
    max: undefined
  },
  rooms: {
    min: 0,
    max: undefined
  },
  communications: 1,
  allowAgent: false,
  contactNumber: '',
  balcony: {
    value: 1,
    importance: undefined
  },
  elevator: {
    value: 1,
    importance: undefined
  },
  floor: {
    value: 1,
    importance: undefined
  },
  furnished: {
    value: 1,
    importance: undefined
  },
  garden: {
    value: 1,
    importance: undefined
  },
  hadicap: {
    value: 1,
    importance: undefined
  },
  panthouse: {
    value: 1,
    importance: undefined
  },
  parking: {
    value: 1,
    importance: undefined
  },
  remodel: {
    value: 0,
    importance: undefined
  },
  safeRoom: {
    value: 1,
    importance: undefined
  },
  storage: {
    value: 1,
    importance: undefined
  },
  view: {
    value: 1,
    importance: undefined
  }
})
