import React, { SVGProps } from 'react'
const NonPublished = (props: SVGProps<SVGSVGElement>): JSX.Element => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={16}
    height={16}
    fill='none'
    {...props}
    color={props.color || 'inherit'}
  >
    <path
      fill='currentcolor'
      d='M8 1.6c3.528 0 6.4 2.872 6.4 6.4a6.346 6.346 0 0 1-.896 3.248l1.168 1.168A8.003 8.003 0 0 0 8 0a7.928 7.928 0 0 0-4.408 1.328L4.76 2.496A6.295 6.295 0 0 1 8 1.6Zm2.328 6.464L12.4 3.6 7.936 5.672l2.392 2.392ZM.08 2.344l1.248 1.248A7.928 7.928 0 0 0 0 8c0 4.416 3.584 8 8 8a7.928 7.928 0 0 0 4.408-1.328l1.248 1.248 1.128-1.128L1.208 1.208.08 2.344Zm2.416 2.408L5.68 7.936 3.6 12.4l4.464-2.072 3.184 3.184c-.96.56-2.064.888-3.248.888A6.408 6.408 0 0 1 1.6 8c0-1.184.328-2.288.896-3.248Z'
    />
  </svg>
)

export default NonPublished
