import { createStyles } from '@material-ui/core/styles'

import palette from '~/ThemeProvider/config/palette'

export const FloatingBottomBarStyled = createStyles({
  root: {
    position: 'fixed',
    bottom: 0,
    zIndex: 999,
    right: 0,
    left: 0,
    backgroundColor: palette.common.white,
    borderTop: '1px solid rgb(221, 221, 221)'
  }
})
