import React, { useState, useRef } from 'react'

import { useModal } from '~/utils/useModal'
import AlreadyContactedWarningModal from '~/components/modules/AlreadyContactedWarningModal'
// import useLeadContactDetails from '~/hooks/useLeadContactDetails'
import { ContactMethods } from '~/types/Leads'

type Params = {
  leadId: string,
  email?: string
  phoneNumber?: string
  onSubmit?: () => void
  sendCount?: number
}

export default function useLeadContactWithWarning({
  // leadId,
  // email,
  // phoneNumber,
  onSubmit,
  sendCount
}: Params): [(JSX.Element | null), () => void] {
  const curretTarget = useRef<ContactMethods | null>(null)
  // const [emailVal, setEmail] = useState<string | undefined>(email)
  // const [phoneNumberVal, setPhoneNumber] = useState<string | undefined>(phoneNumber)
  const [aleadryContacted, setAleadryContacted] = useState<boolean>(false)
  // const { getContactDetails } = useLeadContactDetails({
  //   leadId,
  //   email: emailVal,
  //   phoneNumber: phoneNumberVal,
  //   onEmailSuccess: setEmail,
  //   onPhoneNumberSuccess: setPhoneNumber
  // })
  const { showModal, hideModal, isOpen } = useModal()

  const handleOnSubmit = () => {
    hideModal()

    curretTarget.current = null
    onSubmit?.()
  }

  const handleOnContactDetails = () => {
    if (Number(sendCount) || aleadryContacted) {
      showModal()
    } else {
      setAleadryContacted(true)
      if (onSubmit) {
        onSubmit()
      }
    }
  }

  const warningModal = (
    <AlreadyContactedWarningModal
      onClose={hideModal}
      onSubmit={handleOnSubmit}
      isOpen={isOpen}
    />
  )

  return [warningModal, handleOnContactDetails]
}
