import React from 'react'
import { Grid } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import FilterListIcon from '@material-ui/icons/FilterList'

import { useBreakpoint } from '~/utils/breakpoints'
import SortingButton from '~/components/core/SortingButton'
import Typography from '~/components/core/Typography'
import { FilterType } from '~/hooks/useFilterLeads'

export interface Props {
  onFilterChange?: (type: 'unreachable' | 'deviation') => void
  filtersState: FilterType[]
}

// eslint-disable-next-line complexity
const SellerLeadsFilters: React.FC<Props> = ({
  onFilterChange,
  filtersState
}) => {
  const isCompactLayout = useBreakpoint(['small', 'medium'])
  const { t } = useTranslation()

  return (
    <Grid
      container
      spacing={2}
      alignItems={isCompactLayout ? 'flex-start' : 'center'}
      direction='row'
      wrap='nowrap'
    >
      <Grid item style={{ minWidth: '70px' }}>
      <Grid container spacing={0} alignItems='center' wrap='nowrap'>
        <Grid item>
          <FilterListIcon />
        </Grid>
        <Grid item style={{ minWidth: '60px' }}>
          <Typography size='large'>
            {t('filter')}
          </Typography>
        </Grid>
      </Grid>
      </Grid>
      <Grid item>
        <Grid container spacing={2}>
        <Grid item>
          <SortingButton size='small' noIcon label='סטיית מחיר' active={filtersState.includes('deviation')} onClick={() => onFilterChange?.('deviation')} />
        </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default SellerLeadsFilters
