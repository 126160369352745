import React from 'react'
import {
  useField,
  FieldProps as FinalFieldProps,
  FieldRenderProps
} from 'react-final-form'
import { useTranslation } from 'react-i18next'

import { FORBIDEN_CHARS } from '~/hooks/useAssetFormValidation'
import BaseMultiLocation, { Props as BaseLocationProps, LocationData } from '~/components/core/Inputs/LocationMultiSelectInput'
import FormField from '../FormField'

export type FieldProps<TInputValue> = FinalFieldProps<
  TInputValue,
  FieldRenderProps<TInputValue, HTMLInputElement>,
  HTMLInputElement
>

export type Props = BaseLocationProps & FieldProps<BaseLocationProps['value']>

// eslint-disable-next-line complexity
export const MultiLocation = React.forwardRef<HTMLDivElement, Props>((props, ref) => {
  const { t } = useTranslation()
  const { meta, input } = useField(props.name)
  const errChars = (props.allowFreeText && FORBIDEN_CHARS.filter((char) => input.value?.name?.includes(char)).length) ? t('error-ilagalChars') : undefined
  const error = errChars || (meta.touched && (meta.error || meta.submitError))
  const handleOnSelect = (data: LocationData[]) => {
    input.onChange(data)
  }

  return (
    <FormField error={error}>
      <BaseMultiLocation {...props} {...input} value={input.value?.name || ''} name={input.name} onSelect={handleOnSelect} error={error} ref={ref} />
    </FormField>
  )
})

export default MultiLocation
