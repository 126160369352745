import firebase from 'firebase/app'

type FirebaseConfigObj = {
    apiKey: string | undefined,
    authDomain: string | undefined,
    databaseURL: string | undefined,
    projectId: string | undefined,
    storageBucket: string | undefined,
    messagingSenderId: string | undefined,
    appId: string | undefined,
    measurementId: string | undefined
}

export const getConfigObj = (): FirebaseConfigObj => {
    return {
        apiKey: process.env.FIREBASE_APIKEY,
        authDomain: process.env.FIREBASE_AUTHDOMAIN,
        databaseURL: process.env.FIREBASE_DATABASEURL,
        projectId: process.env.FIREBASE_PROJECTID,
        storageBucket: process.env.FIREBASE_STORAGEBUCKET,
        messagingSenderId: process.env.FIREBASE_MESSAGINGSENDERID,
        appId: process.env.FIREBASE_APPID,
        measurementId: process.env.FIREBASE_MEASUREMENTID
    }
}

export const hasVerifiedProvider = (providerData: (firebase.UserInfo | null)[]): boolean => {
  const VERIFIED_PROVIDERS = ['google.com', 'facebook.com']

  return providerData?.some((provider) => VERIFIED_PROVIDERS.includes(provider?.providerId || ''))
}

export const shouldVerifyEmail = (user: firebase.User): boolean => {
  if (user.emailVerified) {
    return false
  }

  return !hasVerifiedProvider(user.providerData)
}
