import React from 'react'
import { CardActions, Grid } from '@material-ui/core'
import MailOutlineIcon from '@material-ui/icons/MailOutline'
import { useTranslation } from 'react-i18next'
import Paper from '@material-ui/core/Paper'
import { makeStyles } from '@material-ui/core/styles'
import WhatsAppIcon from '@material-ui/icons/WhatsApp'

import Button from '~/components/core/Button'
import useContactMessage from '~/hooks/useContactMessage'
import Modal from '~/components/core/Modal'
import Typography from '~/components/core/Typography'
import FloatingBottomBar from '~/components/core/FloatingBottomBar'
import { useBreakpoint } from '~/utils/breakpoints'
import { BuyerAsset } from '~/types/Asset'
import * as S from './styles'

const useStyles = makeStyles(S.ContactActionsStyled)

export interface Props {
  isModal?: boolean
  item: BuyerAsset
  isPublic?: boolean
}

const AssetsDetailsContactsActions: React.FC<Props> = ({ isModal, item, isPublic }) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const { buyerToSellerEmail, buyerToSellerWA } = useContactMessage(isPublic)
  const isCompactLayout = useBreakpoint(['small', 'medium'])

  const handleOnContactPhoneClick = (e: React.MouseEvent) => {
    e.stopPropagation()

    buyerToSellerWA({
      leadId: isPublic ? item.id : item.leadId || '',
      address: item.address,
      city: item.city,
      phoneNumber: item.contactNumber || ''
    })
  }

  const handleOnContactEmailClick = (e: React.MouseEvent) => {
    e.stopPropagation()

    buyerToSellerEmail({
      leadId: isPublic ? item.id : item.leadId || '',
      address: item.address,
      city: item.city,
      email: item.contactEmail || ''
    })
  }

  if (isModal) {
    return (
      <Modal.Actions>
        <CardActions className={classes.actionsBar}>
          <Typography size='small'>
            פנה למפרסם:
          </Typography>
          <Button size='small' color='primary' endIcon={<MailOutlineIcon />} variant='text' onClick={handleOnContactEmailClick}>
            <Typography size='small'>
              {t('emailLabel')}
            </Typography>
          </Button>
          <Button size='small' color='primary' endIcon={<WhatsAppIcon />} onClick={handleOnContactPhoneClick} variant='text'>
            <Typography size='small'>
              {t('label-WhatsApp')}
            </Typography>
          </Button>
        </CardActions>
      </Modal.Actions>
    )
  }

  if (!isCompactLayout) {
    return (
      <Paper elevation={3} className={classes.paperRoot}>
        <Grid container direction='column' spacing={1} alignItems='center' justify='center'>
          <Grid item>
            <Typography size='large'>
              פנה למפרסם:
            </Typography>
          </Grid>
          <Grid item container style={{ marginTop: '10px' }}>
            <Button size='small' color='primary' endIcon={<MailOutlineIcon />} onClick={handleOnContactEmailClick} variant='outlined' fullWidth>
              <Typography size='medium'>
                {t('emailLabel')}
              </Typography>
            </Button>
          </Grid>
          <Grid item container>
            <Button size='small' color='primary' endIcon={<WhatsAppIcon />} onClick={handleOnContactPhoneClick} variant='outlined' fullWidth>
              <Typography size='medium'>
                {t('label-WhatsApp')}
              </Typography>
            </Button>
          </Grid>
        </Grid>
      </Paper>
    )
  }

  return (
    <FloatingBottomBar>
      <CardActions className={classes.actionsBar}>
        <Typography size='small'>
          פנה למפרסם:
        </Typography>
        <Button size='small' color='primary' endIcon={<MailOutlineIcon />} onClick={handleOnContactEmailClick} variant='text'>
          <Typography size='small'>
            {t('emailLabel')}
          </Typography>
        </Button>
        <Button size='small' color='primary' endIcon={<WhatsAppIcon />} onClick={handleOnContactPhoneClick} variant='text'>
          <Typography size='small'>
            {t('label-WhatsApp')}
          </Typography>
        </Button>
      </CardActions>
    </FloatingBottomBar>
  )
}

export default AssetsDetailsContactsActions
