import React, { useState } from 'react'
import Grid from '@material-ui/core/Grid'

import useFilesToImagesString from '~/hooks/useFilesToImagesString'
import UploadButton from '../item'

export type DataProps = FileList | (string | File)[]

export interface Props {
  filesArr?: string[]
  onChange?: (files: DataProps) => void
}

const MAX_IMAGES = 7

const UploadButtonsGroup: React.FC<Props> = ({ filesArr = [], onChange = () => {} }) => {
  const [currentFiles, setFiles] = useState<(string | File)[] | FileList>(filesArr)
  const [fliesStrArr] = useFilesToImagesString(currentFiles)

  const handleOnReset = (index: number) => {
    const arr = Array.from(currentFiles).filter((_file, indx) => indx !== index)

    setFiles(arr)
    onChange(arr)
  }

  const handleOnChange = (files: FileList) => {
    const newFiles = [...Array.from(files)]
    const arr = [...Array.from(currentFiles), ...newFiles]

    if (arr.length > MAX_IMAGES) {
      return alert('Cannot have more then 7 images')
    }

    setFiles(arr)
    onChange(arr)
  }

  const renderSmallInputs = (start: number, end: number) => {
    const arr = []

    for (let index = start; index < end; index++) {
      const file = fliesStrArr[index]
      const elm = (
        <Grid item>
          <UploadButton
            onReset={() => handleOnReset(index)}
            image={file}
            onChange={handleOnChange}
            size='small'
          />
        </Grid>
      )

      arr.push(elm)
    }

    return arr
  }

  return (
    <Grid
      direction='column'
      spacing={2}
      container
    >
      <Grid item>
        <UploadButton
          onReset={() => handleOnReset(0)}
          image={fliesStrArr[0]}
          onChange={handleOnChange}
          size='large'
        />
      </Grid>
      <Grid item>
        <Grid
          direction='column'
          container
          spacing={2}
        >
          <Grid
            spacing={2}
            style={{ padding: '5px 8px' }}
            container
            wrap='nowrap'
            justify='space-between'
          >
            {renderSmallInputs(1, 4)}
          </Grid>
          <Grid
            spacing={2}
            style={{ padding: '5px 8px' }}
            container
            wrap='nowrap'
            justify='space-between'
          >
            {renderSmallInputs(4, 7)}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default UploadButtonsGroup
