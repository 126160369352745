import React from 'react'
import {
  BrowserRouter,
  Route,
  Switch
} from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import PageLoader from '~/components/core/PageLoader'
import PublicHoome from './public'
import HoomeRouter from './router'
import VerifyEmail from '~/components/pages/VerifyEmail'

const Hoome: React.FC = () => {
  const { ready } = useTranslation()

  if (!ready) {
    return <PageLoader invisible open />
  }

  return (
    <BrowserRouter>
      <Switch>
        <Route path='/verifyEmail' exact>
          <VerifyEmail />
        </Route>
        <Route path='/public'>
          <PublicHoome />
        </Route>

        {/* public asset details */}
        <Route path='/'>
          <HoomeRouter />
        </Route>
      </Switch>
    </BrowserRouter>
  )
}

export default Hoome
