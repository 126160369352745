import React from 'react'
import Fab from '@material-ui/core/Fab'
import WbIncandescentOutlinedIcon from '@material-ui/icons/WbIncandescentOutlined'
import { makeStyles } from '@material-ui/core/styles'

import SellerTipsModal, { Props as SellerTipsModalProps } from '../modal'
import { useModal } from '~/utils/useModal'
import * as S from './styles'

const useStyles = makeStyles(S.Styled)

interface Props {
  pattern?: SellerTipsModalProps['pattern']
}

const SellerTipsFab: React.FC<Props> = ({ pattern }) => {
  const classes = useStyles()
  const { showModal, hideModal, isOpen } = useModal()

  return (
    <>
    <Fab
      aria-label='tips'
      size='small'
      onClick={showModal}
      className={classes.root}
    >
      <WbIncandescentOutlinedIcon color='secondary' className={classes.icon} />
    </Fab>
    <SellerTipsModal
      open={isOpen}
      onClose={hideModal}
      pattern={pattern}
    />
    </>
  )
}

export default SellerTipsFab
