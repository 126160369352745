import React from 'react'
import Grid from '@material-ui/core/Grid'

import NumberInput, { Props as NumberInputProps } from '../NumberInput'
import LabelWrapper, { Props as LabelProps } from '../../LabelWrapper'

export type RangeValue = {
  min: number | string | undefined
  max: number | string | undefined
}

export interface Props {
  label?: React.ReactNode
  value?: RangeValue
  onChange?: (val: RangeValue) => void
  inputProps?: NumberInputProps
  minLabel?: string
  maxLabel?: string
  name?: string
  labelProps?: LabelProps
}

const RangeNumbers: React.FC<Props> = ({
  onChange = () => {}, value, label = 'Range', inputProps = {}, minLabel, maxLabel, name, labelProps = {}
}) => {
  const handleOnChangeMin = (values: NumberInputProps['NumberFormatValues']) => {
    const { floatValue } = values

    onChange({
      min: floatValue,
      max: value?.max
    })
  }

  const handleOnChangeMax = (values: NumberInputProps['NumberFormatValues']) => {
    const { floatValue } = values

    onChange({
      min: value?.min || 0,
      max: floatValue
    })
  }

  return (
    <LabelWrapper label={label} direction='column' {...labelProps}>
      <Grid direction='row' container spacing={2} alignItems='stretch'>
        <Grid item>
          <LabelWrapper label={minLabel}>
            <NumberInput {...inputProps} name={name} onValueChange={handleOnChangeMin} value={value?.min} />
          </LabelWrapper>
        </Grid>
        <Grid item>
          <LabelWrapper label={maxLabel}>
            <NumberInput {...inputProps} name={name} onValueChange={handleOnChangeMax} value={value?.max} />
          </LabelWrapper>
        </Grid>
      </Grid>
    </LabelWrapper>
  )
}

export default RangeNumbers
