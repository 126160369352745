import { useTranslation } from 'react-i18next'

import { Props as SelecProps } from '~/components/core/Inputs/Select'

export default function useSelectOptions(): {getOptions: (options: SelecProps['options']) => SelecProps['options']} {
  const { t } = useTranslation()

  const getOptions = (options: SelecProps['options']) => {
    return options.map((opt) => ({ ...opt, text: t(opt.text) }))
  }

  return { getOptions }
}
