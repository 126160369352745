import React from 'react'
import { Grid } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

import Typography from '~/components/core/Typography'

const policyParts = ['general', 'setting', 'copyright', 'safety', 'payments', 'services', 'warranty', 'misc'] as const
const pravicyParts = [
  'privacy-general', 'privacy-setting',
  'privacy-host-service', 'privacy-registry',
  'privacy-data', 'privacy-third-party',
  'privacy-research', 'privacy-data-securty',
  'privacy-data-read', 'privacy-misc'
] as const

type PolicyParts = typeof policyParts[number]

interface Props {
  policyPartKey: PolicyParts
}

const PolicySection: React.FC<Props> = ({ policyPartKey }) => {
  const { t } = useTranslation()

  const partsStrArr = t(`${policyPartKey}.parts`, { returnObjects: true }) as string[]

  if (!Array.isArray(partsStrArr)) {
    return null
  }

  return (
    <Grid container direction='column' spacing={1} style={{ padding: 10, width: '100%' }}>
      <Grid item>
        <Typography size='large'>{t(`${policyPartKey}.title`)}</Typography>
      </Grid>
      {
        partsStrArr.map((partStr, index) => (
          <Grid container item key={`policyPartKey-${index}`}>
            <Typography>{partStr}</Typography>
          </Grid>
        ))
      }
    </Grid>
  )
}

const Policy: React.FC = () => {
  const { t, ready } = useTranslation()
  const topSection = t(`policyPage.parts`, { returnObjects: true }) as string[]

  if (!ready) {
    return null
  }

  return (
    <>
    <Grid container direction='column' alignItems='center' spacing={2} style={{ marginTop: 80, marginBottom: 80 }}>
      <Grid item>
        <Typography weight='bold' style={{ fontSize: '1.8rem' }}>
          {t('link-policy')}
        </Typography>
      </Grid>
      <Grid item>
        <Grid container direction='column' spacing={2} style={{ maxWidth: 600 }}>
          <Grid item>
            <Grid container direction='column' spacing={1} style={{ padding: 10, width: '100%' }}>
              {
                topSection.map((partStr, index) => (
                  <Grid container item key={`policyPartKey-${index}`}>
                    <Typography>{partStr}</Typography>
                  </Grid>
                ))
              }
            </Grid>
          </Grid>
          <Grid item>
            <Typography weight='bold' style={{ fontSize: '1.2rem', padding: '10px' }}>
              תנאי שימוש
            </Typography>
          </Grid>
          {
            policyParts.map((part) => (
              <Grid item key={part} id={part}>
                <PolicySection policyPartKey={part} />
              </Grid>
            ))
          }
          <Grid item>
            <Typography weight='bold' style={{ fontSize: '1.2rem', padding: '10px' }}>
            מדיניות פרטיות
            </Typography>
          </Grid>
          {
            pravicyParts.map((part) => (
              <Grid item key={part} id={part}>
                <PolicySection policyPartKey={part as any} />
              </Grid>
            ))
          }
          <Grid item style={{ paddingRight: 18 }}>
            <Typography>
              {t('mnr')}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
    </>
  )
}

export default Policy
