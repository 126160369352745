import React, { ReactNode } from 'react'
import { withStyles } from '@material-ui/core/styles'
import MUIButton, { ButtonProps } from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'

import * as S from './styles'

export interface Props
  extends ButtonProps {
  children?: ReactNode
  noWrap?: boolean
  iconButton?: ReactNode
}

const StyledButton = withStyles(S.StyledButton)(MUIButton)

const Button: React.FC<Props> = ({
  children,
  variant = 'contained',
  iconButton,
  ...rest
}) => {
  if (iconButton) {
    const { onClick } = rest
    const size = rest.size === 'large' ? 'medium' : rest.size

    return (
      <IconButton ref={rest.ref} onClick={onClick} disabled={rest.disabled} color={rest.color} aria-label={rest['aria-label']} size={size}>
        {iconButton}
      </IconButton>
    )
  }

  return (
    <StyledButton
      variant={variant}
      {...rest}
    >
      {children}
    </StyledButton>
  )
}

export default Button
