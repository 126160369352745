import React, { useState } from 'react'
import useFetch, { CachePolicies } from 'use-http'
import { useTranslation } from 'react-i18next'
import Box from '@material-ui/core/Box'
import Tooltip from '@material-ui/core/Tooltip'
import InfoIcon from '@material-ui/icons/Info'
import Grid from '@material-ui/core/Grid'
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone'

import AssetDetailRawField from '~/components/modules/AssetDetailRawField'
import Typography from '~/components/core/Typography'
import Select from '~/components/core/Inputs/Select'
import { AuthProvider } from '~/components/core/AuthProvider/context'
import { ViewerProvider } from '~/components/core/ViewerProvider/context'

const SELECT_OPTIONS = [
  {
    value: 'whatsapp',
    text: 'וואטסאפ'
  },
  {
    value: 'email',
    text: 'אימייל'
  },
  {
    value: 'sms',
    text: 'SMS'
  }
]

interface Props {
  onSumbitDone?: () => void
  avoidSubmitOnChange?: boolean
  onChange?: (value: string[]) => void
  initValues?: string[]
}

export const getinitOptions = (userDetails?: any) => {
  const options = []

  if (userDetails?.whatsappNotifications) options.push('whatsapp')
  if (userDetails?.mailNotifications) options.push('email')
  if (userDetails?.smsNotifications) options.push('sms')

  return options
}

interface Payload {
  userId?: string
  mailNotifications: boolean
  whatsappNotifications: boolean
  smsNotifications: boolean
}

export const getPayloadValues = ({
  userId,
  values
}: {
  userId?: string,
  values: string[]
}): Payload => {
  const payload = {
    userId,
    mailNotifications: false,
    whatsappNotifications: false,
    smsNotifications: false
  }

  values.forEach((item: any) => {
    if (item === 'whatsapp') payload.whatsappNotifications = true
    if (item === 'email') payload.mailNotifications = true
    if (item === 'sms') payload.smsNotifications = true
  })

  return payload
}

const UserNotificationSwitch: React.FC<Props> = ({
  avoidSubmitOnChange, onChange, initValues
}) => {
  const { currentUser } = React.useContext(AuthProvider) || {}
  const { userDetails, refetchUserDetails } = React.useContext(ViewerProvider)
  const [selectValue, setSelectValue] = useState<string[]>(initValues ?? getinitOptions(userDetails))
  const { post, loading: postLoading } = useFetch(`/users/setUserNotifications`, { cachePolicy: CachePolicies.NO_CACHE })
  const { t } = useTranslation()

  const handleChangeMultiple = (event: React.ChangeEvent<{ value: string[] }>) => {
    const { value } = event.target as unknown as any

    setSelectValue(value)

    const payload = getPayloadValues({
      userId: currentUser?.uid,
      values: value
    })

    onChange?.(value)

    if (avoidSubmitOnChange) {
      return
    }

    post(payload).then(() => {
      return refetchUserDetails()
    }).catch((error) => {
      console.error('error', error)
    })
  }

  if (!currentUser) return null

  return (
    <>
    <AssetDetailRawField
      value={
        <Box display='flex' alignItems='center' justifyContent='center'>
          <Select
            multiple
            value={selectValue}
            displayEmpty
            disabled={postLoading}
            renderValue={(value) => {
              if (!value || !(value as string[]).length) return 'בחר אפשרויות להתראות'

              return (value as string[]).map((currentVal) => SELECT_OPTIONS.find((item) => item.value === currentVal)?.text).toString().replace(/,/g, ', ')
            }}
            withCheckbox
            onChange={handleChangeMultiple as any}
            options={SELECT_OPTIONS}
          />
        </Box>
      }
      icon={<NotificationsNoneIcon />}
      label={
        <Grid item container alignItems='center' style={{ direction: 'ltr' }}>
          <span style={{ display: 'flex' }}>
            <Tooltip
              leaveTouchDelay={8000}
              enterTouchDelay={100}
              title={
                <Typography size='large'>{t('tooltip-stopNotifications')}</Typography>
              }
            >
              <InfoIcon htmlColor='#4BB5AF' fontSize='small' />
            </Tooltip>
          </span>
          <Typography>התראות</Typography>
        </Grid>
      }
    />
    </>
  )
}

export default UserNotificationSwitch
