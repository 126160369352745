import React from 'react'
import GoogleMapReact from 'google-map-react'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import ListSubheader from '@material-ui/core/ListSubheader'
import Skeleton from '@material-ui/lab/Skeleton'

import useHeightHelper from '~/hooks/useHeightHelper'
import useMarkersForMap from '~/hooks/useMarkersForMap'
import { MapContext } from '~/components/core/MapProvider/context'
import WidgetErrorBoundary from '~/components/core/WidgetErrorBoundary'

export interface Props {
  position?: {
    lat: number
    lng: number
  }
  zoom?: number
  sticky?: boolean
  show?: boolean
}

const defaultCenter: Props['position'] = {
  lat: 32.109333,
  lng: 34.855499
}

export const Styled = createStyles({
  sticky: {
    padding: 0,
    zIndex: 2,
    width: '100%'
  }
})

const useStyles = makeStyles(Styled)

// eslint-disable-next-line complexity
const MapView: React.FC<Props> = ({ zoom = 16, sticky, show, position }) => {
  const classes = useStyles()
  const { setMapApiLoaded, map, mapApiLoaded } = React.useContext(MapContext)
  const heightHelperRef = React.useRef<HTMLDivElement>(null)
  const [sizes] = useHeightHelper(mapApiLoaded ? heightHelperRef : { current: null }, map)
  const [markers, center, mapZoom] = useMarkersForMap(sizes, position)

  if (!map && !show) {
    return null
  }

  if (sticky) {
    return (
      <Grid item container style={{ flex: 1, position: 'relative' }}>
        <div id='heightHelper' style={{ height: '100vh', width: '100%', position: 'absolute' }} ref={heightHelperRef} />
        {!mapApiLoaded && <Skeleton variant='rect' width='100%' height='100%' />}
        <ListSubheader className={classes.sticky} style={{ height: sizes?.height || 0, visibility: mapApiLoaded ? 'visible' : 'hidden' }}>
          <WidgetErrorBoundary emptyOnError>
            <GoogleMapReact
              onGoogleApiLoaded={() => {
                setMapApiLoaded(true)
              }}
              options={{
                clickableIcons: false
              }}
              yesIWantToUseGoogleMapApiInternals
              defaultCenter={defaultCenter}
              defaultZoom={zoom}
              center={center}
              zoom={mapZoom}
            >
              {markers}
            </GoogleMapReact>
          </WidgetErrorBoundary>
        </ListSubheader>
      </Grid>
    )
  }

  return (
    <WidgetErrorBoundary emptyOnError>
      <GoogleMapReact
        onGoogleApiLoaded={() => {
          setMapApiLoaded(true)
        }}
        yesIWantToUseGoogleMapApiInternals
        defaultCenter={defaultCenter}
        defaultZoom={zoom}
        center={center}
        zoom={mapZoom}
      >
        {markers}
      </GoogleMapReact>
    </WidgetErrorBoundary>
  )
}

export default MapView
