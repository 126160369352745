/* eslint-disable max-statements */
import React, { useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import useFetch, { CachePolicies } from 'use-http'
import { Grid } from '@material-ui/core'

import { rollbarError } from '~/utils/rollbar'
import PageLoader from '~/components/core/PageLoader'
import LeadsList from '~/components/modules/LeadsList'
import SellerLeadsContanair from '~/components/modules/SellerLeadsContanair'
import PaymentsProvider from '~/components/modules/PaymentsProvider'
import SellerLoadingLeads from '~/components/modules/SellerLoadingLeads'
import { ViewerProvider } from '~/components/core/ViewerProvider/context'
import UserFeedbackModal from '~/components/modules/UserFeedback/modal'
import { useModal } from '~/utils/useModal'
import NoDataMessage from '~/components/modules/NoDataMessage'
import getPaginationOffset from '~/utils/get-pagination-offset'
import AssetLeadsTips from '~/components/modules/AssetLeadsTips'
import { LeadsSorting } from '~/types/Leads'
import usePrevious from '~/hooks/usePrevious'
import useResetNewAssetLeads from '~/hooks/useResetNewAssetLeads'
// import useSortLeads from '~/hooks/useSortLeads'
import useSpamWarningModal from '~/hooks/useSpamWarningModal'
import { PaymentsProvider as PaymentsProviderContext } from '~/components/modules/PaymentsProvider/context'
// import SellerLeadsFilters from '~/components/modules/SellerLeadsFilters'
import useFilterLeads from '~/hooks/useFilterLeads'
import { isLoadingLeads, getSentMessagesCount } from './services'

export const PAGE_SIZE = 20

export type Sorting = {
  type: 'percentage' | 'price' | 'date'
  ascending: number
}

// eslint-disable-next-line complexity
const AssetLeads: React.FC = () => {
  const {
    loading: paymentsLoading
  } = React.useContext(PaymentsProviderContext)
  const { id, page } = useParams<{ id?: string, page?: string }>()
  const pageInt = (isNaN(Number(page))) ? 0 : Number(page)
  const pageOffset = getPaginationOffset(pageInt, PAGE_SIZE)
  const [showMessageTooltip, setShowMessageTooltip] = useState(false)
  const [sortBy, setSortBy] = useState<LeadsSorting>({
    type: 'date',
    ascending: 0
  })
  const { filtersState } = useFilterLeads()
  const { loading, error, data: resData, get: refechLeads } = useFetch<any | 'loadingLeads'[]>(`/api/v3/assets/asset/${id}/matches?sortBy=${sortBy.type}&ascending=${sortBy.ascending}&from=${pageOffset}&to=${pageOffset + PAGE_SIZE}`,
    {
      cachePolicy: CachePolicies.NO_CACHE
    },
    [id, sortBy.type, sortBy.ascending, page]
  )

  // const data = dataLeads?.leads as any
  const data = Array.isArray(resData) ? resData : resData?.leads

  const { post: deleteLeadPost, loading: deleteLeadLoading } = useFetch('api/properties/removeLead')
  const { delete: deleteAsset, loading: deleteAssetLoading } = useFetch(`/api/properties/${id}`)
  const { refetchAssets } = React.useContext(ViewerProvider)
  const { showModal, hideModal, isOpen } = useModal()
  const history = useHistory()
  const prevId = usePrevious(id)
  const [resetLeads] = useResetNewAssetLeads()
  // const [currentLeads, sortObj, setSorting] = useSortLeads(data)

  useSpamWarningModal(!(!data && loading), () => setShowMessageTooltip(true))

  const handleDeleteLead = async (leadId?: string) => {
    await deleteLeadPost({ leadId })
    refechLeads()
  }

  const handleOnSortChange = (type: 'percentage' | 'price' | 'date') => {
    setSortBy({
      type,
      ascending: (type === sortBy.type) ? Math.abs(sortBy.ascending - 1) : 0
    })
  }

  const handleDeleteAsset = async () => {
    await deleteAsset()
    await refetchAssets()

    showModal()
  }

  const handleOnCloseModal = async () => {
    hideModal()

    history.push('/seller')
  }

  React.useEffect(() => {
    if (prevId && prevId !== id) {
      resetLeads(prevId)
    }
  }, [id])

  if (error) {
    rollbarError({
      error
    })

    return (
      <NoDataMessage type='asset' />
    )
  }

  if (!data && loading) {
    return <PageLoader open invisible />
  }

  return (
    <>
    <Grid container spacing={3} wrap='nowrap'>
      <Grid item container lg={8}>
        <SellerLeadsContanair
          onDelete={handleDeleteAsset}
          isLoadingLeads={isLoadingLeads(data)}
          hasLeads={!!data?.length}
          onSortChange={handleOnSortChange}
          sorting={sortBy}
          leadsCount={resData?.totalLeadCount}
          onFilterChange={undefined}
          filtersState={filtersState}
          refetchLeads={refechLeads}
          shownToLeadsCount={getSentMessagesCount(data)}
        >
          <SellerLoadingLeads retryCall={refechLeads} data={data}>
            <LeadsList
              items={data || []}
              totalCount={resData?.totalLeadCount}
              loading={loading}
              onRemoveLead={handleDeleteLead}
              refetchLeads={refechLeads}
              showMessageTooltip={!loading && !paymentsLoading && showMessageTooltip}
            />
          </SellerLoadingLeads>
        </SellerLeadsContanair>
      </Grid>
      <AssetLeadsTips />
    </Grid>
    <PageLoader open={deleteLeadLoading || deleteAssetLoading} />
    <UserFeedbackModal
      isOpen={isOpen}
      assetId={id}
      onClose={handleOnCloseModal}
      onSubmit={handleOnCloseModal}
    />
    </>
  )
}

const AssetLeadsPaymentWarapper = (): JSX.Element => {
  const { id } = useParams<{ id?: string }>()

  return (
    <PaymentsProvider id={id as string}>
      <AssetLeads />
    </PaymentsProvider>
  )
}

export default AssetLeadsPaymentWarapper
