import React, { SVGProps } from 'react'
const SentSucess = (props: SVGProps<SVGSVGElement>): JSX.Element => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={23}
    height={21}
    fill='none'
    {...props}
    color={props.color || 'inherit'}
  >
    <path
      fill='currentcolor'
      d='M15.5 6c-.42 0-.83.04-1.24.11L.01 0 0 7l10.06 1.34c-.42.44-.78.93-1.09 1.46L0 11l.01 7 8.07-3.46C8.59 18.19 11.71 21 15.5 21c4.14 0 7.5-3.36 7.5-7.5C23 9.36 19.64 6 15.5 6ZM2 5.25l.01-2.22 7.51 3.22-7.52-1Zm6.1 7.11L2 14.97v-2.22l6.17-.82c-.03.14-.05.28-.07.43ZM15.5 19a5.51 5.51 0 0 1-5.5-5.5c0-3.03 2.47-5.5 5.5-5.5s5.5 2.47 5.5 5.5-2.47 5.5-5.5 5.5Z'
    />
    <path
      fill='currentcolor'
      stroke='#4BB5AF'
      strokeWidth={0.2}
      d='m14.572 14.834-1.108-1.108-.071-.071-.07.07-.394.394-.07.07.07.071 1.572 1.572.07.07.072-.07 3.368-3.368.07-.071-.07-.07-.393-.394-.071-.07-.07.07-2.905 2.905Z'
    />
  </svg>
)

export default SentSucess
