import React from 'react'
import {
  Route,
  Switch,
  Redirect
} from 'react-router-dom'

import Asset from '~/components/pages/Asset'
import AssetLeads from '~/components/pages/AssetLeads'
import PostAsset from '~/components/pages/PostAsset'
import EditAsset from '~/components/pages/EditAsset'
import SellerHall from '~/components/pages/SellerHall'
import AssetPricePage from '~/components/pages/v3/AssetPricePage'

const SellerRouter: React.FC = () => {
  return (
    <Switch>
      <Route exact path='/'>
        <Redirect to='/seller' />
      </Route>

      <Route exact path='/seller'>
        <SellerHall />
      </Route>
      <Route exact path='/seller/editAsset/:id'>
        <EditAsset />
      </Route>
      <Route exact path='/seller/postAsset'>
        <PostAsset />
      </Route>
      <Route exact path='/seller/asset/:id'>
        <Asset />
      </Route>
      <Route exact path={['/seller/asset/:id/leads', '/seller/asset/:id/leads/:page']}>
        <AssetLeads />
      </Route>
      <Route exact path='/seller/asset/:id/price'>
        <AssetPricePage />
      </Route>
    </Switch>
  )
}

export default SellerRouter
