import React, { SVGProps } from 'react'
const ClacAsset = (props: SVGProps<SVGSVGElement>): JSX.Element => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={18}
    height={19}
    fill='none'
    {...props}
    color={props.color || 'inherit'}
  >
    <g fill='currentcolor' clipPath='url(#a)'>
      <path d='M6.253 4.305 1.074 7.916v7.949h3.612V9.82h2.89v6.045h3.611V7.742L6.253 4.305Z' />
      <path d='M7.575 2.266v1.14L9.02 4.412l1.25.877h.917v.642l1.445 1.013V8.31h1.444v1.511h-1.444v1.511h1.444v1.511h-1.444v3.023h4.334v-13.6h-9.39Zm6.501 4.533h-1.444V5.288h1.444v1.511Z' />
    </g>
    <defs>
      <clipPath id='a'>
        <path fill='#fff' d='M.352 0h17.336v18.133H.352z' />
      </clipPath>
    </defs>
  </svg>
)

export default ClacAsset
