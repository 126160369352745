import React, { SVGProps } from 'react'

const UsersConnect = (props: SVGProps<SVGSVGElement>): JSX.Element => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={120}
    height={120}
    fill='none'
    {...props}
  >
    <circle cx={60} cy={60} r={60} fill='url(#a)' />
    <g clipPath='url(#b)'>
      <path
        fill='#fff'
        d='M56.428 67.138h-7.143c0-17.75 14.393-32.143 32.143-32.143v7.143c-13.821 0-25 11.179-25 25Zm25-10.714v-7.143c-9.857 0-17.857 8-17.857 17.857h7.143a10.7 10.7 0 0 1 10.714-10.714Zm-39.286-25A7.118 7.118 0 0 0 35 24.281a7.118 7.118 0 0 0-7.142 7.143 7.118 7.118 0 0 0 7.142 7.143 7.118 7.118 0 0 0 7.143-7.143Zm15.893 1.786h-7.143c-.857 5.071-5.214 8.928-10.535 8.928H29.642a5.35 5.35 0 0 0-5.357 5.358v8.928h21.429v-8.071c6.643-2.108 11.607-7.965 12.321-15.143ZM85 77.853a7.118 7.118 0 0 0 7.143-7.143A7.118 7.118 0 0 0 85 63.567a7.118 7.118 0 0 0-7.142 7.143 7.118 7.118 0 0 0 7.142 7.143Zm5.358 3.571H79.642c-5.321 0-9.678-3.857-10.535-8.928h-7.143c.714 7.178 5.678 13.035 12.321 15.142v8.072h21.429V86.78a5.35 5.35 0 0 0-5.357-5.357Z'
      />
    </g>
    <defs>
      <linearGradient
        id='a'
        x1={60}
        x2={60}
        y1={0}
        y2={120}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#006064' />
        <stop offset={1} stopColor='#006064' stopOpacity={0.89} />
      </linearGradient>
      <clipPath id='b'>
        <path fill='#fff' d='M17.143 17.14h85.714v85.715H17.143z' />
      </clipPath>
    </defs>
  </svg>
)

export default UsersConnect
