import React, { useState } from 'react'
import EditIcon from '@material-ui/icons/Edit'
import { Grid } from '@material-ui/core'

import { useModal } from '~/utils/useModal'
import Button from '~/components/core/Button'
import Typography from '~/components/core/Typography'
import { AuthProvider } from '~/components/core/AuthProvider/context'
import EditDisplayNameModal from './modal'

export type EditableFormValue = {
  displayName: string
}

export interface Props {
  onSave: (values: EditableFormValue) => void
}

const SettingsEditableUserName: React.FC<Props> = ({ onSave }) => {
  const { currentUser } = React.useContext(AuthProvider)
  const { showModal, hideModal, isOpen } = useModal()
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const handleOnClick = async (values: EditableFormValue) => {
    setIsLoading(true)
    await onSave(values)

    setIsLoading(false)
    hideModal()
  }

  return (
    <>
    <Grid container justify='center' spacing={1} alignItems='center'>
      <Grid item>
        <Typography size='xlarge'>{currentUser?.displayName}</Typography>
      </Grid>
      <Grid item>
        <Button size='small' iconButton={<EditIcon fontSize='small' />} onClick={showModal} />
      </Grid>
    </Grid>
    <EditDisplayNameModal
      name={currentUser?.displayName || ''}
      isLoading={isLoading}
      isOpen={isOpen}
      onClose={hideModal}
      onSubmit={handleOnClick}
    />
    </>
  )
}

export default SettingsEditableUserName
