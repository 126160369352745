import React from 'react'
import Tooltip from '@material-ui/core/Tooltip'
// import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core/styles'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'

import Typography from '~/components/core/Typography'
import styles from './styles'

const blockedText = 'לחצו כאן כדי לשלוח את המודעה ולחשוף אותה למחפשים המתאימים'

const useStyles = makeStyles(styles)

interface Props {
  children: React.ReactNode
}

let onTimeGuard = true

const AssetLeadsTooltip = ({
  children
}: Props): JSX.Element => {
  const [open, setOpen] = React.useState(true)

  const handleTooltipClose = () => {
    setOpen(false)
    onTimeGuard = false
  }

  const classes = useStyles()

  return (
    <>
        <Tooltip
          onClose={handleTooltipClose}
          open={open && onTimeGuard}
          placement='top'
          disableFocusListener
          disableHoverListener
          arrow
          disableTouchListener
          title={
            <ClickAwayListener onClickAway={handleTooltipClose}>
              <Typography>{blockedText}</Typography>
            </ClickAwayListener>
          }
        >
          <div className={classes.body}>
            <>{children}</>
          </div>
        </Tooltip>
    </>
      // </ClickAwayListener>
  )
}

export default AssetLeadsTooltip
