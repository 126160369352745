import { createStyles } from '@material-ui/core/styles'

import palette from '~/ThemeProvider/config/palette'

export const SearchResultsHeaderStyled = createStyles({
  sticky: {
    padding: 0,
    zIndex: 10
  },
  root: {
    borderBottom: `1px solid ${palette.grey.main}`,
    backgroundColor: palette.common.white,
    width: '100%'
  }
})
