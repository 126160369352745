import React, { SVGProps } from 'react'
const StepCheck = (props: SVGProps<SVGSVGElement>): JSX.Element => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={120}
    height={120}
    fill='none'
    {...props}
  >
    <circle cx={60} cy={60} r={60} fill='url(#a)' />
    <path
      fill='#fff'
      d='M102.857 59.73 93.35 48.86l1.325-14.377-14.065-3.195-7.364-12.429L60 24.548l-13.247-5.689-7.364 12.429-14.065 3.156 1.325 14.415-9.506 10.87 9.506 10.87-1.325 14.416L39.39 88.21l7.364 12.468L60 94.95l13.246 5.689L80.61 88.21l14.065-3.195L93.35 70.64l9.507-10.91Zm-15.39 5.766-2.182 2.532.312 3.312.701 7.597-7.402 1.676-3.273.74-1.714 2.883-3.857 6.545-6.935-3L60 86.457l-3.078 1.325-6.935 3-3.857-6.507-1.715-2.883-3.272-.74-7.403-1.676.701-7.636.312-3.312-2.182-2.532-5.026-5.727 5.026-5.767 2.182-2.532-.35-3.35-.702-7.56 7.403-1.675 3.272-.74 1.715-2.883 3.857-6.546 6.935 3L60 33.041l3.078-1.324 6.935-3 3.857 6.545 1.714 2.883 3.273.74 7.403 1.676-.702 7.597-.312 3.312 2.182 2.532 5.026 5.727-4.987 5.767Z'
    />
    <path
      fill='#fff'
      d='m52.555 66.593-9.039-9.078-5.766 5.805 14.805 14.844L81.153 49.49l-5.767-5.805-22.83 22.909Z'
    />
    <defs>
      <linearGradient
        id='a'
        x1={60}
        x2={60}
        y1={0}
        y2={120}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#006064' />
        <stop offset={1} stopColor='#006064' stopOpacity={0.89} />
      </linearGradient>
    </defs>
  </svg>
)

export default StepCheck
