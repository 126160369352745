import React from 'react'
import Alert from '@material-ui/lab/Alert'
import { makeStyles } from '@material-ui/core/styles'

import { StyledBox } from './styles'

const useStyles = makeStyles(StyledBox)

interface Props {
  children: React.ReactNode
}

const HelpBox: React.FC<Props> = ({ children }) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Alert severity='warning'>
        <div className={classes.body}>
          {children}
        </div>
      </Alert>
    </div>
  )
}

export default HelpBox
