import React from 'react'
import { Redirect } from 'react-router-dom'

import { ViewerProvider } from '~/components/core/ViewerProvider/context'

const SellerHall: React.FC = () => {
    const { assets } = React.useContext(ViewerProvider)

    if (!assets) {
        return <>NO ASSETS DATA</>
    }

    if (assets.length) {
        return <Redirect to={`/seller/asset/${assets[0].id}/leads`} />
    }

    return <Redirect to='/seller/postAsset' />
}

export default SellerHall
