import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import useFetch from 'use-http'
import { useTranslation } from 'react-i18next'
import OpenInNewIcon from '@material-ui/icons/OpenInNew'

import Link from '~/components/core/Link'
import { useBreakpoint } from '~/utils/breakpoints'
import Modal from '~/components/core/Modal'
import Button from '~/components/core/Button'
import Typography from '~/components/core/Typography'
import { BuyerAsset } from '~/types/Asset'
import AssetDetails from '~/components/modules/AssetDetails'
import AssetsDetailsContactsActions from '~/components/modules/AssetsDetailsContactsActions'
import * as S from './styles'

const useStyles = makeStyles(S.Styled)

interface Props {
  leadId?: string
  onClose: () => void
}

const AssetDetailsModal: React.FC<Props> = ({ leadId, onClose }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { loading, error, data } = useFetch<BuyerAsset>(`/api/v2/searcher/leads/${leadId}`, { data: [] }, [leadId])
  const isCompactLayout = useBreakpoint(['small', 'medium'])
  const [isGalleryFullScreen, setIsGalleryFullScreen] = useState(false)

  if (error) {
    console.error('errorerror', error)

    return <>ERROR</>
  }

  if (!data) {
    return null
  }

  return (
    <Modal open maxWidth='lg' fullWidth={isCompactLayout} classes={isGalleryFullScreen ? { paper: classes.modalWrp } : {}}>
      <Modal.Title onClose={onClose}>
        פרטי הנכס
      </Modal.Title>
      <Modal.Content dividers className={classes.root}>
        <Link color='inherit' to={`/buyer/asset/${leadId}`} target='_blank'>
          <Button startIcon={<OpenInNewIcon />} variant='text' color='primary'>
            <Typography color='textPrimary' noWrap>{t('openAssetPAge')}</Typography>
          </Button>
        </Link>
        <AssetDetails item={data} isModal loading={loading} onToggleGalleryFullScreen={setIsGalleryFullScreen} />
      </Modal.Content>
      <AssetsDetailsContactsActions isModal item={data} />
    </Modal>
  )
}

export default AssetDetailsModal
