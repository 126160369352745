import React, { useState } from 'react'
import { isEmpty } from 'lodash'

import { scrollToTop } from '~/utils/positions'
import Form from '~/components/core/Form'
import PostAssetMain from '~/components/modules/PostAssetMain'
import { AssetFormData, SellerAsset } from '~/types/Asset'
import useAssetFormValidation from '~/hooks/useAssetFormValidation'
import { getInitFormObj } from './helpers'

export interface Props {
  onSubmit?: (data?: AssetFormData) => Promise<any>
  asset?: SellerAsset
}

const PostAssetForm: React.FC<Props> = ({ onSubmit = () => {}, asset }) => {
  const [validateForm] = useAssetFormValidation(!!asset)
  const [initData, setInitData] = useState(getInitFormObj(asset))

  const handleOnSubmit = (data: AssetFormData) => {
    const vlidation = validateForm(data)

    if (!isEmpty(vlidation)) {
      scrollToTop()

      return vlidation
    }

    setInitData(data)
    onSubmit(data)

    return {}
  }

  return (
    <Form initialValues={initData} onSubmit={handleOnSubmit} validate={validateForm}>
      <PostAssetMain asset={asset} />
    </Form>
  )
}

export default PostAssetForm
