import React, { useState } from 'react'
import { isEmpty } from 'lodash'
import { Grid } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import SubjectIcon from '@material-ui/icons/Subject'
import MailOutlineIcon from '@material-ui/icons/MailOutline'
import PersonOutlineIcon from '@material-ui/icons/PersonOutline'

import PageLoader from '~/components/core/PageLoader'
import { scrollToTop } from '~/utils/positions'
import Form from '~/components/core/Form'
import useContactUsFormValidation from '~/hooks/useContactUsFormValidation'
import LabelWrapper from '~/components/core/LabelWrapper'
import Typography from '~/components/core/Typography'

export interface InitProps {
  email: string
  fullName: string
  textComment: string
}

export interface Props {
  onSubmit?: (data?: InitProps) => Promise<any>
  loading?: boolean
}

const INIT_FORM: InitProps = {
  email: '',
  fullName: '',
  textComment: ''
}

const ContactUsForm: React.FC<Props> = ({ onSubmit = () => {}, loading }) => {
  const { t } = useTranslation()
  const [validateForm] = useContactUsFormValidation()
  const [initData, setInitData] = useState(INIT_FORM)

  const handleOnSubmit = (data: InitProps) => {
    const vlidation = validateForm(data)

    if (!isEmpty(vlidation)) {
      scrollToTop()

      return vlidation
    }

    setInitData(data)
    onSubmit(data)

    return {}
  }

  if (loading) {
    return <PageLoader open invisible />
  }

  return (
    <Form initialValues={initData} onSubmit={handleOnSubmit} validate={validateForm}>
      <Grid container alignItems='stretch' spacing={4} style={{ maxWidth: '500px' }}>
        <Grid item container wrap='nowrap'>
          <LabelWrapper label={t('email')} icon={<MailOutlineIcon fontSize='small' />} direction='column'>
            <Form.Input name='email' />
          </LabelWrapper>
        </Grid>
        <Grid item container wrap='nowrap'>
          <LabelWrapper label={t('fullName')} icon={<PersonOutlineIcon fontSize='small' />} direction='column'>
            <Form.Input name='fullName' />
          </LabelWrapper>
        </Grid>
        <Grid item container wrap='nowrap'>
          <LabelWrapper label={t('textComment')} icon={<SubjectIcon fontSize='small' />} direction='column'>
            <Form.Input style={{ border: '1px solid #DFDFDF' }} rows={4} rowsMax={10} multiline name='textComment' />
          </LabelWrapper>
        </Grid>
        <Grid justify='center' item container wrap='nowrap'>
          <Form.SubmitButton color='primary' variant='contained'>
            <Typography color='inherit'>
              {t('send')}
            </Typography>
          </Form.SubmitButton>
        </Grid>
      </Grid>
    </Form>
  )
}

export default ContactUsForm
