import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import ButtonBase, { ButtonBaseProps } from '@material-ui/core/ButtonBase'

import Typography from '~/components/core/Typography'
import { StyledButton } from './styles'

const useStyles = makeStyles(StyledButton)

export interface Props extends ButtonBaseProps {
  title: string
  image: string | React.ReactNode

}

const CustomButton: React.FC<Props> = ({
  title, image
}) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <ButtonBase
        focusRipple
        key={title}
        title={title}
        className={classes.image}
        focusVisibleClassName={classes.focusVisible}
      >
        <span
          className={classes.imageSrc}
          style={{
            backgroundImage: `url(${image})`
          }}
        />
        <span className={classes.imageBackdrop} />
        <span className={classes.imageButton}>
          <Typography
            variant='subtitle1'
            size='xlarge'
            weight='bold'
            color='inherit'
            className={classes.imageTitle}
          >
            {title}
            <span className={classes.imageMarked} />
          </Typography>
        </span>
      </ButtonBase>
    </div>
  )
}

export default CustomButton
