import React, { Component, ReactElement, ReactNode } from 'react'

interface Props {
  emptyOnError?: boolean
  errorComponent?: ReactElement
  bubbleUpError?: boolean
  children: ReactNode
}

interface State {
  hasError: boolean
}

export default class WidgetErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = { hasError: false }
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  isThrowingErrorEnabled() {
    return !!this.props.bubbleUpError
  }

  componentDidCatch(error: Error) {
    if (this.isThrowingErrorEnabled()) {
      throw error
    }

    this.setState({
      hasError: true
    })
  }

  render() {
    const {
      state: { hasError },
      props: { children, errorComponent, emptyOnError }
    } = this

    return hasError
      ? (
      emptyOnError
      ? (
        <></>
      )
      : (
        errorComponent || <div>Error View</div>
      )
    )
: (
      children
    )
  }
}
