import React, { useEffect, useRef } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Autocomplete, { AutocompleteProps, AutocompleteChangeReason } from '@material-ui/lab/Autocomplete'
import { useTranslation } from 'react-i18next'
import Chip from '@material-ui/core/Chip'
import Grid from '@material-ui/core/Grid'
import parse from 'autosuggest-highlight/parse'
import { useDebounce } from 'use-debounce'

import { useBreakpoint } from '~/utils/breakpoints'
import useQueryLocation from '~/hooks/useQueryLocation'
import useGeoPosition, { LatLngItem, Bounds } from '~/hooks/useGeoPosition'
import TextInput, { Props as TextInputProps } from '../TextInput'
import { Styled } from './styles'

export type LocationData = { latLng?: LatLngItem, name: string | null, bounds?: Bounds, formattedAddress?: string, placeId?: string }

const useStyles = makeStyles(Styled)

export interface Props {
  onSelect?: (data: LocationData[]) => void
  citiesOnly?: boolean
  location?: Bounds
  label?: React.ReactNode
  value?: string | null
  name?: string | undefined
  textInputProps?: TextInputProps
  filterType?: string
  includesIn?: string
  isDisabled?: boolean
  error?: boolean
  searchTypes?: string[]
  noOptionsText?: string
  allowFreeText?: boolean
}

const LocationMultiSelectInput = React.forwardRef<HTMLDivElement, Props>(function LocationMultiSelectInput({
  onSelect = () => {},
  citiesOnly,
  location,
  label = '',
  value,
  name,
  textInputProps = {},
  filterType,
  includesIn,
  isDisabled,
  error,
  searchTypes = [],
  noOptionsText
}, ref) {
  const isCompactLayout = useBreakpoint(['small', 'medium'])
  const classes = useStyles()
  const selectedValues = React.useRef<LocationData[]>([])
  const [currentValue, setValue] = React.useState<string | null>('')
  const { t } = useTranslation()
  const [inputValue, setInputValue] = React.useState('')
  const [searchInput] = useDebounce(inputValue, 500)
  const requestOptions = {
    types: citiesOnly ? ['(cities)', ...searchTypes] : [...searchTypes],
    ...((location) ? { location } : {})
  }

  console.log('searchInput', searchInput)
  const [options, loading] = useQueryLocation(currentValue, searchInput, requestOptions, filterType, includesIn)
  const [performGeo] = useGeoPosition()
  const isFreeText = useRef(false)

  useEffect(() => {
    if (!value) {
      setValue('')
    }
  }, [value])

  const handleInputChange: AutocompleteProps<any, any, any, any>['onInputChange'] = (event, inputVal) => {
    setInputValue(inputVal)
  }

  const preOnSelect = (data: LocationData) => {
    selectedValues.current = [...selectedValues.current, data]
    onSelect([...selectedValues.current])
  }

  const handleOnChange = (data: any) => {
    if (Array.isArray(data)) {
      selectedValues.current = selectedValues.current.filter((item) => data.some((optionItem) => item.placeId === optionItem.place_id))

      onSelect([...selectedValues.current])
    }
  }

  const handleSelectedOption = async (selectedOption: any) => {
    const placeId = selectedOption?.place_id
    const { latLng, bounds, formattedAddress } = await performGeo({ placeId })

    preOnSelect({ latLng, bounds, name: selectedOption?.description || null, formattedAddress, placeId })
    isFreeText.current = false
  }

  return (
    <Autocomplete
      loading={loading}
      forcePopupIcon={false}
      multiple
      openOnFocus={false}
      ref={ref}
      noOptionsText={noOptionsText || t('noOptions')}
      loadingText={`${t('loading')}...`}
      disabled={isDisabled}
      limitTags={isCompactLayout ? 1 : 2}
      getOptionLabel={(option: any) => (typeof option === 'string' ? option : option?.description)}
      filterOptions={(opt) => opt}
      options={options.filter((option) => !selectedValues.current.some((item) => item.placeId === option.place_id))}
      autoComplete
      includeInputInList
      filterSelectedOptions
      renderTags={(valueTag, getTagProps) =>
        valueTag.map((option, index) => (
          <Chip
            variant='outlined'
            key={index.toString()}
            label={option.description.split(', ישראל')[0]}
            size='small'
            {...getTagProps({ index })}
          />
        ))
      }
      onChange={(_e, newValue: any | null, reason: AutocompleteChangeReason, singleVal: any) => {
        setValue(newValue?.description?.split(', ישראל')[0] || null)
        if (reason === 'select-option') {
          handleSelectedOption(singleVal.option)
        } else {
          handleOnChange(newValue)
        }
      }}
      onInputChange={handleInputChange}
      renderInput={(params) => {
          const inputParams = params.inputProps as any

          return (
            <TextInput
              onMouseDownCapture={inputParams?.value ? undefined : (e) => e.stopPropagation()}
              name={name}
              ref={params.InputProps.ref}
              label={label}
              type='text'
              className={classes.root}
              {...textInputProps}
              {...params}
              InputProps={{
                ...textInputProps.InputProps,
                ...params.InputProps,
                classes: {
                  root: classes.root
                }
              }}
              error={!!error}
            />
          )
      }}
      renderOption={(option: any) => {
        if (typeof option === 'string') {
          return option
        }

        const matches = option.matched_substrings
        const parts = parse(
          option.description.split(', ישראל')[0],
          matches.map((match: any) => [match.offset, match.offset + match.length])
        )

        return (
          <Grid container alignItems='center'>
            <Grid
              item
              xs
              style={{
                borderBottom: '1px solid #DFDFDF', fontSize: '18px'
              }}
            >
              {parts.map((part, index) => (
                <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                  {part.text}
                </span>
              ))}
            </Grid>
          </Grid>
        )
      }}
    />
  )
})

export default LocationMultiSelectInput
