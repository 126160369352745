import React, { ReactNode } from 'react'
import { withStyles } from '@material-ui/core/styles'
import DialogActions, { DialogActionsProps } from '@material-ui/core/DialogActions'

import * as S from './styles'

const StyledModalActions = withStyles(S.ModalActions)(DialogActions)

export interface Props extends DialogActionsProps {
  children?: ReactNode
}

const ModalActions: React.FC<Props> = ({ children, ...other }) => {
  return (
    <StyledModalActions {...other}>
      {children}
    </StyledModalActions>
  )
}

export default ModalActions
