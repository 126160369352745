import { createStyles } from '@material-ui/core/styles'

import palette from '~/ThemeProvider/config/palette'

export const PercentageBoxStyled = createStyles({
  avatar: {
    backgroundColor: palette.secondary.dark,
    fontSize: 16
  }
})
