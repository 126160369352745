import React from 'react'
import { useParams } from 'react-router-dom'
import useFetch from 'use-http'
import { Box } from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'
import LinkOffIcon from '@material-ui/icons/LinkOff'
import { useTranslation } from 'react-i18next'

import { rollbarError } from '~/utils/rollbar'
import Link from '~/components/core/Link'
import Button from '~/components/core/Button'
import Typography from '~/components/core/Typography'
import { BuyerAsset } from '~/types/Asset'
import AssetDetails from '~/components/modules/AssetDetails'
import ResultsMessage from '~/components/core/ResultsMessage'

interface Props {
  modal?: boolean
}

const AssetBuyer: React.FC<Props> = () => {
  const { leadId } = useParams<{ leadId?: string }>()
  const { t } = useTranslation()
  const { loading, error, data } = useFetch<BuyerAsset>(`/api/v2/searcher/leads/${leadId}`, { data: [] }, [leadId])

  if (error) {
    rollbarError({
      error
    })

    return (
      <ResultsMessage
        title={t('matchNoLeadFound')}
        icon={<LinkOffIcon color='secondary' fontSize='large' style={{ height: 150, width: 'auto', marginTop: 40 }} />}
        actionButton={(
          <Link color='inherit' to='/' style={{ textDecoration: 'none' }}>
            <Button variant='text' color='primary'>
              <Typography size='large' color='textPrimary' noWrap>{t('labelBack')}</Typography>
            </Button>
          </Link>
        )}
      />
    )
  }

  if (!data) {
    return null
  }

  return (
    <>
    {
      (data.searchIdConnect) && (
        <Box padding='4px'>
          <Link color='inherit' to={`/buyer/search/${data.searchIdConnect}`}>
            <Button startIcon={<SearchIcon />} variant='text' color='primary'>
              <Typography color='textPrimary' noWrap>{t('backToSearch')}</Typography>
            </Button>
          </Link>
        </Box>
      )
    }
    <AssetDetails item={data} loading={loading} />
    </>
  )
}

export default AssetBuyer
