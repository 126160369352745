import React, { ReactNode, forwardRef } from 'react'
import { CompoundedComponentWithRef } from '@shared/types'
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer'
import List from '@material-ui/core/List'
import { makeStyles } from '@material-ui/core/styles'

import SidebarItem from './SidebarItem'
import NestedSidebarItem from './NestedSidebarItem'

export interface Props {
  children?: ReactNode
  initOpen?: boolean
  onOpen?: () => void
  onClose?: () => void
  open: boolean
}

export interface StaticProps {
  Item: typeof SidebarItem
  NestedItem: typeof NestedSidebarItem
}

const useStyles = makeStyles({
  root: {
    direction: 'ltr',
    height: '100%'
  },
  list: {
    width: 350,
    height: '100%'
  },
  innerList: {
    height: '100%'
  },
  fullList: {
    width: 'auto'
  }
}, {
  name: 'Sidebar'
})

const Sidebar = forwardRef<HTMLElement, Props>(function Sidebar({
  children, onClose = () => {}, onOpen = () => {}, open = false
}, ref) {
  const classes = useStyles()

  // eslint-disable-next-line complexity
  const toggleDrawer = (isOpen: boolean) => (
    event: React.KeyboardEvent | React.MouseEvent
  ) => {
    if (
      event &&
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' ||
        (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return
    }

    if (isOpen) {
      onOpen()
    } else {
      onClose()
    }
  }

  return (
    <SwipeableDrawer
      ref={ref}
      className={classes.root}
      anchor='left'
      open={open}
      onClose={toggleDrawer(false)}
      onOpen={toggleDrawer(true)}
    >
      <div
        className={classes.list}
        role='presentation'
        onClick={toggleDrawer(false)}
        onKeyDown={toggleDrawer(false)}
      >
        <List className={classes.innerList}>
          {children}
        </List>
      </div>
    </SwipeableDrawer>
  )
}) as CompoundedComponentWithRef<Props, HTMLElement, StaticProps>

Sidebar.Item = SidebarItem
Sidebar.NestedItem = NestedSidebarItem

export default Sidebar
