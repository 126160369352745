import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import MUIBadge, { BadgeProps } from '@material-ui/core/Badge'

import * as S from './styles'

const useStyles = makeStyles(S.Styled)

const Badge = (props: BadgeProps): JSX.Element => {
  const classes = useStyles()

  return (
    <MUIBadge
      {...props}
      classes={classes}
    />
  )
}

export default Badge
