import React from 'react'
import { Grid, Avatar } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'

import Typography from '~/components/core/Typography'
import * as S from './styles'

export interface Props {
  percentage: number
  shortText?: boolean
}

const useStyles = makeStyles(S.PercentageBoxStyled)

const PercentageBox: React.FC<Props> = ({ percentage, shortText }) => {
  const { t } = useTranslation()
  const classes = useStyles()

  return (
    <Grid container alignItems='center' spacing={1}>
      <Grid item>
        <Avatar variant='rounded' aria-label='percentage' className={classes.avatar}>
          {Math.round(percentage * 100)}%
        </Avatar>
      </Grid>
      <Grid item>
        <Typography size='medium'>
          {shortText ? t('Percentagebar-yourMatchToSearch-short') : t('Percentagebar-yourMatchToSearch')}
        </Typography>
      </Grid>
    </Grid>
  )
}

export default PercentageBox
