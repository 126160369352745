import { createStyles } from '@material-ui/core/styles'

import { palette } from '~/ThemeProvider/config/palette'

export const StyledSlider = createStyles({
  root: {
    color: palette.primary.main,
    height: 8
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: palette.common.white,
    border: '2px solid currentColor',
    marginTop: -8,
    marginLeft: -12,
    '&:focus': {
      boxShadow: 'inherit'
    },
    '&:hover, &$active': {
      boxShadow: '0px 0px 0px 8px rgba(0, 96, 100, 0.16);'
    }
  },
  active: {
    boxShadow: '0px 0px 0px 14px rgba(0, 96, 100, 0.16);'
  },
  valueLabel: {
    left: 'calc(-50% + 4px)',
    '& .MuiSlider-track': {
      color: palette.common.white,
      backgroundColor: palette.primary.dark
    }
  },
  track: {
    height: 8,
    borderRadius: 4
  },
  mark: {
    display: 'none'
  },
  rail: {
    height: 8,
    borderRadius: 4,
    opacity: 1,
    color: `${palette.primary.main} !important`
  }
})
