/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { createStyles, Theme } from '@material-ui/core/styles'

import palette from '~/ThemeProvider/config/palette'

export const ModalTitle = (theme: Theme) => createStyles({
  root: {
    margin: 0,
    padding: theme.spacing(2)
  }
})

export const CloseButton = (theme: Theme) => createStyles({
  root: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: palette.grey[500]
  }
})
