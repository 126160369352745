const getInitials = (name: string): string => {
  const fullName = (name.trim()).split(' ')
  const initials = fullName.reduce((res, part) => part[0] ? res + part[0] : res, '')

  return initials.toUpperCase()
}

export default getInitials

export const getFirstNameAndInitials = (name: string): string => {
  const fullName = (name.trim()).split(' ')
  const initials = fullName.reduce((res, part, index) => {
    if (index === 0) {
      return `${res} ${part}`
    }

    if (part[0]) {
      return `${res} ${part[0]}`
    }

    return res
  }, '')

  return initials
}
