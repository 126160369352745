import React from 'react'
import {
  useField,
  FieldProps as FinalFieldProps,
  FieldRenderProps
} from 'react-final-form'

import BaseNumberInput, { Props as BaseNumberInputProps } from '~/components/core/Inputs/NumberInput'
import FormField from '../FormField'

export type FieldProps<TInputValue> = FinalFieldProps<
  TInputValue,
  FieldRenderProps<TInputValue, HTMLInputElement>,
  HTMLInputElement
>

export type Props = BaseNumberInputProps & FieldProps<BaseNumberInputProps['value']>

export const NumberInput: React.FC<Props> = (props) => {
  const { meta, input } = useField(props.name)
  const error = meta.touched && (meta.error || meta.submitError)

  const handleOnChange = (values: BaseNumberInputProps['NumberFormatValues']) => {
    const { value } = values

    input.onChange(value)
  }

  return (
    <FormField error={error}>
      <BaseNumberInput {...props} {...input} type={props.type} format={props.type === 'tel' ? '##########' : props.format} onValueChange={handleOnChange} onChange={undefined} error={!!error} />
    </FormField>
  )
}

export default NumberInput
