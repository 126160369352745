/* eslint-disable complexity */
import React, { useCallback } from 'react'
import { Grid } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { useForm, useFormState } from 'react-final-form'

import Form from '~/components/core/Form'
import useFormNotification from '~/hooks/useFormNotification'
import Typography from '~/components/core/Typography'
import { Props as SelecProps } from '~/components/core/Inputs/Select'
import Button from '~/components/core/Button'
import LabelWrapper from '~/components/core/LabelWrapper'
import { ASSET_PROPS_ICONS } from '~/components/modules/AssetDetails/constants'
import * as selectionOption from '~/components/modules/PostSearchForm/constants'
import { useSearchDealReadinessModal } from '../PostSearchMain/hooks/use-search-deal-readiness-modal'
import { validateIsAdvancedFieldsNotFull } from '~/hooks/useSearchFormValidation'

export interface ImportanceInputProps {
  name: string
  label: React.ReactNode
  selectOpts?: SelecProps['options']
}

const getRequiredLabel = (label: string, error?: boolean) => (
  <>{label}{' '}<span style={error ? { color: 'red' } : {}}>*</span></>
)

const TOGGLE_BUTTONS_OPTIONS: { text: string, value: number }[] = [{ text: 'לא חשוב', value: 0 }, { text: 'נחמד שיהיה', value: 1 }, { text: 'חשוב מאוד', value: 2 }, { text: 'חובה', value: 3 }]

const ImportanceInput: React.FC<ImportanceInputProps> = ({ name, label, selectOpts }) => {
  const { t } = useTranslation()
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const RelevantIcon = ASSET_PROPS_ICONS[name]
  const optsWithT = (options: SelecProps['options']) => (
    options.map((opt) => {
      opt.text = t(opt.text)

      return opt
    })
  )

  return (
    <LabelWrapper label={<LabelWrapper direction='row' label={label}>{selectOpts && <Form.Select style={{ margin: '0 6px', width: '200px' }} options={optsWithT(selectOpts)} name={`${name}.value`} />}</LabelWrapper>} icon={(RelevantIcon) && <RelevantIcon fontSize='small' />} direction='column'>
      {/* {selectOpts && <Form.Select options={optsWithT(selectOpts)} name={`${name}.value`} />} */}
      <Form.ToggleButtonsGroup
        name={`${name}.importance`}
      >
        {
          TOGGLE_BUTTONS_OPTIONS.map(({ text, value }: { text: string, value: number }, index: number) => (
            <Form.ToggleButton key={index} value={value} label={text} />
          ))
        }
      </Form.ToggleButtonsGroup>
    </LabelWrapper>
  )
}

export interface Props {
  onBack?: () => void
}

const PostSearchAdvanced: React.FC<Props> = ({ onBack = () => {} }) => {
  const formObj = useForm()
  const [validationsNotification] = useFormNotification(() => {}, false, 'סמנו את רמת החשיבות בשדות שמסומנים בכוכבית')
  const { values, submitErrors, ...rest } = useFormState()
  const handleSubmit = useCallback(() => {
    formObj.submit()
  }, [formObj])
  const [SearchDealReadinessModal, { showModal }] = useSearchDealReadinessModal(handleSubmit)
  const { t } = useTranslation()
  const handlePreSubmit = useCallback(() => {
    if (validateIsAdvancedFieldsNotFull(values as any)) {
      return handleSubmit()
    }

    if (values.searchType === 'sell' && !values.dealReadiness) {
      return showModal()
    }

     handleSubmit()
  }, [handleSubmit, showModal, values])

  console.log('dfdfdfdf', rest)
  // const empltyRequiredFields = submitErrors getEmptyRequiredAdvancedFields(values as any)

  return (
    <>
    {validationsNotification}
    <Grid container alignItems='stretch' spacing={3} style={{ maxWidth: '500px', margin: 0, width: '100%' }}>
      <Grid item container wrap='nowrap'>
        <ImportanceInput name='remodel' label={getRequiredLabel(t('remodel'), Object.keys(submitErrors || []).includes('remodel'))} selectOpts={selectionOption.REMODEL_OPTIONS_ARR} />
      </Grid>
      <Grid item container wrap='nowrap'>
        <ImportanceInput name='balcony' label={getRequiredLabel(t('balcony'), Object.keys(submitErrors || []).includes('balcony'))} />
      </Grid>
      <Grid item container wrap='nowrap'>
        <ImportanceInput name='elevator' label={getRequiredLabel(t('elevator'), Object.keys(submitErrors || []).includes('elevator'))} />
      </Grid>
      <Grid item container wrap='nowrap'>
        <ImportanceInput name='safeRoom' label={getRequiredLabel(t('safeRoom'), Object.keys(submitErrors || []).includes('safeRoom'))} />
      </Grid>
      <Grid item container wrap='nowrap'>
        <ImportanceInput name='storage' label={getRequiredLabel(t('storage'), Object.keys(submitErrors || []).includes('storage'))} />
      </Grid>
      <Grid item container wrap='nowrap'>
        <ImportanceInput name='floor' label={getRequiredLabel(t('floor'), Object.keys(submitErrors || []).includes('floor'))} selectOpts={selectionOption.FLOOR_OPTIONS_ARR} />
      </Grid>
      <Grid item container wrap='nowrap'>
        <ImportanceInput name='furnished' label={t('furnished')} />
      </Grid>
      <Grid item container wrap='nowrap'>
        <ImportanceInput name='garden' label={t('garden')} />
      </Grid>
      <Grid item container wrap='nowrap'>
        <ImportanceInput name='hadicap' label={t('hadicap')} />
      </Grid>
      <Grid item container wrap='nowrap'>
        <ImportanceInput name='panthouse' label={t('panthouse')} />
      </Grid>
      <Grid item container wrap='nowrap'>
        <ImportanceInput name='parking' label={t('parking')} />
      </Grid>
      <Grid item container wrap='nowrap'>
        <ImportanceInput name='view' label={t('view')} selectOpts={selectionOption.VIEW_OPTIONS_ARR} />
      </Grid>
      <Grid item container>
        <Grid container spacing={2} wrap='nowrap'>
          <Grid item>
            <Button onClick={onBack} color='primary' variant='outlined'>
              <Typography color='inherit'>
                {t('back')}
              </Typography>
            </Button>
          </Grid>
          <Grid item>
            <Button
              color='primary'
              variant='contained'
              onClick={handlePreSubmit}
            >
              <Typography color='inherit'>
                {t('save')}
              </Typography>
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
    {SearchDealReadinessModal}
    </>
  )
}

export default PostSearchAdvanced
