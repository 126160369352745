import { useState, useMemo, useEffect } from 'react'
import throttle from 'lodash/throttle'

const autocompleteService: any = { current: null }

export default function useQueryLocation(value: string | null, inputValue: string, requestOptions: any = {}, filterType: string | undefined, includesIn: string | undefined = ''): [any[], boolean] {
  const [options, setOptions] = useState<string[]>([])
  const [loading, setLoading] = useState(false)
  const fetch = useMemo(
    () =>
      throttle((request, callback) => {
        const reqOptions = {
          ...requestOptions,
          ...((requestOptions.location) ? { bounds: new google.maps.LatLngBounds(requestOptions.location.southwest, requestOptions.location.northeast) } : {})
        }

        autocompleteService.current.getPlacePredictions({
          componentRestrictions: { country: 'il' },
          // filterByCity: 'חיפה',
          ...reqOptions,
          ...request
        }, callback)
      }, 200),
    []
  )

  const filterRes = (res: any) => {
    return res.filter((item: any) => {
      return ((item.types.includes(filterType)) && (item.description.includes(includesIn)))
    })
  }

  // eslint-disable-next-line complexity
  useEffect(() => {
    let active = true

    if (!autocompleteService.current && window.google && window.google.maps.places) {
      autocompleteService.current = new window.google.maps.places.AutocompleteService()
    }
    if (!autocompleteService.current) {
      return undefined
    }

    if (inputValue === '') {
      setOptions(value ? [value] : [])

      return undefined
    }

    setLoading(true)
    fetch({ input: inputValue }, (results: any) => {
      if (active) {
        let newOptions: any[] = []

        if (value) {
          newOptions = [value]
        }

        if (results) {
          const res = filterType ? filterRes(results) : results

          newOptions = [...newOptions, ...res]
        }

        setOptions(newOptions)
        setLoading(false)
      }
    })

    return () => {
      active = false
    }
  }, [value, inputValue, fetch])

  return [options, loading]
}
