import React from 'react'
import numeral from 'numeral'
import { Grid } from '@material-ui/core'

import AssetValueInfo from '~/components/modules/AssetValueInfo'

export interface Props {
  price: number | string
  rooms: number | string
  squareSize: number | string
}

const AssetDetailsMandatoryInfo: React.FC<Props> = ({ rooms, squareSize, price }) => {
  return (
    <Grid container alignItems='flex-start' spacing={3}>
      <Grid item>
        <AssetValueInfo label='חדרים' value={rooms} />
      </Grid>
      <Grid item>
        <AssetValueInfo label='מ״ר' value={squareSize} />
      </Grid>
      <Grid item style={{ marginRight: 'auto' }}>
        <AssetValueInfo label='מחיר' value={`${numeral(price).format('0,0')} ₪`} />
      </Grid>
    </Grid>
  )
}

export default AssetDetailsMandatoryInfo
