import React from 'react'
import numeral from 'numeral'
import { makeStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import Grid from '@material-ui/core/Grid'
import CardActionArea from '@material-ui/core/CardActionArea'
import CardContent from '@material-ui/core/CardContent'
import GridListTileBar from '@material-ui/core/GridListTileBar'

import { SearchLead } from '~/types/Search'
import Typography from '~/components/core/Typography'
import ImagesGallery from '~/components/modules/ImageGallery'
import SearchLeadLabels from '~/components/modules/SearchLeadLabels'
import PercentageBox from '~/components/modules/PercentageBox'
import * as S from './styles'
import WideAssetCard from './Wide'
import AssetCardActions from './Actions'

const useStyles = makeStyles(S.AssetCardStyled)

export interface Props {
  item: SearchLead
  wide?: boolean
  onMouseLeave?: () => void
  onMouseEnter?: () => void
  allowFullScreen?: boolean
  onClick?: (id: string) => void
  onRemove?: (id: string) => void
}

const AssetCard: React.FC<Props> = ({ item, wide, onMouseLeave, onMouseEnter, allowFullScreen = true, onClick = () => {}, onRemove }) => {
  const classes = useStyles()

  if (wide) {
    return (
      <WideAssetCard item={item} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} allowFullScreen={allowFullScreen} onClick={() => onClick(item.leadId)} onRemove={onRemove} />
    )
  }

  return (
    <Card className={classes.root} onClick={() => onClick(item.leadId)}>
      <CardActionArea component='div'>
        <div style={{ position: 'relative' }}>
          <SearchLeadLabels isNew={item.isNew} deviation={item.deviation} />
          <ImagesGallery
            images={item.images}
            allowFullScreen={allowFullScreen}
          />
          <GridListTileBar
            title={item.address}
            classes={{
              root: classes.titleBar
            }}
          />
        </div>
        <CardContent className={classes.cardContent}>
          <Grid container spacing={1}>
            <Grid item container className={classes.infoItems}>
              <Grid container alignItems='stretch' justify='space-between'>
                <Grid item>
                  <Typography size='medium' gutterBottom variant='h5'>
                    {item.rooms} חדרים
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography size='medium' gutterBottom variant='h5'>
                    {item.squareSize} מ״ר
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography size='medium' gutterBottom variant='h5'>
                    {numeral(item.price).format('0,0')} ₪
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <PercentageBox percentage={item.percentage} />
              {/* <Grid container alignItems='center' spacing={1}>
                <Grid item>
                  <Avatar variant='rounded' aria-label='percentage' className={classes.avatar}>
                    {Math.round(item.percentage * 100)}%
                  </Avatar>
                </Grid>
                <Grid item>
                  <Typography size='medium'>
                התאמה לחיפוש המתקדם שלך
                  </Typography>
                </Grid>
              </Grid> */}
            </Grid>
          </Grid>
        </CardContent>
      </CardActionArea>
      <AssetCardActions item={item} onRemove={onRemove} />
    </Card>
  )
}

export default AssetCard
