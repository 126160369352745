import { useEffect, useState } from 'react'

const isBottomElm = (): boolean => (window.innerHeight + window.scrollY) >= document.body.offsetHeight

export default function useScrollToBottom(): [boolean] {
  const [isBottom, setIsBottom] = useState<boolean>(false)

  const handleOnScroll = () => {
    setIsBottom(isBottomElm())
  }

  useEffect(() => {
    window.addEventListener('scroll', handleOnScroll)

    return () => window.removeEventListener('scroll', handleOnScroll)
  }, [])

  return [isBottom]
}
