import React from 'react'
import Grid from '@material-ui/core/Grid'

import Typography from '~/components/core/Typography'

export interface Props {
  label: string | React.ReactNode
  value: string | React.ReactNode
  icon?: React.ReactNode
}

const AssetDetailRawField: React.FC<Props> = ({ label, value, icon }) => {
  return (
    <Grid container alignItems='center' wrap='nowrap'>
      <Grid item sm={4} container wrap='nowrap'>
        <Grid container wrap='nowrap' spacing={1}>
          {icon && (
            <Grid item>
              {icon}
            </Grid>
          )}
          <Grid item container alignItems='center'>
            <Typography size='medium' weight='normal'>
              {label}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item sm={8} alignItems='stretch' container wrap='nowrap'>
        <Typography size='small' style={{ width: '100%' }}>
          {value}
        </Typography>
      </Grid>
    </Grid>
  )
}

export default AssetDetailRawField
