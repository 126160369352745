import { useState, useEffect } from 'react'

import { MinimalAsset, NewLeads } from '~/types/Asset'

export default function useNewLeadsCache(assets: MinimalAsset[] | undefined): [(NewLeads[] | null), (assetId: string) => void] {
  const [newLeads, setNewLeads] = useState<NewLeads[] | null>(null)

  useEffect(() => {
    if (assets && !newLeads) {
      const newLeadObj = assets.map(({ id, numberOfNewLeads }) => ({ id, numberOfNewLeads })).filter(({ numberOfNewLeads }) => numberOfNewLeads)

      setNewLeads(newLeadObj)
    }
  }, [assets])

  const resetAssetNewLeads = (assetId: string) => {
    if (!assetId) {
      return
    }

    const newLeadObj = (newLeads || []).filter(({ id }) => id !== assetId)

    setNewLeads(newLeadObj)
  }

  return [newLeads, resetAssetNewLeads]
}
