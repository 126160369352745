import React from 'react'

import { PaymentStatus, PaymentType } from '~/types/Payment'

interface Props {
  status?: PaymentStatus
  primiumDaysLeft?: number
  currentPlan?: PaymentType
  loading: boolean
  refetch: (route?: string | undefined) => Promise<any>
  currentAssetId: string
  remainingNumberOfClicks: number
}

export const PaymentsProvider = React.createContext({} as Props)
