import React from 'react'

import { SearchLead } from '~/types/Search'

interface Props {
  searchFavorites?: SearchLead[]
  searchFavoritesLoading: boolean
  refetchSearchFavorites: (route?: string | undefined) => Promise<any>
  removeFavoriteState: (leadId: string) => void
  addFavoriteState: (leadId: SearchLead) => void
}

export const ViewerFavoriteContext = React.createContext({} as Props)
