import React from 'react'

import { MinimalAsset, NewLeads } from '~/types/Asset'
import { MinimalSearch } from '~/types/Search'

interface Props {
  searches?: MinimalSearch[]
  assets?: MinimalAsset[]
  userDetails?: any
  refetchSearches: (route?: string | undefined) => Promise<any>
  refetchAssets: (route?: string | undefined) => Promise<any>
  refetchUserDetails: () => Promise<any>
  refetchLoading: boolean
  resetAssetNewLeads: (assetId: string) => void
  newAssetLeads: NewLeads[] | null
  newSearchLeads: NewLeads[] | null
  resetSearchNewLeads: (searchId: string) => void
}

export const ViewerProvider = React.createContext({} as Props)
